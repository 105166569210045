import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filterTypes'
})
export class FilterTypesPipe implements PipeTransform {
	transform(moreHoursTypes: any[], groupedMoreHours: any[]): any[] {
		const existingTypes = groupedMoreHours.map((item: any) => item.hoursTypeId);
		return moreHoursTypes.filter(type => !existingTypes.includes(type.hoursTypeId));
	}
}

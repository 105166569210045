import { Injectable } from '@angular/core';
import { CalendarService } from 'src/app/services/utilities/calendar.service';
import { BaseComponent } from './base.component';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PostService } from 'src/app/services/repositories/post.service';
import { CALENDERMODE } from 'src/app/interfaces/CalendarMode';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';
import { IResponse } from 'src/app/interfaces/IResponse';
import { Debug } from 'src/app/utils/debug';

@Injectable()
export class CalenderBaseComponent extends BaseComponent {
  fullHourPannelData!: any;
  isFullHourPannelVisible: boolean = false;
  calendarSubscription!: Subscription;
  calendarModeSubscription!: Subscription;
  currentSelectedHourSubscription!: Subscription;
  modeSubscription!: Subscription;

  today!: Date;
  mode: CALENDERMODE = CALENDERMODE.YEAR;
  data: any = [];
  currentYear!: number;
  currentDate!: Date;
  currentSelectedHour!: string;

  currentPlaTformsFilters: any = {
    isFacebookON: true,
    isLinkedinON: true,
    isGoogleON: true,
    isInstagramON: true,
  };

  isPublished: boolean = true;
  isPlanned: boolean = true;
  constructor(
    translationService: TranslationService,
    protected calendarService: CalendarService,
    protected postService: PostService,
    protected uiStateService: UiStateService
  ) {
    super(translationService);
  }

  sortPostsByTime(posts: any[]): any[] {
    const getNonNullDate = (post: any) =>
      post.publishingDatetime !== null
        ? post.publishingDatetime
        : post.expectedPublishingDatetime;

    // Create a new array based on a sorted version of `this.posts`
    // Debug.log(a.title,dateA,b.title,dateB,dateB - dateA);
    const sortedPosts = [...posts].sort((a, b) => {
      const dateA = new Date(getNonNullDate(a)).getTime();
      const dateB = new Date(getNonNullDate(b)).getTime();
      return dateA - dateB;
    });
    // Assign the sorted array back to `this.posts` to trigger change detection
    return sortedPosts;
  }

  weekPosts: any = null;
  monthPosts: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  dayPosts: any = null;
  yearPosts: any = null;

  getYearPosts() {
    const groupId = this.postService.currentGroupSource.getValue().id;
    if (groupId != 0) {
      this.postService
        .getYearPosts(this.currentYear, [groupId])
        .subscribe((res) => {
          this.yearPosts = res;
          this.getPostsToDates(this.yearPosts);
        });
    }
  }

  getMonthPosts() {
    const groupIds: number[] = this.postService.currentGroupsSource.getValue();
    if (groupIds.length > 0) {
      let dateStr =
        this.calendarService.currentDate.getFullYear() +
        '-' +
        (this.calendarService.currentDate.getMonth() + 1) +
        '-' +
        this.calendarService.currentDate.getDate();
      this.postService
        .getByTimeFrame(groupIds, dateStr, 'month')
        .subscribe((res: IResponse) => {
          const posts = this.postService.mergePostsBySocialPlatform(res.data);
          Debug.log('posts', posts);
          this.monthPosts.next(posts); // Emitting new value through BehaviorSubject
        });
    }
  }
  groupScopes: number[] = [];
  ngOnInit(): void {
    // this.postService.currentGroups.subscribe((groups) => {

    //   this.groupScopes = groups;
    //   this.postService.updateGroups(this.groupScopes)
    //   Debug.log('selected groups updated', this.groupScopes);

    //    if (this.groupScopes.length> 0) {
    //       this.postService
    //         .getYearPosts(this.calendarService.currentDate.getFullYear(), this.groupScopes)
    //         .subscribe((data) => {
    //           this.getPostsToDates(data);
    //         });
    //     }
    // });
    this.calendarSubscription = this.calendarService
      .getCurrentDate()
      .subscribe((date: Date) => {
        this.currentDate = date;
        this.currentYear = this.currentDate.getFullYear();
      });

    this.calendarModeSubscription = this.calendarService
      .getCurrentCalendarMode()
      .subscribe((calendarMode: CALENDERMODE) => {
        this.mode = calendarMode;
      });

    this.uiStateService.platformsFilterUpdated$.subscribe((data) => {
      // Debug.log(data)
      this.currentPlaTformsFilters = data;
    });

    this.uiStateService.postStateFilterUpdated$.subscribe((newFilter) => {
      // Debug.log('Post state filter updated:', newFilter);
      // Handle the updated filter
      this.isPublished = newFilter.isPublished;
      this.isPlanned = newFilter.isPlanned;
    });

    // Subscribe to the currentSelectedHour observable
    this.currentSelectedHourSubscription = this.calendarService
      .getcurrentSelectedHour()
      .subscribe((hour) => {
        this.currentSelectedHour = hour;
        // Do something with the new hour
      });
  }

  showFullHourPreview(data: any) {
    // Debug.log("need to show and update full hour pannel with ",data)
    this.isFullHourPannelVisible = true;

    this.fullHourPannelData = this.sortPostsByTime(data);
  }
  handleVisibilityChange(isVisible: boolean) {
    Debug.log('visibility', isVisible);
    this.isFullHourPannelVisible = isVisible;
  }

  getPostsToDates(data: any) {
    for (let res of data) {
      let resMonth = this.getmonthString(new Date(res.date));
      let resDay = new Date(res.date).getDate();

      for (let m of this.data) {
        if (resMonth === m.month) {
          for (let week of m.content) {
            // Debug.log(res)
            // Debug.log(week)
            for (let d of week) {
              if (d.day === resDay) {
                d.data = {
                  hasPublished: res.publishedCount > 0,
                  hasPlanned: res.expectedPublishCount > 0,
                  platforms: res.platforms,
                };
              }
            }
          }
        }
      }
    }
  }

  getmonthString(date: Date): string {
    return new Date(2000, date.getMonth()).toLocaleString('fr-FR', {
      month: 'long',
    });
  }

  ngOnDestroy(): void {
    if (this.calendarSubscription) {
      this.calendarSubscription.unsubscribe();
    }

    if (this.calendarModeSubscription) {
      this.calendarModeSubscription.unsubscribe();
    }

    if (this.modeSubscription) {
      this.modeSubscription.unsubscribe();
    }

    if (this.currentSelectedHourSubscription) {
      this.currentSelectedHourSubscription.unsubscribe();
    }
  }

  getHourLabel(hour: number): string {
    const local = this.translationService.getLanguage();
    //handle formatting hours as am and pm when local is en
    if (local == 'en') {
      if (hour + 1 <= 12) {
        return (hour + 1).toString().padStart(2, '0') + ':00' + 'AM';
      } else {
        return (hour + 1 - 12).toString().padStart(2, '0') + ':00' + 'PM';
      }
    }
    return (
      (hour + 1 != 24 ? (hour + 1).toString().padStart(2, '0') : '00') + ':00'
    );
  }

  isLocalSetToEN(): boolean {
    return this.translationService.getLanguage() === 'en';
  }
}

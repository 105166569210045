<!--
  This example requires updating your template:

  ```
  <html class="h-full">
  <body class="h-full">
  ```
-->
<main class="grid min-h-full place-items-center  px-6 py-24 sm:py-32 lg:px-8">
	<div class="text-center">
		<img class="mx-auto h-20 w-auto" src="assets/img/logo.png" alt="Cliking social media">
		<p class="text-base font-semibold text-indigo-600">500</p>
		<h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl c-accent-2">{{"error.genericError" | translate}}</h1>
		<p class="mt-6 text-base leading-7 text-gray-600">{{"error.contactTeam" | translate}}</p>
		<div class="mt-10 flex items-center justify-center gap-x-6">
		</div>
	</div>
</main>

<div class="m-4 rounded-lg">
  <div class="flex justify-between items-center w-full p-4 border border-gray-300 rounded bg-white cursor-pointer" (click)="toggleDropdown()">
    <div>
      <span class="text-lg font-bold">{{ 'pages.hours.' + selectedStatus | translate }}</span>
      <span class="block text-sm text-gray-500">{{ 'pages.hours.' + description | translate }}</span>
    </div>
    <i [ngClass]="{'transform rotate-180': isOpen, 'transform rotate-0': !isOpen}" class="transition-transform duration-200">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
      </svg>
    </i>
  </div>
  <div class="border border-gray-300 rounded-t-none rounded-b bg-white mt-1 rounded-lg" *ngIf="isOpen">
    <div *ngFor="let option of options" class="p-4 cursor-pointer hover:bg-gray-100" (click)="selectStatus(option.status)">
      <span class="text-lg font-bold">{{ 'pages.hours.' + option.label | translate }}</span>
      <span class="block text-sm text-gray-500">{{ 'pages.hours.' + option.description | translate }}</span>
    </div>
  </div>
</div>

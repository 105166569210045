import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-google-fav-icon',
  templateUrl: './google-fav-icon.component.html',
  styleUrls: ['./google-fav-icon.component.scss']
})
export class GoogleFavIconComponent {
@Input() color: string="#FFFFFF"
@Input() size: string="20px"
@Input() showLabel=false
}
<div
  class="overlay-wrapper relative w-full h-auto rounded-b-2xl rounded-t-2xl border border-[2px] bg-white mt-5"
  *ngIf="canShowPreview && filterValue()"
  [ngClass]="{
    'border-dark_blue': displayAs == 'post',
    'border-cliking_green': displayAs == 'draft',
    'border-cliking_yellow': displayAs == 'planned'
  }"
  style="z-index: 0"
>
  <!-- content -->
  <div #content class="content">
    <div
      class="px-2 py-1 text-center rounded-t-xl text-sm text-white"
      [ngClass]="{
        'bg-dark_blue': displayAs == 'post',
        'bg-cliking_green': displayAs == 'draft',
        'bg-cliking_yellow': displayAs == 'planned'
      }"
    >
      {{
        post.title.length > 20
          ? post.title.substring(0, 20) + "..."
          : post.title
      }}
    </div>
    <div
      class="text-center text-xs text-white"
      [ngClass]="{
				'bg-light_purple':displayAs=='post',
				'bg-[rgba(29,201,183,0.75)]':displayAs=='draft',
				'bg-[rgba(255,184,34,0.75)]':displayAs=='planned',
			}"
    >
      {{ "generic.on" | translate }} {{ getPostGroupsNamesList() }}
    </div>

    <div class="text-xs flex justify-center items-center mt-2">
      <div class="m-2">
        <div class="grid grid-row-2 grid-cols-6 m-auto">
          <div class="col-span-2 row-span-2">
            <img
              class="m-auto w-12 border border-1 border-gray-300 rounded-xl mb-2"
              src="{{ apiUrl }}/assets/company-icon.png"
              alt="{{ post.Group }}"
            />
          </div>
          <div class="col-span-3 row-span-2">
            <div class="mb-2 grid grid-cols-2 gap-1 mt-1">
              <!-- views stats button -->
              <button
                class="bg-dark_blue text-white rounded-md flex items-center justify-center w-16"
                title="{{ post.views }}"
              >
                <img
                  class="w-3 mr-2"
                  src="assets/img/label-icons/view.png"
                  alt=""
                />
                {{ formatPostStats(post.views) }}
              </button>
              <!-- likes button -->
              <button
                class="bg-dark_blue text-white rounded-md flex items-center justify-center w-16"
                title="{{ post.clicks }}"
              >
                <img
                  class="w-3 mr-2"
                  src="assets/img/label-icons/like.png"
                  alt=""
                />
                {{ formatPostStats(post.likeCount) }}
              </button>
              <!-- comment button -->
              <button
                class="bg-dark_blue text-white rounded-md flex items-center justify-center w-16"
                title="{{ post.comments }}"
              >
                <img
                  class="w-3 mr-2"
                  src="assets/img/label-icons/comment.png"
                  alt=""
                />
                {{ formatPostStats(post.comments) }}
              </button>
              <!-- share button -->
              <button
                class="bg-dark_blue text-white rounded-md flex items-center justify-center w-16"
                title="{{ post.shares }}"
              >
                <img
                  class="w-3 mr-2"
                  src="assets/img/label-icons/share.png"
                  alt=""
                />
                {{ formatPostStats(post.shares) }}
              </button>
            </div>
          </div>
        </div>
        <p class="pannel-trigger col-span-4">
          {{
            post.text.length > 255
              ? post.text.substring(0, 255) + "..."
              : post.text
          }}
        </p>
      </div>
    </div>

    <!-- post image -->
    <div class="m-0 p-0" *ngIf="mainMedia">
      <video
        *ngIf="mediaIsVideo(mainMedia)"
        controls
        [src]="mainMedia.url"
      ></video>
      <img
        *ngIf="!mediaIsVideo(mainMedia)"
        [src]="mainMedia.url"
        alt=""
        class="w-full"
      />
    </div>

    <!-- time and platform section -->
    <div class="flex justify-center w-full items-center m-auto">
      <div
        class="flex items-center"
        [ngClass]="{
          'text-light_purple ': displayAs == 'post',
          'text-cliking_green ': displayAs == 'draft',
          'text-cliking_yellow': displayAs == 'planned'
        }"
      >
        <p class="text-[12px] mt-2 font-medium text-center">
          {{ calculatePastTimeFromNow() }}
        </p>
      </div>
      <div class="ml-4 mt-2 mb-2"></div>
    </div>
    <div class="flex justify-center">
      <span
        class="mx-3 my-2 flex items-center"
        *ngFor="let platform of post.SocialPlatforms"
      >
        <img
          class="rounded-[3px] w-16"
          [ngClass]="{
            'bg-sky-600':
              platform.source == 'facebook' &&
              currentPlaTformsFilters.isFacebookON,
            'bg-orange-600':
              platform.source == 'google' && currentPlaTformsFilters.isGoogleON,
            'bg-blue-600':
              platform.source == 'linkedin' &&
              currentPlaTformsFilters.isLinkedinON,
            'bg-pink-600':
              platform.source == 'instagram' &&
              currentPlaTformsFilters.isInstagramON
          }"
          src="assets/img/{{ platform.source }}-label.png"
          alt="{{ platform.source }}"
        />
      </span>
    </div>
  </div>

  <!-- overlay -->
  <div
    #overlay
    class="overlay absolute rounded-2xl w-full h-full flex flex-col items-center justify-center"
    [ngClass]="{
			'bg-[rgba(70,80,134,0.75)]':displayAs=='post',
			'bg-[rgba(29,201,183,0.5)]':displayAs=='draft',
			'bg-[rgba(255,184,34,0.5)]':displayAs=='planned',
		}"
  >
    <!-- <div *ngIf="displayAs=='post'"
		class="text-base font-medium w-[60%] bg-gray-300 text-center text-white opacity-1 py-2 rounded-md shadow-md shadow-slate-700 cursor-not-allowed disabled"

		[title]="'generic.comingSoon'|translate"
		(click)="useAsNewPostTemplate()"
		>
		{{ 'posts.copy' | translate}}

		</div> -->
    <button
      *ngIf="displayAs == 'post'"
      class="text-base font-medium w-[60%] text-center text-white opacity-1 py-2 rounded-md shadow-md shadow-slate-700"
      (click)="useAsNewPostTemplate()"
      [title]="'generic.comingSoon' | translate"
      [disabled]="!user?.isAdmin"
      [ngClass]="{
        'cursor-pointer bg-[rgba(70,80,134,0.75)]': user?.isAdmin,
        'cursor-not-allowed disabled bg-gray-300': !user?.isAdmin
      }"
    >
      {{ "posts.copy" | translate }}
    </button>
    <button
      *ngIf="displayAs == 'planned'"
      class="text-base font-medium w-[60%] text-center text-white opacity-1 py-2 rounded-md shadow-md shadow-slate-700"
      (click)="useAsNewPostTemplate()"
      [title]="'generic.comingSoon' | translate"
      [disabled]="!user?.isAdmin"
      [ngClass]="{
        'cursor-pointer bg-[rgba(255,184,34,0.75)]': user?.isAdmin,
        'cursor-not-allowed disabled bg-gray-300': !user?.isAdmin
      }"
    >
      {{ "posts.copy" | translate }}
    </button>
    <div
      *ngIf="displayAs == 'draft'"
      class="text-base font-medium w-[60%] bg-cliking_green text-center text-white opacity-1 py-2 rounded-md shadow-md shadow-slate-700 cursor-pointer"
      (click)="useAsNewPostTemplate()"
    >
      {{ "posts.useThisDraft" | translate }}
    </div>

    <button
      *ngIf="displayAs == 'post'"
      (click)="postDetails()"
      class="mt-4 text-base w-[60%] font-medium text-center text-white opacity-1 py-2 rounded-md shadow-md shadow-slate-700"
      [ngClass]="{
        'cursor-pointer bg-cliking_blue': user?.isAdmin,
        'cursor-not-allowed disabled bg-gray-300': !user?.isAdmin
      }"
      [disabled]="!user?.isAdmin"
    >
      {{ "posts.view" | translate }}
    </button>
    <button
      *ngIf="displayAs == 'planned'"
      (click)="postDetails()"
      class="mt-4 text-base w-[60%] font-medium text-center text-white opacity-1 py-2 rounded-md shadow-md shadow-slate-700"
      [ngClass]="{
        'cursor-pointer bg-cliking_yellow': user?.isAdmin,
        'cursor-not-allowed disabled bg-gray-300': !user?.isAdmin
      }"
      [disabled]="!user?.isAdmin"
    >
      {{ "posts.view" | translate }}
    </button>
    <!-- <div *ngIf="displayAs=='post'" class="cursor-not-allowed mt-4 text-base w-[60%] font-medium text-center text-white opacity-1 bg-gray-300  py-2 rounded-md   shadow-md shadow-slate-700" 
		[routerLink]="'/library/'"
		</div>          -->
    <!-- <div *ngIf="displayAs=='post'" class="cursor-not-allowed mt-4 text-base w-[60%] font-medium text-center text-white opacity-1 bg-gray-300  py-2 rounded-md   shadow-md shadow-slate-700" 
		[routerLink]="!AuthUser().isAdmin?'/library/':'/library/post-details'"
		[title]="'generic.comingSoon'| translate">
				{{'posts.view' | translate}}
		</div>   -->
    <button
      *ngIf="displayAs == 'draft'"
      class="mt-5 btn btn-circle btn-error shadow-md shadow-slate-700"
      (click)="emitOpenModal()"
    >
      <svg
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="white"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
  </div>
</div>

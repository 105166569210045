<div class="relative">
  <div #proxyInput (click)="toggleDropdown()" class="w-[70px] px-2 py-1 text-[14px] flex items-center justify-center border border-gray-300 rounded-lg px-3 py-1 text-center cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm">
    {{ value || 'hh:mm' }}
  </div>
  <div *ngIf="showDropdown" class="dropdown absolute z-10 bg-white border border-gray-300 rounded-lg mt-1 max-h-60 overflow-y-auto left-0 right-0">
    <div *ngFor="let time of timeOptions" (click)="selectTime(time)" class="px-3 py-1 cursor-pointer hover:bg-gray-200">
      {{ time }}
    </div>
  </div>
</div>

import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ElementRef,
  HostListener,
  EventEmitter,
  Output,
} from '@angular/core';

import { BaseComponent } from '../../../base/base.component';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { SocialMediaService } from 'src/app/services/repositories/social-media.service';
import { Debug } from 'src/app/utils/debug';
// Import Fuse.js if using for fuzzy search

@Component({
  selector: 'app-categories-dropdown',
  templateUrl: './categories-dropdown.component.html',
  styleUrls: ['./categories-dropdown.component.scss'],
})
export class CategoriesDropdownComponent extends BaseComponent {
  @Input() SocialMediaId: string | null = null;
  categories: Array<{ name: string; displayName: string }> = [];
  @Input() bgColorClass: string = 'bg-white';
  @Input() tailwindCss: string = '';
  @Input() selectedCategory!: { name: string; displayName: string };
  @Output() selectedCategoryChange = new EventEmitter<{
    name: string;
    displayName: string;
  }>();

  searchPattern: string = '';
  // State for open/close nested items
  public toggledItems: { [key: number]: boolean } = {};
  initialCaption: string = this.getTranslation('generic.choosePage');
  initialData: Array<{ name: string; displayName: string }> = JSON.parse(
    JSON.stringify(this.categories)
  );
  constructor(
    translationService: TranslationService,
    protected socialMediaService: SocialMediaService,
    private eRef: ElementRef
  ) {
    super(translationService);
  }

  ngOnInit(): void {
    // if (this.SocialMediaId) {
    // 	this.socialMediaService.getGooglePagesCategories(this.SocialMediaId).subscribe(res=>{
    // 		Debug.log("Categories: ", res.data.categories)
    // 		this.categories = res.data.categories
    // 	})
    // }
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: any): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  }

  isDropdownOpen = false;
  toggleDropdown(): void {
    // if(this.categories.length>0)
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  toggleSelect(item: any): void {
    this.selectedCategory = item;
    this.selectedCategoryChange.emit(this.selectedCategory);
    Debug.log('this.selectedCategory from dropdown:', this.selectedCategory);
  }

  searchCategories(query: string): void {
    if (this.SocialMediaId) {
      if (query == '') {
        this.categories = [];
      } else {
        this.socialMediaService
          .getGooglePagesCategories(this.SocialMediaId, query)
          .subscribe((res) => {
            Debug.log('Categories: ', res.data.categories);
            this.categories = res.data.categories;
          });
      }
    }
  }
}

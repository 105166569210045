import { Component } from '@angular/core';
import Swal from 'sweetalert2';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { PostService } from 'src/app/services/repositories/post.service';
import { SocialMediaService } from 'src/app/services/repositories/social-media.service';
import { AuthService } from 'src/app/services/utilities/auth.service';
import { SOCIAL_MEDIA_PLATFORMS } from 'src/app/utils/social-media.constant';
import { GroupService } from 'src/app/services/repositories/group.service';
import { ICON } from 'src/app/interfaces/ModalIcon';
import { Debug } from 'src/app/utils/debug';

@Component({
  selector: 'app-social-media-connections',
  templateUrl: './social-media-connections.component.html',
  styleUrls: ['./social-media-connections.component.scss'],
})
export class SocialMediaConnectionsComponent {
  SOCIAL_MEDIA_PLATFORMS = SOCIAL_MEDIA_PLATFORMS;
  groupsWithSocialMedias: any;
  socialMediaCount: number = 0;
  isModalOpen: boolean = false;
  modalIcon: ICON = ICON.delete;
  choosedItem: any;
  constructor(
    private socialMediaService: SocialMediaService,
    private authenticationService: AuthService,
    private postService: PostService,
    private groupService: GroupService,
    private translationService: TranslationService
  ) {}

  accreditedGroupIds: any = [];
  userAccreditations: any = [];
  flatGroupList: any = [];
  ngOnInit(): void {
    // get the connected user
    this.authenticationService.loadCurrentUser().subscribe((user) => {
      this.userAccreditations = user.data.accreditations;
      console.log('this.userAccreditations', this.userAccreditations);

      // if (!user.data.isAdmin) {
      //   // Non-admin logic
      //   this.flatGroupList = this.recursiveAccreditedGroups(
      //     this.userAccreditations
      //   );
			// 	console.log("this.flatGroupList non-admin", this.flatGroupList);

			// 	// this.groupsWithSocialMedias = this.flatGroupList.map((g: any) => {
			// 	// 	return {
			// 	// 		group: g,
			// 	// 		socialMedias: g.socialMedia,
			// 	// 	};
			// 	// });

			// 	// this.socialMediaCount = this.groupsWithSocialMedias.reduce(
			// 	// 	(acc: number, group: any) => acc + group.socialMedias.length,
			// 	// 	0
			// 	// );

			// 	this.updateFlatGroupList(this.flatGroupList);
      //   this.postService.currentGroups.subscribe((groups: number[]) => {
      //     this.updateFlatGroupList(groups);
      //   });
      // } else {
        // Superadmin logic
        const currentGroupIds = this.postService.currentGroupsSource.getValue();

        // Ensure that the flatGroupList reflects the currentGroups initially
        this.groupService
          .getGroupByIds(currentGroupIds)
          .subscribe((res: any) => {
            this.flatGroupList = res.data; // Initialize flatGroupList based on the current groups

            this.groupsWithSocialMedias = this.flatGroupList.map((g: any) => {
              return {
                group: g,
                socialMedias: g.socialMedia,
              };
            });

            this.socialMediaCount = this.groupsWithSocialMedias.reduce(
              (acc: number, group: any) => acc + group.socialMedias.length,
              0
            );
          });

        // Listen for changes in currentGroups for superadmin as well
        this.postService.currentGroups.subscribe((groupIds: any) => {
          this.groupService.getGroupByIds(groupIds).subscribe((res: any) => {
            this.flatGroupList = res.data;

            this.groupsWithSocialMedias = this.flatGroupList.map((g: any) => {
              return {
                group: g,
                socialMedias: g.socialMedia,
              };
            });

            this.socialMediaCount = this.groupsWithSocialMedias.reduce(
              (acc: number, group: any) => acc + group.socialMedias.length,
              0
            );
          });
        });
      // }

    });

  }

  updateFlatGroupList(groups: number[]): void {
    // Filter flatGroupList based on current groups
    this.flatGroupList = this.recursiveAccreditedGroups(
      this.userAccreditations
    );

    // Filter the groups based on the current group ids
    this.flatGroupList = this.flatGroupList.filter((g: any) =>
      groups.includes(g.id)
    );

    // Update groupsWithSocialMedias and socialMediaCount
    this.groupsWithSocialMedias = this.flatGroupList.map((g: any) => {
      return {
        group: g,
        socialMedias: g.socialMedia,
      };
    });

    this.socialMediaCount = this.groupsWithSocialMedias.reduce(
      (acc: number, group: any) => acc + group.socialMedias.length,
      0
    );
  }

  FilterParent(parentId: number) {
    const parent = this.flatGroupList.filter((group: any) => {
      return group.id == parentId;
    })[0];

    let groups = this.flatGroupList.filter((group: any) => {
      return !parent.ancestry.includes(group.name);
    });

    return groups;
  }

  recursiveAccreditedGroups(accreditations: any): any {
    let result = [];

    for (let accreditation of accreditations) {
      result.push(accreditation.group);

      if (
        accreditation.group &&
        accreditation.group.children &&
        accreditation.group.children.length > 0
      ) {
        const transformedChildren = accreditation.group.children.map(
          (child: any) => ({ group: child })
        );

        result = [
          ...result,
          ...this.recursiveAccreditedGroups(transformedChildren),
        ];
      }
    }
    //removing any duplicates
    const seenIds = new Set();
    result = result.filter((item) => {
      if (seenIds.has(item.id)) {
        return false;
      } else {
        seenIds.add(item.id);
        return true;
      }
    });

    result;
    return result;
  }

  deleteSocialMedia(socialMedia: any) {
    this.socialMediaService.deleteSocialMedia(socialMedia.id).subscribe(
      () => {
        /* Swal.fire({
        title: this.translationService.t('generic.deleted'),
        icon: 'success',
        showConfirmButton: false,
        timer: 1000,
      })
 */
        // Remove the social media from the groupsWithSocialMedias array
        this.groupsWithSocialMedias.forEach((group: any) => {
          group.socialMedias = group.socialMedias.filter(
            (sm: any) => sm.id !== socialMedia.id
          );
          this.close();
        });
      } /* , error => {
      Swal.fire(
        'Error!',
        `There was an error deleting the social media: ${error}`,
        'error'
      );
    }*/
    );
  }

  //close modal
  close() {
    this.isModalOpen = false;
  }

  // delete item
  delete() {
    this.deleteSocialMedia(this.choosedItem);
  }

  openModal(item: any) {
    this.isModalOpen = true;
    this.choosedItem = item;
  }
}

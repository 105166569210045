import { Component } from '@angular/core';
import { AuthService } from './services/utilities/auth.service';
import {TranslationService} from './services/utilities/translation.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private translationService: TranslationService,public router: Router) {
    this.translationService.setLanguage('fr'); // Set your default language
  }
  title = 'Cliking Social Media';
  ngOnInit() {

  }
}

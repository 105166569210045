import { Component } from '@angular/core';
import { PostService } from 'src/app/services/repositories/post.service';
import { Subscription } from 'rxjs';
import { GroupService } from 'src/app/services/repositories/group.service';
import { IResponse } from 'src/app/interfaces/IResponse';
import { ScreenSizeService } from 'src/app/services/utilities/screen-size.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  data: any[] = [
    {"label": "dashboard.numberOfPublications", "value":0},
    {"label": "dashboard.numberOfViews", "value":'---'},
    {"label": "dashboard.reactionsObtained", "value":'---'},
    {"label": "dashboard.commentsReceived", "value":'---'},
    {"label": "dashboard.sharesMade", "value":'---'},
    {"label": "dashboard.interactions", "value":'---'},
    {"label": "dashboard.reach", "value":'---'},
  ];
  private subs = new Subscription();
  socialMedias: any[] = [];
  linkedinData: any[] = [];
  googleData: any[] = [];
  facebookData: any[] = [];
  screenWidth: number = 0;
  screenHeight: number = 0;
  instagramData: any[] = [{"label": "dashboard.numberOfPublications", "value":'---'},
    {"label": "dashboard.numberOfViews", "value":'---'},
    {"label": "dashboard.reactionsObtained", "value":'---'},
    {"label": "dashboard.commentsReceived", "value":'---'},
    {"label": "dashboard.sharesMade", "value":'---'},
    {"label": "dashboard.interactions", "value":'---'},
    {"label": "dashboard.reach", "value":'---'},];

  constructor(protected postService: PostService,
    private groupService: GroupService,private screenSizeService: ScreenSizeService){}

  ngOnInit(){
    this.screenSizeService.screenWidth$.subscribe((width: number) => this.screenWidth = width);
    this.screenSizeService.screenHeight$.subscribe((height: number) => this.screenHeight = height);
    this.subs.add(
      this.postService.currentGroups.subscribe((g: number[]) => {
        if (g.length == 0)
          g = this.postService.getGroupScopesFromLocalStorage();
        this.linkedinData = [{"label": "dashboard.numberOfPublications", "value":0},
          {"label": "dashboard.impressionCount", "value":0},
          {"label": "dashboard.reactionsObtained", "value":0},
          {"label": "dashboard.commentsReceived", "value":0},
          {"label": "dashboard.sharesMade", "value":0},
          {"label": "dashboard.interactions", "value":0},
          {"label": "dashboard.reach", "value":0},];
        this.facebookData = [ {"label": "dashboard.numberOfPublications", "value":0},
          {"label": "dashboard.impressionCount", "value":0},
          {"label": "dashboard.reactionsObtained", "value":0},
          {"label": "dashboard.commentsReceived", "value":0},
          {"label": "dashboard.sharesMade", "value":0},
          {"label": "dashboard.interactions", "value":0},
          {"label": "dashboard.reach", "value":0},
          {"label": "dashboard.pageFanAdds", "value":0},
          {"label": "dashboard.pageFanRemoves", "value":0},];
        this.googleData =  [{"label": "dashboard.numberOfPublications", "value":0},
          {"label": "dashboard.CallClicks", "value":0},
          {"label": "dashboard.WebsiteClicks", "value":0},
          {"label": "dashboard.BusinessBookings", "value":0},
          {"label": "dashboard.BusinessFoodOrders", "value":0},
          {"label": "dashboard.BusinessConversations", "value":0},
          {"label": "dashboard.BusinessFoodMenuClicks", "value":0},
          {"label": "dashboard.BusinessDirectionRequests", "value":0},
          {"label": "dashboard.BusinessImpressionsMobileMaps", "value":0},
          {"label": "dashboard.BusinessImpressionsDesktopMaps", "value":0},
          {"label": "dashboard.BusinessImpressionsMobileSearch", "value":0},
          {"label": "dashboard.BusinessImpressionsDesktopSearch", "value":0}]
        
        if (g.length > 0) {
          this.groupService.getGroupByIds(g).subscribe((res: IResponse) => {
            this.getRecursiveSocialMediaPages(
              res.data
            );
            this.socialMedias.forEach((item: any)=>{
              if(item.source == 'linkedin' && item.analytics ){
                this.linkedinData[0].value += this.gVal(item.analytics?.numberOfPublications);
                this.linkedinData[1].value += this.gVal(item.analytics?.impressionCount);
                this.linkedinData[2].value += this.gVal(item.analytics?.likeCount);
                this.linkedinData[3].value += this.gVal(item.analytics?.commentCount);
                this.linkedinData[4].value += this.gVal(item.analytics?.shareCount);
                this.linkedinData[5].value += this.gVal(item.analytics?.clickCount);
                this.linkedinData[6].value += this.gVal(item.analytics?.engagement);
              }
              if(item.source == 'facebook' && item.analytics){
                item.analytics.forEach((element:any)=>{
                  console.log("element", element);
                  if(element.name =='number_of_publications')
                    this.facebookData[0].value += this.gVal(element.value);
                  if(element.name =='page_posts_impressions')
                    this.facebookData[1].value += this.gVal(element.value);
                  if(element.name =='page_actions_post_reactions_total'){
                      this.facebookData[2].value += this.gVal(Object.values(element.value).reduce((accumulator: any, currentValue: any) => {
                        return accumulator + currentValue;
                      }, 0));
                    }
                    if(element.name =='total_comments_on_page')
                      this.facebookData[3].value += this.gVal(element.value);
                    if(element.name =='total_shares_on_page')
                      this.facebookData[4].value += this.gVal(element.value);
                    if(element.name =='page_total_actions')
                      this.facebookData[5].value += this.gVal(element.value);
                    if(element.name =='page_post_engagements')
                      this.facebookData[6].value += this.gVal(element.value);
                    if(element.name =='page_fan_adds')
                      this.facebookData[7].value += this.gVal(element.value)
                    if(element.name =='page_fan_removes')
                      this.facebookData[8].value += this.gVal(element.value)
                  })
              }
              if(item.source == 'google' && item.analytics){
                this.googleData[0].value += this.gVal(item.analytics?.numberOfPublications);
                this.googleData[1].value += this.gVal(item.analytics?.WEBSITE_CLICKS);
                this.googleData[2].value += this.gVal(item.analytics?.BUSINESS_BOOKINGS);
                this.googleData[3].value += this.gVal(item.analytics?.BUSINESS_FOOD_ORDERS);
                this.googleData[4].value += this.gVal(item.analytics?.CALL_CLICKS);
                this.googleData[5].value += this.gVal(item.analytics?.BUSINESS_CONVERSATIONS);
                this.googleData[6].value += this.gVal(item.analytics?.BUSINESS_FOOD_MENU_CLICKS);
                this.googleData[7].value += this.gVal(item.analytics?.BUSINESS_DIRECTION_REQUESTS);
                this.googleData[8].value += this.gVal(item.analytics?.BUSINESS_IMPRESSIONS_MOBILE_MAPS);
                this.googleData[9].value += this.gVal(item.analytics?.BUSINESS_IMPRESSIONS_DESKTOP_MAPS);
                this.googleData[10].value += this.gVal(item.analytics?.BUSINESS_IMPRESSIONS_MOBILE_SEARCH);
                this.googleData[11].value += this.gVal(item.analytics?.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH);
              }
            })
            this.linkedinData[6].value = parseFloat((this.linkedinData[6].value* 100).toFixed(2));
            
            let longestLenghtData = Math.max(this.googleData.length, this.facebookData.length, this.linkedinData.length, this.instagramData.length);
            let shortestLenghtData = Math.min(this.googleData.length, this.facebookData.length, this.linkedinData.length, this.instagramData.length);
            this.data[0].value = this.facebookData[0].value + this.linkedinData[0].value + this.googleData[0].value
            for(let i = 0; i< longestLenghtData - shortestLenghtData ; i++){
              if(this.googleData.length != longestLenghtData)
                this.googleData.push({"label": "", "value":''})
              if(this.facebookData.length != longestLenghtData)
                this.facebookData.push({"label": "", "value":''})
              if(this.linkedinData.length != longestLenghtData)
                this.linkedinData.push({"label": "", "value":''})
              if(this.instagramData.length != longestLenghtData)
                this.instagramData.push({"label": "", "value":''})
            }
          });
        }
      })
    );
  }

  getRecursiveSocialMediaPages(groups: any[]): any[] {
    groups = !Array.isArray(groups) ? [groups] : groups;
    let socialMediaPages: any[] = [];
    groups.forEach((group: any) => {
      // Add the group's social media pages if any
      if (group.socialMedia && group.socialMedia.length > 0) {
        socialMediaPages = [...socialMediaPages, ...group.socialMedia];
      }
      // Recursively add social media pages from children
      if (group.children && group.children.length > 0) {
        for (let child of group.children) {
          socialMediaPages = [
            ...socialMediaPages,
            ...this.getRecursiveSocialMediaPages(child),
          ];
        }
      }
    });
    //removing any duplicates
    const pagesArrayString: string[] = socialMediaPages.map((sm: any) =>
      JSON.stringify(sm)
    );
    const uniquePagesArrayString = [...new Set(pagesArrayString)];
    this.socialMedias = uniquePagesArrayString.map((sm: any) =>
      JSON.parse(sm)
    );
    return uniquePagesArrayString.map((sm: any) => JSON.parse(sm));
  }

  gVal(data: any){
    return data && Number(data)? Number(data): 0
  }


}

import { Component } from '@angular/core';
import { PostService } from 'src/app/services/repositories/post.service';
import { Subscription } from 'rxjs';
import { GroupService } from 'src/app/services/repositories/group.service';
import { IResponse } from 'src/app/interfaces/IResponse';



@Component({
  selector: 'app-linkedin-stats',
  templateUrl: './linkedin-stats.component.html',
  styleUrls: ['./linkedin-stats.component.scss']
})
export class LinkedinStatsComponent {
  data: Array<{
    label: string;
    value: string;
    percentage: number;
  }> = [];
  private subs = new Subscription();
  socialMedias: any[] = [];

  constructor(protected postService: PostService,
  private groupService: GroupService,

  ){}

  ngOnInit(){
    const lsData = this.postService.getGroupScopesFromLocalStorage();
    
    this.data = [
      {"label": "dashboard.numberOfPublications", "value":'0', "percentage":7},
      {"label": "dashboard.impressionCount", "value":'0', "percentage":3},
      {"label": "dashboard.reactionsObtained", "value":'0', "percentage":2},
      {"label": "dashboard.commentsReceived", "value":'0', "percentage":-4},
      {"label": "dashboard.sharesMade", "value":'0', "percentage":5},
      {"label": "dashboard.interactions", "value":'0', "percentage":5},
      {"label": "dashboard.reach", "value":'0', "percentage":5},
    ];
    this.subs.add(
      this.postService.currentGroups.subscribe((g: number[]) => {
        if (g.length == 0)
          g = this.postService.getGroupScopesFromLocalStorage();
        
        if (g.length > 0) {
          this.groupService.getGroupByIds(g).subscribe((res: IResponse) => {
            this.getRecursiveSocialMediaPages(
              res.data
            );
            let likeCount = 0; 
            let commentCount = 0;
            let shareCount = 0;
            let clickCount = 0;
            let engagement = 0;
            let impressionCount = 0;
            let numberOfPublications = 0;
            this.socialMedias.forEach((item: any)=>{
              if(item.source == 'linkedin'){
                numberOfPublications += this.gVal(item.analytics?.numberOfPublications);
                impressionCount += this.gVal(item.analytics?.impressionCount);
                likeCount += this.gVal(item.analytics?.likeCount);
                commentCount += this.gVal(item.analytics?.commentCount);
                shareCount += this.gVal(item.analytics?.shareCount);
                clickCount += this.gVal(item.analytics?.clickCount);
                engagement += this.gVal(item.analytics?.engagement)*100;
              }
            })
            this.data[0].value = numberOfPublications.toString();
            this.data[1].value = impressionCount.toString();
            this.data[2].value = likeCount.toString();
            this.data[3].value = commentCount.toString();
            this.data[4].value = shareCount.toString();
            this.data[5].value = clickCount.toString();
            this.data[6].value = (engagement.toFixed(2)).toString()+'%';
          });
        }
      })
    );
  }
  getRecursiveSocialMediaPages(groups: any[]): any[] {
    groups = !Array.isArray(groups) ? [groups] : groups;
    let socialMediaPages: any[] = [];
    groups.forEach((group: any) => {
      // Add the group's social media pages if any
      if (group.socialMedia && group.socialMedia.length > 0) {
        socialMediaPages = [...socialMediaPages, ...group.socialMedia];
      }

      // Recursively add social media pages from children
      if (group.children && group.children.length > 0) {
        for (let child of group.children) {
          socialMediaPages = [
            ...socialMediaPages,
            ...this.getRecursiveSocialMediaPages(child),
          ];
        }
      }
    });
    //removing any duplicates
    const pagesArrayString: string[] = socialMediaPages.map((sm: any) =>
      JSON.stringify(sm)
    );
    const uniquePagesArrayString = [...new Set(pagesArrayString)];
    this.socialMedias = uniquePagesArrayString.map((sm: any) =>
      JSON.parse(sm)
    );
    return uniquePagesArrayString.map((sm: any) => JSON.parse(sm));
  }

  gVal(data: any){
    return data && Number(data)? Number(data): 0
  }
}

import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/base/base.component';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-regular-hours-dropdown',
  templateUrl: './regular-hours-dropdown.component.html',
  styleUrls: ['./regular-hours-dropdown.component.scss']
})
export class RegularHoursDropdownComponent extends BaseComponent {
  @Input() openInfo: any;
  @Output() openInfoChange = new EventEmitter<any>();

  isOpen: boolean = false;
  selectedStatus: string ='openWithStandardHours'; // Default value
  description: string ='openWithStandardHoursDescription'; // Default value

  options = [
    // { status: 'OPEN', label: 'Ouvert sans horaires standards', description: "Ne pas afficher les heures d'ouverture" },
    { status: 'OPEN', label:'openWithStandardHours', description:'openWithStandardHoursDescription', selected: false },
    { status: 'CLOSED_TEMPORARILY', label:'closedTemporarily', description:'closedTemporarilyDescription', selected: false },
    { status: 'CLOSED_PERMANENTLY', label:'closedPermanently', description:'closedPermanentlyDescription', selected: false }
  ];

	constructor(
		translationService: TranslationService,
		public translate: TranslateService,
	){
		super(translationService)
	}

  ngOnInit() {
    // Initialize selected status if provided
    if (this.openInfo && this.openInfo.status) {
      this.updateSelectedStatus(this.openInfo.status);
    }
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  selectStatus(status: string) {
    this.openInfo.status = status;
    this.openInfoChange.emit(this.openInfo);
    this.isOpen = false; // Close the dropdown after selection
    this.updateSelectedStatus(status);
  }

  updateSelectedStatus(status: string) {
    const selectedOption = this.options.find(option => option.status === status);
		// const previousOption = this.options.find(option => option.selected === true);
		// if (previousOption) {
		// 	previousOption.selected = false;
		// }
    if (selectedOption) {
      this.selectedStatus = selectedOption.label;
      this.description = selectedOption.description;
			// selectedOption.selected = true;
      // this.options = this.options.filter(option => option.selected !== status);
      // this.options.push({ status: this.openInfo.status, label: this.selectedStatus, description: selectedOption.description });
    }
  }
}

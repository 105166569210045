<div id="post-label" class="text-xs  mx-1 h-16 m-auto" *ngIf="posts?.length>0 && isPostPlatformInFilter(posts) && isPostOfThisHour(posts)">
    <div class="  mt-3 rounded-md h-full w-full m-auto"
        [ngClass]="{
            'flex justify-center items-center px-1 pb-1': !hasManyLabels(),
            'grid grid-rows-1 grid-cols-6 -px-3 -py-2 gap-1 w-11/12': hasManyLabels(),
            'c-b-accent-3 text-white': posts[previewIndex].publishingDatetime != null && !hasManyLabels(),
            'c-b-accent-4 text-black': posts[previewIndex].publishingDatetime == null && !hasManyLabels(),
        }">
        <div
            class="p-2"
            [ngClass]="{
            'col-span-5 rounded-md ': hasManyLabels(),
            'c-b-accent-3 text-white': posts[previewIndex].publishingDatetime != null && hasManyLabels(),
            'c-b-accent-4 text-black': posts[previewIndex].publishingDatetime == null && hasManyLabels()
            }"
            >
            <h3 [style.font-size.px]="screenHeight*0.6/100+screenWidth*0.6/100">{{posts[previewIndex].title.length > 10 ? posts[previewIndex].title.slice(0, 10) + '...' : posts[previewIndex].title}}</h3>
            <h3 class="text-xs">
                <ul class="grid grid-cols-2 p-1 gap-1">
                    <!-- post target platforms -->
                    <li *ngFor="let platform of posts[previewIndex].SocialPlatforms" class="rounded-full"
                        [ngClass]="{
                            'bg-sky-600': platform.source.toLowerCase() == 'facebook' && currentPlaTformsFilters.isFacebookON,
                            'bg-orange-600': platform.source.toLowerCase() == 'google' && currentPlaTformsFilters.isGoogleON,
                            'bg-blue-600': platform.source.toLowerCase() == 'linkedin' && currentPlaTformsFilters.isLinkedinON,
                            'bg-pink-600': platform.source.toLowerCase() == 'instagram' && currentPlaTformsFilters.isInstagramON
                        }">
                        <span class="m-auto w-11/12 h-6 py-1" *ngIf="platform.source.toLowerCase() == 'facebook' && currentPlaTformsFilters.isFacebookON || platform.source.toLowerCase() == 'google' && currentPlaTformsFilters.isGoogleON || platform.source.toLowerCase() == 'linkedin' && currentPlaTformsFilters.isLinkedinON || platform.source.toLowerCase() == 'instagram' && currentPlaTformsFilters.isInstagramON">
                            <img src="assets/img/{{ platform.source.toLowerCase() }}-label.png" alt="{{ platform.source }}">
                        </span>
                    </li>
                </ul>
            </h3>
        </div>

        <span class="pannel-trigger col-span-1 bg-white border border-2 border-indigo-400 m-0 rounded" *ngIf="hasManyLabels()">
            <span (click)="next()" class="pannel-trigger cursor-pointer  h-full flex justify-center items-center text-xs text-indigo-400 m-auto ">
                <p class="pannel-trigger">+{{posts.length - 1}}<br> →</p>
            </span>
        </span>
    </div>


</div>

import { Component, Input } from '@angular/core';
import * as moment from 'moment';
import { Debug } from 'src/app/utils/debug';
@Component({
  selector: 'app-post-create-preview',
  templateUrl: './post-create-preview.component.html',
  styleUrls: ['./post-create-preview.component.scss']
})
export class PostCreatePreviewComponent {
  @Input() selectedPlateform: string = ""
  @Input() data: any;
  @Input() screenHeight: number = 0
  @Input() screenWidth: number = 0
  @Input() copyPost: boolean = true
  linkedinData: any = null;
  linkedinMedias: any[] = []
  googleData: any = null;
  googleMedias: any[] = []
  facebookData: any = null;
  facebookMedias: any[] = []
  instagramData: any = null;
  instagramMedias: any[] = []
  mediaAllpreview: any =  null;

  ngOnInit(){
    console.log('data', this.data);
    this.data.socialMedia.forEach((item: { medias: string[]; source: string;})=>{
      if(item.source == "linkedin"){
        this.linkedinData = item;
      }  
      if(item.source == "google"){
        this.googleData = item;
      }
      if(item.source == "facebook"){
        this.facebookData = item;
      }
      if(item.source == "instagram"){
        this.instagramData = item;
      }
    })
    this.linkedinMedias = this.facebookMedias = this.data.MediaList
  }
    
  formatDateWithMoment(dateString: string): string {
    if(dateString!=null)
      return moment(dateString).format('D MMMM YYYY');
    else
      return ''
  }

	mediaIsVideo(medias: any[]){
		return (medias.length == 1 && medias[0]!=null && medias[0]?.type?.startsWith("video/"))
	}
}

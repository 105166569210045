import {
  Component,
  EventEmitter,
  Input,
  Output,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';

@Component({
  selector: 'app-post-full-hour-preview',
  templateUrl: './post-full-hour-preview.component.html',
  styleUrls: ['./post-full-hour-preview.component.scss'],
})
export class PostFullHourPreviewComponent {
  @Input() isVisible: boolean = false;
  @Input() data: any[] = [];
  xpos: number = 0;
  ypos: number = 0;
  @Output() visibilityChange = new EventEmitter<boolean>();
  constructor(private cdr: ChangeDetectorRef) {}
  close() {
    this.isVisible = false;
    this.visibilityChange.emit(this.isVisible as boolean);
  }
  getTime(item: any) {
    const date: Date =
      item.expectedPublishingDatetime == null
        ? new Date(item.publishingDatetime)
        : new Date(item.expectedPublishingDatetime);

    return (
      date.getHours().toString().padStart(2, '0') +
      ':' +
      date.getMinutes().toString().padStart(2, '0')
    );
  }
  ngOnInit() {
    // Debug.log(this.data);
  }

  htmlformat(text: string) {
    // Regular expression to match URL pattern
    const urlRegex = /(https?:\/\/\S+)/g;

    // Regular expression to match hashtag pattern (starting with #)

    // Split the text into words
    let words: string[] = text.split(' ');

    // Iterate through each word and update if it matches URL or hashtag pattern
    for (let i = 0; i < words.length; i++) {
      // Check if the word matches URL pattern
      words[i] = words[i].replace(
        urlRegex,
        (match) =>
          `<a  class="c-accent-1" href="${match}" target="_blank">${match}</a>`
      );
    }

    // Join the words back into a single string
    return words.join(' ');
  }

  ngAfterViewInit() {
    this.updatePannelPosion();
    this.cdr.detectChanges();
  }

  updatePannelPosion() {
    // Define maximum size percentages (for example, 90% of the viewport)
    const maxWidthPercentage = 0.9;
    const maxHeightPercentage = 0.9;

    // Define absolute maximum sizes (for example, 600px width and 400px height)
    const maxAbsoluteWidth = 600;
    const maxAbsoluteHeight = 400;

    // Calculate maximum sizes based on the viewport and absolute maximums
    const maxWidth = Math.min(
      window.innerWidth * maxWidthPercentage,
      maxAbsoluteWidth
    );
    const maxHeight = Math.min(
      window.innerHeight * maxHeightPercentage,
      maxAbsoluteHeight
    );

    // Optionally, you can also set minimum sizes to ensure the panel is not too small
    const minWidth = 300; // Minimum width of the panel in pixels
    const minHeight = 200; // Minimum height of the panel in pixels

    // Use calculated sizes for panel dimensions, respecting both minimum and maximum constraints
    const panelWidth = Math.max(minWidth, maxWidth);
    const panelHeight = Math.max(minHeight, maxHeight);

    // Calculate center positions
    this.xpos = (window.innerWidth - panelWidth) / 2;
    this.ypos = (window.innerHeight - panelHeight) / 2;
  }
}

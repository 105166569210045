import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-linkedin-fav-icon',
  templateUrl: './linkedin-fav-icon.component.html',
  styleUrls: ['./linkedin-fav-icon.component.scss']
})
export class LinkedinFavIconComponent {
@Input() color: string="#FFFFFF"
@Input() size: string="20px"
@Input() showLabel=false

}
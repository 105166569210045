<div *ngIf="isVisible" class="bg-white  rounded-xl shadow-lg absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-11/12 z-20">
	<button type="button" class="absolute top-0 right-0 mt-2 mr-3 text-sm hover:text-pink-600" (click)="close()">✕</button>
	
	<span *ngIf="currentMediaBrowserContext.label=='google'" class="ml-2 inline-flex items-center p-1 bg-orange-600 text-white rounded-md">
		<svg fill="currentColor" width="11px"  viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>google</title> <path d="M16.601 14.648v4.105h9.813c-0.162 2.008-0.982 3.798-2.243 5.18l0.006-0.007c-1.825 1.86-4.364 3.012-7.172 3.012-0.142 0-0.283-0.003-0.424-0.009l0.020 0.001c-5.946-0.003-10.765-4.823-10.765-10.77 0-0.051 0-0.102 0.001-0.152l-0 0.008c-0.001-0.043-0.001-0.094-0.001-0.145 0-5.946 4.819-10.767 10.765-10.77h0c0.040-0.001 0.087-0.001 0.135-0.001 2.822 0 5.383 1.121 7.262 2.941l-0.003-0.003 2.888-2.888c-2.568-2.578-6.121-4.174-10.047-4.174-0.082 0-0.164 0.001-0.246 0.002l0.012-0c-0.002 0-0.005 0-0.008 0-8.337 0-15.11 6.699-15.228 15.009l-0 0.011c0.118 8.32 6.891 15.020 15.228 15.020 0.003 0 0.006 0 0.009 0h-0c0.169 0.007 0.367 0.012 0.566 0.012 3.892 0 7.407-1.616 9.91-4.213l0.004-0.004c2.208-2.408 3.561-5.63 3.561-9.169 0-0.15-0.002-0.3-0.007-0.449l0.001 0.022c0.001-0.054 0.001-0.119 0.001-0.183 0-0.844-0.079-1.669-0.231-2.469l0.013 0.082z"></path></g></svg>
	</span> 

	<span *ngIf="currentMediaBrowserContext.label!='google'" class="ml-2 mt-2 inline-flex items-center ">
		<!-- Facebook SVG Icon -->
		<span class="p-1 bg-blue-600 text-white rounded-md">
			<svg width="12px" height="12px" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"/></svg>
		</span>
		<!-- LinkedIn SVG Icon -->
		<span class="ml-1 p-1 bg-sky-600 text-white rounded-md">
			<svg width="12px" height="12px" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z"/></svg>
		</span>
	</span>

	<div class="flex flex-wrap "> 
		<!-- Skip Media -->
		<div class="" 
			[ngClass]="{
				'text-cliking_green':isSkipped,
				'text-cliking_red':!isSkipped
			}"
			(click)="toggleSkipMedia()"
		>
			<span class="m-3 cursor-pointer ">
				<img  class="ml-3 w-20 h-20 border p-1 border-2 rounded-md " 
					[ngClass]="{
						'border-cliking_green':isSkipped,
						'border-cliking_red':!isSkipped
					}"
					src="assets/img/label-icons/no-media.svg"
					alt=""
				>
				<span class="ml-2 text-xs">{{'generic.noMedia'|translate}}</span>
			</span>
		</div>

		<div *ngFor="let file of browsableFiles(); index as i" 
			[ngClass]="{ 'cursor-not-allowed opacity-50': fileTooSmall(file) }" 
			(click)="!fileTooSmall(file) && select(file)" 
			[title]="fileTooSmall(file) ? ('mediaWarnings.googleImageTooSmall'|translate) : ''"
		>
				
			<span class="m-3" 
				*ngIf="currentMediaBrowserContext.label!='google'"
				[ngClass]="{
					'cursor-not-allowed opacity-50': fileTooSmall(file),
					'cursor-pointer': !fileTooSmall(file)
				}"
			>
				<img  class=" w-20 h-20 border p-1 mx-2 border-2 rounded-md " 
					[ngClass]="{
						'border-cliking_green':isFileSelected(file),
						'border-cliking_blue':currentSelectedFile!=file && !selectedFiles.includes(file),
						'opacity-20':isSkipped
					}" 
					[src]="file.type.includes('image')?file.url:'assets/img/label-icons/video.png'" 
					alt=""
					>
					<span class="ml-2 text-xs" 
						[ngClass]="{
							'text-cliking_green':isFileSelected(file),
							'text-black':!isFileSelected(file)
						}"
					>
						{{getFileName(i)}}
					</span>
			</span>

			<span class="m-3" 
				*ngIf="currentMediaBrowserContext.label=='google' && file.type.includes('image')"
				[ngClass]="{
					'cursor-not-allowed opacity-50': fileTooSmall(file),
					'cursor-pointer': !fileTooSmall(file)
				}"
			>
				<img  class=" w-20 h-20 border p-1 mx-2 border-2 rounded-md " 
					[ngClass]="{
						'border-cliking_green':isFileSelected(file),
						'border-cliking_blue':currentSelectedFile!=file || !selectedFiles.includes(file),
						'opacity-20':isSkipped
					}" 
					[src]="file.url" 
					alt=""
				>
				<span class="ml-2 text-xs" 
					[ngClass]="{
						'text-cliking_green':isFileSelected(file),
						'text-black':!isFileSelected(file)
					}"
				>
					{{getFileName(i)}}
				</span>
			</span> 
		</div>
	</div>

	<div class="m-auto flex items-center justify-center">
		<button type="button" class="bg-cliking_blue text-white p-1 px-6 rounded-[3px] mb-3 text-xs" [class.cursor-not-allowed]="!isSkipped && currentSelectedFile==null" [disabled]="!isSkipped && currentSelectedFile==null" (click)="confirmFile()">{{'generic.choose'|translate}}</button>
	</div>
</div>
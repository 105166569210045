<div class=" ml-5  rounded-xl bg-gray-100 flex justify-between flex-col">
    <table class="">
        <thead>
            <tr class="">
                <div class="flex items-center justify-center py-4 px-4 rounded-t-lg h-20 flex-shrink-0" [ngClass]="{'bg-linkedin':plateform=='linkedin', 'bg-orange-600':plateform=='google', 'bg-facebook':plateform=='facebook', 'bg-gradient-to-r from-pink-500 to-yellow-500':plateform=='instagram'}">
                    <img src='{{"assets/img/"+plateformeLogo()+".png"}}' alt="{{plateform +' Logo'}}" class="object-contain max-w-full max-h-full" [ngClass]="{'max-w-full max-h-full':plateform=='linkedin' || plateform=='google', 'w-[72%] h-[80%]':plateform=='facebook', 'w-96 h-[120%] from-pink-500 to-yellow-500':plateform=='instagram'}">
                </div>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of data,  index as i" class="justify-center flex items-center h-[70px] ">
                <div *ngIf="item.value!==''" class="rounded-lg border-2 w-[90%] h-[80%]" [ngClass]="{'border-linkedin':plateform=='linkedin', 'border-orange-600':plateform=='google', 'border-facebook':plateform=='facebook', 'border-pink-500':plateform=='instagram'}">
                    <div [style.font-size.px]="screenWidth*0.9/100" class="ml-[4%]">{{item.label | translate}}</div>
                    <div [style.margin-top.px]="-screenWidth*0.2/100" class="text-md font-semibold ml-[4%]">{{item.value}}<span *ngIf="i==pourcentageSymbol">%</span></div>
                </div>
                <!-- <img *ngIf="item.value==''" class="w-[90%] h-[80%]" src='{{"assets/img/empty-data.png"}}' alt=""> -->
                <div *ngIf="item.value===''" class="relative w-[90%] h-[80%]">
                    <svg class="w-[100%] h-[100%] rounded-btn overflow-hidden border-2" [ngClass]="{'border-linkedin text-linkedin':plateform=='linkedin', 'border-orange-600 text-orange-600':plateform=='google', 'border-facebook text-facebook':plateform=='facebook', 'border-pink-500 text-pink-500':plateform=='instagram'}">
                        <rect width="100%" height="100%" fill="none"   />
                        <line x1="0" y1="100%" x2="100%" y2="0" stroke="currentColor"/>
                    </svg>
                </div>
            </tr>
        </tbody>
    </table>
</div>
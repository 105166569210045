<div class="container w-full h-full p-4">
	<div class="w-full rounded-t-lg flex gap-1"> <!-- tabs -->
		<div 
				class="py-2 cursor-pointer w-1/3 text-sm text-center rounded-t-lg rounded-tl-3xl" 
				[ngClass]="{'bg-white': activeTab === 'regularHours', 'bg-violet-200': activeTab != 'regularHours'}"
				(click)="setActiveTab('regularHours')"
			>
				{{('pages.hours.regularHours' | translate)}}
			</div>
			<div 
				class="py-2 cursor-pointer w-1/3 text-sm text-center rounded-t-lg" 
				[ngClass]="{'bg-white': activeTab === 'specialHours', 'bg-violet-200': activeTab != 'specialHours'}"
				(click)="setActiveTab('specialHours')"
			>
				{{('pages.hours.specialHours' | translate)}}
			</div>
			<div 
				class="py-2 cursor-pointer w-1/3 text-sm text-center rounded-t-lg rounded-tr-3xl" 
				[ngClass]="{'bg-white': activeTab === 'moreHours', 'bg-violet-200': activeTab != 'moreHours'}"
				(click)="setActiveTab('moreHours')"
			>
				{{('pages.hours.moreHours' | translate)}}
			</div>
	</div>
	
	
	<div class="w-full h-full bg-white rounded-b-3xl h-full"> <!-- tab content-->
		<div *ngIf="activeTab === 'regularHours'" class="flex flex-col h-full overflow-auto">
			<span class="text-xs px-6 py-4 font-light text-neutral-400">{{'pages.hours.regularHoursDescription' | translate}} </span>
			<div class="bg-zinc-50 flex-grow m-5">
				<app-regular-hours-dropdown [openInfo]="openInfo" (openInfoChange)="onOpenInfoChange($event)"></app-regular-hours-dropdown>
				<app-regular-hours 
					*ngIf="openInfo.status == 'OPEN'"
					[regularHours]="regularHours" 
					(regularHoursChange)="onRegularHoursChange($event)"
				></app-regular-hours>
			</div>
		</div>

		<div *ngIf="activeTab === 'specialHours'" class="flex flex-col h-full overflow-auto">
			<span class="text-xs px-6 py-4 font-light text-neutral-400">{{'pages.hours.specialHoursDescription' | translate}} </span>
			<app-special-hours 
				[specialHours]="specialHours" 
				(specialHoursChange)="onSpecialHoursChange($event)"
			></app-special-hours>
		</div>

		<div *ngIf="activeTab === 'moreHours'" class="flex flex-col h-full overflow-auto">
			<span class="text-xs px-6 py-4 font-light text-neutral-400">{{'pages.hours.moreHoursDescription' | translate}} </span>
			<app-more-hours 
				[moreHours]="moreHours" 
				[moreHoursTypes]="moreHoursTypes" 
				(moreHoursChange)="onMoreHoursChange($event)"
			></app-more-hours>
		</div>
		
	</div>

</div>

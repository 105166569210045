import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocialMediaAPIService {
  
  constructor(private http:HttpClient) { }
  private apiUrl = environment.apiUrl;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  fetchPagePosts(pageId:any):Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/social-medias/api-posts-by-page/${pageId}`,this.httpOptions)
  }

  fetchPostInsights(postId:any,socialMediaId:any):Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/social-medias/api-fetch-post-insights?postId=${postId}&socialMediaId=${socialMediaId}`)
  }

  fetchPageInsights(pagetId:any):Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/social-medias/api-fetch-page-insights`)
  }

  fetchPostsComments(postExternalId:any):Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/social-medias/api-comments-by--post/${postExternalId}`,this.httpOptions)
  }

  fetchBullJsQueues():Observable<any>{
    return this.http.get<any>(`${this.apiUrl}/jobs`,this.httpOptions)
  }

  clearJob(queueName: string, group: string):Observable<any>{
    const params={queueName,group}
    return this.http.post<any>(`${this.apiUrl}/jobs/clear-jobs`,params,  this.httpOptions)
  }

}
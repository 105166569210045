import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Subject, Observable, catchError, throwError } from 'rxjs';

import { Group } from 'src/app/interfaces/Group';
import { AuthService } from '../utilities/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SocialMediaService {

  private apiUrl = environment.apiUrl;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient,private authService:AuthService) {}

	public currentGroupSource:BehaviorSubject<Group>= new BehaviorSubject<Group>({ 
    id:0,
    name:"",
    logoUrl:"",
    parent:null,
    ancestry:[],
    children:0,
    socialMedia:[]
  });
  currentGroup = this.currentGroupSource.asObservable()


  // Add other methods for different operations (get, update, delete, etc.)
  getGroupsWithSocialMedias(ids:any=null): Observable<any> {
    const url = ids.length==0?
        `${this.apiUrl}/social-medias/groups-with-social-medias`:
        `${this.apiUrl}/social-medias/groups-with-social-medias?ids=${encodeURIComponent(ids)}`

        return this.http.get<any>(url,this.httpOptions).pipe(
      catchError((error) => {
        console.error('Error fetching groups:', error);
        return throwError(() => error);
      })
    );
  }
	
	getSocialMedia(pageId:number|string): Observable<any> {
		return this.http.get<any>(`${this.apiUrl}/social-medias/${pageId}`,this.httpOptions).pipe(
			catchError((error) => {
        console.error('Error fetching groups:', error);
        return throwError(() => error);
      })
    );
	}

	getGooglePage(pageIds:any[]): Observable<any> {
		return this.http.get<any>(`${this.apiUrl}/social-medias/${pageIds}/google-page`,this.httpOptions).pipe(
			catchError((error) => {
        console.error('Error fetching groups:', error);
        return throwError(() => error);
      })
    );
	}

	getGooglePagesCategories(pageId:number|string, query?: string): Observable<any> {
		let params = new HttpParams();
		if (query) {
			params = params.set('query', query);
		}
    
		return this.http.get<any>(`${this.apiUrl}/social-medias/${[pageId]}/list-google-pages-categories`, { ...this.httpOptions, params }).pipe(
			catchError((error) => {
        console.error('Error fetching groups:', error);
        return throwError(() => error);
      })
    );
	}

	getGooglePages(groupIds:any[]): Observable<any> {
		let params = new HttpParams();
		params = params.append('groupIds', groupIds.join(','));
		const options = {
			...this.httpOptions,
			params: params 
		};
		
		return this.http.get<any>(`${this.apiUrl}/social-medias/google-pages`,options).pipe(
      catchError((error) => {
        console.error('Error fetching groups:', error);
        return throwError(() => error);
      })
    );
	}
	
	patchGooglePage(pageid: number | string, pageData: any): Observable<any> {
		const options = {
			...this.httpOptions,
		};
		return this.http.patch<any>(`${this.apiUrl}/social-medias/${pageid}/update-google-page`, pageData, options).pipe(
			catchError((error) => {
				return throwError(() => error);
			})
		);
	}
	
	logApiError(errorName: string, errorObject: any): void {
		this.http.post(`${this.apiUrl}/api-errors`, { errorName, errorObject }).subscribe();
	}

	deleteSocialMedia(socialMediaId:number): Observable<any> {
		return this.http.delete<any>(`${this.apiUrl}/social-medias/${socialMediaId}`).pipe(
      catchError((error) => {
        console.error('Error deleting Social Media:', error);
        return throwError(() => error);
      })
    );
	}

	refreshLocationData(pageId:number|string): Observable<any> {
		const options = {
			...this.httpOptions,
		};
		return this.http.patch<any>(`${this.apiUrl}/social-medias/${pageId}/refesh-location-data`, {}, options).pipe(
      catchError((error) => {
        console.error('Error refreshing data of google page:', error);
        return throwError(() => error);
      })
    );
	}
}

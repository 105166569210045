import { Component, EventEmitter, Input, Output,OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss']
})
export class TreeViewComponent {
  @Input() data: any[] = [];
  @Input() displayProperty: string = 'name';
  @Input() childrenProperty: string = 'children';
  @Output() itemSelected: EventEmitter<any> = new EventEmitter()
  selectedItemId:number = 0;
  toggle(item: any, depth: number): void {
    this.selectedItemId = item.id
    item.expanded = !item.expanded;
    if (item.expanded && this.hasChildren(item)) {
      item[this.childrenProperty].forEach((child:any) => {
        child.depth = depth + 1; // Set the depth of each child
      });
    }
    this.itemSelected.emit(item)
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] && this.data) {
      this.initializeData();
    }
  }

  hasChildren(item: any): boolean {
    return item[this.childrenProperty] && item[this.childrenProperty].length > 0;
  }
  initializeData() {
    this.data.forEach(item => {
      item.depth = 0;
    });
  }

}

import { Component, HostListener, Input } from '@angular/core';
import { CalenderBaseComponent } from 'src/app/components/base/calendar.component';
import { CALENDERMODE } from 'src/app/interfaces/CalendarMode';
import { PostService } from 'src/app/services/repositories/post.service';
import { CalendarService } from 'src/app/services/utilities/calendar.service';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';
import { Debug } from 'src/app/utils/debug';
@Component({
  selector: 'app-calendar-year-view',
  templateUrl: './calendar-year-view.component.html',
  styleUrls: ['./calendar-year-view.component.scss'],
})
export class CalendarYearViewComponent extends CalenderBaseComponent {
  constructor(
    protected override uiStateService: UiStateService,
    protected override postService: PostService,
    protected override translationService: TranslationService,
    protected override calendarService: CalendarService
  ) {
    super(translationService, calendarService, postService, uiStateService);
  }

  @Input() screenWidth: number = 0;
  @Input() screenHeight: number = 0;
  calculatedHeight!: string;
  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler() {
    this.isDayPostsPannelVisible = false;
  }
  getTody(): Date {
    return this.calendarService.toDay;
  }
  getCurrentYear(): number {
    return this.calendarService.currentDate.getFullYear();
  }
  @HostListener('click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if ((event.target as HTMLElement).classList.contains('day-preview')) {
      // Capture the click position
      let width: number = document.documentElement.clientWidth;
      let height: number = document.documentElement.clientHeight;
      let offsetX: number = width - event.clientX > 480 ? 0 : 500;
      let offsetY: number = height - event.clientY > 250 ? 0 : 150;

      this.pannelPositions.x = event.clientX - offsetX;
      this.pannelPositions.y = event.clientY - offsetY;
      // Debug.log(width,height,event.clientX,event.clientY,offsetX,offsetY )
    }
  }

  switchToMonthView(data: any) {
    this.calendarService.setCurrentMonth(data.monthIndex);
    data.year = this.calendarService.currentDate.getFullYear();
    this.calendarService.setCurrentCalendarMode(CALENDERMODE.MONTH);
    Debug.log('switchToMonthView', data, this.mode);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.groupSubscription = this.postService.currentGroups.subscribe(
      (groups) => {
        Debug.log('group scopes updated year view', groups);

        this.setYearDates();
        this.setupYearPostIndications();
      }
    );

    //css
    this.adjustMonthPreviewHeight();
    window.addEventListener('resize', this.adjustMonthPreviewHeight.bind(this));
  }
  adjustMonthPreviewHeight() {
    // Example calculation, adjust as needed
    const viewportHeight = window.innerHeight;
    const headerAndPaddingHeight = 200; // Adjust based on your layout's needs
    const availableHeight = viewportHeight - headerAndPaddingHeight;
    const monthPreviewHeight = availableHeight / 3; // Adjust the divisor based on your layout
    this.calculatedHeight = `${monthPreviewHeight}px`;
  }

  setupYearPostIndications() {
    const lsData = this.postService.getGroupScopesFromLocalStorage();
    this.groupScopes = lsData.length > 0 ? lsData : this.groupScopes;

    if (this.groupScopes.length > 0) {
      this.postService
        .getYearPosts(
          this.calendarService.currentDate.getFullYear(),
          this.groupScopes
        )
        .subscribe((data) => {
          this.getPostsToDates(data);
        });
    }
  }

  override handleVisibilityChange(isVisible: boolean) {
    this.isDayPostsPannelVisible = isVisible;
  }

  selectedDayPosts: any = [];
  pannelPositions: any = {};
  isDayPostsPannelVisible: boolean = false;
  showDayPosts(res: any) {
    this.selectedDayPosts = res.data;
    this.isDayPostsPannelVisible = true;
  }

  prevYear() {
    this.navigateWith(-1);
  }

  nextYear() {
    this.navigateWith(1);
  }

  navigateWith(ammount: number) {
    this.currentYear += ammount;
    this.calendarService.setCurrentYear(this.currentYear);
    this.setYearDates();
    this.setupYearPostIndications();
  }

  setYearDates(): void {
    for (let i = 0; i < 12; i++) {
      this.data[i] = this.getDaysOfMonth(i, this.currentYear);
    }
  }

  getDaysOfMonth(month: number, year: number) {
    function getDaysInMonth(year: number, month: number) {
      return new Date(year, month + 1, 0).getDate();
    }

    const startOfMonth = new Date(year, month, 1);
    const endOfMonth = new Date(year, month, getDaysInMonth(year, month));

    // Adjust startOfWeek to the previous Sunday
    let startOfWeek = new Date(startOfMonth);
    const monthIndex = startOfMonth.getMonth();
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());

    // Adjust endOfWeek to the following Saturday
    let endOfWeek = new Date(endOfMonth);
    endOfWeek.setDate(endOfWeek.getDate() + (6 - endOfWeek.getDay()));

    const weeks = [];
    let currentDate = new Date(startOfWeek);

    // Add extra days at the beginning if needed
    if (startOfWeek.getMonth() !== month) {
      for (let i = 0; i < 3; i++) {
        weeks.push({
          day: currentDate.getDate(),
          month: currentDate.getMonth(),
          data: {},
        });
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }

    // Add days of the month and extra days at the end if needed
    while (currentDate <= endOfWeek || weeks.length < 42) {
      weeks.push({
        day: currentDate.getDate(),
        month: currentDate.getMonth(),
        data: {},
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }

    const monthName = new Date(2000, month).toLocaleString('fr-FR', {
      month: 'long',
    });
    return { month: monthName, monthIndex: monthIndex, content: weeks };
  }
  override ngOnDestroy() {
    super.ngOnDestroy();
    window.removeEventListener(
      'resize',
      this.adjustMonthPreviewHeight.bind(this)
    );
  }
}

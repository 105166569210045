<div  class="relative mt-0" [ngClass]="{
  'cursor-not-allowed text-gray-600':data.length==0,
  'cursor-pointer':data.length>0
}"  [title]="data.length==0? getTranslation('posts.choosePlatformBeforePageSelection'):''">
  <div
  (click)="toggleDropdown()"
    type="button"
    class=" {{ tailwindCss }}"
    aria-haspopup="listbox"
    aria-expanded="true"
    aria-labelledby="listbox-label"
    [ngClass]="{
      'opacity-0': isDropdownOpen
    }"
    [style]="customCss + sizeCss"
  >
    <span   class=" ">{{ caption }}</span>

    <span class="ml-auto">
      <svg
        class="w-4 h-4 mr-2"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 520 512"
      >
        <path
          d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
        />
      </svg>
    </span>
  </div>
  <ul
    class="absolute -mt-8 z-10 w-11/12 -ml-1 bg-white left-5 rounded-2xl border border-1 border-cliking_blue"
    [class.hidden]="!isDropdownOpen"
    style="top: 100%; left: 4.2vh"
		
  >
    <span *ngIf="enableFuzzySearch && isMultiSelect">
      <li
        *ngIf="enableFuzzySearch"
        class="font-normal ml-3 cursor-pointer text-md rounded-2xl w-full"
      >
        <div>
          <div class="mt-1 flex rounded-2xl shadow-sm">
            <div
              class="relative flex items-stretch flex-grow focus-within:z-10"
            >
              <input
                style="width: 95%; height: 4vh"
                type="search"
                [placeholder]="getTranslation('generic.fiterPages')"
                (keyup)="searchItems(searchPattern)"
                [(ngModel)]="searchPattern"
                name="search"
                id="search"
                class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-2 rounded-none rounded-l-md sm:text-md border-gray-300"
              />
            </div>
          </div>
        </div>
      </li>
    </span>
    <li
      *ngIf="isMultiSelect && data.length > 0"
      class="font-normal ml-3 cursor-pointer text-base text-indigo-400 select-none relative py-1 pl-3 pr-9"
    >
      {{ caption }}
      <span
        class="font-normal ml-3 text-gray-700 font-sans font-black block truncate rounded-2xl"
      >
        <svg
          (click)="toggleDropdown()"
          class="absolute inset-y-1 right-2 mt-1 w-4 h-4 mr-3"
          viewBox="5 5 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <path
              d="M6 15L12 9L18 15"
              stroke="#5C78FF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </g>
        </svg>
        <!-- <input  type=checkbox 
      *ngIf="isMultiSelect && data.length>1"
       (click)="toggleSelectAll(data)"
      [(ngModel)]="isAllSelected"
      class="absolute inset-y-1 right-2 flex items-center h-4 w-4"> -->
      </span>
    </li>

		<li
			class="cursor-pointer text-md select-none relative py-1 pl-3 pr-9 hover:bg-gray-100"
			(click)="toggleSelectAll(data)"
		>
			<span 
				class="font-normal ml-2 mr-3 text-gray-700 font-sans font-black block truncate rounded-sm"
				[ngStyle]="{'font-size': (screenHeight / 60) + 'px'}"
			>
				{{'generic.selectAll' | translate}}
			</span>
		</li>

		<div
			class="overflow-auto"
			[style]="'max-height: ' + (screenHeight * 17 / 100 + screenHeight *0.87/10) + 'px'"
		>
			<li
				class="cursor-pointer text-md select-none relative py-1 pl-3 pr-9 hover:bg-gray-100"
				*ngFor="let item of data"
				[id]="item.id"
				role="option"
			>
				<div class="flex items-center" (click)="toggleSelect(item)">
					<span class="ml-2">
						<svg
							[style.width.px]="(screenHeight / 55)"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
							<g
								id="SVGRepo_tracerCarrier"
								stroke-linecap="round"
								stroke-linejoin="round"
							></g>
							<g id="SVGRepo_iconCarrier">
								<path
									d="M4 10.1433C4 5.64588 7.58172 2 12 2C16.4183 2 20 5.64588 20 10.1433C20 14.6055 17.4467 19.8124 13.4629 21.6744C12.5343 22.1085 11.4657 22.1085 10.5371 21.6744C6.55332 19.8124 4 14.6055 4 10.1433Z"
									stroke="#5C78FF"
									stroke-width="1.5"
								></path>
								<circle
									cx="12"
									cy="10"
									r="3"
									stroke="#5C78FF"
									stroke-width="1.5"
								></circle>
							</g>
						</svg>
					</span>
					<div
						[ngClass]="getPlatformProperty(item.source, 'bg_class')"
						class="inline-block rounded-md ml-2 text-white"
					>
						<img
							[style.width.px]="(screenWidth / 25)"
							[style.height.px]="(screenHeight / 55)"
							[src]="getPlatformProperty(item.source, 'logoPath')"
							alt="{{ getPlatformProperty(item.source, 'name') }} Logo"
							class="object-contain h-4 w-16 flex justify-content-between"
						/>
					</div>
					<span
						class="font-normal ml-2 mr-3 text-gray-700 font-sans font-black block truncate rounded-sm"
						[ngStyle]="{'font-size': (screenHeight / 60) + 'px'}"
					>
						{{ item.name }}
						<input
							[(ngModel)]="toggledItems[item.id]"
							type="checkbox"
							*ngIf="isMultiSelect"
							class="absolute inset-y-1 right-2 flex items-center h-4 w-4 mr-3"
						/>
					</span>
				</div>
			</li>
		</div>
    <!-- <li class="cursor-pointer text-md select-none relative py-1 pl-3 pr-9 hover:bg-gray-100">
      <a href="/another-route">{{'logout' | translate}} </a>
    </li>   -->
  </ul>
</div>

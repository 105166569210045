<div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
  <div class="hidden lg:flex lg:flex-1 lg:justify-end">
    <a (click)="switchFr()" class="flex items-center text-sm font-semibold leading-6 text-gray-900 mr-4">
        <img style="height: 18px;" src="{{env.apiUrl}}/assets/fr.png" alt="" class="flex-shrink-0 h-6 w-6 rounded-md"> 
        <span class="ml-1 cursor-pointer">fr</span>
    </a>
    <a (click)="switchEn()" class="flex items-center text-sm font-semibold leading-6 text-gray-900">
        <img style="height: 18px;" src="{{env.apiUrl}}/assets/en.png" alt="" class="flex-shrink-0 h-6 w-6 rounded-md"> 
        <span class="ml-1 cursor-pointer">en</span>
    </a>

  </div>
  <div class="sm:mx-auto sm:w-full sm:max-w-sm">
    <img class="mx-auto h-20 w-auto" src="assets/img/logo.png" alt="Clicking social media">
    <h2 class="mt-10 text-center text-2xl font-bold lea
    ding-9 tracking-tight text-gray-900">{{'generic.resetPassword' | translate}}</h2>
  </div>
  <div *ngIf="userId" class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
    <form class="space-y-6">
      <div>
        <label for="newPassword" class="block text-sm font-medium leading-6 text-gray-900">{{'generic.newPassword' | translate}}</label>
        <div class="mt-2">
          <input id="newPassword" name="newPassword" type="password" [(ngModel)]="newPassword" (input)="checkPasswords()" required class="pl-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
        </div>
      </div>
      <div>
        <div class="flex items-center justify-between">
          <label for="confirmPassword" class="block text-sm font-medium leading-6 text-gray-900">{{'generic.confirmPassword' | translate}}</label>
        </div>
        <div class="mt-1">
          <input id="confirmPassword" name="confirmPassword" type="password" [(ngModel)]="confirmPassword" (input)="checkPasswords()" required class="pl-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
        </div>
      </div>
      
      <div *ngIf="passwordMismatch" class="text-red-600">
        Les mots de passe ne correspondent pas.
      </div>
      
      <div>
        <button type="submit" class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" (click)="resetPassword(newPassword, $event)">
          {{'generic.save' | translate}}
        </button>
      </div>
    </form>
  </div>
  <div *ngIf="!userId" class="flex items-center justify-center mt-10 ">
    <span class="text-3xl text-cliking_red text-center font-semibold w-[60%]"> {{'generic.invalidToken'|translate}}</span>
  </div>
</div>
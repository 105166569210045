<div class="join">
    <button class="btn btn-info text-white mr-5 justify-start" (click)="newMap()" [disabled]="newMapPressed">

        New Map
    </button>
</div>
<div class="text-left">

    <div class="flex items-start w-full">
        <!-- ui permissions table -->
        <table class="table w-3/4">

            <tr>
                <th><h3 class="text-lg ">Ui Map</h3></th>
                <th><h3 class="text-lg">Actions</h3></th>
            </tr>
            <!-- new map creation -->
            <tr *ngIf="newMapPressed">
                <td>
                    <label class="input input-bordered input-accent flex items-center gap-2">
                        <input (keyup)="validate()" type="text" class="grow" placeholder="enter map name" [(ngModel)]="tmpMap.name" #newMapName/>
                        <span *ngIf="isMapAlreadyExists"  class="text-red-600 text-xs">already existst</span>
                        <svg *ngIf="!isMapAlreadyExists" (click)="insert()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="w-4 h-4 cursor-pointer" fill="currentColor"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>
                      </label>
                </td>
                <td class="w-fill flex-grow">
                    <span class="badge badge-accent m-1" *ngFor="let action of tmpMap.actions">{{action}}</span>
                </td>
            </tr>


            <tr (mouseenter)="targetUiPermission=uiPermission" (mouseleave)="targetUiPermission=null" *ngFor="let uiPermission of uiPermissions">
                <td  >
                    <svg  *ngIf="targetUiPermission && targetUiPermission.name==uiPermission.name" (click)="remove(uiPermission)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-4 h-4 cursor-pointer absolute -left-1" fill="#fd397a"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM184 232H328c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"/></svg>
                    <span  (click)="toggleUIPermissions(uiPermission)" class="cursor-pointer " [ngClass]="{
                        'text-accent bold': selectedUIPermission?.name==uiPermission.name
                    }" >
                        <span class="">{{uiPermission.name.replaceAll('.',' ')}}</span>
                    </span>
                </td>
                <td>
                    <span class="badge badge-accent m-1" *ngFor="let action of uiPermission.actions" [ngClass]="{
                        ' badge-outline': selectedUIPermission?.name != uiPermission.name
                    }">{{action}}</span>
                </td>
            </tr>
           
        </table>
        <div class="divider divider-horizontal"></div>
        <!-- system permissions -->
        <table class="mt-3 w-1/4 justify-end items-end" *ngIf="selectedUIPermission ">
            <tr>
                <th>
                    <h3 class="text-lg">Sys Actions {{ selectedUIPermission.actions.length+" / "+sysActions.length}}</h3>
                    <h4 class="text-warning font-medium"> matching Context : {{getCurrentSelectedGroup()}}</h4>
                </th>
            </tr>
            
            <!-- import actions of existing profile -->
            <tr>
                <a href="#"> Import Existing Profile Actions ?</a>
                <div class="join m-1">
                    
                    <select class="select select-bordered select-sm w-full max-w-xs mr-2" [(ngModel)]="selectedProfile">
                        <option value="" disabled selected>Select a Profile</option>
                        <option *ngFor="let profile of appProfiles">{{profile.name}}</option>
                        
                    </select>
                    <button (click)="loadSelectedProfileActions()" class="btn btn-sm btn-success text-white">Import</button>
                </div>
            </tr>
            <!-- scope visibility -->
            <tr>
                <td>
                    <span  (click)="SetDisplayModeTo('selected')"  [ngClass]="{
                            'border border-4 border-warning':displayMode=='selected'
                        }"  class="cursor-pointer badge badge-accent text-xs m-1"> selected </span>
                    <span  (click)="SetDisplayModeTo('nonSelected')"  [ngClass]="{
                        ' border border-4 border-warning':displayMode=='nonSelected'
                    }"  class="cursor-pointer badge badge-accent badge-outline text-xs m-1"> NonSelected</span>
                       
                    <span  (click)="SetDisplayModeTo('filter')"  [ngClass]="{
                        ' border border-4 border-warning':displayMode=='filter'
                    }"  class="cursor-pointer badge badge-info badge-outline text-xs m-1"> Filter</span>
                </td>
            </tr>
            
            <!-- filter input -->
            <tr *ngIf="displayMode=='filter'||displayMode=='unused'">
                <td>
                    <input (keyup)="filterActions()" [(ngModel)]="filtredValue" type="text" placeholder="Type here to filter actions" class="input input-bordered input-xs w-full max-w-xs" />
                </td>
            </tr>

            <!-- select all / select none --> 
             <tr *ngIf="sysActionSource.length>1">
                <td>
                    <div class="form-control">
                        <label class="cursor-pointer label justify-start">
                            <input type="checkbox" (click)="toggleSelectAll()" [checked]="isAllSelected()" class="checkbox checkbox-accent mr-2"  />
                            <span class="label-text text-accent">{{renderToggleAllText()}}</span>
                        </label>
                    </div>
                </td>
            </tr>
            <!-- rendering the actions -->
            <tr>
                <div *ngFor="let permission of sysActionSource " [ngClass]="{
                    'opacity-60':!isActionInUiPermissions(permission)
                }" > 
                    <td>           
                        <div class="form-control">
                            <label class="cursor-pointer label">
                                <input type="checkbox" (click)="toggleAction(permission)" [checked]="isActionInUiPermissions(permission)" class="checkbox checkbox-accent mr-2"  />
                                <span class="label-text" 
                                [ngClass]="{
                                    'text-gray-400  hover:text-gray-700':unUsedAtions.includes(permission),
                                    'text-gray-900':!unUsedAtions.includes(permission)
                                }"[title]="unUsedAtions.includes(permission)?'not used in any map':''" >{{permission}}</span>
                            </label>
                        </div>
                    </td>  
                </div>
            </tr>
        </table>
    </div>  
    
</div>
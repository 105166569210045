import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
	private langChangeSubject = new BehaviorSubject<string>(this.getLanguage());

  constructor(private translate: TranslateService) {}

  public t(key: string): string {
    return this.translate.instant(key);
  }

  public setLanguage(lang: string): void {
    this.translate.use(lang);
  }
  public getLanguage(): string {
    return this.translate.currentLang ? this.translate.currentLang : this.translate.getDefaultLang();

  }

	public getLanguageChangeObservable() {
    return this.langChangeSubject.asObservable();
  }
}

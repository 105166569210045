import { Injectable } from '@angular/core';
import { TranslationService } from 'src/app/services/utilities/translation.service';

@Injectable({
  providedIn: 'root',
})

export class OpeningHoursService {
  constructor(private translate: TranslationService) {}

  checkOpenStatus(hours: any) {

		if (!hours.specialHours && !hours.regularHours) {
			return { hasHours: false, isOpen: false, closingAt: '' };
		}

    const now = new Date();
    const today = now.toLocaleString('en-us', { weekday: 'long' }).toUpperCase();
    const currentTime = now.getHours() + now.getMinutes() / 60;

    // First check special hours
		if (hours.specialHours) {
			for (let period of hours.specialHours.specialHourPeriods) {
				if (this.isDateWithin(now, period.startDate, period.endDate)) {
					if (period.closed) {
						return { isOpen: false, nextOpen: this.findNextOpeningTime(now, hours) };
					}
					if (this.isTimeWithin(currentTime, period.openTime.hours + (period.openTime.minutes || 0) / 60, period.closeTime.hours + (period.closeTime.minutes || 0) / 60)) {
						return { hasHours: true, isOpen: true, closingAt: `${period.closeTime.hours}:${period.closeTime.minutes || '00'}` };
					}
				}
			}
		}

    // Check regular hours
		if (hours.regularHours) {
			for (let period of hours.regularHours.periods) {
				if (period.openDay === today) {
					if (this.isTimeWithin(currentTime, period.openTime.hours + (period.openTime.minutes || 0) / 60, period.closeTime.hours + (period.closeTime.minutes || 0) / 60)) {
						return { hasHours: true, isOpen: true, closingAt: `${period.closeTime.hours}:${period.closeTime.minutes || '00'}` };
					}
				}
			}
		}

    return { hasHours: true, isOpen: false, nextOpen: this.findNextOpeningTime(now, hours) };
  }

  isDateWithin(currentDate: Date, start: any, end: any): boolean {
    const startDate = new Date(start.year, start.month - 1, start.day);
    const endDate = new Date(end.year, end.month - 1, end.day);
    return currentDate >= startDate && currentDate <= endDate;
  }

  isTimeWithin(currentTime: number, openTime: number, closeTime: number): boolean {
    return currentTime >= openTime && currentTime <= closeTime;
  }

  findNextOpeningTime(currentDate: Date, hours: any): string {
    let nextOpening = null;

    // Check special hours
		if (hours.specialHours) {
			for (let period of hours.specialHours.specialHourPeriods) {
				const startDate = new Date(period.startDate.year, period.startDate.month - 1, period.startDate.day, period.openTime?.hours || 0, period.openTime?.minutes || 0);
				if (currentDate < startDate && (!nextOpening || startDate < nextOpening)) {
					nextOpening = startDate;
				}
			}
		}

    // Check regular hours
		if (hours.regularHours) {
			const daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
			const todayIndex = currentDate.getDay();
			const currentTime = currentDate.getHours() + currentDate.getMinutes() / 60;
	
			for (let i = 0; i < 7; i++) {
				const dayIndex = (todayIndex + i) % 7;
				const dayName = daysOfWeek[dayIndex];
	
				for (let period of hours.regularHours.periods) {
					if (period.openDay === dayName) {
						let openingTime = new Date(currentDate);
						openingTime.setDate(currentDate.getDate() + i);
						openingTime.setHours(period.openTime.hours, period.openTime.minutes || 0, 0, 0);
	
						if (i === 0 && currentTime < period.openTime.hours + (period.openTime.minutes || 0) / 60) {
							// If today, and current time is before opening time
							if (!nextOpening || openingTime < nextOpening) {
								nextOpening = openingTime;
							}
						} else if (i > 0) {
							// Future days
							if (!nextOpening || openingTime < nextOpening) {
								nextOpening = openingTime;
							}
						}
					}
				}
			}
		}

    if (nextOpening) {
      const isToday = nextOpening.toDateString() === currentDate.toDateString();
      const options: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit'
      };
      if (isToday) {
        return `${this.translate.t("pages.nextOpen")} ${nextOpening.toLocaleTimeString([], options)}`;
      } else {
        return `${this.translate.t("pages.openOn")} ${nextOpening.toLocaleDateString()} ${this.translate.t("pages.at")} ${nextOpening.toLocaleTimeString([], options)}`;
      }
    } else {
      return 'No opening time found';
    }
  }
}
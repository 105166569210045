import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  animations: [
    trigger('slideAnimation', [
      transition(':decrement', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-out', style({ transform: 'translateX(0)' })),
      ]),
      transition(':increment', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-out', style({ transform: 'translateX(0)' })),
      ]),
    ]),
  ],
})
export class CarouselComponent  {

  @Input() slides: any[] =[];
  @Input() activeSlideIndex = 0;
  @Input() isShrinkable:boolean = false;


	ngOnChanges(changes: SimpleChanges) {
		// TODO: trouver une methode plus opti !
		
    // Check if 'slides' input has changed
    if (changes['slides'] && !this.arrayComparison(changes['slides'].currentValue, changes['slides'].previousValue)) {
      // Reset the active slide index to 0 whenever the slides input changes
      //this.activeSlideIndex = 0;
    }
  }
	
  nextSlide() {
    this.activeSlideIndex = (this.activeSlideIndex + 1) % this.slides.length;
  }

  prevSlide() {
    this.activeSlideIndex = (this.activeSlideIndex - 1 + this.slides.length) % this.slides.length;
  }

	isImage(file: any): boolean {
    const vides_exts=['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv'];
    if(typeof(file)==='string') {  
    const extention = file.slice(-3);
    return !vides_exts.includes(extention)
    }
		return !file.type || file.type.split('/')[0] === 'image';
	}

	isVideo(file: any): boolean {
    const vides_exts=['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv'];
    if(typeof(file)==='string') {  
    const extention = file.slice(-3);
    return vides_exts.includes(extention)
    }
		return file.type?.split('/')[0] === 'video';
	}

	private arrayComparison(array1: any[], array2: any[]) {
		if (!array1 || !array2) {
			return false;
		}
		if(array1 === array2) {
			return true;
		}
    // compare lengths - can save a lot of time 
    if (array1.length != array2.length) {
			return false;
		}
		for (var i = 0, l=array1.length; i < l; i++) {
			// Check if we have nested arrays
			if (array1[i] instanceof Array && array2[i] instanceof Array) {
				// recurse into the nested arrays
				if (!this.arrayComparison(array1[i], array2[i]))
					return false;       
			}           
			else if (array1[i] != array2[i]) { 
				// Warning - two different object instances will never be equal: {x:20} != {x:20}
				return false;   
			}           
		}
		return true;
	}
}


<div class="w-full h-full border-2 border-gray-600 rounded-3xl bg-white overflow-auto my-4">
	<div class="h-1/4 w-full flex items-center justify-around rounded-t-3xl overflow-hidden border-b-2 border-gray-600">
		<ng-container *ngIf="medias.length > 0; else noImage" class="rounded-t-3xl">
			<img *ngFor="let media of medias" [src]="media" [alt]="media" class="h-full w-auto flex-grow object-cover">
		</ng-container>
		<ng-template #noImage>
			<img src="assets/img/nomedia1.png" alt="No images available" class="h-full w-full object-cover">
		</ng-template>
	</div>
	<div class="h-3/4 w-full pt-2 pr-5 pl-6">
		<span class="font-semibold">{{title}}</span> <!-- Title -->
		<br>
		<span class="font-light text-sm text-gray-600">{{primaryCategory}}</span>
		<br>
		<hr class="border-gray-600 mb-2">
		<div *ngIf="address">
			<span class="font-semibold text-sm ">{{ "pages.address" | translate }}:</span> <span class="text-xs">&nbsp;{{address}}</span> <!-- Address -->
		</div>
		<div *ngIf="openStatus && openStatus.hasHours">
			<div *ngIf="openStatus.isOpen">
				<span  class="font-semibold text-sm ">{{ "pages.hours.hours" | translate }}:</span> 
				<span class="text-xs text-green-400">&nbsp;{{ "pages.hours.open" | translate }}</span><span class="text-xs"> ⋅ {{ openStatus.closingAt}}</span> 
			</div>
			<div *ngIf="!openStatus.isOpen">
				<span  class="font-semibold text-sm ">{{ "pages.hours.hours" | translate }}:</span> 
				<span class="text-xs text-red-400">&nbsp;{{ "pages.hours.closed" | translate }}</span><span class="text-xs"> ⋅ {{ openStatus.nextOpen}}</span> 
			</div>
		</div>
		<div *ngIf="phoneNumber">
			<span class="font-semibold text-sm ">{{ "pages.phone" | translate }}:</span> <span class="text-xs">&nbsp;{{phoneNumber}}</span> <!-- Address -->
		</div>
		<br>
		<hr *ngIf="openStatus && openStatus.hasHours || address" class="border-gray-600 mb-2">
		<div *ngIf="description">
			<span class="font-semibold text-sm ">{{ "pages.description" | translate }}:</span> <span class="text-xs">&nbsp;{{description}}</span> <!-- Address -->
		</div>
	</div>
</div>

<!-- Image Container -->
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { CalenderBaseComponent } from 'src/app/components/base/calendar.component';
import { IResponse } from 'src/app/interfaces/IResponse';
import { PostService } from 'src/app/services/repositories/post.service';
import { CalendarService } from 'src/app/services/utilities/calendar.service';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';

import { CALENDERMODE } from 'src/app/interfaces/CalendarMode';
import { Debug } from 'src/app/utils/debug';

@Component({
  selector: 'app-calendar-month-view',
  templateUrl: './calendar-month-view.component.html',
  styleUrls: ['./calendar-month-view.component.scss'],
})
export class CalendarMonthViewComponent extends CalenderBaseComponent {
  constructor(
    protected override postService: PostService,
    protected override uiStateService: UiStateService,
    protected override translationService: TranslationService,
    protected override calendarService: CalendarService
  ) {
    super(translationService, calendarService, postService, uiStateService);
  }

  @Input() screenWidth: number = 0;
  @Input() screenHeight: number = 0;
  @Output() switchToYearView = new EventEmitter<any>();
  @Output() labelClicked = new EventEmitter();
  currentCalenderDays: number[] = [];
  currentMonthData: { day: number; data: any; previewIndex: number }[] = [];

  override handleVisibilityChange(isVisible: boolean) {
    Debug.log('visibility', isVisible);
    this.isFullHourPannelVisible = isVisible;
  }

  override ngOnInit(): void {
    super.ngOnInit();

    const lsData = this.postService.getGroupScopesFromLocalStorage();
    this.groupScopes = lsData.length > 0 ? lsData : this.groupScopes;

    this.groupSubscription = this.postService.currentGroups.subscribe(
      (groups) => {
        Debug.log('group scopes updated month view', groups);
        this.groupScopes = groups;
        this.getPostsByTimeFrame();
      }
    );

    this.currentCalenderDays = this.GetCalanderDays(this.currentDate);
    for (let d of this.currentCalenderDays) {
      this.currentMonthData.push({ day: d, data: null, previewIndex: 0 });
    }
  }

  gotoYear(year: number) {
    Debug.log('switching to year ...', year);
    this.calendarService.setCurrentYear(year);
    this.switchToYearView.emit(year);
    //emit event to calender mode
  }

  goToDay(data: any, i: number) {
    if (!this.idDayFromThisMonth(data.day, i)) {
      //change current date
      let dateStr =
        this.currentDate.getFullYear() +
        '-' +
        (this.currentDate.getMonth() + 1) +
        '-' +
        data.day;
      this.calendarService.setCurrentDate(new Date(dateStr));
      this.calendarService.setCurrentCalendarMode(CALENDERMODE.DAY);
      this.mode = CALENDERMODE.DAY;
    }
  }

  idDayFromThisMonth(day: number, i: number) {
    return (day > 20 && i < 6) || (day < 10 && i > 29);
  }

  prevMonth() {
    this.currentDate.setMonth(this.currentDate.getMonth() - 1);
    this.calendarService.setCurrentMonth(this.currentDate.getMonth());
    this.getPostsByTimeFrame();
  }

  nextMonth() {
    this.currentDate.setMonth(this.currentDate.getMonth() + 1);
    this.calendarService.setCurrentMonth(this.currentDate.getMonth());
    this.getPostsByTimeFrame();
  }

  getPostsByTimeFrame() {
    const lsData = this.postService.getGroupScopesFromLocalStorage();
    this.groupScopes = lsData.length > 0 ? lsData : this.groupScopes;

    Debug.log('😀', lsData, this.groupScopes);

    const dateStr =
      this.calendarService.currentDate.getFullYear() +
      '-' +
      (this.calendarService.currentDate.getMonth() + 1) +
      '-' +
      this.calendarService.currentDate.getUTCDate();

    this.postService
      .getByTimeFrame(this.groupScopes, dateStr, 'month')
      .subscribe((res: IResponse) => {
        this.data = res.data;
        // Debug.log(this.data)
        this.currentMonthData = [];
        this.currentMonthData = this.GetCalenderData(this.currentDate);
      });
  }

  GetCalanderDays(date: Date): number[] {
    function getDaysInMonth(year: number, month: number) {
      return new Date(year, month + 1, 0).getDate();
    }

    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const endOfMonth = new Date(
      date.getFullYear(),
      date.getMonth(),
      getDaysInMonth(date.getFullYear(), date.getMonth())
    );

    // Adjust startOfWeek to the previous Monday
    let startOfWeek = new Date(startOfMonth);
    let dayOfWeek = startOfMonth.getDay();
    startOfWeek.setDate(
      startOfWeek.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1)
    );

    // Ensure endOfWeek is the following Sunday after endOfMonth
    let endOfWeek = new Date(endOfMonth);
    dayOfWeek = endOfWeek.getDay();
    // This ensures it always extends to the next Sunday, even if endOfMonth is Sunday
    endOfWeek.setDate(endOfWeek.getDate() + (7 - dayOfWeek));

    const weeks = [];
    let currentDate = new Date(startOfWeek);
    while (currentDate <= endOfWeek) {
      weeks.push(currentDate.getDate());
      currentDate.setDate(currentDate.getDate() + 1);
    }

    // Ensure we have a complete set of 35 or 42 days
    // If the calculation above doesn't give us enough days to fill 6 weeks (42 days),
    // it means we're missing the last Sunday because it's considered part of the next week.
    if (weeks.length < 42) {
      // Add days to include the last Sunday
      while (weeks.length < 42) {
        weeks.push(currentDate.getDate());
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }

    return weeks;
  }

  isCurrentDay(d: any): boolean {
    // Check if it's the current day
    let isDayFromAnotherMonth: boolean = !(
      (d.day < 7 && this.currentMonthData.indexOf(d) > 27) ||
      (d.day > 7 && this.currentMonthData.indexOf(d) < 7)
    );
    return (
      isDayFromAnotherMonth &&
      this.currentDate.getFullYear() ===
        this.calendarService.toDay.getFullYear() &&
      this.currentDate.getMonth() === this.calendarService.toDay.getMonth() &&
      this.calendarService.toDay.getDate() === d.day
    );
  }

  getTodayMonthString() {
    return this.getTranslation(
      'generic.month' + (this.currentDate.getMonth() + 1)
    );
  }

  GetCalenderData(
    date: Date
  ): { day: number; data: any[]; previewIndex: number }[] {
    const result: { day: number; data: any[]; previewIndex: number }[] = [];

    function getDaysInMonth(year: number, month: number) {
      return new Date(year, month + 1, 0).getDate();
    }

    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const endOfMonth = new Date(
      date.getFullYear(),
      date.getMonth(),
      getDaysInMonth(date.getFullYear(), date.getMonth())
    );

    // Adjust startOfWeek to the previous Monday
    let startOfWeek = new Date(startOfMonth);
    let dayOfWeek = startOfMonth.getDay();
    startOfWeek.setDate(
      startOfMonth.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1)
    );

    // Ensure endOfWeek is the next Sunday after endOfMonth, even if it goes into the next month
    let endOfWeek = new Date(endOfMonth);
    dayOfWeek = endOfWeek.getDay();
    if (dayOfWeek !== 0) {
      // If not Sunday
      endOfWeek.setDate(endOfMonth.getDate() + (7 - dayOfWeek));
    } else {
      // If endOfMonth is a Sunday, no need to adjust
      endOfWeek = endOfMonth;
    }

    let currentDate = new Date(startOfWeek);

    // Iterate over each day in the calendar range
    while (currentDate <= endOfWeek) {
      let dailyPosts = [];
      // Your existing logic to gather daily posts
      for (let post of this.data) {
        let publishDate = post.publishingDatetime
          ? new Date(post.publishingDatetime)
          : null;
        let plannedDate = post.expectedPublishingDatetime
          ? new Date(post.expectedPublishingDatetime)
          : null;

        if (
          (publishDate && this.isSameDay(publishDate, currentDate)) ||
          (plannedDate && this.isSameDay(plannedDate, currentDate))
        ) {
          dailyPosts.push(post);
        }
      }
      result.push({
        day: currentDate.getDate(),
        data: dailyPosts,
        previewIndex: 0,
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }

    // Sort currentMonthData each day's data by date ascending or descending
    for (let item of result) {
      if (item.data.length > 1) {
        item.data = this.sortPostsByTime(item.data);
      }
    }

    return result;
  }

  isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  isPostPlatformInFilter(d: any): boolean {
    if (this.isPublished || this.isPlanned) {
      if (
        this.currentPlaTformsFilters.isInstagramON ||
        this.currentPlaTformsFilters.isGoogleON ||
        this.currentPlaTformsFilters.isLinkedinON ||
        this.currentPlaTformsFilters.isFacebookON
      ) {
        let isCurrentPostPlanned = d.data[0].expectedPublishingDatetime
          ? true
          : false;
        let isCurrentPostPublished = d.data[0].publishingDatetime
          ? true
          : false;

        let postPlatforms = d.data[0].SocialPlatforms;
        return postPlatforms.some(
          (platform: { source: string; id: number }) => {
            let platformName = platform.source.toLowerCase();
            // Debug.log(platformName)
            let isPlatformOn =
              this.currentPlaTformsFilters[
                `is${
                  platformName.charAt(0).toUpperCase() + platformName.slice(1)
                }ON`
              ];
            // Debug.log(isPlatformOn)
            return (
              isPlatformOn &&
              ((isCurrentPostPlanned && this.isPlanned) ||
                (isCurrentPostPublished && this.isPublished))
            );
          }
        );
      }
    }
    return false;
  }

  updatePreviewIndex(d: any) {
    // Debug.log("need to show and update gull hour pannel with ",data)
    if (d.data.length > 1) {
      d.previewIndex =
        d.previewIndex + 1 == d.data.length ? 0 : d.previewIndex + 1;
    }
  }
}

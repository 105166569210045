<!-- all action toggle -->
<!-- <label class="cursor-pointer label w-[15%]">
  <span class="px-2 label-text text-warning text-xl">{{ isAllSelected? 'Deny All ?':'Grant All?'}}</span> 
  <input type="checkbox" class="mr-4 toggle toggle-sm toggle-warning "  (change)="toggleAll()" />
</label> -->

<!-- root accordian -->
<div class="collapse collapse-arrow bg-base-100 text-start mt-2" *ngFor="let route of getRoutes().sort()">
    <input type="checkbox" name="my-accordion-1"  />
    <div class="collapse-title text-lg font-medium" >
      {{route}}
    </div>
    <div class="collapse-content">
      <ul>
        <li class=" m-2 collapse collapse-arrow bg-base-200 text-start  rounded-md border border-1 border-slate-300" *ngFor="let method of getMethods(route)">
          <input type="checkbox" name="my-accordion-2"  />
          <div class="collapse-title text-lg font-medium" >
            {{method}} {{getActions(route,method).length>1?getActions(route,method).length+" actions":""}}
          </div>
          <div class="collapse-content">
          
              <ul>
                <li class=" m-2 collapse collapse-arrow bg-base-300 text-start  rounded-md border border-1 border-slate-300" *ngFor="let permission of getActions(route,method)">
                  
                  
                  <div class="form-control">
                    <label class="cursor-pointer label">
                      <span class="px-2 label-text" [ngClass]="{
                        'text-cliking_green':isPemissionInProfile(permission.action)
                      }">{{permission.label}} </span> 
                      <span class="text-xs" [ngClass]="{
                        'text-teal-500':isPemissionInProfile(permission.action)
                      }">{{permission.action}}</span>
                      <input type="checkbox" class="mr-2 toggle toggle-sm toggle-accent"  (change)="permissionToggle(permission)" [checked]="isPemissionInProfile(permission.action)" />
                    </label> 
                  </div>

                </li>
              </ul>
            </div>
        </li>
      </ul>
    </div>
  </div>
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Group } from '../../interfaces/Group';
import { Response } from '../../interfaces/Response';
import { IPaginatableService } from 'src/app/interfaces/IPaginTableService';

@Injectable({
  providedIn: 'root',
})
export class GroupService implements IPaginatableService {
  private apiUrl = environment.apiUrl;
  private responseSubject = new BehaviorSubject<Response>({
    data: [],
    navigation: {
      page: 0,
      totalPages: 0,
      totalRows: 0,
      itemsPerPage: 0,
    },
    success: false,
    error: '',
    status: 0,
  });

  constructor(private http: HttpClient) {}
  // Fetch and emit the latest groups along with pagination data
  getGroups(): Observable<Response> {
    return this.http.get<Response>(
      `${this.apiUrl}/groups?page=1&pageSize=2000000`
    );
  }
  getGroupByIds(groupIds: number[]): Observable<Response> {
    return this.http.get<Response>(`${this.apiUrl}/groups/${groupIds}`);
  }

  getStructuredGroups(): Observable<Response> {
    return this.http.get<Response>(
      `${this.apiUrl}/groups/groups-hierarchy?page=1&pageSize=100000`
    );
  }

  getSubGroups(): Observable<Response> {
    this.http
      .get<Response>(`${this.apiUrl}/groups/sub-groups?page=1&pageSize=20`)
      .pipe(
        catchError((error) => {
          console.error('Error fetching groups:', error);
          return throwError(() => error);
        })
      )
      .subscribe((response: Response) => {
        this.responseSubject.next(response);
      });

    return this.responseSubject.asObservable();
  }
  getDataByPage(page: number, itemsPerPage: number): Observable<Response> {
    return this.http
      .get<Response>(`${this.apiUrl}/groups?page=${page}&limit=${itemsPerPage}`)
      .pipe(
        catchError((error) => {
          console.error('Error fetching groups:', error);
          return throwError(() => error);
        })
      );
  }

  // Add a new group
  addGroup(newGroup: Group): Observable<Response> {
    return this.http.post<Response>(`${this.apiUrl}/groups`, newGroup).pipe(
      catchError((error) => {
        console.error('Error creating group:', error);
        return throwError(() => error);
      }),
      tap((response: Response) => {
        if (response && response.data) {
        }
      })
    );
  }

  // Update a group
  updateGroup(id: string, group: Group): Observable<Response> {
    return this.http.patch<Response>(`${this.apiUrl}/groups/${id}`, group).pipe(
      catchError((error) => {
        console.error('Error updating group:', error);
        return throwError(() => error);
      })
    );
  }
  // Delete a group
  deleteGroup(id: string): Observable<Response> {
    return this.http.delete<Response>(`${this.apiUrl}/groups/${id}`).pipe(
      catchError((error) => {
        console.error('Error deleting group:', error);
        return throwError(() => error);
      }),
      tap(() => {
        const updatedGroups = this.responseSubject
          .getValue()
          .data.filter((u) => u.id !== id);
        this.responseSubject.next({
          ...this.responseSubject.getValue(),
          data: updatedGroups,
        });
      })
    );
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBySource'
})
export class FilterBySourcePipe implements PipeTransform {
  transform(socialMedias: any[], source: string): any[] {
    return socialMedias.filter(sm => sm.source === source);
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-instagram-fav-icon',
  templateUrl: './instagram-fav-icon.component.html',
  styleUrls: ['./instagram-fav-icon.component.scss']
})
export class InstagramFavIconComponent {
@Input() color:string = '#FFFFFF'
@Input() size: string="20px"
@Input() showLabel=false

}
<div class="flex overflow-hidden relative">
	<div class="p-3 m-auto flax justify-center items-center">
		<div *ngFor="let slide of slides; let i = index" 
				 [@slideAnimation]="activeSlideIndex" 
				 class=" inset-0 transition-all duration-300 ease-out m-auto" 
				 [ngClass]="{'block': i === activeSlideIndex, 'hidden': i !== activeSlideIndex}">

			<img *ngIf="isImage(slide)" [src]="slide.url || slide" class="m-auto object-contain">
			<video *ngIf="isVideo(slide)" [src]="slide.url || slide" controls class="m-auto rounded-xl" [class.shrinkable]="isShrinkable" ></video>
		</div>
	</div>
	<button *ngIf="slides.length > 1" (click)="prevSlide()" type="button" class="absolute left-0 top-1/2 ">
		<svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-cliking_blue ">
			<path stroke-linecap="round" stroke-linejoin="round" d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
		</svg>
	</button>
	
	<button *ngIf="slides.length > 1" (click)="nextSlide()" type="button" class="absolute right-0 top-1/2 ">
		<svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-cliking_blue">
			<path stroke-linecap="round" stroke-linejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
		</svg>
	</button>
	
</div>


import { Component, Input, Output, EventEmitter } from '@angular/core';
import Swal from 'sweetalert2';
import {ICON} from "src/app/interfaces/ModalIcon"

interface TimePeriod {
  openTime: string;
  closeTime: string;
}

interface SpecialHour {
  date: string;
  open: boolean;
  allDay: boolean;
  periods: TimePeriod[];
}

@Component({
  selector: 'app-special-hours',
  templateUrl: './special-hours.component.html',
  styleUrls: ['./special-hours.component.scss']
})
export class SpecialHoursComponent {
  @Input() specialHours: any;
  @Output() specialHoursChange = new EventEmitter<SpecialHour[]>();
	newSpecialHourPeriods: any[] = []
	groupedSpecialHours: any[] = []
	futureSpecialHours: SpecialHour[] = [];
  pastSpecialHours: SpecialHour[] = [];
	showPastHours: boolean = false;
	expandedIndexFuture: number | null = null;
	expandedIndexPast: number | null = null;

	activeItemId!: any;
  isEditModeOn: boolean = false;
  isModalOpen: boolean = false;
  modalIcon: ICON= ICON.delete;
  heading: string = '';
  subHeading: string = '';
	choosedItemIndex: number | null = null ;

	ngOnInit() {
		if (!this.specialHours || !Array.isArray(this.specialHours.specialHourPeriods)) {
			this.specialHours = { specialHourPeriods: [] };
		} else {
			this.groupedSpecialHours = this.specialHours.specialHourPeriods.reduce((acc: any[], curr: any) => {
				const dateKey = `${curr.startDate.year}-${curr.startDate.month < 10 ? '0' : ''}${curr.startDate.month}-${curr.startDate.day < 10 ? '0' : ''}${curr.startDate.day}`;
				const existingEntry = acc.find(item => item.date === dateKey);
				
				const allDay = (!curr.closed && curr.closeTime.hours == 24 &&  curr.openTime.hours == undefined && curr.openTime.minutes == undefined)
				if (!existingEntry) {
					const newEntry: SpecialHour = {
						date: dateKey,
						open: !curr.closed,
						allDay: allDay,
						periods: [],
					};
					
					console.log("curr", curr)
					if (!allDay) {
						if (!curr.closed) {
							const openTimeString = `${curr.openTime.hours.toString().padStart(2, '0')}:${(curr.openTime.minutes || 0).toString().padStart(2, '0')}`;
							const closeTimeString = `${curr.closeTime.hours.toString().padStart(2, '0')}:${(curr.closeTime.minutes || 0).toString().padStart(2, '0')}`;
							newEntry.periods.push({
								openTime: openTimeString,
								closeTime: closeTimeString
							});
						} else {
							newEntry.open = false;
						}
					}
					
					console.log("newEntry", newEntry)
					acc.push(newEntry);
				} else {
					if (!curr.closed) {
						const openTimeString = `${curr.openTime.hours.toString().padStart(2, '0')}:${(curr.openTime.minutes || 0).toString().padStart(2, '0')}`;
						const closeTimeString = `${curr.closeTime.hours.toString().padStart(2, '0')}:${(curr.closeTime.minutes || 0).toString().padStart(2, '0')}`;
						existingEntry.periods.push({
							openTime: openTimeString,
							closeTime: closeTimeString
						});
					} else {
						existingEntry.open = false;
					}
				}
	
				return acc;
			}, []);
			
			console.log("this.groupedSpecialHours", this.groupedSpecialHours )
			this.sortGroupedSpecialHours();
			this.splitSpecialHoursByDate();
		}
	}
	
	sortGroupedSpecialHours() {
		this.groupedSpecialHours.sort((a, b) => {
			const dateA = new Date(a.date).getTime();
			const dateB = new Date(b.date).getTime();
			return dateA - dateB;
		});
	}
	
	splitSpecialHoursByDate() {
		const currentDate = new Date().getTime();
		this.pastSpecialHours = this.groupedSpecialHours.filter(specialHour => new Date(specialHour.date).getTime() < currentDate);
		this.futureSpecialHours = this.groupedSpecialHours.filter(specialHour => new Date(specialHour.date).getTime() >= currentDate);
	}
	
	
	toggleFormFuture(index: number) {
		this.expandedIndexPast = null; 

    if (this.expandedIndexFuture === index) {
      this.expandedIndexFuture = null; 
    } else {
      this.expandedIndexFuture = index; 
    }
  }

	toggleFormPast(index: number) {
		this.expandedIndexFuture = null; 

    if (this.expandedIndexPast === index) {
      this.expandedIndexPast = null; 
    } else {
      this.expandedIndexPast = index; 
    }
  }

  addSpecialHour() {
    const newSpecialHour: SpecialHour = {
      date: '',
      open: true,
      allDay: false,
      periods: [{ openTime: '', closeTime: '' }],
    };
		this.newSpecialHourPeriods.push(newSpecialHour);
    // this.specialHours.specialHourPeriods.push(newSpecialHour);
  }

	createNewSpecialHour(i: number) {
		this.groupedSpecialHours.push(this.newSpecialHourPeriods[i]);
		this.sortGroupedSpecialHours();
		this.splitSpecialHoursByDate();
		this.cancelSpecialHourCreation(i);
		this.transformGroupedToSpecialHours(); // Update specialHours
	}

  cancelSpecialHourCreation(index: number) {
    this.newSpecialHourPeriods.splice(index, 1);
    this.emitChanges();
  }


	toggleOpen(specialHour: any, i: number, type: string | null = null) {
    specialHour.open = !specialHour.open;
		
		if (type && type == "new") {
			this.newSpecialHourPeriods[i].open = !this.newSpecialHourPeriods[i].open
		} else {
			this.groupedSpecialHours[i].open = !this.groupedSpecialHours[i].open
		}
    if (specialHour.open && specialHour.periods.length == 0) {
      this.addTimePeriod(specialHour);
    }
    this.transformGroupedToSpecialHours(); // Update specialHours
  }

	toggleAllDay(specialHour: any, i: number, type: string | null = null) {
		console.log("specialHour", specialHour)
    specialHour.allDay = !specialHour.allDay;
		if (type && type == "new") {
			this.newSpecialHourPeriods[i].allDay = !this.newSpecialHourPeriods[i].allDay
		} else {
			this.groupedSpecialHours[i].allDay = !this.groupedSpecialHours[i].allDay
		}
    if (specialHour.allDay && specialHour.periods.length == 0) {
      this.addTimePeriod(specialHour);
    }
    this.transformGroupedToSpecialHours(); // Update specialHours
  }

  addTimePeriod(specialHour: any) {
    specialHour.periods.push({ openTime: '', closeTime: '' });
    this.emitChanges();
  }

  removeTimePeriod(specialHour: any, periodIndex: number) {
    specialHour.periods.splice(periodIndex, 1);
    this.emitChanges();
  }

	transformGroupedToSpecialHours() {
		const transformed = this.groupedSpecialHours.reduce((acc: any[], curr: SpecialHour) => {
			console.log("transformGroupedToSpecialHours curr:", curr)
		
			// Handle the case where the day is closed all day
			if (curr.open === false) {
				acc.push({
					startDate: {
						year: parseInt(curr.date.split('-')[0], 10),
						month: parseInt(curr.date.split('-')[1], 10),
						day: parseInt(curr.date.split('-')[2], 10)
					},
					endDate: {
						year: parseInt(curr.date.split('-')[0], 10),
						month: parseInt(curr.date.split('-')[1], 10),
						day: parseInt(curr.date.split('-')[2], 10)
					},
					closed: true
				});
			} else {
				if (curr.allDay === true) {
					acc.push({
						startDate: {
							year: parseInt(curr.date.split('-')[0], 10),
							month: parseInt(curr.date.split('-')[1], 10),
							day: parseInt(curr.date.split('-')[2], 10)
						},
						endDate: {
							year: parseInt(curr.date.split('-')[0], 10),
							month: parseInt(curr.date.split('-')[1], 10),
							day: parseInt(curr.date.split('-')[2], 10)
						},
						openTime: { },
						closeTime: { hours: 24 },
						closed: false
					});

				} else {
					curr.periods.forEach(period => {
						const [openHours, openMinutes] = period.openTime.split(':').map(Number);
						const [closeHours, closeMinutes] = period.closeTime.split(':').map(Number);
			
						acc.push({
							startDate: {
								year: parseInt(curr.date.split('-')[0], 10),
								month: parseInt(curr.date.split('-')[1], 10),
								day: parseInt(curr.date.split('-')[2], 10)
							},
							endDate: {
								year: parseInt(curr.date.split('-')[0], 10),
								month: parseInt(curr.date.split('-')[1], 10),
								day: parseInt(curr.date.split('-')[2], 10)
							},
							openTime: { hours: openHours, minutes: openMinutes || 0 },
							closeTime: { hours: closeHours, minutes: closeMinutes || 0 },
							closed: !curr.open
						});
					});
				}
			}
	
			return acc;
		}, []);
	
		this.specialHours.specialHourPeriods = transformed;
		this.emitChanges();
		console.log("this.specialHours", this.specialHours)
	}


  emitChanges() {
    this.specialHoursChange.emit(this.specialHours);
  }

	// modal
	closeModal(){
    this.isModalOpen = false;
  }

	removeSpecialHour(index: number) {
		// this.subHeading = 'generic.confirmUserDeletionMsg';
    this.heading = 'generic.confirmDeletion';
    this.modalIcon = ICON.delete;
    this.isModalOpen = true;
    this.choosedItemIndex = index;
  }

	deleteSpecialHour(){
		console.log("this.choosedItemIndex", this.choosedItemIndex)
		if (this.choosedItemIndex !== null && this.choosedItemIndex !== undefined) {
			console.log('this.groupedSpecialHours[this.choosedItemIndex]', this.groupedSpecialHours[this.choosedItemIndex])
			this.specialHours.specialHourPeriods.splice(this.choosedItemIndex, 1);
			this.groupedSpecialHours.splice(this.choosedItemIndex, 1);
			this.expandedIndexFuture = null;
			this.expandedIndexPast = null;
			this.transformGroupedToSpecialHours(); // Update specialHours
		}
		this.closeModal();
		this.choosedItemIndex = null;
	}

	isDatePast(specialHourDate: string): boolean {
    const currentDate = new Date();
    const specialHour = new Date(specialHourDate);
    return specialHour < currentDate;
  }
}

import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/user/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GlobalSettingsComponent } from './components/dynamic/globalSettings/globalSettings.component';
import { TableComponent } from './components/dynamic/table/table.component';
import { PaginatorComponent } from './components/dynamic/paginator/paginator.component';
import { NotfoundComponent } from './components/error/notfound/notfound.component';
import { SocialMediaConnectComponent } from './components/socialmedia/socialmedias/social-media-connect/social-media-connect.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { PostsComponent } from './components/post/posts/posts.component';
import { SocialmediasComponent } from './components/socialmedia/socialmedias/socialmedias.component';
import { ChoosePageComponent } from './components/socialmedia/socialmedias/choose-page/choose-page.component';
import { GridListComponent } from './components/dynamic/grid-list/grid-list.component';
import { CreatePostComponent } from './components/post/create-post/create-post.component';
import { LanguageComponent } from './components/dynamic/language/language.component';
import { InnerSettingsComponent } from './components/dynamic/inner-settings/inner-settings.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SocialMediaConnectionsComponent } from './components/socialmedia/socialmedias/social-media-connections/social-media-connections.component';
import { PostLabelPreviewComponent } from './components/post/calendar/post-label-preview/post-label-preview.component';
import { DropdownComponent } from './components/dynamic/dropdown/dropdown.component';
import { FilterBySourcePipe } from './pipes/filter-by-source.pipe';
import { FilterTypesPipe } from './pipes/filter-types.pipe';
import { DateTimePickerComponent } from './components/dynamic/date-time-picker/date-time-picker.component';
import { SideBarComponent } from './components/dynamic/sidebar/sidebar.component';
import { TopBarComponent } from './components/dynamic/top-bar/top-bar.component';
import { MainLayoutComponent } from './components/layouts/main-layout/main-layout.component';
import { AuthLayoutComponent } from './components/layouts/auth-layout/auth-layout.component';
import { PostCalenderComponent } from './components/post/post-calender/post-calender.component';
import { PlatformFilterComponent } from './components/dynamic/platform-filter/platform-filter.component';
import { PostsLibraryComponent } from './components/post/posts-library/posts-library.component';
import { GenericDropDownComponent } from './components/dynamic/generic-drop-down/generic-drop-down.component';
import { PostPreviewComponent } from './components/post/post-preview/post-preview.component';
import { DateFilterComponent } from './components/dynamic/date-filter/date-filter.component';
import { CalendarMonthViewComponent } from './components/post/calendar/calendar-month-view/calendar-month-view.component';
import { CalendarYearViewComponent } from './components/post/calendar/calendar-year-view/calendar-year-view.component';
import { CalendarWeekViewComponent } from './components/post/calendar/calendar-week-view/calendar-week-view.component';
import { CalendarDayViewComponent } from './components/post/calendar/calendar-day-view/calendar-day-view.component';
import { MonthPreviewComponent } from './components/post/calendar/calendar-year-view/month-preview/month-preview.component';
import { DayPostsPreviewComponent } from './components/post/calendar/calendar-year-view/day-posts-preview/day-posts-preview.component';
import { PostFullHourPreviewComponent } from './components/post/calendar/calendar-week-view/post-full-hour-preview/post-full-hour-preview.component';
import { ShowPostComponent } from './components/post/show-post/show-post.component';
import { PostDayLabelComponent } from './components/post/calendar/post-day-label/post-day-label.component';
import { CarouselComponent } from './components/dynamic/carousel/carousel.component';
import { SociabblePageComponent } from './components/sociabble-page/sociabble-page.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MediaPreviewComponent } from './components/dynamic/media-preview/media-preview.component';
import { MiniMediaBrowserComponent } from './components/post/mini-media-browser/mini-media-browser.component';
import { DatePickerComponent } from './components/dynamic/date-picker/date-picker.component';
import { TimePickerComponent } from './components/dynamic/time-picker/time-picker.component';
import { PresenceManagementComponent } from './components/presence-management/presence-management.component';
import { ManagePageComponent } from './components/presence-management/manage-page/manage-page.component';
import { CategoriesDropdownComponent } from './components/presence-management/manage-page/categories-dropdown/categories-dropdown.component';
import { GooglePagePreviewComponent } from './components/presence-management/google-page-preview/google-page-preview.component';
import { PermissionsEditorComponent } from './components/user/permissions-editor/permissions-editor.component';
import { EmojiPickerComponent } from './components/dynamic/emoji-picker/emoji-picker.component';
import { UiPermissionsMapperComponent } from './components/dynamic/ui-permissions-mapper/ui-permissions-mapper.component';
import { UsersManagerComponent } from './components/user/management/users-manager/users-manager.component';
import { ProfileManagerComponent } from './components/user/management/profile-manager/profile-manager.component';
import { PermissionProfileManagerComponent } from './components/user/management/permission-profile-manager/permission-profile-manager.component';
import { AccreditationManagerComponent } from './components/user/management/accreditation-manager/accreditation-manager.component';
import { GroupManagerComponent } from './components/user/management/group-manager/group-manager.component';
import { TreeViewComponent } from './components/dynamic/tree-view/tree-view.component';
import { AddressAutocompleteComponent } from './components/presence-management/manage-page/address-autocomplete/address-autocomplete.component';
import { ApiErrorsComponent } from './components/presence-management/api-errors/api-errors.component';
import { HoursFormComponent } from './components/presence-management/manage-page/hours-form/hours-form.component';
import { RegularHoursComponent } from './components/presence-management/manage-page/hours-form/regular-hours/regular-hours.component';
import { ChangePasswordModalComponent } from './components/user/management/users-manager/change-password-modal/change-password-modal.component';
import { ModalComponent } from './components/dynamic/modal/modal.component';
import { InstagramFavIconComponent } from './components/dynamic/svgs/instagram-fav-icon/instagram-fav-icon.component';
import { FacebookFavIconComponent } from './components/dynamic/svgs/facebook-fav-icon/facebook-fav-icon.component';
import { GoogleFavIconComponent } from './components/dynamic/svgs/google-fav-icon/google-fav-icon.component';
import { LinkedinFavIconComponent } from './components/dynamic/svgs/linkedin-fav-icon/linkedin-fav-icon.component';
import { RegularHoursDropdownComponent } from './components/presence-management/manage-page/hours-form/regular-hours/regular-hours-dropdown/regular-hours-dropdown.component';
import { CustomTimeInputComponent } from './components/dynamic/custom-time-input/custom-time-input.component';
import { SpecialHoursComponent } from './components/presence-management/manage-page/hours-form/special-hours/special-hours.component';
import { WidgetStatsComponent } from './components/dynamic/widget-stats/widget-stats.component';
import { SocialMediaStatsComponent } from './components/dynamic/social-media-stats/social-media-stats.component';
import { LinkedinStatsComponent } from './components/dashboard/stats/linkedin-stats/linkedin-stats.component';
import { FacebookStatsComponent } from './components/dashboard/stats/facebook-stats/facebook-stats.component';
import { GoogleStatsComponent } from './components/dashboard/stats/google-stats/google-stats.component';
import { InstagramStatsComponent } from './components/dashboard/stats/instagram-stats/instagram-stats.component';
import { WidgetPlateformeStatsComponent } from './components/dashboard/stats/widget-plateforme-stats/widget-plateforme-stats.component';
import { PostDetailsComponent } from './components/post/post-preview/post-details/post-details.component';
import { SemanticAnalysisComponent } from './components/dynamic/semantic-analysis/semantic-analysis.component';
import { PostPlateformPreviewComponent } from './components/dynamic/post-plateform-preview/post-plateform-preview.component';
import { PostPlateformCommentsComponent } from './components/dynamic/post-plateform-comments/post-plateform-comments.component';
import { TooltipLeftComponent } from './components/dynamic/form/tooltip-left/tooltip-left.component';
import { TooltipUpComponent } from './components/dynamic/form/tooltip-up/tooltip-up.component';
import { ListItemComponent } from './components/dynamic/form/list-item/list-item.component';
import { PostStatsWidgetsComponent } from './components/dynamic/form/post-stats-widgets/post-stats-widgets.component';
import { GridImagesFbComponent } from './components/dynamic/form/grid-images-fb/grid-images-fb.component';
import { GridImagesLinkedinComponent } from './components/dynamic/form/grid-images-linkedin/grid-images-linkedin.component';
import { MoreHoursComponent } from './components/presence-management/manage-page/hours-form/more-hours/more-hours.component';
import { PostCreatePreviewComponent } from './components/dynamic/post-create-preview/post-create-preview.component';
import { ErrorPageComponent } from './components/error/error-page/error-page.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { AuthService } from './services/utilities/auth.service';
import { NewPasswordComponent } from './components/user/new-password/new-password.component';
import { LoaderComponent } from './components/dynamic/loader/loader.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SocialmediasComponent,
    DashboardComponent,
    GlobalSettingsComponent,
    TableComponent,
    PaginatorComponent,
    NotfoundComponent,
    SocialMediaConnectComponent,
    PostsComponent,
    ChoosePageComponent,
    GridListComponent,
    CreatePostComponent,
    LanguageComponent,
    InnerSettingsComponent,
    SocialMediaConnectionsComponent,
    DropdownComponent,
    FilterBySourcePipe,
    FilterTypesPipe,
    DateTimePickerComponent,
    SideBarComponent,
    TopBarComponent,
    MainLayoutComponent,
    AuthLayoutComponent,
    PostCalenderComponent,
    PlatformFilterComponent,
    PostsLibraryComponent,
    GenericDropDownComponent,
    PostPreviewComponent,
    DateFilterComponent,
    CalendarMonthViewComponent,
    CalendarYearViewComponent,
    CalendarWeekViewComponent,
    CalendarDayViewComponent,
    MonthPreviewComponent,
    DayPostsPreviewComponent,
    PostLabelPreviewComponent,
    PostFullHourPreviewComponent,
    ShowPostComponent,
    PostDayLabelComponent,
    CarouselComponent,
    PresenceManagementComponent,
    ManagePageComponent,
    CategoriesDropdownComponent,
    GooglePagePreviewComponent,
    SociabblePageComponent,
    MediaPreviewComponent,
    MiniMediaBrowserComponent,
    DatePickerComponent,
    TimePickerComponent,
    PermissionsEditorComponent,
    EmojiPickerComponent,
    UiPermissionsMapperComponent,
    UsersManagerComponent,
    ProfileManagerComponent,
    PermissionProfileManagerComponent,
    AccreditationManagerComponent,
    GroupManagerComponent,
    TreeViewComponent,
    AddressAutocompleteComponent,
    ApiErrorsComponent,
    HoursFormComponent,
    RegularHoursComponent,
    ChangePasswordModalComponent,
    ModalComponent,
    InstagramFavIconComponent,
    FacebookFavIconComponent,
    GoogleFavIconComponent,
    LinkedinFavIconComponent,
    RegularHoursDropdownComponent,
    CustomTimeInputComponent,
    SpecialHoursComponent,
    WidgetStatsComponent,
    SocialMediaStatsComponent,
    LinkedinStatsComponent,
    FacebookStatsComponent,
    GoogleStatsComponent,
    InstagramStatsComponent,
    WidgetPlateformeStatsComponent,
    PostDetailsComponent,
    SemanticAnalysisComponent,
    PostPlateformPreviewComponent,
    PostPlateformCommentsComponent,
    TooltipLeftComponent,
    TooltipUpComponent,
    ListItemComponent,
    PostStatsWidgetsComponent,
    GridImagesFbComponent,
    GridImagesLinkedinComponent,
    MoreHoursComponent,
    PostCreatePreviewComponent,
    ErrorPageComponent,
    NewPasswordComponent,
    LoaderComponent,
  ],
  imports: [
    NgxJsonViewerModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    EditorModule,
    DragDropModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    // FlatpickrModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      // useValue: Sentry.createErrorHandler({
      // showDialog: true,
      // }),
    },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      // deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

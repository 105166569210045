<!-- api-errors.component.html -->
<div class="container mx-auto p-4">
  <h1 class="text-2xl font-bold mb-4">API Errors</h1>
  <table class="min-w-full divide-y divide-gray-200">
    <thead class="bg-gray-50">
      <tr>
        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Error Name</th>
        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Page Data</th>
        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created At</th>
      </tr>
    </thead>
    <tbody class="bg-white divide-y divide-gray-200">
      <tr *ngFor="let error of errors">
        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ error.id }}</td>
        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ error.errorName }}</td>
        <td class="px-6 py-4 text-sm text-gray-500 max-w-[500px] overflow-y-auto overflow-x-hidden">
					<pre class="text-xs whitespace-pre-wrap">{{ error.formValues.pageData | json }}</pre>
				</td>
        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ error.createdAt | date:'short' }}</td>
      </tr>
    </tbody>
  </table>
</div>

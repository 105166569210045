<div class="fixed post-item pannel-trigger" [style.left.px]="xpos" [style.top.px]="ypos" *ngIf="isVisible" style="pointer-events: none;" [attr.data-post-id]="data[0].SocialPlatform[0].postId" >
    <div class="post-item pannel-trigger post-preview relative bg-white px-4 my-5 mx-auto border border-indigo-500 rounded-md font-medium shadow-lg w-[25rem] max-h-[90vh] overflow-hidden" style="pointer-events: all; max-height: 400px; overflow-y: auto;" [attr.data-post-id]="data[0].SocialPlatform[0].postId" >
        <button class=" absolute  bg-white px-1 rounded-full top-0 right-0 mt-2 mr-3 text-sm hover:text-pink-600" (click)="close()">✕</button>
        
        <ul class="post-item pannel-trigger list-none mt-3 text-sm" [attr.data-post-id]="data[0].SocialPlatform[0].postId" >
            <li class="post-item pannel-trigger flex flex-col justify-start items-start mb-2 p-2 rounded-md" *ngFor="let post of data; let i = index" [ngClass]="{
                'c-b-accent-3 text-white': post.publishingDatetime != null,
                'c-b-accent-4': post.expectedPublishingDatetime != null}"
                [attr.data-post-id]="post.SocialPlatform[0].postId" 
                >
                <div class="post-item pannel-trigger w-full" [attr.data-post-id]="post.SocialPlatform[0].postId" >
                    <span class="post-item pannel-trigger text-sm" [attr.data-post-id]="post.SocialPlatform[0].postId" >
                        {{ getTime(post) }}
                    </span>
                </div>
       
                <div class="post-item pannel-trigger flex-1 px-2 w-full" [attr.data-post-id]="post.SocialPlatform[0].postId" >
                    {{ post.title }}
                </div>
                <div class="post-item pannel-trigger w-full" [attr.data-post-id]="post.SocialPlatform[0].postId" >
                    <p class="post-item pannel-trigger" [innerHTML]="htmlformat(post.text)" [attr.data-post-id]="post.SocialPlatform[0].postId" ></p>
                </div>
                <div class="post-item pannel-trigger w-full" [attr.data-post-id]="post.SocialPlatform[0].postId" >
                    <ul class="post-item  pannel-trigger grid grid-cols-2 p-1 gap-1" [attr.data-post-id]="post.SocialPlatform[0].postId" >
                        <li [attr.data-post-id]="post.SocialPlatform[0].postId"  *ngFor="let platform of post.SocialPlatform" class="post-item pannel-trigger rounded-full w-24"
                            [ngClass]="{
                                'bg-sky-600': platform.name.toLowerCase() == 'facebook',
                                'bg-orange-600': platform.name.toLowerCase() == 'google',
                                'bg-blue-600': platform.name.toLowerCase() == 'linkedin',
                                'bg-pink-600': platform.name.toLowerCase() == 'instagram'
                            }"
                            >
                            <span [attr.data-post-id]="post.SocialPlatform[0].postId"  class="post-item pannel-trigger m-auto w-11/12 h-6 py-1">
                                <img [attr.data-post-id]="post.SocialPlatform[0].postId" src="assets/img/{{ platform.name.toLowerCase() }}-label.png" class="post-item pannel-trigger" alt="{{ platform.name }}">
                            </span>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
    </div>
</div>

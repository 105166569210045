import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import { JwtPayload } from '../interfaces/JwtPayload';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.getToken(); // Implement this method to retrieve your token

    if (authToken) {

      const authReq = request.clone({
        headers: request.headers.set('Authorization', `${authToken}`)
      });

      // Send the newly created request
      return next.handle(authReq);
    } 

    // If no token, send the original request
    return next.handle(request);
  }

  private isTokenValid(token:string) {
    {
      try {
        const decodedToken = jwtDecode(token) as JwtPayload;
        // Check if decodedToken exists and has the 'exp' property
        
        if (decodedToken && decodedToken.exp) {
          // Check if the token has expired
          const currentTime = Date.now() / 1000; // Convert milliseconds to seconds
          return decodedToken.exp > currentTime;
        } else {
          // Handle the case where 'exp' is not present in the token
          console.error('Token does not have an expiration time.');
          localStorage.removeItem('token')
          return false;
        }
      } catch (error) {
        // Handle decoding errors or invalid tokens
        console.error('Error decoding or validating the token:', error);
        localStorage.removeItem('token')
        return false;
      }
    }
  }
  


private getToken(): string | null {
  const token = localStorage.getItem('token');
  if (token && this.isTokenValid(token)) {
   
        return token;
      
    } else  {
      return null;
    }

  }
}
  

  



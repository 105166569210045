import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-post-plateform-comments',
  templateUrl: './post-plateform-comments.component.html',
  styleUrls: ['./post-plateform-comments.component.scss']
})
export class PostPlateformCommentsComponent {
  @Input() comments: any[] = [];
  @Output() itemSelected = new EventEmitter<any>();

  reply(item: any){
    console.log("item", item);
    
    this.itemSelected.emit(item);;
  }

}

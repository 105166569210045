import { AbstractControl, ValidatorFn, FormGroup } from '@angular/forms';

export function googlePlatformValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control instanceof FormGroup) {
      const typePost = control.get('typePost')?.value;
      const selectedPlatform = control.get('selectedPlatform')?.value; // Make sure this control exists in your form

      if (selectedPlatform?.includes('GOOGLE') && typePost === 'N/A') {
        // Validation failed
        return { invalidGoogleTypePost: true };
      }
    }
    // Validation passed
    return null;
  };
}

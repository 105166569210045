import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Address } from 'src/app/interfaces/Address';
import { FormGroup } from '@angular/forms';
import { Debug } from 'src/app/utils/debug';

// Import google maps types
declare const google: any;

@Component({
  selector: 'app-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  styleUrls: ['./address-autocomplete.component.scss'],
})
export class AddressAutocompleteComponent implements OnInit {
  @ViewChild('autocomplete', { static: false }) autocompleteInput!: ElementRef;
  @Output() addressSelected: EventEmitter<any> = new EventEmitter();
  @Input() currentAddress: any; // Add this input property to accept the current address
  @Input() pageForm!: FormGroup;

  autocomplete!: google.maps.places.Autocomplete;

  ngOnInit() {
    // Initialization moved to ngAfterViewInit
  }

  ngAfterViewInit() {
    this.initAutocomplete();
    Debug.log('currentAddress', this.currentAddress);
    if (this.currentAddress) {
      this.setCurrentAddress(this.currentAddress);
    }
  }
  initAutocomplete() {
    Debug.log('initAutocomplete');
    const options = {
      types: ['address'],
    };
    Debug.log('this.autocompleteInput', this.autocompleteInput);
    this.autocomplete = new google.maps.places.Autocomplete(
      this.autocompleteInput.nativeElement,
      options
    );
    Debug.log('this.autocomplete', this.autocomplete);

    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace();
      const address = this.parseAddress(place);
      this.addressSelected.emit(address);
    });
  }

  parseAddress(place: google.maps.places.PlaceResult): Address {
    const address: Address = {
      addressLines: [],
      locality: '',
      postalCode: '',
      regionCode: '',
    };

    if (!place.address_components) {
      return address;
    }

    place.address_components.forEach((component) => {
      const types = component.types;

      if (types.includes('street_number')) {
        address.addressLines[0] = component.long_name;
      }

      if (types.includes('route')) {
        address.addressLines[1] = component.long_name;
      }

      if (types.includes('locality')) {
        address.locality = component.long_name;
      }

      if (types.includes('postal_code')) {
        address.postalCode = component.long_name;
      }

      if (types.includes('country')) {
        address.regionCode = component.short_name;
      }
    });

    return address;
  }

  formatPart(part: string | undefined): string {
    return part ? part : '';
  }

  setCurrentAddress(address: Address) {
    const addressString = this.formatAddress(address);
    this.autocompleteInput.nativeElement.value = addressString;
  }

  formatAddress(address: Address): string {
    const addressLines = address.addressLines
      .filter((line) => !!line)
      .join(', ');
    const postalCode = this.formatPart(address.postalCode);
    const locality = this.formatPart(address.locality);
    const regionCode = this.formatPart(address.regionCode);

    // Only join parts that are defined and not empty
    return [addressLines, postalCode, locality, regionCode]
      .filter((part) => part !== '')
      .join(', ');
  }
}

<div class="-mt-10 overflow-auto h-[85vh]">
  <div class="ml-5">
    <div class="join">
      <button
        *ngIf="all"
        (click)="selectPlateform('all')"
        class="text-xl font-semibold h-[50px] rounded-t-lg flex justify-center items-center mt-6 w-[10%]"
        [ngClass]="{
          'text-gray-400': selectedPlateform != 'all',
          'bg-cliking_blue': selectedPlateform == 'all',
          'text-white': selectedPlateform == 'all'
        }"
      >
        <span class="mb-1">{{ "generic.all" | translate }}</span>
      </button>
      <button
        *ngIf="linkedin"
        (click)="selectPlateform('linkedin')"
        class="flex h-[50px] rounded-t-lg justify-center items-center w-[10%] mt-[24px]"
        [ngClass]="{
          'bg-cliking_blue': selectedPlateform == 'linkedin',
          'cursor-not-allowed': !linkedin
        }"
      >
        <div class="flex justify-center items-center">
          <img
            [src]="
              selectedPlateform == 'linkedin'
                ? 'assets/img/linkedin-logo.png'
                : 'assets/img/linkedin-logo-disabled.png'
            "
            alt="LinkedIn Logo"
            class="w-[60%] h-[60%] mb-1"
          />
        </div>
      </button>
      <button
        *ngIf="google"
        (click)="selectPlateform('google')"
        class="flex h-[50px] rounded-t-lg justify-center items-center w-[10%] mt-[24px]"
        [ngClass]="{
          'bg-cliking_blue': selectedPlateform == 'google',
          'cursor-not-allowed': !google
        }"
      >
        <div class="flex justify-center items-center">
          <img
            [src]="
              selectedPlateform == 'google'
                ? 'assets/img/google-logo.png'
                : 'assets/img/google-logo-disabled.png'
            "
            alt="Google Logo"
            class="w-[50%] h-[50%]"
          />
        </div>
      </button>
      <button
        *ngIf="facebook"
        (click)="selectPlateform('facebook')"
        class="flex h-[50px] rounded-t-lg justify-center items-center w-[10%] mt-[24px]"
        [ngClass]="{
          'bg-cliking_blue': selectedPlateform == 'facebook',
          'cursor-not-allowed': !facebook
        }"
      >
        <div class="flex justify-center items-center">
          <img
            [src]="
              selectedPlateform == 'facebook'
                ? 'assets/img/facebook-logo.png'
                : 'assets/img/facebook-logo-disabled.png'
            "
            alt="Facebook Logo"
            class="w-[60%] h-[60%]"
          />
        </div>
      </button>
      <button
        *ngIf="instagram"
        (click)="selectPlateform('instagram')"
        class="flex h-[50px] rounded-t-lg justify-center items-center w-[10%] mt-[24px]"
        [ngClass]="{
          'bg-cliking_blue': selectedPlateform == 'instagram',
          'cursor-not-allowed': !instagram
        }"
      >
        <div class="flex justify-center items-center">
          <img
            [src]="
              selectedPlateform == 'instagram'
                ? 'assets/img/instagram-logo.png'
                : 'assets/img/instagram-logo-disabled.png'
            "
            alt="Instagram Logo"
            class="w-[60%] h-[60%]"
          />
        </div>
      </button>
    </div>
    <div class="h-[5px] w-[98%] bg-cliking_blue mb-5"></div>
  </div>
  <div class="flex justify-between h-[620px]">
    <div class="w-[47.5%] relative bg-white h-full ml-5 rounded-3xl">
      <div class="font-bold text-xl ml-5 mt-4">
        {{ "posts.preview" | translate }}
      </div>
      <app-post-plateform-preview
        [data]="data"
        [selectedPlateform]="selectedPlateform"
      />
      <button
        *ngIf="selectedPlateform == 'all'"
        class="bg-cliking_blue w-full h-10 rounded-b-2xl bottom-0 absolute text-white text-lg"
      >
        {{ "posts.copy" | translate }}
      </button>
    </div>
    <div class="w-[47.5%] mr-5">
      <div class="h-[22%] mb-4">
        <div class="bg-white h-[33%] rounded-t-3xl pt-3">
          <span class="font-bold text-xl ml-5">{{
            "generic.relevantPages" | translate
          }}</span>
        </div>
        <div class="bg-white h-[67%] rounded-b-3xl" style="overflow-y: scroll">
          <!-- <app-list-item [type]="groups.length>1?'generic.establishmentsSelected':'generic.establishmentSelected'" [list]="groups" class="bg-cliking_blue "/> -->
          <app-list-item
            *ngIf="selectedPlateform == 'all'"
            [type]="
              pages.length > 1
                ? 'generic.selectedPages'
                : 'generic.selectedPage'
            "
            [list]="pages"
          />
          <app-list-item
            *ngIf="selectedPlateform == 'linkedin'"
            [type]="
              linkedinPages.length > 1
                ? 'generic.selectedPages'
                : 'generic.selectedPage'
            "
            [list]="linkedinPages"
          />
          <app-list-item
            *ngIf="selectedPlateform == 'facebook'"
            [type]="
              facebookPages.length > 1
                ? 'generic.selectedPages'
                : 'generic.selectedPage'
            "
            [list]="facebookPages"
          />
          <app-list-item
            *ngIf="selectedPlateform == 'google'"
            [type]="
              googlePages.length > 1
                ? 'generic.selectedPages'
                : 'generic.selectedPage'
            "
            [list]="googlePages"
          />
          <app-list-item
            *ngIf="selectedPlateform == 'instagram'"
            [type]="
              instagramPages.length > 1
                ? 'generic.selectedPages'
                : 'generic.selectedPage'
            "
            [list]="instagramPages"
          />
        </div>
      </div>
      <div class="bg-white mb-4 rounded-3xl">
        <div class="font-bold text-xl ml-5 pb-3 pt-4">
          {{ "generic.statistics" | translate }}
        </div>
        <div *ngIf="selectedPlateform == 'all'" class="flex flex-wrap ml-3">
          <app-post-stats-widgets
            [value]="renderVal(allData?.clickCount)"
            [type]="'views'"
          />
          <app-post-stats-widgets
            [value]="renderVal(allData?.likeCount)"
            [type]="'reactions'"
          />
          <app-post-stats-widgets
            [value]="renderVal(allData?.commentCount)"
            [type]="'comments'"
          />
          <app-post-stats-widgets
            [value]="renderVal(allData?.shareCount)"
            [type]="'shares'"
          />
          <app-post-stats-widgets
            [value]="renderVal(allData?.uniqueImpressionsCount)"
            [type]="'interactions'"
          />
          <app-post-stats-widgets
            [value]="renderVal(allData?.engagement * 100).toFixed(2) + '%'"
            [type]="'reach'"
          />
        </div>
        <div
          *ngIf="selectedPlateform == 'linkedin'"
          class="flex flex-wrap ml-3"
        >
          <app-post-stats-widgets
            [value]="renderVal(linkedinData?.postAnalytics?.clickCount)"
            [type]="'views'"
          />
          <app-post-stats-widgets
            [value]="renderVal(linkedinData?.postAnalytics?.likeCount)"
            [type]="'reactions'"
          />
          <app-post-stats-widgets
            [value]="renderVal(linkedinData?.postAnalytics?.commentCount)"
            [type]="'comments'"
          />
          <app-post-stats-widgets
            [value]="renderVal(linkedinData?.postAnalytics?.shareCount)"
            [type]="'shares'"
          />
          <app-post-stats-widgets
            [value]="
              renderVal(linkedinData?.postAnalytics?.uniqueImpressionsCount)
            "
            [type]="'interactions'"
          />
          <app-post-stats-widgets
            [value]="
              renderVal(linkedinData?.postAnalytics?.engagement) != '-'
                ? (linkedinData?.postAnalytics?.engagement * 100).toFixed(2) +
                  '%'
                : '-'
            "
            [type]="'reach'"
          />
        </div>
        <div
          *ngIf="selectedPlateform == 'facebook'"
          class="flex flex-wrap ml-3"
        >
          <!-- <app-post-stats-widgets [value]="renderVal(facebookData?.postAnalytics?.clickCount)" [type]="'views'"/> -->
          <app-post-stats-widgets
            [value]="renderVal(facebookData?.postAnalytics?.reactions)"
            [type]="'reactions'"
          />
          <app-post-stats-widgets
            [value]="renderVal(facebookData?.postAnalytics?.comments)"
            [type]="'comments'"
          />
          <app-post-stats-widgets
            [value]="renderVal(facebookData?.postAnalytics?.shares)"
            [type]="'shares'"
          />
          <app-post-stats-widgets
            [value]="renderVal(facebookData?.postAnalytics?.metrics[0]?.value)"
            [type]="'impressions'"
          />
          <app-post-stats-widgets
            [value]="renderVal(facebookData?.postAnalytics?.metrics[1]?.value)"
            [type]="'interactions'"
          />
        </div>
        <div *ngIf="selectedPlateform == 'google'" class="flex flex-wrap ml-3">
          <app-post-stats-widgets
            [value]="renderVal(googleData?.postAnalytics?.clickCount)"
            [type]="'views'"
          />
          <app-post-stats-widgets
            [value]="renderVal(googleData?.postAnalytics?.clickCount)"
            [type]="'reactions'"
          />
          <app-post-stats-widgets
            [value]="renderVal(googleData?.postAnalytics?.clickCount)"
            [type]="'comments'"
          />
          <app-post-stats-widgets
            [value]="renderVal(googleData?.postAnalytics?.clickCount)"
            [type]="'shares'"
          />
          <app-post-stats-widgets
            [value]="renderVal(googleData?.postAnalytics?.clickCount)"
            [type]="'interactions'"
          />
          <app-post-stats-widgets
            [value]="renderVal(googleData?.postAnalytics?.clickCount)"
            [type]="'reach'"
          />
        </div>
        <div
          *ngIf="selectedPlateform == 'instagram'"
          class="flex flex-wrap ml-3"
        >
          <app-post-stats-widgets
            [value]="renderVal(instagramData?.postAnalytics?.clickCount)"
            [type]="'views'"
          />
          <app-post-stats-widgets
            [value]="renderVal(instagramData?.postAnalytics?.clickCount)"
            [type]="'reactions'"
          />
          <app-post-stats-widgets
            [value]="renderVal(instagramData?.postAnalytics?.clickCount)"
            [type]="'comments'"
          />
          <app-post-stats-widgets
            [value]="renderVal(instagramData?.postAnalytics?.clickCount)"
            [type]="'shares'"
          />
          <app-post-stats-widgets
            [value]="renderVal(instagramData?.postAnalytics?.clickCount)"
            [type]="'interactions'"
          />
          <app-post-stats-widgets
            [value]="renderVal(instagramData?.postAnalytics?.clickCount)"
            [type]="'reach'"
          />
        </div>
      </div>
      <div class="bg-white h-[41%] mb-2 rounded-3xl">
        <div class="font-bold text-xl ml-5 pt-4">
          {{ "dashboard.semanticAnalysis" | translate }}
        </div>
        <img
          *ngIf="!false"
          src="/assets/img/coming soon semantique.png"
          alt="{{ 'generic.comingSoon' | translate }}"
          class="w-20 mx-auto"
        />
        <div *ngIf="!false" class="mx-auto mt-5 mb-2 text-center">
          {{ ("generic.comingSoon" | translate) + " !" }}
        </div>
        <div
          *ngIf="!false"
          class="mx-auto mb-5 pb-2 text-center text-sm text-gray-400"
        >
          {{ "generic.featureUnderDevelopment" | translate }}
        </div>
        <div
          *ngIf="false"
          class="w-[92.5%] h-[70%] border-2 rounded-3xl border-[#465086] my-4 ml-5 mr-5 flex justify-center items-center relative"
        >
          <span
            class="absolute text-blue-600 text-xl font-semibold"
            style="top: 50%; left: 5%"
            >Génial</span
          >
          <span
            class="absolute text-pink-600 text-lg"
            style="top: 15%; left: 10%"
            >Aide</span
          >
          <span
            class="absolute text-teal-500 text-2xl font-bold"
            style="top: 75%; left: 15%"
            >Agréable</span
          >
          <span class="absolute text-red-400 text-sm" style="top: 5%; left: 50%"
            >Mitigé</span
          >
          <span
            class="absolute text-blue-400 text-sm"
            style="top: 45%; left: 20%"
            >Simple</span
          >
          <span
            class="absolute text-blue-500 text-lg"
            style="top: 20%; left: 25%"
            >Plaisant</span
          >
          <span
            class="absolute text-blue-700 text-lg font-bold"
            style="top: 75%; left: 55%"
            >Accompagnement</span
          >
          <span
            class="absolute text-pink-500 text-sm"
            style="top: 30%; left: 60%"
            >Support</span
          >
          <span
            class="absolute text-blue-600 text-lg"
            style="top: 45%; left: 80%"
            >Super</span
          >
          <span
            class="absolute text-green-500 text-xl font-bold"
            style="top: 40%; left: 40%"
            >J’adore</span
          >
          <span
            class="absolute text-blue-500 text-base"
            style="top: 5%; left: 75%"
            >Incroyable</span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="w-[97.5%%] my-10 pt-5 bg-white mx-5 rounded-3xl">
    <h2 class="font-bold text-xl ml-5 py-4">
      {{ "generic.comments" | translate }}
    </h2>
    <img
      *ngIf="!false"
      src="/assets/img/coming soon commentaires.png"
      alt="{{ 'generic.comingSoon' | translate }}"
      class="w-32 mx-auto"
    />
    <div *ngIf="!false" class="mx-auto mt-5 mb-2 text-center">
      {{ ("generic.comingSoon" | translate) + " !" }}
    </div>
    <div
      *ngIf="!false"
      class="mx-auto mb-5 pb-10 text-center text-sm text-gray-400"
    >
      {{ "generic.featureUnderDevelopment" | translate }}
    </div>
    <div *ngIf="false" class="mx-auto bg-white rounded-lg p-6">
      <div class="flex text-sm">
        <div class="w-1/2">
          <app-post-plateform-comments
            (itemSelected)="reply($event)"
            [comments]="comments"
          />
        </div>
        <div class="w-1 rounded-3xl ml-4 bg-[#465086]"></div>
        <div class="w-1/2">
          <div class="bg-white p-4 rounded-lg" *ngIf="writeReply != null">
            <div class="flex items-center space-x-4 mb-4">
              <div>
                <svg
                  width="60px"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#ffffff"
                  viewBox="-2.4 -2.4 28.80 28.80"
                  stroke="#ffffff"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0">
                    <rect
                      x="-2.4"
                      y="-2.4"
                      width="28.80"
                      height="28.80"
                      rx="14.4"
                      fill="#bdbfc5"
                      strokewidth="0"
                    ></rect>
                  </g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
                    ></path>
                  </g>
                </svg>
              </div>
              <div>
                <p class="font-semibold">{{ writeReply.name }}</p>
                <p class="text-sm text-gray-500">{{ writeReply.date }}</p>
              </div>
            </div>
            <div
              class="border-2 my-2 bg-gray-200 border-blue-500 p-4 rounded-b-3xl rounded-r-3xl"
            >
              <p class="">{{ writeReply.comment }}</p>
            </div>
            <button class="bg-blue-600 text-white w-full py-2 my-2 rounded-md">
              {{ "generic.addLike" | translate }}
            </button>
            <div
              class="bg-[#465086] mx-auto rounded-2xl w-[50%] h-1 my-4"
            ></div>
            <textarea
              type="text"
              class="w-full h-32 border rounded-lg p-2 border-gray-500 resize-none"
              placeholder="Répondre au commentaire"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

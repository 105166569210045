<div class="mt-0  relative ">
  <div (click)="toggleDropdown()" type="button" class=" {{tailwindCss}}" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label" [ngClass]="{
    'opacity-0':isDropdownOpen
  }">
   <span class=" " >{{selectedCategory ? selectedCategory.displayName : 'pas de catégorie séléctionnée'}}</span>

      <span class="ml-auto ">
        <svg  class="w-4 h-4 mr-2" fill="currentColor"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 520 512">
          <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/>
        </svg>
      </span>
  </div>
	 <!-- Dropdown List -->
  <ul class="absolute -mt-8 pb-3 z-10 w-11/12 -ml-1 bg-white left-5 rounded-2xl border border-1 border-cliking_blue" [class.hidden]="!isDropdownOpen"  style="top: 100%; left: 4.2vh;">
		<!--  -->

		<li class="font-normal ml-3 cursor-pointer text-base text-indigo-400 select-none relative py-1 pl-3 pr-9 "> 
			{{selectedCategory ? selectedCategory.displayName : 'pas de catégorie séléctionnée'}}
			<span class="font-normal ml-3 text-gray-700 font-sans font-black block truncate rounded-2xl">
				<svg (click)="toggleDropdown()" class="absolute inset-y-1 right-2 mt-1 w-4 h-4 mr-3" viewBox="5 5 16 16" fill="none"  xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6 15L12 9L18 15" stroke="#5C78FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
				
				</span>
			</li>
			
			<!-- Search Bar -->
			<li class="p-2">
				<input type="search" [(ngModel)]="searchPattern" (keyup)="searchCategories(searchPattern)" class="w-full p-2 text-sm" placeholder="Search...">
			</li>
    <li class=" cursor-pointer text-sm select-none relative py-1 pl-3 pr-9    text-gray-700"
    *ngFor="let item of categories"
    role="option"
		[ngClass]="{'bg-cliking_blue hover:bg-cliking_blue text-white':selectedCategory && item.name == selectedCategory.name,
								'hover:bg-gray-100':!selectedCategory || item.name != selectedCategory.name
							}">
      <div class="flex items-center" (click)="toggleSelect(item)" >
        <span  class="font-normal ml-2 mr-3 font-sans font-black block truncate rounded-sm"> {{item.displayName}}
        </span>
      </div>
    </li>
  </ul>
</div>


import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/utilities/auth.service';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent {
  env = environment;
  newPassword: string = '';
  confirmPassword: string = '';
  passwordMismatch: boolean = false;
  userId: any = false ; 
  token: any ; 
  apiUrl:string = environment.apiUrl;

  constructor(
    private translationService: TranslationService,
    private router : Router,
    private authService: AuthService
  ){}

  ngOnInit(){
    let url =  this.router.url.split('/')
    this.token = url[url.length-1]
    this.authService.checkResetPasswordToken(this.token).subscribe((res)=>{
      this.userId =  res
    });
  }

  resetPassword(newPassword: string, event: Event): any{
    console.log(this.userId);
    
    this.authService.resetPassword(this.userId, newPassword).subscribe(
      (response) => {
        this.authService.deleteResetedPasswordToken(this.token).subscribe((response)=>{
          window.location.href = '/login';
        })
      },
      (error:any) => {
        console.error('Error changing password', error);
      }
    );
  }

  checkPasswords() {
    if( this.newPassword !== '' && this.confirmPassword !== '')
      this.passwordMismatch = this.newPassword !== this.confirmPassword;
  }

  switchEn() {
    this.translationService.setLanguage('en');
  }

  switchFr() {
    this.translationService.setLanguage('fr');
  }

}

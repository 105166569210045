import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError, catchError, tap } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import { JwtPayload } from '../../interfaces/JwtPayload';
import { environment } from '../../../environments/environment';
import { UserService } from '../repositories/user.service';
import { Debug } from 'src/app/utils/debug';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl = environment.apiUrl;
  public currentUserSubject: BehaviorSubject<any | null>;
  public currentUser: Observable<any | null>;

  constructor(private http: HttpClient, private userService: UserService) {
    const token = window.localStorage.getItem('token');
    this.currentUserSubject = new BehaviorSubject<any | null>(null);
    this.currentUser = this.currentUserSubject.asObservable();

    if (token && this.isTokenValid(token)) {
      this.loadCurrentUser().subscribe();
    }
  }
  loadedUser: any = {};

  getLoadedUser(): any {
    return this.currentUserSubject.value;
  }
  loadCurrentUser(): Observable<any | null> {
    // Check if user is already loaded
    if (this.loadedUser && this.loadedUser.data?.id !== undefined) {
      // Return the loaded user as an Observable
      return of(this.loadedUser);
    }

    // Get the connected user ID
    const userId = this.connectedUserId();

    // If the user ID is valid (non-zero), fetch the user from backend
    if (userId !== 0) {
      return this.userService.getUserById(userId).pipe(
        tap((user) => {
          //set all ui permissions
          
          //encrypt the user
          this.loadedUser = user; // Cache the loaded user


          this.currentUserSubject.next(user); // Update the user observable
        })
      );
    } else {
      // If no user ID, set the current user to null
      this.currentUserSubject.next(null);
      return of(null);
    }
  }

  login(username: string, password: string): Observable<any> {
    const loginData = { email: username, password: password };
    return this.http.post<any>(`${this.apiUrl}/auth/login`, loginData).pipe(
      tap((response) => {
        if (response.token) {
          localStorage.setItem('token', response.token);
          this.loadCurrentUser().subscribe((res) => {
            this.currentUser = res.data;
          });
        }
      })
    );
  }

  logout(): void {
    localStorage.removeItem('token');
    this.currentUserSubject.next(null);
  }

  isTokenValid(token: string): boolean {
    try {
      const decodedToken = jwtDecode(token) as JwtPayload;
      if (decodedToken && decodedToken.exp) {
        const currentTime = Date.now() / 1000; // seconds
        return decodedToken.exp > currentTime;
      }
    } catch (error) {
      console.error('Error validating token:', error);
    }
    localStorage.removeItem('token');
    return false;
  }

  connectedUserId(): number {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token) as JwtPayload;
      return decodedToken.userId;
    }
    return 0;
  }

  updateAuthenticatedUser(user: any) {
    // Debug.log("user UI permissions Updated ",user.UI);
    this.loadedUser = user;

    let storedData=localStorage.getItem(environment.sessionData);
    if(storedData){
      let storedDataObject = JSON.parse(storedData)

      console.log("storedDataObject",storedDataObject);
      // storedDataObject.AuthenticatedUser=user
      sessionStorage.setItem(environment.sessionData, JSON.stringify(user))
    }
    this.currentUserSubject.next(user);
  }

  resetPasswordEmail(email: string): Observable<Response> {
    return this.http.get<Response>(`${this.apiUrl}/auth/reset-password/${email}`).pipe(
      catchError((error) => {
        console.error('Error fetching user:', error);
        return throwError(() => error);
      })
    );
  }

  checkResetPasswordToken(token: string): Observable<Response> {
    return this.http.get<Response>(`${this.apiUrl}/auth/check-password-token/${token}`).pipe(
      catchError((error) => {
        console.error('Error fetching user:', error);
        return throwError(() => error);
      })
    );
  }

  resetPassword(userId: number, newPassword: string): Observable<any> {
    return this.http.patch(`${this.apiUrl}/auth/password/${userId}`, {
      newPassword,
    });
  }

  deleteResetedPasswordToken(token: any): Observable<any> {
    return this.http.delete(`${this.apiUrl}/auth/delete-password-token/${token}`);
  }
}


<ul class="pl-0 text-left text-sm">
    <ng-template #recursiveList let-list>
      <li *ngFor="let item of list" class="cursor-pointer mx-1 px-1">
        <div (click)="toggle(item, item.depth)" style="margin-left: {{item.depth * 10}}px !important; " [ngClass]="{
            'mx-2 rounded-md bg-cliking_blue text-white': selectedItemId == item.id
          }" >
          <!-- Ensure the text and icons are in the same inline span -->
          <span class="ml-2">
            {{ item[displayProperty] }}
            <ng-container *ngIf="hasChildren(item)">
              <svg *ngIf="item.expanded" width="8" fill="currentColor" style="display: inline; vertical-align: middle;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
              <svg *ngIf="!item.expanded" width="8" fill="currentColor" style="display: inline; vertical-align: middle;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
       
            </ng-container>
          </span>
        </div>
        <ul *ngIf="item.expanded && hasChildren(item)" style="padding-left: 0;">
          <ng-container *ngTemplateOutlet="recursiveList; context: {$implicit: item[childrenProperty]}"></ng-container>
        </ul>
      </li>
    </ng-template>
    <ng-container *ngTemplateOutlet="recursiveList; context: {$implicit: data}"></ng-container>
  </ul>

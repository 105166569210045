import { Injectable } from '@angular/core';
import { BehaviorSubject,Subject, Observable, catchError, throwError } from 'rxjs';
import { Debug } from 'src/app/utils/debug';

@Injectable({
  providedIn: 'root'
})
export class UiStateService {

  constructor() { }

  platformsFilter :any={
    isFacebookON: true,
    isLinkedinON: true,
    isGoogleON: true,
    isInstagramON: true,
  }

  postStateFilter:any={
    isPlanned: true,
    isPublished: true,
  }
  dateRange!:{startDate:Date, endDate:Date}
  
  
  public dateRangeUpdate= new Subject<{startDate:Date,endDate:Date}>();
  dateRangeUpdate$ = this.dateRangeUpdate.asObservable();
  updateDateRange(data:any){
    this.dateRangeUpdate.next(data)
  }
  
  public platformsFilterUpdated = new Subject<any>();
  platformsFilterUpdated$ = this.platformsFilterUpdated.asObservable();
  emitEvent(data: any) {
    this.platformsFilter = data
    console.log(data)
    this.platformsFilterUpdated.next(data)
  }
  public postStateFilterUpdated = new Subject<any>();
  postStateFilterUpdated$ = this.postStateFilterUpdated.asObservable();
  
  updatePostStateFilter(newFilter: any) {
    this.postStateFilter = newFilter;
    this.postStateFilterUpdated.next(this.postStateFilter);
  }

  updatePlatformsFilter(newFilter: any){
    this.platformsFilterUpdated.next(newFilter)
    this.platformsFilter = newFilter
    Debug.success(this.platformsFilter)
  }
}
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-emoji-picker',
  templateUrl: './emoji-picker.component.html',
  styleUrls: ['./emoji-picker.component.scss'],
})
export class EmojiPickerComponent {
  groups: string[][] = [
      [
      //faces
      '😀','😁','😂','😃','😄','😅','😆','😇','😈','😉','😊','😋','😌','😍','😎','😏','😐','😑','😒','😓','😔','😕','😖','😗','😘','😙','😚','😛','😜','😝','😞','😟','😠','😡','😢','😣','😤','😥','😦','😧','😨','😩','😪','😫','😬','😭','😮','😯','😰','😱','😲','😳','😴','😵','😶','😷','🙁','🙂','🙃','🙄','🤐','🤑','🤒','🤓','🤔','🤕','🤗','🤠','🤢','🤣','🤤','🤥','🤧','🤨','🤩','🤪','🤫','🤭','🤮','🤯','🥰','🥱','🥳','🥴','🥵','🥶','🥺','🧐','😸','😹','😺','😻','😼','😽','😾','😿','🙀','🙈','🙉','🙊','😸','😹','😺','😻','😼','😽','😾','😿','🙀','🙈','🙉','🙊', '✋','👋','🖐','🖖','🤚','☝','👆','👇','👈','👉','🖕','✊','👊','👍','👎','🤛','🤜','✌','👌','🤏','🤘','🤙','🤞','🤟','✍','👏','👐','💅','🤝','🤝','🤲','🤳'
      ],

      [
      //peapole
      '👮','👯','👰','👲','👳','👷','👸','🕴','🕵','💁','💂','💃','🤴','🤵','👪','👫','💏','💑','🤰','🤱','👤','👥','👦','👧','👨','👩','👱','👴','👵','👶','🕺','🧍','🧎','🧑','🧒','🧓','🧔','🧕','🧖','🧗','🧘','💆','💇','🙅','🙆','🙇','🙋','🙌','🙍','🙎','🙏','🤦','🤱','🤷','🧏','🙈','🙉','🙊','🎅','👹','👺','👻','👼','👽','👾','👿','💀','🤖','🤡','🤶','🦸','🦹','🧙','🧚','🧛','🧜','🧝','🧞','🧟','👀','👁','👂','👃','👄','👅','👣','💪','🦴','🦵','🦶','🦷','🦻','🦼','🦽','🦾','🦿','🧠','🧡','💌','💒','💓','💔','💕','💖','💗','💘','💙','💚','💛','💜','💝','💞','💟','🖤','🤍','🤎','💄','💅','💈','💉','💊','💋','💍','💎','💤','💩','💫'
      ],
      [
      //office
      '💺','💻','💼','💽','💾','💿','📀','📁','📂','📃','📄','📅','📆','📇','📈','📉','📊','📋','📌','📍','📎','📏','📐','📑','📒','📓','📔','📕','📖','📗','📘','📙','📚','📛','📜','📝','📞','📟','📠','📡','📢','📣','📤','📥','📰','🔋','🔌','📱','📲','📳','📴','📵','📶','📤','📥','📦','📧','📨','📩','📪','📫','📬','📭','📮','📯','📰','🕐','🕑','🕒','🕓','🕔','🕕','🕖','🕗','🕘','🕙','🕚','🕛','🕜','🕝','🕞','🕟','🕠','🕡','🕢','🕣','🕤','🕥','🕦','🕧'
      ],
      //places
      [
      '⛲','⛳','⛺','⛽','🌋','🗺','🗻','🗼','🗽','🗾','🗿','⛪','🏠','🏡','🏢','🏣','🏤','🏥','🏦','🏧','🏨','🏩','🏪','🏫','🏬','🏭','🏮','🏯','🏰','🕋','🕌','🕍','🛕',
      '🚀','🚁','🚂','🚃','🚄','🚅','🚆','🚇','🚈','🚉','🚊','🚋','🚌','🚍','🚎','🚏','🚐','🚑','🚒','🚓','🚔','🚕','🚖','🚗','🚘','🚙','🚚','🚛','🚜','🚝','🚞','🚟','🚠','🚡','🚢','🚣','🚤','⛔','🚥','🚦','🚧','🚨','🚩','🚪','🚫','🚬','🚭','🚮','🚯','🚰','🚱','🚲','🚳','🚴','🚵','🚶','🚷','🚸','🚹','🚺','🚻','🚼','🚽','🚾','🚿','🛀','🛁','🛂','🛃','🛄','🛅'
      ],

      [//animal
      '🐀','🐁','🐂','🐃','🐄','🐅','🐆','🐇','🐈','🐉','🐊','🐋','🐌','🐍','🐎','🐏','🐐','🐑','🐒','🐓','🐔','🐕','🐖','🐗','🐘','🐙','🐚','🐛','🐜','🐝','🐞','🐟','🐠','🐡','🐢','🐣','🐤','🐥','🐦','🐧','🐨','🐩','🐪','🐫','🐬','🐭','🐮','🐯','🐰','🐱','🐲','🐳','🐴','🐵','🐶','🐷','🐸','🐹','🐺','🐻','🐼','🐽','🐾','🦀','🦁','🦂','🦃','🦄','🦅','🦆','🦇','🦈','🦉','🦊','🦋','🦌','🦍','🦎','🦏','🦐','🦑','🦒','🦓','🦔','🦕','🦖','🦗','🦘','🦙','🦚','🦛','🦜','🦝','🦞','🦟','🦠','🦡','🦢'
      ]
      ,
      [
      //food
      '☕','🍵','🍶','🍷' ,'🍸','🍹','🍺','🍻','🍼', '🍾','🏺','🥂','🥃','🥛','🥤','🧃','🧉','🧊','🌭','🌮','🌯','🍔','🍕','🍖','🍗','🍘','🍙','🍚','🍛','🍜','🍝','🍞','🍟','🍠','🍡','🍢','🍣','🍤','🍥','🍦','🍧','🍨','🍩','🍪','🍫','🍬','🍭','🍮','🍯','🍰','🍱','🍲','🍳','🍿','🥐','🥓','🥖','🥗','🥘','🥙','🥚','🥜','🥞','🥟','🥠','🥡','🥣','🥥','🥧','🥨','🥩','🥪','🥫','🥮','🥯','🧀','🧁','🧆','🧇','🧈','🌽','🌾','🍅','🍆','🥑','🥒','🥔','🥕','🥦','🥬','🧄','🧅','🍇','🍈','🍉','🍊','🍋','🍌','🍍','🍎','🍏','🍐','🍑','🍒','🍓','🥝','🥭'
      ],

      [//sport
      '👔','👕','👖','👗','👘','👙','👚','🥻','🥼','🦺','🧥','👞','👟','👠','👡','👢','🥾','🥿','🧦','🎓','🎩','👑','👒','🧢','👓','👛','👜','👝','🥽','🧣','🧤',
      '⚽','⚾','⛳','🎣','🎽','🎾','🎿','🏀','🏁','🏂','🏃','🏄','🏅','🏆','🏇','🏈','🏉','🏊','🏋','🏌','🏍','🏎','🏏','🏐','🏑','🏒','🏓','🏸','🏹','🤸','🤹','🤺','🤻','🤼','🤽','🤾','🤿','🥅','🥇','🥈','🥉','🥊','🥋','🥌','🥍','🥎','🥏','🧗','🎮','🎯','🎰','🎱','🎲','🎳','🎴','🧩'
      ],
      [//earth and sky
      '🌌','🌍','🌎','🌏','🌐','🌑','🌒','🌓','🌔','🌕','🌖','🌗','🌘','🌙','🌚','🌛','🌜','🌝','🌞','🌟','🌠','⛄','⛅','☔','🌀','🌁','🌂','🌃','🌄','🌅','🌆','🌇','🌈','🌉','🌊'
      ]
      // ,
      // [
      //   //symbole
      //   '🎤','🎥','🎦','🎧','🎬','🎵','🎶','🎷','🎸','🎹','🎺','🎻','🎼','🥁','📷','📸','📹','📺','📻','📼','📽','📾','🔇','🔈','🔉','🔊','📡','📢','📣','🎀','🎁','🎂','🎃','🎄','🎅','🎆','🎇','🎈','🎉','🎊','🎋','🎌','🎍','🎎','🎏','🎐','🎑','🎒','🎓','🎟','🎠','🎡','🎢','🎨','🎪','🎫','🎭','💯','💰','💱','💲', '💳','💴','💵','💶','💷','💸','💹','⛪','📿','🕋','🕌','🕍','🕎','🛕','🔯','🔍','🔎','🔏','🔐','🔑','🔒','🔓','🔦','🔧','🔨','🔩','🔬','🔭','🧭','🧰','🧲','🔲','🔳','🔴','🔵','🔶','🔷','🔸','🔹','🔺','🔻','🔘','🔙','🔚','🔛','🔜','🔝','🔞','🔟','🔠','🔡','🔢','🔣','🔤','💠','💡','💢','💣','💥','💦','💧','💨','🔔','🔕','🔖','🔗','🔥','🔫','🔮','🔰','🔱','🧪','🧫','🧬','🧮','🧯','🧱','🧳','🧴','🧵','🧶','🧷','🧸','🧹','🧺','🧻','🧼','🧽','🧾','🧿',
      // ]
  ];

  @Input() isVisible: boolean = false;
  @Output() emojiPicked = new EventEmitter<String>()
  currentTabIndex: number = 0;
  currentGroup: string = '';

  ngOnInit() {
    this.getGroupHeads();
  }



  getGroupHeads() {
    const result = [];
    for (let item of this.groups) {
      result.push(item[0]);
    }
    return result;
  }

  updateGroupHead(emoji: string, index: number) {
    this.currentGroup = emoji;
    this.currentTabIndex = index;
  }
  picked($event:any){
    this.emojiPicked.emit($event)
  }
}
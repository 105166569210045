import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { BaseComponent } from '../../base/base.component';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { OpeningHoursService } from '../../../services/utilities/opening-hours.service';
import { Debug } from 'src/app/utils/debug';

@Component({
  selector: 'app-google-page-preview',
  templateUrl: './google-page-preview.component.html',
  styleUrls: ['./google-page-preview.component.scss'],
})
export class GooglePagePreviewComponent extends BaseComponent {
  @Input() size: 'small' | 'large' = 'small'; // Default size
  @Input() medias: string[] = [];
  @Input() title: string = '';
  @Input() primaryCategory: string = '';
  @Input() horaires: any = {};
  @Input() address: string | null = null;
  @Input() description: string | null = null;
  @Input() phoneNumber: string | null = null;
  openStatus!: any; //{ hasHours: boolean; isOpen: boolean; nextOpen: string; closingAt?: undefined; } | { hasHours: boolean; isOpen: boolean; closingAt: string; nextOpen?: undefined; }

  constructor(
    translationService: TranslationService,
    private openingHoursService: OpeningHoursService
  ) {
    super(translationService);
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['horaires'] && !changes['horaires'].isFirstChange()) {
      Debug.log('Horaires updated:', this.horaires);
      Debug.log(
        'OpeningHoursService',
        (this.openStatus = this.openingHoursService.checkOpenStatus(
          this.horaires
        ))
      );
    }
  }
}

<div *ngIf="facebookMedias.length==1" class="flex bg-black w-full">
    <img [style.max-height]="imgContainerHeight(1)" class="mx-auto" src="{{facebookMedias[0].url}}" alt="Post Image">
</div>
<div *ngIf="facebookMedias.length==2">
    <div *ngIf="orientation=='landscape'" class="grid grid-rows-2 " [style.height]="imgContainerHeight(1)">
        <img class="w-full h-[100%] object-cover object-center" src="{{facebookMedias[0].url}}">
        <img class="w-full h-[100%] object-cover object-center" src="{{facebookMedias[1].url}}">
    </div>
    <div *ngIf="orientation=='square'" class="grid grid-cols-2" [style.height]="imgContainerHeight(2)">
        <img [style.height]="imgContainerHeight(2)" class="w-full object-cover object-center" src="{{facebookMedias[0].url}}">
        <img [style.height]="imgContainerHeight(2)" class="w-full object-cover object-center" src="{{facebookMedias[1].url}}">
    </div>
    <div *ngIf="orientation=='portrait'" class="grid grid-cols-2 " [style.height]="imgContainerHeight(1)">
        <img class="w-full h-[100%] object-cover object-center" src="{{facebookMedias[0].url}}">
        <img class="w-full h-[100%] object-cover object-center" src="{{facebookMedias[1].url}}">
    </div>
</div>
<div *ngIf="facebookMedias.length==3">
    <div *ngIf="orientation=='square' || orientation== 'FirstImglandscape'" class="grid grid-cols-2 grid-rows-2 gap-0 " [style.height]="imgContainerHeight(1)">
        <div [style.height]="imgContainerHeight(2)" class="col-span-2 gap-0 row-span-1">
            <img class="w-full h-full object-cover object-center" src="{{facebookMedias[0].url}}">
        </div>  
        <div [style.height]="imgContainerHeight(2)" class="grid grid-cols-2 gap-0 col-span-2 row-span-2">
            <img [style.height]="imgContainerHeight(2)" class="w-full object-cover object-center" src="{{facebookMedias[1].url}}">
            <img [style.height]="imgContainerHeight(2)" class="w-full object-cover object-center" src="{{facebookMedias[2].url}}">
        </div>
    </div>
    <div *ngIf="orientation == 'landscape' || orientation == 'FirstImgsquare'" class="grid grid-rows-3 gap-0 " [style.height]="imgContainerHeight(1)">
        <div class="row-span-2 relative w-full h-full">
            <img class="absolute inset-0 w-full h-full object-cover object-center" src="{{facebookMedias[0].url}}">
        </div>
        <div class="grid grid-cols-2 gap-0 row-span-1 w-full h-full">
            <div class="relative w-full h-full">
                <img class="absolute inset-0 w-full h-full object-cover object-center" src="{{facebookMedias[1].url}}">
            </div>
            <div class="relative w-full h-full">
                <img class="absolute inset-0 w-full h-full object-cover object-center" src="{{facebookMedias[2].url}}">
            </div>
        </div>
    </div>
    <div *ngIf="orientation=='portrait' || orientation=='FirstsquareRestportrait'" class="grid grid-cols-3 gap-0 " [style.height]="imgContainerHeight(1)">
        <div [style.height]="imgContainerHeight(1)" class="col-span-2">
            <img class="w-full h-full object-cover object-center" src="{{facebookMedias[0].url}}">
        </div>  
        <div [style.height]="imgContainerHeight(1)" class="gird grid-cols-3 col-span-1 gap-0">
            <img class="w-full h-[50%] object-cover object-center" src="{{facebookMedias[1].url}}">
            <img class="w-full h-[50%] object-cover object-center" src="{{facebookMedias[2].url}}">
        </div>
    </div>
    <div *ngIf="orientation=='FirstImgportrait'" class="grid grid-cols-2 gap-0 " [style.height]="imgContainerHeight(1)">
        <div class="" [style.height]="imgContainerHeight(1)">
            <img class="w-full h-full object-cover object-center" src="{{facebookMedias[0].url}}">
        </div>
        <div [style.height]="imgContainerHeight(1)" class="gird grid-cols-3 gap-0">
            <img class="w-full h-[50%] object-cover object-center" src="{{facebookMedias[1].url}}">
            <img class="w-full h-[50%] object-cover object-center" src="{{facebookMedias[2].url}}">
        </div>
    </div>
</div>
<div *ngIf="facebookMedias.length==4">
    <div *ngIf="orientation == 'landscape' || orientation == 'FirstImgsquare'" class="grid grid-rows-[2fr_1fr] gap-0 " [style.height]="imgContainerHeight(1)">
        <div class="row-span-2">
            <img class="w-full h-full object-cover object-center" src="{{facebookMedias[0].url}}">
        </div>
        <div class="grid grid-cols-3 gap-0 row-span-1">
            <img class="w-full h-full object-cover object-center" src="{{facebookMedias[1].url}}">
            <img class="w-full h-full object-cover object-center" src="{{facebookMedias[2].url}}">
            <img class="w-full h-full object-cover object-center" src="{{facebookMedias[3].url}}">
        </div>
    </div>    
    <div *ngIf="orientation=='portrait' || orientation=='FirstsquareRestportrait'" class="grid grid-cols-3 gap-0 " [style.height]="imgContainerHeight(1)">
        <div [style.height]="imgContainerHeight(1)" class="col-span-2">
            <img class="w-full h-full object-cover object-center" src="{{facebookMedias[0].url}}">
        </div>  
        <div [style.height]="imgContainerHeight(1)" class="gird grid-cols-3 col-span-1 gap-0">
            <img class="w-full h-1/3 object-cover object-center" src="{{facebookMedias[1].url}}">
            <img class="w-full h-1/3 object-cover object-center" src="{{facebookMedias[2].url}}">
            <img class="w-full h-1/3 object-cover object-center" src="{{facebookMedias[3].url}}">
        </div>
    </div>
    <div *ngIf="orientation=='square'" class="grid grid-cols-2 grid-rows-2 gap-0 " [style.height]="imgContainerHeight(1)">
        <div class="w-full h-full">
            <img class="w-full h-full object-cover object-center" src="{{facebookMedias[0].url}}">
        </div>
        <div class="w-full h-full">
            <img class="w-full h-full object-cover object-center" src="{{facebookMedias[1].url}}">
        </div>
        <div class="w-full h-full">
            <img class="w-full h-full object-cover object-center" src="{{facebookMedias[2].url}}">
        </div>
        <div class="w-full h-full">
            <img class="w-full h-full object-cover object-center" src="{{facebookMedias[3].url}}">
        </div>
    </div>
</div>
<div *ngIf="facebookMedias.length>4">
    <div *ngIf="orientation == 'portrait' || orientation == 'square'" class="grid grid-rows-[60%_40%] gap-0 " [style.height]="imgContainerHeight(1)">
        <!-- Première ligne avec deux images -->
        <div class="grid grid-cols-2 gap-0 h-full">
            <div class="relative w-full h-full">
                <img class="absolute inset-0 w-full h-full object-cover object-center" src="{{facebookMedias[0].url}}">
            </div>
            <div class="relative w-full h-full">
                <img class="absolute inset-0 w-full h-full object-cover object-center" src="{{facebookMedias[1].url}}">
            </div>
        </div>
        <div class="grid grid-cols-3 gap-0 h-full">
            <div class="relative w-full h-full">
                <img class="absolute inset-0 w-full h-full object-cover object-center" src="{{facebookMedias[2].url}}">
            </div>
            <div class="relative w-full h-full">
                <img class="absolute inset-0 w-full h-full object-cover object-center" src="{{facebookMedias[3].url}}">
            </div>
            <div class="relative w-full h-full">
                <img *ngIf="facebookMedias.length == 5" class="absolute inset-0 w-full h-full object-cover object-center" src="{{facebookMedias[4].url}}">
                <div *ngIf="facebookMedias.length > 5" class="relative w-full h-full">
                    <img class="absolute inset-0 w-full h-full object-cover object-center" src="{{facebookMedias[4].url}}">
                    <div class="absolute inset-0 bg-black bg-opacity-60 flex justify-center items-center">
                        <span class="text-white text-2xl">+{{facebookMedias.length - 4}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="orientation=='landscape'" class="grid grid-cols-2 gap-0 " [style.height]="imgContainerHeight(1)">
        <div class="" [style.height]="imgContainerHeight(1)">
            <img class="w-full h-1/2 object-cover object-center" src="{{facebookMedias[0].url}}">
            <img class="w-full h-1/2 object-cover object-center" src="{{facebookMedias[1].url}}">
        </div>  
        <div [style.height]="imgContainerHeight(1)" class="gird grid-cols-3 gap-0">
            <img class="w-full h-1/3 object-cover object-center" src="{{facebookMedias[2].url}}">
            <img class="w-full h-1/3 object-cover object-center" src="{{facebookMedias[3].url}}">
            <img *ngIf="facebookMedias.length==5" class="w-full h-1/3 object-cover object-center" src="{{facebookMedias[4].url}}">
            <div *ngIf="facebookMedias.length>5" class="relative h-1/3">
                <img class="w-full h-full object-cover object-center" src="{{facebookMedias[4].url}}">
                <div class="absolute inset-0 bg-black bg-opacity-60 flex justify-center items-center">
                    <span class="text-white text-2xl object-cover object-center">+{{facebookMedias.length-4}}</span>
                </div>
            </div>
        </div>
    </div>    
</div>


<!-- <div *ngIf="facebookMedias.length>3" class="grid grid-cols-2 gap-0 " [style.height]="imgContainerHeight(1)">
    <div class="" [style.height]="imgContainerHeight(1)">
        <img class="w-full object-cover object-center" [ngClass]="{'h-[50%]':facebookMedias.length>4, 'h-full':facebookMedias.length==4}" src="{{facebookMedias[0].url}}">
        <img *ngIf="facebookMedias.length>4"  class="w-full h-[50%] object-cover object-center" src="{{facebookMedias[1].url}}">
    </div>
    <div [style.height]="imgContainerHeight(1)" class="gird grid-cols-3 gap-0">
        <img class="w-full h-[33.34%] object-cover object-center" src="{{facebookMedias[facebookMedias.length-3].url}}">
        <img class="w-full h-[33.33%] object-cover object-center" src="{{facebookMedias[facebookMedias.length-2].url}}">
        <img *ngIf="facebookMedias.length==4 || facebookMedias.length==5" class="w-full h-[33.33%] object-cover object-center" src="{{facebookMedias[facebookMedias.length-1].url}}">
        <div *ngIf="facebookMedias.length>5" class="relative h-[33.33%]">
            <img class="w-full h-full object-cover object-center" src="{{facebookMedias[facebookMedias.length-1].url}}">
            <div class="absolute inset-0 bg-black bg-opacity-60 flex justify-center items-center">
                <span class="text-white text-2xl object-cover object-center">+{{facebookMedias.length-4}}</span>
            </div>
        </div>
    </div>
</div> -->
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-post-stats-widgets',
  templateUrl: './post-stats-widgets.component.html',
  styleUrls: ['./post-stats-widgets.component.scss']
})
export class PostStatsWidgetsComponent {
  @Input() value: string = ""
  @Input() type: string = ""

}

import { Component, Input, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/utilities/auth.service';
import { ScreenSizeService } from 'src/app/services/utilities/screen-size.service';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SideBarComponent {
  isConnectedUserAdmin: boolean = false;
  hoveringOverLink: boolean = false;
  logoURL: string = 'assets/img/logo.png';
  logoSize: string = 'h-16';
  constructor(
    public router: Router,
    private authService: AuthService,
    private translationService: TranslationService,
    private screenSizeService: ScreenSizeService
  ) {}
  // authenticatedUser!: any;
  accreditedGroups: any[] = [];
  env: string = environment.env;
  dropDown: boolean = false;
  @Input() auth!: any;
  screenWidth: number = 0;
  screenHeight: number = 0;

  ngOnInit() {
    // if (isSagamm ??) {
    this.logoURL = 'assets/img/logo-sagamm.png';
    this.logoSize = 'h-36';

    // }
    if (this.router.url.includes('dashboard')) {
      this.dropDown = true;
    }    
   // this.auth = this.authService.currentUserSubject.getValue();
    console.log("sidebar",this.auth);
    this.screenSizeService.screenWidth$.subscribe(
      (width: number) => (this.screenWidth = width)
    );
    this.screenSizeService.screenHeight$.subscribe(
      (height: number) => (this.screenHeight = height)
    );
  }

  getAccreditedGroups() {
    if (!this.auth || !this.auth.accreditations) {
      // console.error("Authenticated user or accreditations are not available.");
      return [];
    }
    const accg: any = [];
    for (let accreditation of this.auth.accreditations) {
      // Debug.log("acc",accreditation)
      const acc_permissions = [];
      for (let permission of accreditation.profile.permissions) {
        // Debug.log(permission.action,permission.ProfilePermission.value)
        acc_permissions.push({
          action: permission.action,
          value: permission.ProfilePermission.value,
        });
      }
      accg.push({ group: accreditation.group, profile: accreditation.profile });
    }

    return accg;
  }

  getTranslation(key: string): string {
    return this.translationService.t(key);
  }

  openDropDown() {
    if (this.auth?.UI?.canAccessDashboard) this.dropDown = !this.dropDown;
  }
  hoveredIcon: string | null = null;

  onHover(icon: string | null) {
    this.hoveredIcon = icon;
  }
}


    <span class=" flex justify-center font-semibold	 text-base mb-1  cursor-pointer" [ngStyle]="{'font-size': screenHeight/55 + 'px'}" (click)="goToMonth(month.monthIndex)">{{toTitleCase(getMonthStr())}}</span>
    
    <div class="grid grid-cols-7  grid-rows-1 gap-0 " *ngFor="let week of month.content; let j=index" [style.width.px]="screenWidth*16.43/100"  [style.height.px]="screenHeight*2.5/100">
        
        <div *ngFor="let d of week; let i=index" 
        class="flex items-center justify-center col-span-1 font-semibold text-xs border border-1 border-gray-300 bg-gray-100"  [ngStyle]="{'font-size': screenHeight/60 + 'px'}"
        (click)="showDay(d,j)"
        [ngClass]="{
            'rounded-tl-md':i==0 && j==0 ,
            'rounded-tr-md':i==6 && j==0 ,
            'rounded-bl-md':i==0 && j==5 ,
            'rounded-br-md':i==6 && j==5 ,
            'text-gray-400': d.day > 20 && i < 7 && j<2 || d.day < 15 &&  (j ==5||j==4),
            'text-orange-500': isCurrentDay(d.day, month.month,j,i),
            'day-preview':canShowIndication(d,i,j),
            'cursor-pointer':canShowIndication(d,i,j),
            'underlinePlanned': d.data.hasPlanned && !(d.day > 20 && i < 7 && j<2 || d.day < 15 &&  (j ==5||j==4)) && isPlanned,
            'underlinePublished': d.data.hasPublished && !d.data.hasPlanned && !(d.day > 20 && i < 7 && j<2 || d.day < 15 &&  (j ==5||j==4)) && isPublished,
        }"
        style="text-decoration-thickness: 4px;" 
        >

            {{ d.day.toString().padStart(2, '0')  }}

        <!-- <span 
            [ngClass]="{
                'c-accent-4': d.data.hasPlanned ,
                'c-accent-3': d.data.hasPublished && !d.data.hasPlanned,
                'day-preview':canShowIndication(d,i,j),
                'opacity-0':!canShowIndication(d,i,j),
                
            }" class="sticky ml-1 " style="font-size: large;">•</span> -->
        </div>
    </div>
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { PostService } from 'src/app/services/repositories/post.service';
import { Debug } from 'src/app/utils/debug';
import { ScreenSizeService } from 'src/app/services/utilities/screen-size.service';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
})
export class DateTimePickerComponent implements OnInit {
  constructor(
    private postService: PostService,
    private screenSizeService: ScreenSizeService
  ) {}
  currentMonth: string = '';
  daysArray: { label: number; value: Date }[] = [];
  today!: Date;
  public selectedDate!: Date | null;
  public selectedTime!: string | null;
  screenHeight: number = 0;
  screenWidth: number = 0;

  @Output() dateSelected = new EventEmitter<Date>();

  ngOnInit() {
    this.screenSizeService.screenWidth$.subscribe(
      (width: number) => (this.screenWidth = width)
    );
    this.screenSizeService.screenHeight$.subscribe(
      (height: number) => (this.screenHeight = height)
    );
    this.today = new Date();
    // this.today.setHours(0, 0, 0, 0); // Reset time to start of the day
    this.currentMonth = this.today.toLocaleString('fr-FR', {
      month: 'long',
      year: 'numeric',
    });
    this.selectedDate = this.today;

    let hours = this.today.getHours().toString().padStart(2, '0');
    let minutes = this.today.getMinutes().toString().padStart(2, '0');
    this.selectedTime = `${hours}:${minutes}`;
    this.postService.updatePickedDate(this.today);
    this.populateDaysArray();
    this.postService.currentPickedDate.subscribe((pickedDate) => {
      this.selectedDate = pickedDate;
    });
  }

  private populateDaysArray() {
    this.daysArray = [];
    const year = this.today.getFullYear();
    const month = this.today.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    for (let day = 1; day <= daysInMonth; day++) {
      const dateValue = new Date(year, month, day);
      this.daysArray.push({ label: day, value: dateValue });
    }
  }

  isPastDate(date: Date): boolean {
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    return date < currentDate;
  }
  prevMonth() {
    if (this.canGoBack()) {
      this.today.setMonth(this.today.getMonth() - 1);
      this.currentMonth = this.today.toLocaleString('fr-FR', {
        month: 'long',
        year: 'numeric',
      });
      this.populateDaysArray();
    }
  }
  nextMonth() {
    this.today.setMonth(this.today.getMonth() + 1);
    this.currentMonth = this.today.toLocaleString('fr-FR', {
      month: 'long',
      year: 'numeric',
    });
    this.populateDaysArray();
  }
  canGoBack() {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    return (
      this.today.getMonth() !== currentMonth ||
      this.today.getFullYear() !== currentYear
    );
  }
  pickDate(day: any) {
    Debug.log('day.value', day.value);
    if (this.selectedTime) {
      const [hours, minutes] = this.selectedTime.split(':').map(Number);
      day.value.setHours(hours, minutes);
    }
    this.selectedDate = day.value;
    this.postService.updatePickedDate(this.selectedDate);
  }

  unPickDate() {
    this.postService.updatePickedDate(null);
  }

  pickTime(event: any) {
    this.selectedTime = event.target.value;
    if (!this.selectedDate) {
      return;
    }

    const [hours, minutes] = event.target.value.split(':').map(Number);
    this.selectedDate.setHours(hours, minutes);
    this.postService.updatePickedDate(this.selectedDate);
  }

  isSelectedDate(date: Date | null | undefined): boolean {
    if (!date || !this.selectedDate) {
      return false;
    }

    // const format = (date: Date) => {
    // 	return date.toISOString().split('T')[0]
    // };
    // const comparison = format(this.selectedDate) === format(date)
    // if (comparison) {
    // 	Debug.log("you telling me this ", format(this.selectedDate), "is equal to this ", format(date), "?")
    // }
    // return comparison;

    const day1 = date.getDate();
    const month1 = date.getMonth();
    const year1 = date.getFullYear();

    const day2 = this.selectedDate.getDate();
    const month2 = this.selectedDate.getMonth();
    const year2 = this.selectedDate.getFullYear();

    return day1 === day2 && month1 === month2 && year1 === year2;
  }

  ngOnDestroy() {}

  legendWidth(): number {
    return (this.screenWidth * 16.38) / 100 > 248
      ? (this.screenWidth * 16.38) / 100
      : 248;
  }
}

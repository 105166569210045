
<div class="mt-0 mr-5 relative ">
  <button (click)="toggleDropdown()" type="button" [style.width.px]="screenWidth * 10/100" [style.height.px]="screenHeight * 4/100" [ngClass]="{'pr-10': !isMobileView, 'pr-[1.75rem]': isMobileView}" class="mt-3 h-8 relative bg-white border border-gray-300 rounded-lg shadow-sm pl-3 py-1 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm font-sans font-black" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
    <span class="flex items-center">
      <img class=""[style.width.px]="screenWidth * 2/120" [style.height.px]="screenHeight * 2.5/120" src="{{env.apiUrl}}/assets/{{selectedLanguage.code}}.png" alt="" class="flex-shrink-0 h-6 w-6 rounded-md">
      <span [ngStyle]="{'font-size': screenWidth/167 + screenHeight/167+ 'px'}" class="m-auto text-gray-700 " *ngIf="!isMobileView"> {{selectedLanguage?.label }} </span>
    </span>
    <span class="ml-3 absolute inset-y-0 right-0 flex items-center pr-1 pointer-events-none text-sm font-sans font-black">
      <!-- Heroicon name: solid/selector -->
      <span class="mr-2">
        <!-- Heroicon name: solid/selector -->
        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="10" viewBox="0 0 320 512"><path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/></svg>
      </span>
    </span>
  </button>
  <ul class="absolute z-10 mt-1 w-full bg-white rounded-md" [class.hidden]="!isDropdownOpen">
    <li class=" cursor-pointer text-sm select-none relative py-1 pl-3 pr-9 rounded-md hover:bg-gray-100"
        *ngFor="let language of languages"
        (click)="selectLanguage(language.code)"
        [id]="'listbox-option-' + language.code"
        role="option">
      <div class="flex items-center ">
        <img [style.width.px]="screenWidth * 2/100" [style.height.px]="screenHeight * 2.5/100" src="{{env.apiUrl}}/assets/{{language.code}}.png" alt="" class="flex-shrink-0 h-6 w-6 rounded-md">
        <span [ngStyle]="{'font-size': screenWidth/167 + screenHeight/167+ 'px'}" class="ml-3 text-gray-700 font-sans font-black block truncate rounded-md"> {{language.label}} </span>
      </div>
    </li>
  </ul>
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocialMediaService {
	apiUrl:string = environment.apiUrl;
  constructor(private httpClient: HttpClient) { }

	getPagesFromSocialMedia(socialMediaId: string): Observable<any> {
		return this.httpClient.get(`${this.apiUrl}/social-medias/${socialMediaId}/pages`);
	}

	updateSelectedPage(socialMediaId: string, pageId: string, groupId: number): Observable<any> {
		return this.httpClient.put(`${this.apiUrl}/social-medias/${socialMediaId}/update-page`, { pageId: pageId, groupId: groupId });
	}
	
}

import {
  Component,
  HostListener,
  Input,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { CalenderBaseComponent } from 'src/app/components/base/calendar.component';
import { CALENDERMODE } from 'src/app/interfaces/CalendarMode';
import { PostService } from 'src/app/services/repositories/post.service';
import { CalendarService } from 'src/app/services/utilities/calendar.service';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';
import { PostFullHourPreviewComponent } from '../calendar-week-view/post-full-hour-preview/post-full-hour-preview.component';
import { Debug } from 'src/app/utils/debug';

@Component({
  selector: 'app-calendar-day-view',
  templateUrl: './calendar-day-view.component.html',
  styleUrls: ['./calendar-day-view.component.scss'],
})
export class CalendarDayViewComponent extends CalenderBaseComponent {
  @Input() screenWidth: number = 0;
  @Input() screenHeight: number = 0;
  ngAfterViewInit() {
    this.scrollToElement();
  }
  height = 500;
  scrollToElement() {
    Debug.log('scrolling to post-label ...');
    let elementId = 'post-label';
    setTimeout(() => {
      const element = document.getElementById(elementId);
      const container = document.getElementById('week-container'); // Target the specific scrollable container

      if (element && container) {
        // Calculate the position of the element relative to the container
        const elementRect = element.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        // Calculate how much we need to scroll the container to bring the element into view
        const scrollTop =
          elementRect.top - containerRect.top + container.scrollTop;

        container.scrollTo({
          top: scrollTop,
          behavior: 'smooth',
        });
      }
    }, 1000);
  }
  selectedPostId: string = '';

  @ViewChildren(PostFullHourPreviewComponent)
  postFullHourPreviewComponents!: QueryList<PostFullHourPreviewComponent>;
  isClickHandled: boolean = false;
  @HostListener('click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const element = event.target as HTMLElement;
    //  Debug.log( "element",element)
    //check if we clicked on post item to
    if (element.classList.contains('post-item')) {
      this.selectedPostId = element.getAttribute('data-post-id') || '';

      if (this.selectedPostId) {
        //update the currentPostIndex based on the extracted post Id
      } else {
        Debug.log('could not load the clicked post into the preview ', element);
      }
      //we need to get the post item of the target element
      //and get the index of it on the post preview component
    }
    //how to find if this component containt app-post-full-hour-preview component as child
    if (this.isClickHandled) {
      // Reset the flag to allow immediate hiding of the component on subsequent clicks
      this.isClickHandled = false;
    } else {
      // Introduce a delay before checking if the component should be hidden
      setTimeout(() => {
        //chcek if the clicked target is not elemnt that triggers tha pannel
        if (!element.classList.contains('pannel-trigger')) {
          if (
            this.postFullHourPreviewComponents.toArray().length > 0 &&
            this.isFullHourPannelVisible
          ) {
            // Hide the panel
            this.isFullHourPannelVisible = false;
          }
        }
      }, 100);
    }
  }

  currentDayPosts: any[] = [];

  constructor(
    protected override postService: PostService,
    protected override uiStateService: UiStateService,
    protected override translationService: TranslationService,
    protected override calendarService: CalendarService
  ) {
    super(translationService, calendarService, postService, uiStateService);
  }
  override ngOnInit(): void {
    super.ngOnInit();

    this.groupSubscription = this.postService.currentGroups.subscribe(
      (groups) => {
        this.groupScopes = groups;
        Debug.log('selected groups updated', this.groupScopes);
        if (this.groupScopes.length > 0) {
          this.getDayPosts();
          this.scrollToElement();
        }
      }
    );

    this.getDayPosts();
    this.scrollToElement();
  }

  prevDay() {
    this.navigateBy(-1);
  }

  nextDay() {
    this.navigateBy(1);
  }

  navigateBy(ammount: number) {
    this.calendarService.addDay(ammount);
    this.getDayPosts();
    this.scrollToElement();
  }

  getDayPosts() {
    const lsData = this.postService.getGroupScopesFromLocalStorage();
    this.groupScopes = lsData.length > 0 ? lsData : this.groupScopes;
    if (this.groupScopes.length > 0) {
      let dateStr: string =
        this.currentDate.getFullYear() +
        '-' +
        (this.currentDate.getMonth() + 1) +
        '-' +
        this.currentDate.getDate();
      Debug.log('loading posts day for ' + dateStr + this.groupScopes);

      this.postService
        .getByTimeFrame(this.groupScopes, dateStr, 'day')
        .subscribe((res) => {
          this.currentDayPosts = res.data;
          Debug.log(this.currentDayPosts);
        });
    }
  }

  togglePostFullHourPannelVisibility(posts: any) {
    this.isFullHourPannelVisible = true;
    this.fullHourPannelData = this.sortPostsByTime(posts);
  }

  getDayPostsByHour(hour: number) {
    let hourStr = hour.toString().padStart(2, '0');
    let filtredposts = this.currentDayPosts.filter((post) => {
      let postDate =
        post.expectedPublishingDatetime == null
          ? post.publishingDatetime
          : post.expectedPublishingDatetime;
      let localTime = this.utcToCurrentTimeZone(new Date(postDate));
      let localHour = localTime.split(' ')[1].split(':')[0];
      // Debug.log("postDate",postDate,"localTime",localTime,"UTCH:",hourStr ,"LTZH:",localHour,"same hour",localHour === hourStr)
      if (localHour === hourStr) {
        //Debug.log("👌 local post Time",localTime,"post UTC",postDate,"UTCH:",hourStr ,"LTZH:",localHour,"same hour",localHour === hourStr);
      }
      return localHour === hourStr;
    });
    //  Debug.log(filtredposts,"formatted hour",hourStr,"row hour",hour)
    return filtredposts;
  }

  createUniqueKey(post: any, excludeKeys: string[]): string {
    return Object.entries(post)
      .filter(([key]) => !excludeKeys.includes(key))
      .map(([key, value]) => `${key}:${value}`)
      .join('|');
  }

  getCurrentDayStr() {
    return this.getTranslation('generic.weekDay' + this.currentDate.getDay());
  }

  getCurrentMonthStr() {
    return this.getTranslation(
      'generic.month' + (this.currentDate.getMonth() + 1)
    );
  }

  generateRange(n: number): number[] {
    return Array.from({ length: n }, (v, k) => k);
  }

  switchToMonthView(month: number): void {
    // Debug.log("switching to month",month)
    this.calendarService.setCurrentMonth(month);
    this.calendarService.setCurrentCalendarMode(CALENDERMODE.MONTH);
  }

  switchToYearView(year: number): void {
    // Debug.log("switching to year",year)
    this.calendarService.setCurrentYear(year);
    this.calendarService.setCurrentCalendarMode(CALENDERMODE.YEAR);
  }

  switchToWeekView() {
    this.calendarService.setCurrentCalendarMode(CALENDERMODE.WEEK);
  }
}

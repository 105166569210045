import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class DateService {

	public getStartOfWeek(date: Date, locale: string): Date {
		const startOfWeek = new Date(date);
		if (locale == "en") {
			startOfWeek.setDate(date.getDate() - date.getDay());
		} else if (locale == "fr") {
			startOfWeek.setDate(date.getDate() - (date.getDay() + 6) % 7);
		}
		return startOfWeek
	}

	public getEndOfWeek(date: Date, locale: string): Date {
		const endOfWeek = this.getStartOfWeek(date, locale);
		endOfWeek.setDate(endOfWeek.getDate() + 6);
		return endOfWeek
	}

	public getStartOfMonth(date: Date): Date {
		return	new Date(date.getFullYear(), date.getMonth(), 1);
	}

	public getEndOfMonth(date: Date): Date {
		return	new Date(date.getFullYear(), date.getMonth()+ 1, 0);
	}

	public getStartOfYear(date: Date): Date {
		return new Date(date.getFullYear(), 0, 1);
	}

	public getEndOfYear(date: Date): Date {
		return new Date(date.getFullYear() + 1, 0, 0);
	}
}
<div *ngFor="let item of comments">
    <div class="flex items-start space-x-4 mb-4 ">
        <div class="mt-4">
            <svg width="60px" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" viewBox="-2.4 -2.4 28.80 28.80" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"><rect x="-2.4" y="-2.4" width="28.80" height="28.80" rx="14.4" fill="#bdbfc5" strokewidth="0"></rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path></g></svg>
        </div>
        <div class="w-full relative my-5">
            <div class="border-2 border-blue-500 p-4 rounded-b-3xl rounded-r-3xl" [ngClass]="{'bg-cliking_green text-white':item.name == 'Admin', 'bg-gray-200':item.name != 'Admin'}">
                <p class="">{{item.comment}}</p>
            </div>
            <div class="flex space-x-4 absolute right-0 mr-4 pt-2">
                <button class="text-blue-600 border-2 border-blue-400 rounded-3xl px-3">{{"generic.like" | translate}}</button>
                <button class="text-blue-600 border-2 border-blue-400 rounded-3xl px-3" (click)="reply(item)">{{"generic.reply" | translate}}</button>
            </div>
            <div *ngFor="let rep of item.replies" class="flex items-start space-x-4 mt-16">
                <div>
                    <svg width="50px" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" viewBox="-2.4 -2.4 28.80 28.80" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"><rect x="-2.4" y="-2.4" width="28.80" height="28.80" rx="14.4" fill="#bdbfc5" strokewidth="0"></rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path></g></svg>
                </div>
                <div class="w-full">
                    <div class="p-4 rounded-b-3xl rounded-r-3xl" [ngClass]="{'bg-cliking_green text-white':rep.name == 'Admin', 'bg-gray-200':rep.name != 'Admin'}">
                        <p>{{rep.comment}}</p>
                    </div>
                    <div class="flex space-x-4 absolute right-0 mr-4 pt-2">
                        <button class="text-blue-600 border-2 border-blue-400 rounded-3xl px-3">{{"generic.like" | translate}}</button>
                        <button class="text-blue-600 border-2 border-blue-400 rounded-3xl px-3" (click)="reply(rep)">{{"generic.reply" | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
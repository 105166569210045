<div class="mt-0  relative ">
  <button (click)="toggleDropdown()" type="button" [style.width.px]="screenWidth * 10/100" [style.height.px]="screenHeight * 4/100" class="h-8 flex items-center justify-between mt-3 bg-white border border-gray-300 rounded-lg shadow-sm pl-3 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm font-sans font-black" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
    <svg  class="flex-shrink-0 h-6 w-6 text-gray-600"
    fill="currentColor" xmlns="http://www.w3.org/2000/svg" height="15" width="10" viewBox="0 1 576 512"><path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/></svg>
   <span class="flex-grow font-sans font-black text-center text-gray-700 block truncate" [ngStyle]="{'font-size': screenWidth/167 + screenHeight/167+ 'px'}" *ngIf="!isMobileView" >{{"header.settings"|translate}}</span>
      <!-- Heroicon name: solid/selector -->
      <span class="flex-shrink-0 h-6 w-6">
        <!-- Heroicon name: solid/selector -->
        <svg class="mt-1" xmlns="http://www.w3.org/2000/svg" height="16" width="10" viewBox="0 0 320 512"><path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/></svg>
      </span>
  </button>
  <ul  [style.width.px]="screenWidth * 10/100"  [ngStyle]="{'font-size': screenWidth/175 + screenHeight/175+ 'px'}"  class="absolute z-10 mt-1 bg-white rounded-md" [class.hidden]="!isDropdownOpen"  style="top: 100%; left: 0;">
    <a (click)="isDropdownOpen=false" [routerLink]="auth?.isAdmin?'/settings':'/login'" [ngClass]="{
      'cursor-pointer':auth?.isAdmin,
      'cursor-not-allowed text-gray-400':!auth?.isAdmin
    }">
      <li class="select-none relative  py-1 pl-3  hover:bg-gray-100 rounded-md">
        {{ 'header.appGlobalSettings' | translate }} 
      </li>
    </a>

    <a (click)="isDropdownOpen=false" [routerLink]="auth?.UI.canAccessSocialPages?'/social-medias':'/login'"  [ngClass]="{
      'cursor-pointer':auth?.UI.canAccessSocialPages ,
      'cursor-not-allowed text-gray-400':!auth?.UI.canAccessSocialPages 
    }" >
      <li class=" select-none relative  py-1 pl-3  hover:bg-gray-100 rounded-md" >
        {{ 'header.socialMedia' | translate }}
      </li>
    </a>
    <a href="#" (click)="logout()">
      <li class="cursor-pointer select-none relative  py-1 pl-3  hover:bg-gray-100 rounded-md">
        {{'generic.logout' | translate}}
      </li>
    </a>
    <!-- <li class="cursor-pointer text-sm select-none relative py-1 pl-3 pr-9 hover:bg-gray-100">
      <a href="/another-route">{{'logout' | translate}} </a>
    </li>   -->
  </ul>
</div>
import { Component, Input } from '@angular/core';
import * as moment from 'moment';
import { Debug } from 'src/app/utils/debug';


@Component({
  selector: 'app-post-plateform-preview',
  templateUrl: './post-plateform-preview.component.html',
  styleUrls: ['./post-plateform-preview.component.scss']
})
export class PostPlateformPreviewComponent {
  @Input() selectedPlateform: string = ""
  @Input() data: any;
  @Input() screenHeight: number = 0
  @Input() screenWidth: number = 0
  @Input() copyPost: boolean = true
  linkedinData: any = null;
  linkedinMedias: any[] = []
  googleData: any = null;
  googleMedias: any[] = []
  facebookData: any = null;
  facebookMedias: any[] = []
  instagramData: any = null;
  instagramMedias: any[] = []
  mediaAllpreview: any =  null;

  ngOnInit(){
    
    this.data.SocialPlatforms.forEach((item: { medias: string[]; source: string;})=>{
      if(item.source == "linkedin"){
        this.linkedinData = item;
        if(item.hasOwnProperty("medias")){ 
          this.linkedinMedias = item.medias;
        }
      }  
      if(item.source == "google"){
        this.googleData = item;
        if(item.hasOwnProperty("medias"))
          this.googleMedias = item.medias
      }
      if(item.source == "facebook"){
        this.facebookData = item;
        if(item.hasOwnProperty("medias")){
          this.facebookMedias = item.medias;
        }
      }
      if(item.source == "instagram"){
        this.instagramData = item;
        if(item.hasOwnProperty("medias"))
          this.instagramMedias = item.medias
      }
    })
    this.choiceAllMediaPreview();
    this.setOrientation();
    this.timeSince(this.data.publishingDatetime)
  }

  choiceAllMediaPreview(){
    if(this.googleMedias.length > 0){
      this.mediaAllpreview = this.googleMedias[0];
      return
    }
    
    if(this.linkedinMedias.length > 0){
      this.mediaAllpreview = this.linkedinMedias[0];
      return
    }
    
    if(this.facebookMedias.length > 0){
      this.mediaAllpreview = this.facebookMedias[0];
      return
    }
    
    if(this.instagramMedias.length > 0){
      this.mediaAllpreview = this.instagramMedias[0];
      return
    }
  }
  
  timeSince(dateString: string): string {
    if (dateString == null) {
        return '';
    }
    const date = new Date(dateString);
    const now = new Date();
    const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    if (seconds < 0) {
        const remainingSeconds = Math.abs(seconds);
        let interval = Math.floor(remainingSeconds / 31536000);
        if (interval >= 1) {
            return interval === 1 ? 'Il sera publié dans 1 an' : `Il sera publié dans ${interval} ans`;
        }
        interval = Math.floor(remainingSeconds / 2592000);
        if (interval >= 1) {
            return interval === 1 ? 'Il sera publié dans 1 mois' : `Il sera publié dans ${interval} mois`;
        }
        interval = Math.floor(remainingSeconds / 86400);
        if (interval >= 1) {
            return interval === 1 ? 'Il sera publié dans 1 jour' : `Il sera publié dans ${interval} jours`;
        }
        interval = Math.floor(remainingSeconds / 3600);
        if (interval >= 1) {
            return interval === 1 ? 'Il sera publié dans 1 heure' : `Il sera publié dans ${interval} heures`;
        }
        interval = Math.floor(remainingSeconds / 60);
        if (interval >= 1) {
            return interval === 1 ? 'Il sera publié dans 1 minute' : `Il sera publié dans ${interval} minutes`;
        }
        return 'Il sera publié dans quelques secondes';
    }

    let interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
        return interval === 1 ? 'Il y a 1 an' : `Il y a ${interval} ans`;
    }
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
        return interval === 1 ? 'Il y a 1 mois' : `Il y a ${interval} mois`;
    }
    interval = Math.floor(seconds / 86400);
    if (interval >= 1) {
        return interval === 1 ? 'Il y a 1 jour' : `Il y a ${interval} jours`;
    }
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
        return interval === 1 ? 'Il y a 1 heure' : `Il y a ${interval} heures`;
    }
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
        return interval === 1 ? 'Il y a 1 minute' : `Il y a ${interval} minutes`;
    }
    return 'Il y a quelques secondes';
}

  
  formatDateWithMoment(dateString: string): string {
    if(dateString!=null)
      return moment(dateString).format('D MMMM YYYY');
    else
      return ''
  }

  checkImageOrientation(imageUrl: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        const isLandscape = img.width > img.height;
        if (isLandscape) {
          resolve("landscape");
        } else {
          resolve("portrait");
        }
      };
      img.onerror = (error) => {
        reject("Error loading image");
      };
    });
  }

  async setOrientation(): Promise<void> {
    try {
      if(this.linkedinMedias.length>1)
        this.linkedinData.orientation = await this.checkImageOrientation(this.linkedinMedias[0].url);
      if(this.facebookMedias.length>1)
        this.facebookData.orientation = await this.checkImageOrientation(this.facebookMedias[0].url);
    } catch (error) {
      Debug.fail('Erreur lors du chargement de l\'image :', error);
    }
  }

	mediaIsVideo(medias: any[]){
		return (medias.length == 1 && medias[0]!=null && medias[0]?.mimeType?.startsWith("video/"))
	}

}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-widget-stats',
  templateUrl: './widget-stats.component.html',
  styleUrls: ['./widget-stats.component.scss']
})
export class WidgetStatsComponent {
  @Input() label: string = '';
  @Input() value: string = '';
  @Input() percentage: number = 0;

  getPercentage(){
    return (this.percentage>0?'+'+this.percentage:'-'+Math.abs(this.percentage))+'%'
  }

  formatNumber(value: string):any {
    if (Number(value) > 1000) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
    return value;
  }

}

<div class=" ml-5 h-28 py-2 rounded-xl bg-gray-100 flex justify-between flex-col">
    <div class="text-sm ml-2 font-semibold leading-5">{{label | translate}}</div>
    <div class="join">

        
        <span class="text-2xl mx-auto font-bold">{{formatNumber(value)}}</span>   
        <!-- <span class=" ml-1 join" [ngClass]="{'text-cliking_red':percentage<0, 'text-cliking_green':percentage>0 }" >
            <svg *ngIf="percentage<0" class="w-4 -mt-[38px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="currentcolor">
                <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/>
            </svg>
            <svg *ngIf="percentage>0" class="w-4 -mt-[30px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="currentcolor">
                <path d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8l256 0c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z"/>
            </svg>
            <span class="text-xs -mt-2 ml-1">
                <span>{{getPercentage()}}</span>
            </span>
        </span> -->
    </div>
</div>

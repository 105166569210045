<div class="bg-white rounded-3xl" >
    <div class="grid grid-rows-1 grid-cols-7">
        <span class="text-center col-span-7 row-span-1 text-gray-400 font-semibold cursor-pointer" [title]="getTranslation('generic.showTheYear')+' '+currentDate.getFullYear()" (click)="gotoYear(currentDate.getFullYear())"> 
            {{toTitleCase(getTodayMonthString())+" "+currentDate.getFullYear()   }}
        </span>
    </div>
    <div class="grid grid-cols-7 ">
        <span [style.font-size.px]="screenWidth*0.7/100 + screenHeight*0.7/100" class="col-span-1 text-gray-400 font-medium mx-auto mt-3">
        <span class="cursor-pointer" [style.font-size.px]="screenWidth*0.7/100 + screenHeight*0.7/100" [style.margin-right.px]="screenWidth*2/100" (click)="prevMonth()">←</span>{{'generic.weekDay1'|translate}}</span>
        <span [style.font-size.px]="screenWidth*0.7/100 + screenHeight*0.7/100" class="col-span-1 text-gray-400 font-medium mx-auto mt-3">{{'generic.weekDay2'|translate}}</span>
        <span [style.font-size.px]="screenWidth*0.7/100 + screenHeight*0.7/100" class="col-span-1 text-gray-400 font-medium mx-auto mt-3">{{'generic.weekDay3'|translate}}</span>
        <span [style.font-size.px]="screenWidth*0.7/100 + screenHeight*0.7/100" class="col-span-1 text-gray-400 font-medium mx-auto mt-3">{{'generic.weekDay4'|translate}}</span>
        <span [style.font-size.px]="screenWidth*0.7/100 + screenHeight*0.7/100" class="col-span-1 text-gray-400 font-medium mx-auto mt-3">{{'generic.weekDay5'|translate}}</span>
        <span [style.font-size.px]="screenWidth*0.7/100 + screenHeight*0.7/100" class="col-span-1 text-gray-400 font-medium mx-auto mt-3">{{'generic.weekDay6'|translate}}</span>
        <span [style.font-size.px]="screenWidth*0.7/100 + screenHeight*0.7/100" [style.margin-right.px]="screenWidth*1.5/100" class="col-span-1 text-gray-400 font-medium  mt-3">{{'generic.weekDay0'|translate}} <span class="cursor-pointer" [style.font-size.px]="screenWidth*0.7/100 + screenHeight*0.7/100" [style.margin-left.px]="screenWidth*1/100" (click)="nextMonth()">→</span></span>
    </div>
    <div class="calender-days grid grid-cols-7 grid-rows-5  rounded-2xl m-5 -mt-[0.05rem] pb-5" [style.height.px]="screenHeight*65/100">
        <span class="border border-1.5 border-gray-300 w-fullcol-span-1 row-span-1 bg-gray-100"
        [ngClass]="{
            'rounded-tl-2xl':i==0,
            'rounded-tr-2xl':i==6,
            'rounded-bl-2xl':i==28 && currentMonthData.length==35 ||i==35 && currentMonthData.length==42,
            'rounded-br-2xl':i==34 && currentMonthData.length==35 ||i==41 && currentMonthData.length==42,
            'text-gray-300':  idDayFromThisMonth(d.day,i)
            }"
            [style.height]="currentMonthData.length == 35 ? screenHeight*14/100+'px' : screenHeight*11.5/100+'px'"
        *ngFor="let d of currentMonthData; let i = index">
            <span
            [ngClass]="{
                'text-orange-500':isCurrentDay(d)
                }"
                class="ml-4 mt-8 font-medium cursor-pointer"
                ><span (click)="goToDay(d,i)" [style.font-size.px]="screenHeight>1000?screenHeight*0.8/100+screenWidth*0.8/100: screenHeight*0.6/100+screenWidth*0.6/100">{{d.day.toString().padStart(2,'0')}} </span>
                    <div>
                        <!-- post label -->
                        <div class="text-[10px] -mt-[6px] mx-1" *ngIf="d.data?.length>0 && isPostPlatformInFilter(d)" [ngClass]="{
                            'grid grid-cols-6':d.data?.length>1
                        }">
                            <div
                            [ngClass]="{
                                'c-b-accent-3 text-white': d.data[d.previewIndex].publishingDatetime!=null,
                                'c-b-accent-4 text-black': d.data[d.previewIndex].publishingDatetime==null,
                                'col-span-5': d.data?.length > 1
                            }"
                                    class="px-2 pb-1 rounded-md w-full h-full m-auto" >
                                    
                                    <h3  [style.font-size.px]="currentMonthData.length == 35?screenHeight*0.6/100+screenWidth*0.6/100: screenHeight*0.5/100+screenWidth*0.5/100">{{d.data[d.previewIndex].title.length > 10 ? d.data[d.previewIndex].title.slice(0, 10) + "..." : d.data[d.previewIndex].title }}</h3>
                                    <h3>
                                        <!-- post target platforms -->
                                        <ul class="grid grid-cols-2 gap-1">
                                            <li *ngFor="let platform of d.data[d.previewIndex].SocialPlatforms"
                                            class="rounded-full"
                                            [ngClass]="{
                                                'bg-sky-600':platform.source.toLowerCase()=='facebook' && currentPlaTformsFilters.isFacebookON,
                                                'bg-orange-600':platform.source.toLowerCase()=='google' && currentPlaTformsFilters.isGoogleON,
                                                'bg-blue-600':platform.source.toLowerCase()=='linkedin' && currentPlaTformsFilters.isLinkedinON,
                                                'bg-pink-600':platform.source.toLowerCase()=='instagram' && currentPlaTformsFilters.isInstagramON,
                                            }">
                                                <span class="m-auto w-11/12 h-80 py-1" *ngIf="
                                                platform.source.toLowerCase()=='facebook' && currentPlaTformsFilters.isFacebookON ||
                                                platform.source.toLowerCase()=='google' && currentPlaTformsFilters.isGoogleON ||
                                                platform.source.toLowerCase()=='linkedin' && currentPlaTformsFilters.isLinkedinON ||
                                                platform.source.toLowerCase()=='instagram' && currentPlaTformsFilters.isInstagramON
                                                ">
                                                    <img  class="w-full" src="assets/img/{{ platform.source.toLowerCase() }}-label.png" alt="{{ platform.source }}">
                                                </span>
                                            </li>
                                        </ul>
                                    </h3>
                            </div>
                            <div *ngIf="d.data.length>1"(click)="updatePreviewIndex(d)" class="pannel-trigger  bg-white border border-2 border-indigo-400 rounded cursor-pointer  h-full flex justify-center items-center text-xs text-indigo-400 m-auto ">
                                <p class="pannel-trigger">+{{d.data.length-1}}&nbsp;<br>&nbsp;→</p>
                            </div>
                        </div>
                    </div>
            </span>
        </span>
    </div>      
                       
</div>
<div class="flex flex-wrap pb-2 pr-1">
    <div class="bg-[#465086] ml-2 rounded-lg px-2 mb-2 h-[30%]">
        <div class="flex">
            <span *ngIf="type=='views'||type=='impressions'" class="my-1 mr-3">
                <svg width="20px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill="#ffffff" d="M512 160c320 0 512 352 512 352S832 864 512 864 0 512 0 512s192-352 512-352zm0 64c-225.28 0-384.128 208.064-436.8 288 52.608 79.872 211.456 288 436.8 288 225.28 0 384.128-208.064 436.8-288-52.608-79.872-211.456-288-436.8-288zm0 64a224 224 0 1 1 0 448 224 224 0 0 1 0-448zm0 64a160.192 160.192 0 0 0-160 160c0 88.192 71.744 160 160 160s160-71.808 160-160-71.744-160-160-160z"></path></g></svg>
            </span>
            <span *ngIf="type=='reactions'" class="my-1 mr-3">
                <svg width="20px" viewBox="-1.6 -1.6 19.20 19.20" xmlns="http://www.w3.org/2000/svg" fill="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.576"></g><g id="SVGRepo_iconCarrier"> <path fill="#ffffff" fill-rule="evenodd" d="M8,0 C12.4183,0 16,3.58172 16,8 C16,12.4183 12.4183,16 8,16 C3.58172,16 0,12.4183 0,8 C0,3.58172 3.58172,0 8,0 Z M8,2 C4.68629,2 2,4.68629 2,8 C2,11.3137 4.68629,14 8,14 C11.3137,14 14,11.3137 14,8 C14,4.68629 11.3137,2 8,2 Z M11,9 C11,10.6569 9.65685,12 8,12 C6.40232321,12 5.09633941,10.7511226 5.00509271,9.17627735 L5,9 L11,9 Z M6,5 C6.55228,5 7,5.44772 7,6 C7,6.55228 6.55228,7 6,7 C5.44772,7 5,6.55228 5,6 C5,5.44772 5.44772,5 6,5 Z M10,5 C10.5523,5 11,5.44772 11,6 C11,6.55228 10.5523,7 10,7 C9.44771,7 9,6.55228 9,6 C9,5.44772 9.44771,5 10,5 Z"></path> </g></svg>
            </span>
            <span *ngIf="type=='comments'" class="my-1 mr-3">
                <svg width="20px" fill="#ffffff" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" transform="matrix(-1, 0, 0, 1, 0, 0)" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M144 208c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm112 0c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm112 0c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zM256 32C114.6 32 0 125.1 0 240c0 47.6 19.9 91.2 52.9 126.3C38 405.7 7 439.1 6.5 439.5c-6.6 7-8.4 17.2-4.6 26S14.4 480 24 480c61.5 0 110-25.7 139.1-46.3C192 442.8 223.2 448 256 448c141.4 0 256-93.1 256-208S397.4 32 256 32zm0 368c-26.7 0-53.1-4.1-78.4-12.1l-22.7-7.2-19.5 13.8c-14.3 10.1-33.9 21.4-57.5 29 7.3-12.1 14.4-25.7 19.9-40.2l10.6-28.1-20.6-21.8C69.7 314.1 48 282.2 48 240c0-88.2 93.3-160 208-160s208 71.8 208 160-93.3 160-208 160z"></path></g></svg>
            </span>
            <span *ngIf="type=='shares'" class="my-1 mr-3">
                <svg width="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M13.803 5.33333C13.803 3.49238 15.3022 2 17.1515 2C19.0008 2 20.5 3.49238 20.5 5.33333C20.5 7.17428 19.0008 8.66667 17.1515 8.66667C16.2177 8.66667 15.3738 8.28596 14.7671 7.67347L10.1317 10.8295C10.1745 11.0425 10.197 11.2625 10.197 11.4872C10.197 11.9322 10.109 12.3576 9.94959 12.7464L15.0323 16.0858C15.6092 15.6161 16.3473 15.3333 17.1515 15.3333C19.0008 15.3333 20.5 16.8257 20.5 18.6667C20.5 20.5076 19.0008 22 17.1515 22C15.3022 22 13.803 20.5076 13.803 18.6667C13.803 18.1845 13.9062 17.7255 14.0917 17.3111L9.05007 13.9987C8.46196 14.5098 7.6916 14.8205 6.84848 14.8205C4.99917 14.8205 3.5 13.3281 3.5 11.4872C3.5 9.64623 4.99917 8.15385 6.84848 8.15385C7.9119 8.15385 8.85853 8.64725 9.47145 9.41518L13.9639 6.35642C13.8594 6.03359 13.803 5.6896 13.803 5.33333Z" fill="#ffffff"></path> </g></svg>
            </span>
            <span *ngIf="type=='interactions'" class="my-1 mr-3">
                <svg width="20" height="20" viewBox="0 0 5.2916666 5.2916666" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><g id="g4" transform="rotate(99.882277,2.9662436,2.6471663)"><g id="g5" transform="matrix(1.5482632,0,0,1.5482632,-1.7829298,-1.0687243)"><g id="g3" transform="rotate(-141.89299,3.1136427,1.8497607)"><path id="path2" style="fill:none;stroke:#ffffff;stroke-width:0.219;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;paint-order:stroke fill markers" d="M 2.0369011,2.5867942 2.6560134,1.5685488 3.2547652,2.5867942 Z"/><path style="fill:none;stroke:#ffffff;stroke-width:0.219;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1;paint-order:stroke fill markers" d="M 2.6458333,3.2053801 V 2.6926655"></path>/</g><path id="path4" style="fill:none;stroke:#ffffff;stroke-width:0.265;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;paint-order:stroke fill markers" d="m 4.2632085,2.6472294 c 0,0.5271687 -0.3190712,1.0018953 -0.8072006,1.2009845 C 2.9678785,4.0473032 2.4078079,3.9311447 2.0391164,3.5543511 1.6704249,3.1775575 1.5664665,2.6150946 1.7761177,2.1314075 1.9857689,1.6477205 2.4673181,1.3390423 2.9943622,1.3504998"/><path id="circle4" style="fill:none;stroke:#ffffff;stroke-width:0.265;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;paint-order:stroke fill markers" d="m 3.6480848,2.6472294 c 0,0.3755658 -0.3036751,0.6804342 -0.679238,0.6819071 C 2.5932839,3.3306094 2.2872268,3.0281323 2.2842811,2.652578 2.2813353,2.2770237 2.5826099,1.9697828 2.9581497,1.9653643"/></g></g></svg>
            </span>
            <span *ngIf="type=='reach'" class="my-1 mr-3">
                <svg width="20" height="20" viewBox="0 0 5.291666 5.291666" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><g transform="translate(-0.28181101,0.13933254)"><circle style="fill:#ffffff;fill-opacity:0;stroke:#ffffff;stroke-width:0.305289;stroke-linecap:round;stroke-linejoin:bevel;stroke-dasharray:none" cx="2.6226194" cy="1.4956609" r="0.95539892" /><path style="fill-opacity:0;stroke:#ffffff;stroke-width:0.42423;stroke-linecap:round;stroke-linejoin:bevel" d="M 1.2282315,4.4132691 C 1.2192083,3.7237927 1.7395116,3.1421781 2.4257332,3.0746567" /><path style="fill:#ffffff;fill-opacity:1;stroke:#ffffff;stroke-width:0.365;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;paint-order:stroke fill markers" d="M 4.2002602,3.4478516 3.5258193,3.0596608 2.8860765,3.4541964 3.0468542,2.6928075 2.4739371,2.2062941 3.247744,2.1239206 3.5334047,1.4287032 3.850866,2.1391825 4.600331,2.196028 4.022726,2.7175018 Z" transform="matrix(0.8012027,0,0,0.8012027,1.0072904,1.6733872)" /><path style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.365001;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;paint-order:stroke fill markers" d="m 3.6132161,3.0757812 0.046827,0.066071 0.080402,-0.00968 -0.048366,0.064952 0.034048,0.073477 -0.07672,-0.025928 -0.059359,0.055087 9.512e-4,-0.080977 -0.070734,-0.039431 0.077307,-0.024118 z" transform="matrix(1.4707277,0,0,1.4707277,-1.3173218,-0.87560015)" /></g></svg> 
            </span>
            <span class="text-white mr-3 my-auto text-[15px] font-semibold">{{'generic.'+type | translate}}</span>
        </div>
        <div class="bg-gray-100 rounded-md h-5 mb-2 mx-auto flex"><span class="justify-center items-center mx-auto text-[12px] font-bold  ">{{value}}</span></div>
    </div>
</div>
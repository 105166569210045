import { Component } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { PostService } from 'src/app/services/repositories/post.service';
import { SocialMediaService } from 'src/app/services/repositories/social-media.service';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';
import { Router, NavigationExtras } from '@angular/router';
import { GroupService } from 'src/app/services/repositories/group.service';
import { Debug } from 'src/app/utils/debug';

@Component({
  selector: 'app-presence-management',
  templateUrl: './presence-management.component.html',
  styleUrls: ['./presence-management.component.scss'],
})
export class PresenceManagementComponent extends BaseComponent {
  pages: any = [];
  hoverState: boolean[] = [];
  selectedPageIds: number[] = [];
  multiSelectionMode: boolean = false;

  constructor(
    translationService: TranslationService,
    protected postService: PostService,
    protected groupService: GroupService,
    protected socialMediaService: SocialMediaService,
    protected uiStateService: UiStateService,
    private router: Router
  ) {
    super(translationService);
  }

  ngOnInit(): void {
    Debug.log('this.multiSelectionMode', this.multiSelectionMode);
    this.groupSubscription = this.postService.currentGroups.subscribe(
      (group) => {
        this.getPages();
      }
    );
    this.getPages();
  }

  getPages(): void {
    Debug.log('getpages', this.postService.currentGroupsSource.getValue());
    if (this.postService.currentGroupsSource.getValue().length > 0) {
      let googlePages: any = [];
      this.groupService
        .getGroupByIds(this.postService.currentGroupsSource.getValue())
        .subscribe((res) => {
          this.socialMediaService
            .getGooglePages(this.postService.currentGroupsSource.getValue())
            .subscribe((res) => {
              this.pages = res.data;

              Debug.log('google pages', this.pages);
              this.pages.forEach((page: any, index: number) => {
                this.hoverState[index] = false;
              });
            });
        });
    }
  }

  displayCardOption(index: number, state: boolean) {
    this.hoverState[index] = state;
  }

  handleCardClick(pageId: number, event: MouseEvent) {
    Debug.log('handleClick');
    if (!this.multiSelectionMode) {
      this.selectedPageIds = [pageId];
      const navigationExtras: NavigationExtras = {
        queryParams: { pageIds: this.selectedPageIds },
      };
      this.router.navigate(
        ['/presence-management/edit-page'],
        navigationExtras
      );
    } else {
      this.selectPage(pageId);
    }
  }

  handleSelectPage(pageId: number, event: MouseEvent) {
    Debug.log('selectPage');
    event.stopPropagation();
    this.selectPage(pageId);
  }

  selectPage(pageId: number) {
    const index = this.selectedPageIds.indexOf(pageId);
    if (index === -1) {
      // Item is not in the array, add it
      this.selectedPageIds = [...this.selectedPageIds, pageId];
    } else {
      // Item is in the array, remove it
      this.selectedPageIds = this.selectedPageIds.filter((_, i) => i !== index);
    }

    this.multiSelectionMode = this.selectedPageIds.length > 0;
    Debug.log('this.multiSelectionMode', this.multiSelectionMode);
  }

  selectAllPages() {
    this.selectedPageIds = this.pages.map((page: any) => page.socialMedia.id);
  }

  isPageSelected(pageId: number): boolean {
    return this.selectedPageIds.includes(pageId);
  }

  cancelSelection() {
    this.selectedPageIds = [];
    this.multiSelectionMode = false;
  }
}

<div #dateFilter class="date-filter filter-div relative h-full box-border border-[2px] border-[#8E8E8E]  bg-white rounded-[11px] hover:border-cliking_blue">
	<button (click)="toggleDropdown()" class="filter-pill h-full px-2 w-full  mr-4 flex items-center text-black text-base font-semibold font-poppins break-words hover:text-cliking_blue ">
		<div class="flex justify-center align-center grow"><span #dateDisplay class="text-sm" ></span></div>
		<i class="fa fa-angle-down"></i>
	</button>

	<div [class.hidden]="!isDropdownOpen" class="min-w-[453px] bg-white box-border border border-[#8E8E8E] shadow-[0px 0px 4px 5px rgba(0, 0, 0, 0.25)] rounded-[11px] top-[60px] min-w-[15rem] bg-white shadow-[0px 0px 4px 5px rgba(0, 0, 0, 0.25)] rounded-[11px] z-[46] absolute font-light">
		<div class="w-full flex">
			<!-- Shortcuts -->
			<div class="w-1/3">
				<div class="mt-6 px-4">
					<button type="button" [class.active]="activeButton === 'day'" class="date-shortcuts" (click)="setToday()" data-range="today">{{"date.day"|translate}}</button>
					<button type="button" [class.active]="activeButton === 'week'" class="date-shortcuts" (click)="setThisWeek()" data-range="this-week">{{"date.week"|translate}}</button>
					<button type="button" [class.active]="activeButton === 'month'" class="date-shortcuts" (click)="setThisMonth()" data-range="this-month">{{"date.month"|translate}}</button>
					<button type="button" [class.active]="activeButton === 'year'" class="date-shortcuts" (click)="setThisYear()" data-range="this-year">{{"date.year"|translate}}</button>
					<button type="button" [class.active]="activeButton === 'all-data'" class="date-shortcuts" (click)="setAllData()" data-range="all-data">{{"date.all-data"|translate}}</button>
					<button type="button" [class.active]="activeButton === 'custom'" class="date-shortcuts" (click)="clearDate($event)" data-range="custom">{{"date.custom"|translate}}</button>
				</div>
			</div>
			<!-- Divider -->
			<div class="my-5 relative">
				<div class="absolute inset-y-0 left-0 bg-gray-300" style="width: 1px;"></div>
			</div> 
			<!-- Calendar -->
			<div class="flex-grow p-4">
				<div class="w-full flex items-center justify-center">
					<button type="button" class="calendar-begin-end-btn border rounded-l-[7px]" (click)="navigateToStartDate($event)">{{"generic.start"|translate}}</button>
					<button type="button" class="calendar-begin-end-btn border border-l-0 rounded-r-[7px]" (click)="navigateToEndDate($event)">{{"generic.end"|translate}}</button>
				</div>
				<div class="my-4">
					<input id="calendar" type="text" class="hidden" #flatpickrInput/>

					<div #flatpickrYearSelect class="flatpickr-calendar rangeMode animate flatpickr-monthSelect-theme-light" tabindex="-1">

						<div class="flatpickr-months">
							<span class="flatpickr-prev-month"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 17 17"><g></g>
								<path d="M5.207 8.471l7.146 7.147-0.707 0.707-7.853-7.854 7.854-7.853 0.707 0.707-7.147 7.146z"></path></svg>
							</span>
							<div class="flatpickr-month">
								<div class="flatpickr-current-month">
									<div>
										{{ yearsArray[0] }} - 
										<span *ngIf="yearsArray[0] !== yearsArray[yearsArray.length - 1]">
											{{ yearsArray[yearsArray.length - 1] }}
										</span>
									</div>
									<div class="numInputWrapper">
										<input class="numInput cur-year" type="number" tabindex="-1" aria-label="Year" >
										<span class="arrowUp"></span>
										<span class="arrowDown"></span>
									</div>
								</div>
							</div>
							<span class="flatpickr-next-month">
								<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 17 17"><g></g><path d="M13.207 8.472l-7.854 7.854-0.707-0.707 7.146-7.146-7.146-7.148 0.707-0.707 7.854 7.854z"></path></svg>
							</span>
						</div>
					
						<div class="flatpickr-innerContainer">
							<div class="flatpickr-rContainer w-full min-h-[10.5rem]">
								<div class="flatpickr-monthSelect-months grid grid-cols-3" tabindex="-1">
									<span *ngFor="let year of yearsArray" class="flatpickr-monthSelect-month w-full" [id]="'year' + year" tabindex="-1" (click)="setDateCustomYearFlatpickr($event, year)" (mouseenter)="hoverDateCustomYearFlatpickr($event, year)" >{{year}}</span>
								</div>
							</div>
    				</div>

					</div>
					<div class="flex justify-evenly my-2">
						<button type="button" (click)="clearDate($event)" class="calendar-control-btn bg-cliking_red hover:bg-cliking_red_nuance ">{{"generic.clear"|translate}}</button>
						<button type="button" (click)="onSubmit()" class="calendar-control-btn bg-cliking_blue hover:bg-cliking_blue_nuance" data-action="nav-filters#clearDate">{{"generic.submit"|translate}}</button>
					</div>
				</div>
			</div>
		</div>

	</div>
</div>


import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoogleErrorsService {
	constructor(private http: HttpClient) {}
  private apiUrl = environment.apiUrl;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  private errors: any = {
    ERROR_CODE_UNSPECIFIED: { fr: "Code d'erreur manquant.", en: "Missing error code." },
    INVALID_ATTRIBUTE_NAME: { fr: "L'un des attributs fournis ne peut pas être appliqué à cet emplacement.", en: "One of the attributes provided cannot be applied to this location." },
    ASSOCIATE_OPERATION_ON_VERIFIED_LOCATION: { fr: "Cette erreur est renvoyée lorsqu'un client essaie d'associer ou de dissocier un emplacement en état vérifié.", en: "This error is returned when a client tries to associate a location or clear a location association on a location that is in verified state." },
    ASSOCIATE_LOCATION_INVALID_PLACE_ID: { fr: "Le placeId fourni dans la demande d'association de l'emplacement ne correspond pas ou est invalide.", en: "The placeId provided in the associateLocation request is not a match for the location or is invalid." },
    LAT_LNG_UPDATES_NOT_PERMITTED: { fr: "Ce projet n'est pas autorisé à mettre à jour le champ lat/lng de l'emplacement.", en: "This project is not allowed to update the lat/lng field on the Location." },
    PO_BOX_IN_ADDRESS_NOT_ALLOWED: { fr: "La boîte postale ne peut pas être utilisée dans l'adresse.", en: "PO box cannot be used in address." },
    BLOCKED_REGION: { fr: "En raison de sanctions internationales, nous ne pouvons pas accepter d'entreprises de cette région.", en: "Due to international sanctions, we are unable to accept businesses from this region." },
    MISSING_BOTH_PHONE_AND_WEBSITE: { fr: "Un téléphone ou un site web est requis pour les entreprises CUSTOMER_LOCATION_ONLY.", en: "Either a phone or a website is required for CUSTOMER_LOCATION_ONLY businesses." },
    MISSING_STOREFRONT_ADDRESS_OR_SAB: { fr: "Tous les emplacements doivent avoir au moins une adresse de vitrine ou une zone de service.", en: "All Locations must have at least a storefront address or a service area." },
    LAT_LNG_TOO_FAR_FROM_ADDRESS: { fr: "La latitude/longitude et l'adresse sont trop éloignées.", en: "The latitude/longitude and address pair are too far apart." },
    LAT_LNG_REQUIRED: { fr: "REMPLACÉ. Requis par PIN_DROP_REQUIRED. L'adresse spécifiée ne peut pas être localisée. Veuillez fournir une latitude/longitude.", en: "DEPRECATED. Replaced by PIN_DROP_REQUIRED. The specified address cannot be located. Please provide a latitude/longitude." },
    INVALID_CHARACTERS: { fr: "Le texte contient des caractères invalides", en: "The text contains invalid characters" },
    FORBIDDEN_WORDS: { fr: "Le texte contient des mots interdits", en: "The text contains forbidden words" },
    INVALID_INTERCHANGE_CHARACTERS: { fr: "Le texte contient des caractères d'échange invalides", en: "The text contains invalid characters" },
    FIELDS_REQUIRED_FOR_CATEGORY: { fr: "Des champs supplémentaires sont requis pour cette catégorie d'emplacement.", en: "Additional fields are required for this category of location." },
    STOREFRONT_REQUIRED_FOR_CATEGORY: { fr: "Votre catégorie d'entreprise nécessite un emplacement de vitrine où les clients peuvent se rendre.", en: "Your business category requires a storefront location customers can visit." },
    ADDRESS_MISSING_REGION_CODE: { fr: "L'adresse est manquante du code de région requis.", en: "Address is missing required regionCode." },
    ADDRESS_EDIT_CHANGES_COUNTRY: { fr: "L'adresse ne peut pas être déplacée dans un autre pays.", en: "The address cannot be moved to different country." },
    SPECIAL_HOURS_SET_WITHOUT_REGULAR_HOURS: { fr: "Les heures spéciales ne peuvent être spécifiées que si l'emplacement a des heures d'ouverture régulières.", en: "Special hours can only be specified if the location has regular business hours." },
    INVALID_TIME_SCHEDULE: { fr: "Horaire invalide, les heures se chevauchent ou l'heure de fin est antérieure à l'heure de début.", en: "Invalid time schedule, overlapped or end time is earlier than start time." },
    INVALID_HOURS_VALUE: { fr: "Format ou valeur d'heures invalide.", en: "Invalid hours format or value." },
    OVERLAPPED_SPECIAL_HOURS: { fr: "Les heures spéciales ne peuvent pas se chevaucher.", en: "Special_hours cannot overlap." },
    INCOMPATIBLE_MORE_HOURS_TYPE_FOR_CATEGORY: { fr: "Votre catégorie d'entreprise principale ne prend pas en charge ce type d'heures.", en: "Your business primary category doesn't support this hours type." },
    DUPLICATE_CHILDREN_LOCATIONS: { fr: "Emplacements enfants en double dans relationshipData.", en: "Duplicate childrenLocations in relationshipData." },
    INCOMPATIBLE_SERVICE_AREA_AND_CATEGORY: { fr: "Une entreprise de zone de service ne peut pas avoir la catégorie principale sélectionnée.", en: "A service area business cannot have the selected primary category." },
    INVALID_SERVICE_AREA_PLACE_ID: { fr: "PlaceId invalide dans serviceArea.", en: "Invalid placeId in serviceArea." },
    INVALID_AREA_TYPE_FOR_SERVICE_AREA: { fr: "Type de zone invalide pour serviceArea.", en: "Invalid area type for serviceArea." },
    OPENING_DATE_TOO_FAR_IN_THE_FUTURE: { fr: "Entrez une date d'ouverture dans l'année.", en: "Enter an opening date that's within a year." },
    OPENING_DATE_MISSING_YEAR_OR_MONTH: { fr: "La date d'ouverture doit avoir une année ou un mois spécifié.", en: "Opening date must have a year or a month specified." },
    OPENING_DATE_BEFORE_1AD: { fr: "La date d'ouverture ne peut pas être avant l'an 1 après J.-C.", en: "Opening date cannot be before 1 AD." },
    TOO_MANY_ENTRIES: { fr: "Trop d'entrées pour le champ.", en: "Too many entries for the field." },
    INVALID_PHONE_NUMBER: { fr: "Numéro de téléphone non reconnu.", en: "Phone number input is not recognized." },
    INVALID_PHONE_NUMBER_FOR_REGION: { fr: "Numéro de téléphone invalide pour la région.", en: "Invalid phone number for region." },
    MISSING_PRIMARY_PHONE_NUMBER: { fr: "Numéro de téléphone principal manquant.", en: "Missing primary phone number." },
    THROTTLED: { fr: "Le champ ne peut pas être mis à jour pour le moment.", en: "The field cannot be updated at this time." },
    UNSUPPORTED_POINT_RADIUS_SERVICE_AREA: { fr: "Les zones de service de rayon de point ne sont plus prises en charge.", en: "Point radius service areas are no longer supported." },
    INVALID_CATEGORY: { fr: "Identifiant de catégorie invalide.", en: "Invalid category id." },
    CANNOT_REOPEN: { fr: "L'entreprise ne peut pas rouvrir.", en: "The business cannot reopen." },
    INVALID_BUSINESS_OPENING_DATE: { fr: "Date d'ouverture d'entreprise invalide.", en: "Invalid business opening date." },
    INVALID_LATLNG: { fr: "Latlng invalide.", en: "Invalid latlng." },
    PROFILE_DESCRIPTION_CONTAINS_URL: { fr: "La description de l'entreprise ne doit pas contenir d'URL.", en: "Business description should not contain URL." },
    LODGING_CANNOT_EDIT_PROFILE_DESCRIPTION: { fr: "La description du profil de l'emplacement d'hébergement ne peut pas être modifiée.", en: "Lodging location's profile description can't be edited." },
    INVALID_URL: { fr: "Lien invalide.", en: "Invalid link." },
    INVALID_ADDRESS: { fr: "Adresse invalide.", en: "Invalid address." },
    PARENT_CHAIN_CANNOT_BE_THE_LOCATION_ITSELF: { fr: "ParentChain ne peut pas être l'emplacement lui-même.", en: "ParentChain cannot be the location itself." },
    RELATION_CANNOT_BE_THE_LOCATION_ITSELF: { fr: "La relation ne peut pas être l'emplacement lui-même.", en: "Relation cannot be the location itself." },
    MISSING_ADDRESS_COMPONENTS: { fr: "Valeur manquante pour les composants d'adresse.", en: "Missing value for address components." },
    READ_ONLY_ADDRESS_COMPONENTS: { fr: "Les composants d'adresse en lecture seule ne peuvent pas être modifiés.", en: "Can't edit readonly address components." },
    STRING_TOO_LONG: { fr: "Le texte est trop long", en: "The text is too long" },
    STRING_TOO_SHORT: { fr: "Le texte est trop court", en: "The text is too short" },
    REQUIRED_FIELD_MISSING_VALUE: { fr: "Valeur manquante pour le champ requis.", en: "Missing value for required field." },
    ATTRIBUTE_PROVIDER_URL_NOT_ALLOWED: { fr: "L'URL d'un fournisseur ne peut pas être ajoutée ou modifiée.", en: "URL for a provider cannot be added or edited." },
    ATTRIBUTE_INVALID_ENUM_VALUE: { fr: "Valeur inconnue pour l'attribut enum.", en: "Unknown value for enum attribute." },
    ATTRIBUTE_NOT_AVAILABLE: { fr: "Attribut évolutif non valide pour cet emplacement.", en: "Scalable attribute not valid for this location." },
    ATTRIBUTE_CANNOT_BE_REPEATED: { fr: "L'attribut évolutif ne peut être spécifié qu'une seule fois.", en: "Scalable attribute only be specified once." },
    ATTRIBUTE_TYPE_NOT_COMPATIBLE_FOR_CATEGORY: { fr: "L'attribut évolutif n'est pas compatible avec les catégories définies sur l'emplacement.", en: "Scalable attribute is not compatible with the categories set on the location." },
    ADDRESS_REMOVAL_NOT_ALLOWED: { fr: "La suppression d'adresse n'est pas autorisée pour votre entreprise.", en: "Address removal is not allowed for your business." },
    AMBIGUOUS_TITLE: { fr: "Le meilleur nom est ambigu pour une langue.", en: "Best name is ambiguous for a language." },
    INVALID_CATEGORY_FOR_SAB: { fr: "Un pur SAB ne peut pas avoir de gcids qui sont des sous-types de gcid:establishment_poi.", en: "A pure SAB cannot have gcids that are subtypes of gcid:establishment_poi." },
    RELATION_ENDPOINTS_TOO_FAR: { fr: "Les points de terminaison de la relation sont trop éloignés les uns des autres.", en: "Relation endpoints are too far from each other." },
    INVALID_SERVICE_ITEM: { fr: "Ni structuredServiceItem ni freeFormServiceItem n'est défini.", en: "Neither structuredServiceItem nor freeFormServiceItem is set." },
    SERVICE_ITEM_LABEL_NO_DISPLAY_NAME: { fr: "L'étiquette est manquante d'un nom d'affichage.", en: "Label is missing display name." },
    SERVICE_ITEM_LABEL_DUPLICATE_DISPLAY_NAME: { fr: "Le nom d'affichage n'est pas unique pour toutes les étiquettes de toutes les listes de prix.", en: "Display name is not unique for all labels across all price lists." },
    SERVICE_ITEM_LABEL_INVALID_UTF8: { fr: "L'étiquette contient des symboles UTF-8 invalides.", en: "Label contains invalid UTF-8 symbols." },
    FREE_FORM_SERVICE_ITEM_WITH_NO_CATEGORY_ID: { fr: "Champ category_id manquant dans freeFormServiceItem.", en: "Missing category_id field in freeFormServiceItem." },
    FREE_FORM_SERVICE_ITEM_WITH_NO_LABEL: { fr: "Champ label manquant dans freeFormServiceItem.", en: "Missing label in freeFormServiceItem." },
    SERVICE_ITEM_WITH_NO_SERVICE_TYPE_ID: { fr: "Champ serviceTypeId manquant dans structuredServiceItem.", en: "Missing serviceTypeId field in structuredServiceItem." },
    INVALID_LANGUAGE: { fr: "Code de langue invalide.", en: "Language code is invalid." },
    PRICE_CURRENCY_MISSING: { fr: "Code de devise ISO 4217 manquant.", en: "Missing ISO 4217 currency code." },
    PRICE_CURRENCY_INVALID: { fr: "Le code de devise fourni n'est pas valide ISO 4217.", en: "Provided currency code is not valid ISO 4217." },
    SERVICE_TYPE_ID_DUPLICATE: { fr: "Les identifiants de type de service ne sont pas uniques au sein de l'emplacement.", en: "Service type ids are not unique within the Location." },
    PIN_DROP_REQUIRED: { fr: "L'adresse spécifiée ne peut pas être localisée. Veuillez utiliser l'interface utilisateur pour fournir une épingle.", en: "The specified address cannot be located. Please use the UI to provide a pin drop." },
    STALE_DATA: { fr: "Un ou plusieurs éléments ont été récemment mis à jour par Google. Seul le propriétaire de cette entreprise peut modifier les données à ce moment en visitant business.google.com.", en: "One or more items were recently updated by Google. Only the owner of this business can make changes to the data at this time by visiting business.google.com." },
    PHONE_NUMBER_EDITS_NOT_ALLOWED: { fr: "Le champ du numéro de téléphone a été désactivé pour cet emplacement et les modifications ne sont pas autorisées.", en: "The phone number field has been disabled for this location and edits are not allowed." },
    MULTIPLE_ORGANIZATIONALLY_PART_OF_RELATION: { fr: "Il existe plus d'une relation modélisant la relation logique entre deux emplacements (par exemple, département de, travaille à).", en: "There are more than one relations that model the logical relation between two locations (e.g. department of, works at)." }
	};

	escapeHtml(text: string): string {
    const map: { [key: string]: string } = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#039;'
    };
    return text.replace(/[&<>"']/g, function(m) { return map[m]; });
  };

  getErrorMessage(errorDetail: any, lang: string = 'fr'): string {
		const code = errorDetail.reason;
		let location = '';
		let invalidElement = '';
		let ret = this.errors[code] ? this.errors[code][lang] : 'Unknown error';
	
		switch (code) {
			case 'STRING_TOO_LONG':
			case 'STRING_TOO_SHORT':
				location = this.escapeHtml(errorDetail.metadata.field_mask.replace("profile.", ""));
				ret = ret + ` (<i>${location}</i>)`;
				break;
			case 'INVALID_CHARACTERS':
			case 'INVALID_INTERCHANGE_CHARACTERS':
				location = this.escapeHtml(errorDetail.metadata.field_mask.replace("profile.", ""));
				invalidElement = errorDetail.metadata.invalid_characters.split(',')
					.map((char:string) => this.escapeHtml(char))
					.join("</span>', '<span style='color: #fd397a'>");
				ret = ret + ` (<i>${location}</i>): '<span style='color: #fd397a'>${invalidElement}</span>'`;
				break;
		}
		return ret;
	}

	getApiErrors(): Observable<any> {
		return this.http.get<any>(`${this.apiUrl}/api-errors`,this.httpOptions);
	}
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import {ICON} from "src/app/interfaces/ModalIcon"


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  @Input() isOpen: boolean = false;
  @Input() isAutoClosed: boolean = false;
  @Input() autoClosedDuration: number = 1500;
  @Input() Heading: string= "";
  @Input() subHeading: string= "";
  @Input() icon: ICON= ICON.nothing;
  @Input() showCloseBtn: boolean = false;

  @Output() closeModal = new EventEmitter<void>();
  @Output() deleteItem = new EventEmitter<void>();

  close() {
    this.closeModal.emit();
  }

  isIconProvided(){
    return this.icon!=ICON.nothing
  }

  isIconQuestion(){
    return this.icon==ICON.question
  }

  isIconWarning(){
    return this.icon==ICON.warning
  }

  isIconInfo(){
    return this.icon==ICON.info
  }

  isIconDelete(){
    return this.icon==ICON.delete
  }

  isIconSuccess(){
    return this.icon==ICON.success
  }

  isIconFail(){
    return this.icon==ICON.fail
  }

  delete(){
    this.deleteItem.emit();    
  }

  ngOnInit() {
    if(this.isAutoClosed){
      setTimeout(()=>{
        this.close()
      },this.autoClosedDuration)
    }
  }
}

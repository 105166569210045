<fieldset class="mt-3 mx-auto border border-black border-1 rounded-3xl relative " [style.height.px]="screenHeight<850 ? screenHeight * 40/100: screenHeight * 33/100" [style.width.px]="screenWidth*41.59/100">
    <legend class="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mx-auto rounded-full p-2 px-4 c-bg-blue text-white" [style.width.px]="legendWidth()" [ngStyle]="{'font-size': (legendWidth()/15.5) + 'px'}"
      >{{'posts.planPost'|translate}}</legend>
    <div class="m-auto grid grid-rows-1 grid-cols-8 gap-1">
      <div class="col-span-8 flex justify-between mt-8 w-3/4 mx-auto">
        <button type="button" class=" cursor-pointer col-span-2 text-4xl" (click)="prevMonth()">←</button>
        <span class="row-span-1 col-span-6" >{{currentMonth | titlecase}}</span>
        <button type="button" class="mr-1 cursor-pointer col-span-2 text-4xl left-[4px]" (click)="nextMonth()">→</button>
      </div>
      <div class="col-span-8 grid grid-cols-8 gap-2 w-3/4 m-auto datetime-btn my-auto">
        <button type="button" 
        (click)="pickDate(day)" 
        class="flex justify-center items-center rounded-md text-xl bg-white w-12 h-10" 
        [disabled]="isPastDate(day.value)" 
        [class.bg-blue-500]="isSelectedDate(day.value)"
        [class.selectedDate]="isSelectedDate(day.value)"
        [style.width.px]="screenWidth*3.19/100"
        [style.height.px]="screenHeight*4/100"
        *ngFor="let day of daysArray" [ngStyle]="{'font-size': screenHeight/45 + 'px'}">{{day.label}}</button>

      </div>
			<div class="flex items-center rounded-md col-span-8 justify-center" [style.margin-bottom.px]="screenHeight<850 ? screenHeight*1.6/100: screenHeight*0.8/100" [style.margin-top.px]="screenHeight<850 ? screenHeight*1.6/100: screenHeight*0.8/100" >
				<span class="flex items-center" [ngStyle]="{'font-size': screenWidth/70 + 'px'}">
					{{'generic.at'|translate}} 
					<input [value]="selectedTime"
					(change)="pickTime($event)" 
					class="m-2 appearance-none block p-1 cursor-pointer border rounded-lg text-white bg-cliking_blue leading-tight focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500" type="time" id="time"   [style.height.px]="screenHeight*4/100" [ngStyle]="{'font-size': screenWidth/70 + 'px'}" [style.width.px]="screenWidth*7/100" name="time">
				</span>
			</div>
    </div>
</fieldset>
<div class=" p-3 bg-white rounded-3xl h-[75vh]">

    <div class="grid grid-rows-1 grid-cols-12 ">
        <span class="col-span-1">
            <span class="ml-5 text-2xl cursor-pointer" (click)="prevYear()">←</span>
            
        </span>
        <span class="col-span-10 text-gray-400 font-bold text-base w-42 px-5  mx-auto mt-2 ">{{getCurrentYear()}}</span>
        
        <span class="col-span-1">
            <span class="text-2xl float-right mr-5 cursor-pointer" (click)="nextYear()">→</span>
        </span>
    </div>
    <div >
        <div class="calender-days grid grid-cols-4   gap-x-8 gap-y-2  m-5 h-[68vh]" >
            <div class="col-span-1" *ngFor="let m of data">
                <div>
                    <app-month-preview
                    [screenHeight]="screenHeight" [screenWidth]="screenWidth"
                    [month]="m"
                    (switchToMonthView)="switchToMonthView($event)"
                    (daySelected)="showDayPosts($event)"
                    />
                </div>
            </div>
            <div class="absolute overflow-y-scroll" *ngIf="isDayPostsPannelVisible">
                <app-day-posts-preview
                [previewHeight]="calculatedHeight"
                [data]="selectedDayPosts"
                [xpos]="pannelPositions.x"
                [ypos]="pannelPositions.y"
                [isVisible]="isDayPostsPannelVisible"
                (visibilityChange)="handleVisibilityChange($event)"
                />
            </div>
        </div>
    </div>
</div>
<div class="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-0 text-center" *ngIf="isOpen">
  <div class="bg-white rounded-3xl shadow-lg max-w-md w-full">
    <div *ngIf="showCloseBtn" class=" px-4 py-2 ">
        <button type="button" class="text-gray-400 hover:text-gray-600 float-right" (click)="close()">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="mt-2 w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
    </div>

    <div class="px-4 py-6 ">
      <div class="join-vertical">
        <div *ngIf="isIconProvided()" class="avatar">
          <div  class="w-12 h-12 bg-[#f0f0f0] rounded-full flex items-center justify-center">
            
            <svg *ngIf="isIconFail()" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-8 text-cliking_red mx-auto mt-[17.5%]">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
            <svg *ngIf="isIconWarning()" width="36px" class="w-8  mx-auto mt-[9.5%]" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <polygon style="fill:#FFFFFF;" points="13.728,473.992 256,46.24 498.272,473.992 "></polygon> <path style="fill:#DB2B42;" d="M256,62.472l228.552,403.52H27.448L256,62.472 M256,30.008L0,481.992h512L256,30.008L256,30.008z"></path> <path style="fill:#2D2D2D;" d="M226.112,396.344c0-17.216,12.024-29.56,29.232-29.56c17.216,0,28.584,12.344,28.912,29.56 c0,16.888-11.368,29.552-28.912,29.552C237.808,425.896,226.112,413.232,226.112,396.344z M236.84,350.536l-7.48-147.144h51.648 l-7.152,147.152L236.84,350.536L236.84,350.536z"></path> </g></svg>
            
            <svg *ngIf="isIconDelete()" width="36px" viewBox="0 0 24 24" fill="none"  class="w-8  mx-auto mt-[17.5%]" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062 21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17" stroke="#FD397A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>

            <svg *ngIf="isIconSuccess()" xmlns="http://www.w3.org/2000/svg" height="36" width="16" class="w-8  mx-auto mt-[14%]" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" fill="#1DC9B7" /></svg>

          </div>
        </div>

        <h3 class="font-semibold !text-lg text-black mb-2 py-3">{{Heading | translate}}</h3>
        <h5 *ngIf="subHeading!=''" class="!text-sm text-gray-500 mb-5">{{subHeading | translate}}</h5>
        <button *ngIf="isIconFail() || isIconWarning()" type="button" (click)="close()"
            class="rounded-xl !text-lg flex w-full h-12 justify-center items-center text-white bg-blue-600">
          {{ 'Compris' }}
        </button>
        <div *ngIf="isIconDelete()" class="flex justify-center space-x-4">
          <button type="button" (click)="delete()"
              class="rounded-xl  flex w-[45%] h-12 justify-center items-center bg-gray-200 text-gray-400 hover:bg-red-600 hover:text-white" style="font-size: 15px;">
            {{ 'generic.deleteButton'|translate }}
          </button>
          <button type="button" (click)="close()"
              class="rounded-xl  flex w-[45%] h-12 ml-[50%] justify-center items-center text-white bg-blue-600 hover:bg-blue-800 " style="font-size: 15px;">
            {{ 'generic.cancel'|translate  }}
          </button>
        </div>
      </div>

      <ng-content></ng-content>
    </div>
  </div>
</div>
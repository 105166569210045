import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-widget-plateforme-stats',
  templateUrl: './widget-plateforme-stats.component.html',
  styleUrls: ['./widget-plateforme-stats.component.scss']
})
export class WidgetPlateformeStatsComponent {
  @Input() label: string = '';
  @Input() value: string = '';
  @Input() percentage: number = 0;
  @Input() plateform: string = '';

  getPercentage(){
    return (this.percentage>0?'+'+this.percentage:'-'+Math.abs(this.percentage))+'%'
  }

  formatNumber(value: string):any {
    if (Number(value) > 1000) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
    return value;
  }
}

import { Component } from '@angular/core';
import { ScreenSizeService } from 'src/app/services/utilities/screen-size.service';

@Component({
  selector: 'app-sociabble-page',
  templateUrl: './sociabble-page.component.html',
  styleUrls: ['./sociabble-page.component.scss']
})
export class SociabblePageComponent {
	screenWidth: number = 0;
	constructor(private screenSizeService: ScreenSizeService) {}

	ngOnInit(): void {
		this.screenSizeService.screenWidth$.subscribe((width: number) => this.screenWidth = width);
	}
}

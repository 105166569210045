import { Component, forwardRef, ElementRef, Renderer2, ViewChild, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-custom-time-input',
  templateUrl: './custom-time-input.component.html',
  styleUrls: ['./custom-time-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomTimeInputComponent),
      multi: true
    }
  ]
})
export class CustomTimeInputComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild('proxyInput', { static: false }) proxyInput!: ElementRef<HTMLDivElement>;
  @Output() timeChange = new EventEmitter<any>();
  @Input() context: any;

  value: string = '';
  isDisabled: boolean = false;
  showDropdown: boolean = false;
  timeOptions: string[] = [];

  onChange: any = () => {};
  onTouch: any = () => {};

  constructor(private renderer: Renderer2, private elRef: ElementRef) {
    this.generateTimeOptions();
  }

  ngAfterViewInit(): void {
    this.renderer.listen('document', 'click', (event: Event) => {
      if (!this.elRef.nativeElement.contains(event.target)) {
        this.showDropdown = false;
      }
    });
  }

  generateTimeOptions(): void {
    for (let hour = 0; hour < 24; hour++) {
      const hourStr = hour.toString().padStart(2, '0');
      this.timeOptions.push(`${hourStr}:00`);
      this.timeOptions.push(`${hourStr}:30`);
    }
  }

  writeValue(value: string): void {
    this.value = this.convertTime(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  handleChange(event: any): void {
    let newValue = event.target.value;
    newValue = this.convertTime(newValue);
    this.value = newValue;
    this.onChange(this.value);
    this.onTouch();
    this.emitChanges();
  }

  convertTime(time: string): string {
    if (!time) {
      return '';
    }
    if (time === '24:00') {
      return '00:00';
    }
    return time;
  }

  selectTime(time: string): void {
    this.value = time;
    this.onChange(this.value);
    this.onTouch();
    this.showDropdown = false;
    this.emitChanges();
  }

  toggleDropdown(): void {
    this.showDropdown = !this.showDropdown;
    if (this.showDropdown) {
      this.closeOtherDropdowns();
    }
  }

  closeOtherDropdowns(): void {
    const allDropdowns = document.querySelectorAll('app-custom-time-input .dropdown');
    allDropdowns.forEach(dropdown => {
      if (dropdown !== this.proxyInput.nativeElement.nextElementSibling) {
        dropdown.classList.add('hidden');
      }
    });
  }


  emitChanges() {
    const updatedTime = {
      value: this.value,
      context: this.context
    };
    this.timeChange.emit(updatedTime);
  }
}

import { Component } from '@angular/core';
import { IResponse } from 'src/app/interfaces/IResponse';
import { PostService } from 'src/app/services/repositories/post.service';

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss']
})
export class PostsComponent {
constructor(public postService: PostService){}
  res: IResponse = { data: [],
    navigation: {
      page: 0,
      totalPages: 0,
      totalRows: 0,
      itemsPerPage: 0
    },
   success: false, error: '', status: 0 };

   ngOnInit(){
    //  this.postService.getPosts().subscribe(res=>{
    //   this.res = (res)

    //  })
   }
}

import { Component } from '@angular/core';
import { PostService } from 'src/app/services/repositories/post.service';
import { Subscription } from 'rxjs';
import { GroupService } from 'src/app/services/repositories/group.service';
import { IResponse } from 'src/app/interfaces/IResponse';
@Component({
  selector: 'app-google-stats',
  templateUrl: './google-stats.component.html',
  styleUrls: ['./google-stats.component.scss']
})
export class GoogleStatsComponent {
  data: Array<{
    label: string;
    value: string;
  }> = [];
  private subs = new Subscription();
  socialMedias: any[] = [];

  constructor(protected postService: PostService,
  private groupService: GroupService,

  ){}

  ngOnInit(){
    const lsData = this.postService.getGroupScopesFromLocalStorage();
    
    
    this.subs.add(
      this.postService.currentGroups.subscribe((g: number[]) => {
        if (g.length == 0)
          g = this.postService.getGroupScopesFromLocalStorage();
        
        if (g.length > 0) {
          this.groupService.getGroupByIds(g).subscribe((res: IResponse) => {
            this.getRecursiveSocialMediaPages(
              res.data
            );
            this.data = [{"label": "dashboard.numberOfPublications", "value":''},
              {"label": "dashboard.CallClicks", "value":''},
              {"label": "dashboard.WebsiteClicks", "value":''},
              {"label": "dashboard.BusinessBookings", "value":''},
              {"label": "dashboard.BusinessFoodOrders", "value":''},
              {"label": "dashboard.BusinessConversations", "value":''},
              {"label": "dashboard.BusinessFoodMenuClicks", "value":''},
              {"label": "dashboard.BusinessDirectionRequests", "value":''},
              {"label": "dashboard.BusinessImpressionsMobileMaps", "value":''},
              {"label": "dashboard.BusinessImpressionsDesktopMaps", "value":''},
              {"label": "dashboard.BusinessImpressionsMobileSearch", "value":''},
              {"label": "dashboard.BusinessImpressionsDesktopSearch", "value":''}];
            this.socialMedias.forEach((item: any)=>{
              if(item.source == 'google' && item.analytics){
                this.data[0].value = (this.gVal(this.data[0].value) + this.gVal(item.analytics?.numberOfPublications)).toString();
                this.data[1].value = (this.gVal(this.data[1].value) + this.gVal(item.analytics?.WEBSITE_CLICKS)).toString();
                this.data[2].value = (this.gVal(this.data[2].value) + this.gVal(item.analytics?.BUSINESS_BOOKINGS)).toString();
                this.data[3].value = (this.gVal(this.data[3].value) + this.gVal(item.analytics?.BUSINESS_FOOD_ORDERS)).toString();
                this.data[4].value = (this.gVal(this.data[4].value) + this.gVal(item.analytics?.CALL_CLICKS)).toString();
                this.data[5].value = (this.gVal(this.data[5].value) + this.gVal(item.analytics?.BUSINESS_CONVERSATIONS)).toString();
                this.data[6].value = (this.gVal(this.data[6].value) + this.gVal(item.analytics?.BUSINESS_FOOD_MENU_CLICKS)).toString();
                this.data[7].value = (this.gVal(this.data[7].value) + this.gVal(item.analytics?.BUSINESS_DIRECTION_REQUESTS)).toString();
                this.data[8].value = (this.gVal(this.data[8].value) + this.gVal(item.analytics?.BUSINESS_IMPRESSIONS_MOBILE_MAPS)).toString();
                this.data[9].value = (this.gVal(this.data[9].value) + this.gVal(item.analytics?.BUSINESS_IMPRESSIONS_DESKTOP_MAPS)).toString();
                this.data[10].value = (this.gVal(this.data[10].value) + this.gVal(item.analytics?.BUSINESS_IMPRESSIONS_MOBILE_SEARCH)).toString();
                this.data[11].value = (this.gVal(this.data[11].value) + this.gVal(item.analytics?.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH)).toString();
              }
            })
          });
        }
      })
    );
  }
  getRecursiveSocialMediaPages(groups: any[]): any[] {
    groups = !Array.isArray(groups) ? [groups] : groups;
    let socialMediaPages: any[] = [];
    groups.forEach((group: any) => {
      // Add the group's social media pages if any
      if (group.socialMedia && group.socialMedia.length > 0) {
        socialMediaPages = [...socialMediaPages, ...group.socialMedia];
      }

      // Recursively add social media pages from children
      if (group.children && group.children.length > 0) {
        for (let child of group.children) {
          socialMediaPages = [
            ...socialMediaPages,
            ...this.getRecursiveSocialMediaPages(child),
          ];
        }
      }
    });
    //removing any duplicates
    const pagesArrayString: string[] = socialMediaPages.map((sm: any) =>
      JSON.stringify(sm)
    );
    const uniquePagesArrayString = [...new Set(pagesArrayString)];
    this.socialMedias = uniquePagesArrayString.map((sm: any) =>
      JSON.parse(sm)
    );
    return uniquePagesArrayString.map((sm: any) => JSON.parse(sm));
  }

  gVal(data: any){
    return data && Number(data)? Number(data): 0
  }
}

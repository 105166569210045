import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent {
  @Input() value: any
  @Output() valueChanged: EventEmitter<number> = new EventEmitter()
  displayValue: string="";
  today: string = 'a';

  ngOnInit() {
    this.updateDisplayValue();
    const todayDate = new Date();
    const year = todayDate.getFullYear();
    const month = String(todayDate.getMonth() + 1).padStart(2, '0');
    const day = String(todayDate.getDate()).padStart(2, '0');
    this.today = `${year}-${month}-${day}`;
  }
  update(event: any){

    this.value = new Date(event.target.value) 

    this.valueChanged.emit(this.value)
  }
  private updateDisplayValue(){
    const d = (this.value as Date)
    if(d!=undefined)
      this.displayValue = d.getFullYear()+ '-'+ (d.getMonth()+1).toString().padStart(2,'0')+ '-'+ d.getDate().toString().padStart(2,'0')
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-instagram-stats',
  templateUrl: './instagram-stats.component.html',
  styleUrls: ['./instagram-stats.component.scss']
})
export class InstagramStatsComponent {
  data: any[] = [];

  ngOnInit(){
    this.data = [
      {"label": "dashboard.numberOfPublications", "value":'118', "percentage":7},
      {"label": "dashboard.numberOfViews", "value":'3680', "percentage":3},
      {"label": "dashboard.reactionsObtained", "value":'579', "percentage":2},
      {"label": "dashboard.commentsReceived", "value":'74', "percentage":-4},
      {"label": "dashboard.sharesMade", "value":'6', "percentage":5},
      {"label": "dashboard.interactions", "value":'734', "percentage":5},
      {"label": "dashboard.reach", "value":'345', "percentage":5},
    ];
  }
}

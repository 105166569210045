import {
  Component,
  ElementRef,
  HostListener,
  ViewChild,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PostService } from '../../../services/repositories/post.service';
import { environment } from 'src/environments/environment';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import Compressor from 'compressorjs';

import { SOCIAL_MEDIA_PLATFORMS } from 'src/app/utils/social-media.constant';
import { SocialMediaPlatform } from 'src/app/interfaces/SocialMediaPlatform';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { googlePlatformValidator } from 'src/app/validators/google-postType.validator';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { BaseComponent } from '../../base/base.component';
import { Subscription } from 'rxjs';
import { SocialMedia } from 'src/app/interfaces/SocialMedia';
import { GroupService } from 'src/app/services/repositories/group.service';
import { IResponse } from 'src/app/interfaces/IResponse';
import { ICON } from 'src/app/interfaces/ModalIcon';
import { Debug } from 'src/app/utils/debug';
import { ScreenSizeService } from 'src/app/services/utilities/screen-size.service';

// interface PostData {
//   typePost: string;
//   text: string;
//   publishingDatetime: Date;
//   category: string;
//   distributor: string;
//   author: string;
//   public: boolean ;
//   title: string;
// }

@Component({
  selector: 'app-create-post',
  templateUrl: './create-post.component.html',
  styleUrls: ['./create-post.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [
        // ':enter' is alias to 'void => *'
        style({ opacity: 0 }),
        animate(300),
      ]),
      transition(':leave', [
        // ':leave' is alias to '* => void'
        animate(300, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class CreatePostComponent extends BaseComponent {
  postForm!: FormGroup;
  private subs = new Subscription();
  screenWidth: number = 0;
  screenHeight: number = 0;
  ratio: string = '';
  aspectRatios = [
    { label: '5:4', value: 5 / 4 },
    { label: '4:3', value: 4 / 3 },
    { label: '3:2', value: 3 / 2 },
    { label: '8:5', value: 8 / 5 },
    { label: '16:9', value: 16 / 9 },
  ];
  tooltipPositions: any;

  SOCIAL_MEDIA_PLATFORMS = SOCIAL_MEDIA_PLATFORMS;

  constructor(
    translationService: TranslationService,
    private postService: PostService,
    private groupService: GroupService,
    private formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private screenSizeService: ScreenSizeService
  ) {
    super(translationService);
  }
  data: any = [];
  apiUrl: string = environment.apiUrl;
  selectedGroup: Number = 0;
  selectedSocialMedia: Number = 0;
  socialMediaOptions: any[] = [];
  socialMediaPagesList: any[] = [];

  SocialMediaPlatform = SocialMediaPlatform; // Expose the enum to the template
  selectedPlatform: SocialMediaPlatform[] = [];

  googleExists: boolean = false;
  linkedInExists: boolean = false;
  facbookExists: boolean = false;
  instagramExists: boolean = false;
  socialMediaId: number = 0;
  currentSocialMediaPages: any[] = [];
  selectedSocialMediaPage: any;

  isOfferSelected: boolean = false;
  isUpdateSelected: boolean = false;
  isEventSelected: boolean = false;
  typePost: string = 'N/A';

  uploadedImagePath!: string;
  selectedFiles: File[] = [];

  creationStep: number = 0;
  currentSocialMedia: any;
  selectedDate: Date | null = null;
  selectedPagesSources: any = [];

  //google post event or offer settings
  // LocalPostEvent
  googleEventOfferStartDate!: Date;
  googleEventOfferEndDate!: Date;

  selectedLabel: string = 'generic.link'; // Default value
  DynamicAdditionalData: string = '';

  postUrl: any;
  postUrlAction: string = '';
  urlPostToggledItems: any[] = [];
  toolTipMsgTitle: boolean = false;
  fieldsetColorTitle: boolean = false;
  toolTipMsgPages: boolean = false;
  fieldsetColorPages: boolean = false;
  toolTipMsgPlateforms: boolean = false;
  fieldsetColorPlateforms: boolean = false;
  toolTipMsgGoogle: boolean = false;
  fieldsetColorGoogle: boolean = false;
  toolTipMsg: boolean = false;
  MediaForInstagram: boolean = false;
  fieldsetColorstagram: boolean = false;
  toolTipMsgGoogleMediaInvalid: boolean = false;
  fieldsetColorGoogleMediaInvalid: boolean = false;
  toolTipMsgValidLink: boolean = false;
  fieldsetColorValidLink: boolean = false;
  toolTipMsgGoogleEventOfferDateValid: boolean = false;
  fieldsetColorGoogleEventOfferDateValid: boolean = false;
  AdditionalDataValidation: string = '';
  fieldsetColorDataValidation: boolean = false;
  facebookLinkedinMessageVideo: boolean = false;
  fieldsetColorMessageVideo: boolean = false;
  toolTipMsgcuponCode: boolean = false;
  fieldsetColorcuponCode: boolean = false;
  isModalOpen: boolean = false;
  modalIcon: ICON = ICON.fail;
  headinsSuccessMsg: string = 'generic.creationFailure';
  subHeadinsSuccessMsg: string = 'generic.creationPostFailureMsg';
  canPressNext: boolean = true;
  elementsPositions: any[] = [];
  toolTiHeights: any[] = [];
  isDraftValidFlag: boolean = false;

  @ViewChildren('myElement') myElements!: QueryList<ElementRef>;
  @ViewChildren('tooltipElement') tooltipElementts!: QueryList<ElementRef>;

  ngOnInit(): void {
    //check if the user was redirected from another route (calendar or postLibrary)
    if (document.URL.split('?').length > 1) {
      const queryString = document.URL.split('?')[1];
      const params = queryString.split('&').map((item: any) => {
        return { key: item.split('=')[0], value: item.split('=')[1] };
      });

      for (let param of params) {
        //params will containe 'useAs' key and 'postTemplate' if it was redirected from library and 'postTemplate' if it was from calendar
        if (param.key === 'postTemplate') {
          this.postUrl = JSON.parse(decodeURIComponent(param.value)); // actual Post Object if exist
        }
        if (param.key === 'useAs') {
          this.postUrlAction =
            param.value === 'draft' ? 'newPostFromDraft' : 'newPostFromOldPost'; // set action to create from draft or existing post
        }
      }
      this.postUrlAction =
        this.postUrlAction == '' ? 'update' : this.postUrlAction; //if action wasn't set above it means the post url was redirected from calender
      //fetch the group from the database and update selected group
      const RootGroupId = this.postUrl.SocialPlatforms[0].group.id;

      console.log('this.postUrl', this.postUrl);
      console.log('this.postUrlAction', this.postUrlAction);

      this.groupService
        .getGroupByIds(this.postService.currentGroupsSource.getValue())
        .subscribe((res) => {
          this.postService.updateGroup(res.data);
          //loading Draft / Post properties
          //set the post type
          this.isOfferSelected = this.postUrl.typePost.toLowerCase() == 'offer';
          this.isUpdateSelected =
            this.postUrl.typePost.toLowerCase() == 'update' ||
            this.postUrl.typePost.toLowerCase() == 'standard';
          this.isEventSelected = this.postUrl.typePost.toLowerCase() == 'event';

          this.typePost = this.postUrl.typePost;

          // load the media of the post
          const urlPostImages = this.getUrlPostMedias();

          this.previewFilesList = urlPostImages;

          urlPostImages.forEach((image) => {
            Debug.log('image url', JSON.stringify(image));
            if (image.url.split('uploads_')[1]) {
              image.name = decodeURIComponent(
                image.url.split('uploads_')[1].split('_')[1]
              );
            }
            if (image.url.split('uploads/')[1]) {
              image.name = decodeURIComponent(
                image.url.split('uploads/')[1].split('-')[1]
              );
            }
          });

          // Debug.log('--- urlPostImages', urlPostImages);
          this.previewFilesList = urlPostImages;

          this.calculateMediaDimensions();
        });
    }

    //initialize the postForm
    this.postForm = this.formBuilder.group(
      {
        typePost: ['N/A', [Validators.required]],
        text: [''],
        title: ['', [Validators.required, Validators.minLength(1)]],
        publishingDatetime: [''],
        expectedPublishingDatetime: [''],
        g_offer_event_promotionCode: [''],
        g_offer_event_link: [''],
        g_offer_event_termsOfUse: [''],
        g_update_event_info: [''],
        // Add other form controls as needed
      },
      { validators: googlePlatformValidator }
    );
    this.positionTooltip();

    this.googleEventOfferStartDate = new Date();
    this.googleEventOfferEndDate = new Date();
    this.googleEventOfferStartDate.setHours(0, 0, 0, 0);
    this.googleEventOfferEndDate.setHours(23, 59, 0, 0);
    this.subs.add(
      this.postService.currentGroups.subscribe((g: number[]) => {
        if (g.length == 0)
          g = this.postService.getGroupScopesFromLocalStorage();

        if (g.length > 0) {
          this.groupService.getGroupByIds(g).subscribe((res: IResponse) => {
            const GroupSocialMediaTree = this.getRecursiveSocialMediaPages(
              res.data
            );
            // Debug.log('GroupSocialMediaTree', GroupSocialMediaTree);
            //get all social media from groups and subgroups recursively
            if (GroupSocialMediaTree.length > 0) {
              // Initialize all flags to false
              let googleFound = false;
              let linkedInFound = false;
              let facebookFound = false;
              let instagramFound = false;

              // Loop through all social media entries once and update flags
              for (let sm of GroupSocialMediaTree) {
                const source = sm.source.toLowerCase();
                if (source === 'google' && !googleFound) {
                  googleFound = true;
                }
                if (source == 'linkedin' && !linkedInFound) {
                  linkedInFound = true;
                }
                if (source == 'facebook' && !facebookFound) {
                  facebookFound = true;
                }
                if (source == 'instagram' && !instagramFound) {
                  instagramFound = true;
                }
              }

              // Update BehaviorSubjects based on the found flags
              this.postService.googleExists.next(googleFound);
              this.postService.linkedInExists.next(linkedInFound);
              this.postService.facebookExists.next(facebookFound);
              this.postService.instagramExists.next(instagramFound);
            } else {
              // No social media found, set all to false
              this.postService.googleExists.next(false);
              this.postService.linkedInExists.next(false);
              this.postService.facebookExists.next(false);
              this.postService.instagramExists.next(false);
            }
          });
        }
        this.selectedPagesSources = [];

        this.selectedPlatform = [];
        this.socialMediaPagesList = [];
        // this.currentSocialMediaPages=[]
        this.isOfferSelected =
          this.isEventSelected =
          this.isUpdateSelected =
            false;

        if (this.postUrl) {
          this.isOfferSelected = this.postUrl.typePost.toLowerCase() == 'offer';
          this.isUpdateSelected =
            this.postUrl.typePost.toLowerCase() == 'update';
          this.isEventSelected = this.postUrl.typePost.toLowerCase() == 'event';

          this.typePost = this.postUrl.typePost;
          this.postForm.setValue({
            typePost: this.typePost,
            text: this.postUrl.text,
            title: this.postUrl.title,
            publishingDatetime:
              this.postUrlAction == 'update'
                ? this.postUrl.publishingDatetime
                : '',
            expectedPublishingDatetime:
              this.postUrlAction == 'update'
                ? this.postUrl.expectedPublishingDatetime
                : '',
            g_offer_event_promotionCode: [''],
            g_offer_event_link: [''],
            g_offer_event_termsOfUse: [''],
            g_update_event_info: [''],
          });
          //update the toggled platforms
          let urlPostPagesPlatforms: string[] =
            this.getUrlPostSocilaMediPLatforms();

          for (let url of urlPostPagesPlatforms) {
            switch (url) {
              case 'linkedin':
                this.selectPlatform('linkedin');
                break;
              case 'google':
                this.selectPlatform('google');
                break;
              case 'facebook':
                this.selectPlatform('facebook');
                break;
              case 'instagram':
                this.selectPlatform('instagram');
                break;
            }
          }
          //update each selected page fron the page dropdown

          this.urlPostToggledItems = this.postUrl.SocialPlatforms.reduce(
            (accumulator: any, socialPlatform: any) => {
              accumulator[socialPlatform.id] = true;
              return accumulator;
            },
            {}
          );

          this.postService.updateSelectedSources(this.urlPostToggledItems);
        }
      })
    );

    this.subs.add(
      this.postService.facebookExists.subscribe((value) => {
        this.facbookExists = value;
      })
    );
    this.subs.add(
      this.postService.linkedInExists.subscribe((value) => {
        this.linkedInExists = value;
      })
    );
    this.subs.add(
      this.postService.instagramExists.subscribe((value) => {
        this.instagramExists = value;
      })
    );
    this.subs.add(
      this.postService.googleExists.subscribe((value) => {
        this.googleExists = value;
      })
    );

    this.subs.add(
      this.postService.selectedSocialMedia.subscribe((value) => {
        this.selectedSocialMedia = value;
      })
    );

    this.subs.add(
      this.postService.currentPickedDate.subscribe((date) => {
        this.selectedDate = date;
      })
    );

    // Reset the selected pages sources
    this.postService.updateSelectedSources([]); // Reset the BehaviorSubject to an empty array
    this.subs.add(
      this.postService.currentSelectedPages.subscribe((selectedSources) => {
        this.selectedPagesSources = selectedSources;
      })
    );
    this.screenSizeService.screenWidth$.subscribe(
      (width: number) => (this.screenWidth = width)
    );
    this.screenSizeService.screenHeight$.subscribe(
      (height: number) => (this.screenHeight = height)
    );

    if (this.screenWidth != 0) this.getClosestAspectRatio();
    this.ngAfterViewInit();
  }

  getClosestAspectRatio() {
    const currentRatio = this.screenWidth / this.screenHeight;
    let closestRatio = this.aspectRatios[0];

    this.aspectRatios.forEach((aspectRatio) => {
      const difference = Math.abs(currentRatio - aspectRatio.value);
      if (difference < Math.abs(currentRatio - closestRatio.value)) {
        closestRatio = aspectRatio;
      }
    });
    this.ratio = closestRatio.label;
  }

  getUrlPostSocilaMediPLatforms(): string[] {
    let urlPostPagesPlatforms: string[] = [];
    if (this.postUrl) {
      this.postUrl.SocialPlatforms.forEach((sm: any) => {
        urlPostPagesPlatforms.push(sm.source);

        if (this.postService.currentGroupSource.getValue().id != sm.group.id) {
          sm.group.socialMedia = this.postUrl.SocialPlatforms.map(
            ({ group, ...rest }: SocialMedia) => {
              return { ...rest };
            }
          );
        }
      });
    }

    return Array.from(new Set(urlPostPagesPlatforms));
  }
  getRecursiveSocialMediaPages(groups: any[]): any[] {
    groups = !Array.isArray(groups) ? [groups] : groups;
    let socialMediaPages: any[] = [];
    groups.forEach((group: any) => {
      // Add the group's social media pages if any
      if (group.socialMedia && group.socialMedia.length > 0) {
        socialMediaPages = [...socialMediaPages, ...group.socialMedia];
      }

      // Recursively add social media pages from children
      if (group.children && group.children.length > 0) {
        for (let child of group.children) {
          socialMediaPages = [
            ...socialMediaPages,
            ...this.getRecursiveSocialMediaPages(child),
          ];
        }
      }
    });
    //removing any duplicates
    const pagesArrayString: string[] = socialMediaPages.map((sm: any) =>
      JSON.stringify(sm)
    );
    const uniquePagesArrayString = [...new Set(pagesArrayString)];
    this.socialMediaOptions = uniquePagesArrayString.map((sm: any) =>
      JSON.parse(sm)
    );
    return uniquePagesArrayString.map((sm: any) => JSON.parse(sm));
  }
  getUrlPostMedias() {
    const medias: any[] = [];
    if (this.postUrl) {
      for (let sm of this.postUrl.SocialPlatforms) {
        for (let m of sm.medias) {
          if (medias.filter((ms) => ms.id == m.id).length == 0) {
            medias.push(m);
          }
        }
      }
    }
    return medias;
  }

  ngOnDestroy() {
    Debug.log('destroy');
    this.subs.unsubscribe();
  }

  selectPlatform(platform: string) {
    // this.socialMediaPagesList = this.socialMediaOptions.filter(
    //   (sm: { source: string }) =>
    //     sm.source.toLowerCase().includes(platform.toLowerCase())
    // );
    // console.log("socialMediaPagesList 1", this.socialMediaPagesList)
    // this.selectedSocialMediaPage =
    //   this.socialMediaPagesList.length == 1
    //     ? this.socialMediaPagesList[0].id
    //     : null;
    // console.log("socialMediaPagesList 2", this.socialMediaPagesList)
    // //this.socialMediaPagesList is filtred by the platform
    // //this.socialMediaOptions

    switch (platform) {
      case 'instagram':
        if (!this.selectedPlatform.includes(SocialMediaPlatform.INSTAGRAM)) {
          this.selectedPlatform.push(SocialMediaPlatform.INSTAGRAM);
        } else {
          this.selectedPlatform = this.selectedPlatform.filter(
            (p: SocialMediaPlatform) => {
              return p !== SocialMediaPlatform.INSTAGRAM;
            }
          );
        }
        break;
      case 'google':
        if (!this.selectedPlatform.includes(SocialMediaPlatform.GOOGLE)) {
          this.selectedPlatform.push(SocialMediaPlatform.GOOGLE);
        } else {
          this.selectedPlatform = this.selectedPlatform.filter(
            (p: SocialMediaPlatform) => {
              return p !== SocialMediaPlatform.GOOGLE;
            }
          );
        }
        break;
      case 'facebook':
        if (!this.selectedPlatform.includes(SocialMediaPlatform.FACEBOOK)) {
          this.selectedPlatform.push(SocialMediaPlatform.FACEBOOK);
        } else {
          this.selectedPlatform = this.selectedPlatform.filter(
            (p: SocialMediaPlatform) => {
              return p !== SocialMediaPlatform.FACEBOOK;
            }
          );
        }
        break;
      case 'linkedin':
        if (!this.selectedPlatform.includes(SocialMediaPlatform.LINKEDIN)) {
          this.selectedPlatform.push(SocialMediaPlatform.LINKEDIN);
        } else {
          this.selectedPlatform = this.selectedPlatform.filter(
            (p: SocialMediaPlatform) => {
              return p !== SocialMediaPlatform.LINKEDIN;
            }
          );
        }
        break;
    }
    if (!this.selectedPlatform.includes(SocialMediaPlatform.GOOGLE)) {
      this.typePost = 'N/A';
      this.postForm.patchValue({
        typePost: this.typePost,
      });
      this.isOfferSelected =
        this.isEventSelected =
        this.isUpdateSelected =
          false;
    }

    //update social media pages list
    const groupIds = this.postService.currentGroupsSource.getValue();
    this.groupService.getGroupByIds(groupIds).subscribe((res: any) => {
      this.socialMediaPagesList = this.getRecursiveSocialMediaPages(
        res.data
      ).filter((sm: any) => {
        if (this.selectedPlatform.includes(sm.source.toLowerCase())) {
          return sm;
        }
      });
    });
    // console.log("socialMediaPagesList 3", this.socialMediaPagesList)
    //remove any toggledItem
    // this.postService.SyncSelectedPagesWithCurrentSelectedPlatforms(
    //   this.socialMediaPagesList
    // );
    // reset posttype to N/A if google is not selected

    this.handlePlatformExclusion(SocialMediaPlatform.GOOGLE);
    this.handlePlatformExclusion(SocialMediaPlatform.FACEBOOK);
    this.handlePlatformExclusion(SocialMediaPlatform.INSTAGRAM);
    this.handlePlatformExclusion(SocialMediaPlatform.LINKEDIN);
    this.selectPlatformValidation();
  }

  getPlatformProperty(
    source: string,
    property: keyof (typeof this.SOCIAL_MEDIA_PLATFORMS)[0]
  ): any {
    const platform = this.SOCIAL_MEDIA_PLATFORMS.find(
      (p) => p.source === source
    );
    return platform ? platform[property] : null;
  }

  handlePlatformExclusion(platform: SocialMediaPlatform) {
    let toggledItems = this.postService.toggledItemsSource.getValue();
    if (!this.selectedPlatform.includes(platform)) {
      if (platform == SocialMediaPlatform.GOOGLE) {
        this.typePost = 'N/A';
        this.postForm.patchValue({ typePost: 'N/A' });
        //remove any toggled item where the id a google page id
        //get google pages ids
        const googlePagesIds = this.selectedPagesSources
          .filter((p: any) => p.source === platform.toLowerCase())
          .map((p: any) => p.id);

        // Loop through each property in toggledItems and delete if the key is in googlePagesIds
        for (const prop in toggledItems) {
          if (googlePagesIds.includes(Number(prop))) {
            delete toggledItems[prop];
          }
        }
      }

      this.selectedPagesSources = this.selectedPagesSources.filter(
        (p: any) => p.source !== platform.toLowerCase()
      );

      let selectedPages = this.postService.selectedSourcesSubject
        .getValue()
        .filter((p: any) => p.source !== platform.toLowerCase());

      const newToggledItems = selectedPages.reduce(
        (acc: { [key: number]: boolean }, s: any) => {
          if (toggledItems[s.id] !== undefined) {
            acc[s.id] = toggledItems[s.id];
          }
          return acc;
        },
        {}
      );

      this.postService.updateToggledItems(newToggledItems);
    }
  }
  switchPostType(button: string) {
    if (this.isGooglePageSelected()) {
      this.isOfferSelected =
        this.isEventSelected =
        this.isUpdateSelected =
          false;
      this.typePost = button;
      this.postForm.patchValue({ typePost: button });

      switch (button) {
        case 'offer':
          this.isOfferSelected = true;
          break;
        case 'update':
          this.isUpdateSelected = true;
          break;
        case 'event':
          this.isEventSelected = true;
          break;
      }
    } else {
      this.isOfferSelected =
        this.isEventSelected =
        this.isUpdateSelected =
          false;
    }
    this.postTypeValidation();
  }

  async onFilesSelected(event: any) {
    this.calculateMediaDimensions();
    if (event.target.files) {
      //   Debug.log("input files", event.target.files);
      const fileProcessPromises = Array.from(event.target.files).map(
        (file: any) => {
          return new Promise((resolve, reject) => {
            if (file.type.startsWith('image/')) {
              // It's an image, apply compression
              new Compressor(file, {
                quality: 1, // Adjust quality as needed
                success: (compressedResult) => {
                  // Convert the Blob back into a File
                  const compressedFile = new File(
                    [compressedResult],
                    file.name,
                    {
                      type: compressedResult.type,
                      lastModified: Date.now(),
                    }
                  );
                  resolve(compressedFile);
                },
                error: (err) => {
                  console.error('Compression error:', err.message);
                  reject(err);
                },
              });
            } else {
              // It's not an image, add the file directly
              resolve(file);
            }
          });
        }
      );

      // Wait for all files to be processed
      const newFiles = (await Promise.all(fileProcessPromises)) as File[];
      this.selectedFiles = [...this.selectedFiles, ...newFiles];
      // Debug.log('selectedFiles', this.selectedFiles);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.positionTooltip();
    this.ngAfterViewInit();
  }
  @ViewChild('tooltip') tooltipRef!: ElementRef;
  @ViewChild('button') buttonRef!: ElementRef;
  tooltipLeft: number = 0;
  positionTooltip() {
    // Ensure the elements are loaded
    if (this.tooltipRef && this.buttonRef) {
      const buttonEl = this.buttonRef.nativeElement;
      const tooltipEl = this.tooltipRef.nativeElement;

      // Calculate the button's position
      const buttonRect = buttonEl.getBoundingClientRect();

      // Adjust the tooltip's left position to be the same as the button's
      this.tooltipLeft =
        buttonRect.left + buttonRect.width / 2 - tooltipEl.offsetWidth / 2;

      Debug.log('tooltipLeft', this.tooltipLeft);
    }
  }

  onSubmit(isDraft: boolean = false) {
    /* Swal.fire({
      title: this.translationService.t('posts.postCreationInProgress'),
      text: this.translationService.t('generic.pleaseWait'),
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
    }); */

    // Check if there are files selected for upload

    if (this.selectedFiles && this.selectedFiles.length > 0) {
      const formData = new FormData();

      //exclude any images if post created from a draft or other existing post

      this.selectedFiles.forEach((file, index) => {
        formData.append(`files`, file);
      });

      // If there are files, upload them
      this.postService
        .uploadFile(formData)
        .toPromise()
        .then((response) => {
          this.createPost([this.processUploadResponse(response)], isDraft);
        });
    } else {
      // If there are no files, directly submit the post
      this.createPost([], isDraft);
    }
  }

  previewFilesList: Array<{
    name: string;
    url: string | ArrayBuffer;
    type: string;
    dimensions?: string;
  }> = [];

  previewOn: boolean = false;
  hidePreview() {
    this.previewOn = false;
  }

  isImage(file: any | null): boolean {
    return file?.type.split('/')[0] === 'image';
  }

  isVideo(file: any | null): boolean {
    return file?.type.split('/')[0] === 'video';
  }
  isEmojiPickerOpen: boolean = false;
  justToggledEmojiPicker = false;
  toggleEmojiPicker() {
    this.isEmojiPickerOpen = !this.isEmojiPickerOpen;
    this.justToggledEmojiPicker = true;

    // Reset the flag after a short delay
    setTimeout(() => {
      this.justToggledEmojiPicker = false;
    }, 100);

    // Debug.log('toggleEmojiPicker...');
  }
  @ViewChild('postTextArea') postTextArea!: ElementRef;
  addHashTag() {
    const curreText = this.postForm.get('text')?.value.trim() + ' #';
    this.postForm.patchValue({ text: curreText });
    this.postTextArea.nativeElement.focus();
  }
  insertEmoji(emoji: String) {
    const newTxt = this.postForm.get('text')?.value + emoji;
    this.postForm.patchValue({ text: newTxt });
    this.postTextArea.nativeElement.focus();
    this.isEmojiPickerOpen = false;
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.justToggledEmojiPicker) {
      // Do nothing if the emoji picker was just toggled
      return;
    }

    const targetElement = event.target as HTMLElement;

    // Check if the click is outside the emoji picker
    if (
      targetElement &&
      !targetElement.closest('app-emoji-picker') &&
      this.isEmojiPickerOpen
    ) {
      // Debug.log("emoji picker is open now");
      this.isEmojiPickerOpen = false;
      // Debug.log("emoji picker is closed now");
    }
  }

  getSlideDimentions(media: any) {
    const url = media.url;

    if (media.type.includes('image/')) {
      const img = new Image();
      img.src = url;
      return img.width + ' x ' + img.height;
    } else if (media.type.includes('video/')) {
      return this.getVideoDuration(media.url).then((duration) => {
        return duration;
      });
    }
    return '';
  }

  calculateMediaDimensions() {
    this.previewFilesList.forEach((slide) => {
      if (slide.type.includes('image/')) {
        const img = new Image();
        img.onload = () => {
          slide.dimensions = img.width + ' x ' + img.height;
        };
        img.src = slide.url as string;
      } else if (slide.type.includes('video/')) {
        this.getVideoDuration(slide.url as string).then((duration) => {
          slide.dimensions = this.formatDuration(Math.floor(duration));
        });
      }
    });
  }

  formatDuration(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const paddedHours = hours.toString().padStart(2, '0');
    const paddedMinutes = minutes.toString().padStart(2, '0');
    const paddedSeconds = remainingSeconds.toString().padStart(2, '0');

    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
  }

  getVideoDuration(videoUrl: string): Promise<number> {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata'; // Preload the metadata of the video
      // Set up event listeners
      let isCleanedUp = false; // Flag to indicate if cleanup has been performed
      // Success handler
      const onLoadMetadata = () => {
        if (!isCleanedUp) {
          resolve(video.duration);
          cleanup(); // Clean up after loading metadata
        }
      };

      video.addEventListener('loadedmetadata', onLoadMetadata, false);
      video.src = videoUrl; // Set the video URL to start loading the metadata

      // Clean up function to remove event listeners and the video element
      const cleanup = () => {
        video.remove();
      };
    });
  }

  removeSlide(slide: any) {
    this.previewFilesList = this.previewFilesList.filter(
      (file) => file.url != slide.url
    );
    const names = this.previewFilesList.map((file: any) => file.name);
    this.selectedFiles = this.selectedFiles.filter((file: any) =>
      names.includes(file.name)
    );
    this.uplodedMediaValidation();
  }

  previewIndex: number = 0;
  previewSlide(media: any) {
    Debug.log('previewing...', media);
    this.previewOn = true;
    this.previewIndex = this.previewFilesList.findIndex(
      (file) => file.url == media.url
    );
    Debug.log('previewIndex', this.previewFilesList[this.previewIndex]);
  }

  previewFiles(event: Event, mediaType: string = 'all'): void {
    const element = event.target as HTMLInputElement;
    let files: FileList | null = element.files;
    let validFileTypes: any[] = [];
    if (mediaType == 'images') {
      validFileTypes = ['image/png', 'image/jpg', 'image/jpeg'];
    } else if (mediaType == 'video') {
      validFileTypes = ['video/mp4'];
    } else {
      validFileTypes = ['image/png', 'image/jpg', 'image/jpeg', 'video/mp4'];
    }
    Debug.log(files);

    if (files) {
      Array.from(files).forEach((file) => {
        // Check if the file type is valid
        const isValidType = validFileTypes.includes(file.type);
        const isValidSize =
          (file.type.startsWith('image') && file.size <= 4 * 1024 * 1024) || // 4MB for images
          (file.type.startsWith('video') &&
            file.size <= 1 * 1024 * 1024 * 1024); // 1GB for videos

        if (isValidType && isValidSize) {
          const url = URL.createObjectURL(file);
          this.previewFilesList.push({
            name: file.name,
            url: url,
            type: file.type,
          });
        } else if (!isValidType) {
          console.warn(`File type ${file.type} is not allowed.`);
        } else if (!isValidSize) {
          console.warn(`File ${file.name} exceeds size limits.`);
        }
      });

      // Trigger file selection event if valid files were added
      if (this.previewFilesList.length > 0) {
        this.onFilesSelected(event);
      }
    }
    this.uplodedMediaValidation();
  }

  getGoogleMediaWarningMessage() {
    let message: string = '';

    // cases:
    // 1 - video , no images -> "no video"
    // 2 - video + 1 image -> "no video"
    // 3 - video + 1+ images -> "only one image (and no video)"
    // 4 - 1+ images -> "only one image"
    // 5 - 1 image, but too small -> "image too small"
    // 6 - 1 image, valid -> no message

    const hasVideo =
      this.postForm.get('typePost')?.value != 'N/A' &&
      this.previewFilesListVideosCount() > 0;
    const imageCount = this.previewFilesListImagesCount();
    const hasImages = imageCount > 0;
    const isSingleImage = imageCount === 1;
    const firstImage = isSingleImage ? this.previewFilesList[0] : null;
    const isImageTooSmall = isSingleImage && this.fileTooSmall(firstImage);

    // Case 1 and 2: No video allowed
    if (hasVideo && !hasImages) {
      message = this.getTranslation('mediaWarnings.googleNoVideos'); // Case 1
    } else if (hasVideo && isSingleImage) {
      message = this.getTranslation('mediaWarnings.googleNoVideos'); // Case 2
    } else if (hasVideo && imageCount > 1) {
      message = this.getTranslation('mediaWarnings.googleOneImageNoVideo'); // Case 3
    }
    // Case 4: More than 1 valid image, no video
    else if (!hasVideo && imageCount > 1) {
      message = this.getTranslation('mediaWarnings.googleOneImage'); // Case 4
    }
    // Case 5: 1 image but too small
    else if (!hasVideo && isSingleImage && isImageTooSmall) {
      message = this.getTranslation('mediaWarnings.googleImageTooSmall'); // Case 5
    }
    //

    return message;
  }

  previewFilesListVideosCount() {
    let videosCount = this.previewFilesList.filter((m: any) => {
      return this.isVideo(m);
    }).length;
    // Debug.log('media',this.previewFilesList,"count",videosCount);
    return videosCount;
  }

  previewFilesListImagesCount() {
    return this.previewFilesList.filter((m: any) => {
      return this.isImage(m);
    }).length;
  }

  clearFiles(): void {
    // Revoke URLs to release memory
    this.previewFilesList.forEach((file) => {
      if (typeof file.url === 'string') {
        URL.revokeObjectURL(file.url);
      }
    });

    // Clear the array
    this.previewFilesList = [];
    this.selectedFiles = [];
  }

  private processUploadResponse(response: any) {
    let imageObjects = [];
    Debug.log('processUploadResponse....', response);

    if (response && response.files) {
      imageObjects = response.files.filter((file: any) => file !== undefined);
    }

    // Submit the post form with the image paths
    return imageObjects;
  }

  isMiniMediaBrowserOpen: boolean = false;
  miniMediaBrowserMediaType: string = 'all';
  currentMediaBrowserContext: any = null;
  googleMediaBrowserContext: any = { label: 'google', data: null };
  facebookLinkedInMediaBrowserContext: any = {
    label: 'faceBookLinkedIn',
    data: null,
  };

  OpenMiniMediaBrowser(
    event: MouseEvent,
    mediaContext: any,
    mediaType: string = 'all'
  ): void {
    event.stopPropagation(); // Prevent click event from bubbling to the document
    this.currentMediaBrowserContext = mediaContext;
    this.isMiniMediaBrowserOpen = true;
    this.miniMediaBrowserMediaType = mediaType;
  }

  miniMediaBrowserConfirmed(data: any) {
    //update the contex
    if (this.currentMediaBrowserContext.label == 'google') {
      this.googleMediaBrowserContext.data = data;
      // Debug.log(this.googleMediaBrowserContext);
    }
    if (this.currentMediaBrowserContext.label == 'faceBookLinkedIn') {
      this.facebookLinkedInMediaBrowserContext.data = data;
      console.log(
        'this.facebookLinkedInMediaBrowserContext',
        this.facebookLinkedInMediaBrowserContext
      );
      if (this.facebookLinkedInMediaBrowserContext.data.isSkipped) {
        this.backToMainSelection();
        this.selectMediaType('none');
      }
      // Debug.log(this.facebookLinkedInMediaBrowserContext);
    }
    this.isMiniMediaBrowserOpen = false;
  }

  drop(event: CdkDragDrop<string[]>) {
    // Debug.log('drop', event.previousIndex, event.currentIndex);
    // Debug.log('previewFilesList', this.previewFilesList);
    moveItemInArray(
      this.previewFilesList,
      event.previousIndex,
      event.currentIndex
    );
  }
  isgoogleMediaValid(): boolean {
    return (
      this.previewFilesListVideosCount() == 0 &&
      this.previewFilesList.length == 1
    );
  }
  isfacebookLinkedInValid(): boolean {
    return (
      (this.previewFilesListVideosCount() == 0 &&
        this.previewFilesList.length > 0) ||
      (this.previewFilesListVideosCount() == 1 &&
        this.previewFilesList.length == 1)
    );
  }

  private createPost(uploadedMedia: any, isDraft: boolean = false) {
    let googleTypePost;
    switch (this.typePost) {
      case 'offer':
        googleTypePost = 'OFFER';
        break;
      case 'event':
        googleTypePost = 'EVENT';
        break;
      default:
        googleTypePost = 'STANDARD';
        break;
    }
    // CallToAction
    let callToAction = null;
    if (googleTypePost != 'OFFER' && this.DynamicAdditionalData) {
      callToAction = {
        actionType: this.DynamicAdditionalData,
        url: this.postForm.get('g_update_event_info')?.value,
      };
    }

    // LocalPostEvent
    Debug.log(
      ' this.googleEventOfferStartDate.getDate()',
      this.googleEventOfferStartDate.getDate()
    );
    let localPostEvent = null;
    if (googleTypePost != 'STANDARD') {
      localPostEvent = {
        title: this.postForm.get('title')?.value,
        schedule: {
          startDate: {
            // object (Date)
            year: this.googleEventOfferStartDate.getFullYear(),
            month: this.googleEventOfferStartDate.getMonth() + 1,
            day: this.googleEventOfferStartDate.getDate(),
          },
          startTime: {
            // object (TimeOfDay)
            hours: this.googleEventOfferStartDate.getHours(),
            minutes: this.googleEventOfferStartDate.getMinutes(),
            seconds: this.googleEventOfferStartDate.getSeconds(),
            nanos: 0,
          },
          endDate: {
            // object (Date)
            year: this.googleEventOfferEndDate.getFullYear(),
            month: this.googleEventOfferEndDate.getMonth() + 1,
            day: this.googleEventOfferEndDate.getDate(),
          },
          endTime: {
            // object (TimeOfDay)
            hours: this.googleEventOfferEndDate.getHours(),
            minutes: this.googleEventOfferEndDate.getMinutes(),
            seconds: this.googleEventOfferEndDate.getSeconds(),
            nanos: 0,
          },
        },
      };
    }

    // LocalPostOffer
    let localPostOffer = null;
    if (googleTypePost == 'OFFER') {
      localPostOffer = {
        couponCode: this.postForm.get('g_offer_event_promotionCode')?.value,
        redeemOnlineUrl: this.postForm.get('g_offer_event_link')?.value,
        termsConditions: this.postForm.get('g_offer_event_termsOfUse')?.value,
      };
    }

    const googleAdditionalData = {
      callToAction: callToAction,
      localPostEvent: localPostEvent,
      localPostOffer: localPostOffer,
    };

    if (uploadedMedia && uploadedMedia.length > 0) {
      for (let media of uploadedMedia[0]) {
        media.platforms = [];
        // let mediaName = decodeURIComponent(
        //   media.url.split('-')[media.url.split('-').length - 1]
        // );
        let mediaName = media.originalname;
        // Debug.log(this.selectedFiles)
        for (let socialMedia of this.socialMediaPagesList) {
          switch (socialMedia.source) {
            case 'google':
              if (
                this.googleMediaBrowserContext.data?.selected?.type?.includes(
                  'image'
                ) ||
                this.isgoogleMediaValid()
              ) {
                if (this.isgoogleMediaValid()) {
                  media.platforms.push({
                    id: socialMedia.id,
                    source: socialMedia.source,
                  });
                } else {
                  if (
                    !this.googleMediaBrowserContext.data.isSkipped &&
                    this.googleMediaBrowserContext.data?.selected?.name ==
                      mediaName
                  ) {
                    media.platforms.push({
                      id: socialMedia.id,
                      source: socialMedia.source,
                    });
                  }
                }
              }
              break;
            case 'linkedin':
            case 'facebook':
              if (this.isfacebookLinkedInValid()) {
                media.platforms.push({
                  id: socialMedia.id,
                  source: socialMedia.source,
                });
              } else {
                if (!this.facebookLinkedInMediaBrowserContext.data.isSkipped) {
                  if (
                    this.facebookLinkedInMediaBrowserContext.data?.selected.type.includes(
                      'image'
                    )
                  ) {
                    for (let selectedMedia of this
                      .facebookLinkedInMediaBrowserContext.data.selection) {
                      // Debug.log("facbookLinkedIn image",selectedMedia.name,"=",mediaName,"?",selectedMedia.name==mediaName)
                      if (selectedMedia.name == mediaName) {
                        media.platforms.push({
                          id: socialMedia.id,
                          source: socialMedia.source,
                        });
                      }
                    }
                  } else {
                    // Debug.log("facbookLinkedIn video",this.facebookLinkedInMediaBrowserContext.data.selected.name,"=",mediaName,"?",this.facebookLinkedInMediaBrowserContext.data.selected.name==mediaName)
                    if (
                      this.facebookLinkedInMediaBrowserContext.data.selected
                        .name == mediaName
                    ) {
                      media.platforms.push({
                        id: socialMedia.id,
                        source: socialMedia.source,
                      });
                    }
                  }
                }
              }
              break;
            case 'instagram':
              media.platforms.push({
                id: socialMedia.id,
                source: socialMedia.source,
              });
              break;
          }
        }
      }
    } else {
      uploadedMedia = [];
    }

    //remove dates if isDraftis true
    if (isDraft) {
      this.postForm.patchValue({ expectedPublishingDatetime: '' });
      this.postForm.patchValue({ publishingDatetime: '' });
    }

    /// alter uploadedMedia by adding 'palatform' propety on the image/video opbect
    const postData = {
      ...this.postForm.value,
      typePost: googleTypePost,
      uploadedMedia: uploadedMedia.length > 0 ? uploadedMedia[0] : [],
      socialMedia: this.selectedPagesSources,
      additionalData: googleAdditionalData,
      isDraft: isDraft,
      updateId:
        this.postUrl && this.postUrlAction !== 'newPostFromOldPost'
          ? this.postUrl.id
          : null, // to edit the post if it exists as draft or old post
    };
    if (!isDraft && this.postUrlAction !== 'update') {
      postData.expectedPublishingDatetime.setHours(
        this.selectedDate?.getHours(),
        this.selectedDate?.getMinutes(),
        this.selectedDate?.getSeconds()
      );
    }
    this.canPressNext = false;

    Debug.log('postData', postData);
    this.postService.createPost(postData).subscribe({
      next: (res) => {
        Debug.log('Post created successfully', res);
        // Handle response
        this.headinsSuccessMsg = isDraft
          ? 'generic.successCreateDraft'
          : 'generic.successCreatePost';
        this.subHeadinsSuccessMsg = '';
        this.modalIcon = ICON.success;
        this.isModalOpen = true;
        setTimeout(() => {
          if (isDraft) {
            // stays on the page if it's a draft
            this.isModalOpen = false;
            this.close();
          } else {
            // redirect to library if it's a post
            this.router.navigate(['/library']); // comment to stay on the page after post creation
          }
        }, 2000);
        /* Swal.fire({
          icon: 'success',
          title: isDraft
            ? this.getTranslation('posts.draftSaved')
            : this.getTranslation('posts.createdSuccessfully'),
          showConfirmButton: false,
          timer: 1500,
        }).then(() => { 
          this.router.navigate(['/library']); // comment to stay on the page after post creation
          });*/
      },
      error: (err) => {
        console.error('Error creating post', err);
        this.headinsSuccessMsg = 'generic.creationFailure';
        this.subHeadinsSuccessMsg = 'generic.creationDraftFailureMsg';
        this.modalIcon = ICON.fail;
        this.isModalOpen = true;
        this.canPressNext = false;
        /* Swal.fire({
          icon: 'error',
          title: err,
          showConfirmButton: true,
        }); */
      },
    });
  }

  IsDraftValid(): boolean {
    const selectedPages = Object.keys(
      this.postService.toggledItemsSource.getValue()
    );

    return (
      selectedPages.length > 0 &&
      !!this.postForm.get('title')?.value &&
      !!this.postForm.get('text')?.value
    );
  }

  saveDraft($event: Event) {
    $event.preventDefault();
    Debug.log('saving draft');
    this.postForm.get('expectedPublishingDatetime')?.setValue(null);
    this.postForm.get('publishingDatetime')?.setValue(null);
    this.onSubmit(true);
  }

  isGooglePageSelected() {
    if (this.postUrlAction == 'newPostFromDraft') {
      this.selectedPagesSources = this.postUrl.SocialPlatforms;
    }
    this.postService.updateSelectedSources(this.selectedPagesSources);
    const previousGooglePageIfAny =
      this.postUrl && this.getUrlPostSocilaMediPLatforms().includes('google');
    const currentGooglePagesIfAny =
      this.selectedPagesSources &&
      this.selectedPagesSources.length > 0 &&
      this.selectedPagesSources.filter((p: any) => p.source == 'google')
        .length > 0;
    const anyGooglePageSelected =
      currentGooglePagesIfAny || previousGooglePageIfAny;

    if (!anyGooglePageSelected) {
      this.typePost = 'N/A';
      this.postForm.patchValue({
        typePost: this.typePost,
      });
    }

    return anyGooglePageSelected;
  }

  isFacebookPageSelected() {
    return (
      this.selectedPagesSources.filter((p: any) => p.source == 'facebook')
        .length > 0 ||
      (this.postUrl &&
        this.getUrlPostSocilaMediPLatforms().includes('facebook'))
    );
  }
  isLinkedInPageSelected() {
    return (
      this.selectedPagesSources.filter((p: any) => p.source == 'linkedin')
        .length > 0 ||
      (this.postUrl &&
        this.getUrlPostSocilaMediPLatforms().includes('linkedin'))
    );
  }
  isInstagramSelected() {
    return (
      this.selectedPagesSources.filter((p: any) => p.source == 'instagram')
        .length > 0 ||
      (this.postUrl &&
        this.getUrlPostSocilaMediPLatforms().includes('instagram'))
    );
  }

  isThereAnyGoogleMediaIssue(): boolean {
    if (this.isGooglePageSelected()) {
      // valid if there is no media
      if (this.previewFilesList.length === 0) return false;
      // valid if there is one image in previewFilesList
      if (
        this.previewFilesList.length == 1 &&
        this.previewFilesList[0].type.includes('image') &&
        !this.fileTooSmall(this.previewFilesList[0])
      )
        return false;
      // if there is many items in previewFilesList on only one image
      if (
        this.previewFilesListImagesCount() == 1 &&
        this.previewFilesList.length > 1
      ) {
        this.googleMediaBrowserContext.data = {
          selected: this.previewFilesList.filter((p: any) =>
            p.type.includes('image')
          )[0],
        };
        return false;
      }
      // if there is multiple images and  google media is either selected or skipped
      if (
        this.previewFilesListImagesCount() > 1 &&
        this.previewFilesList.length > 1 &&
        this.googleMediaBrowserContext.data != null
      )
        return false;

      if (this.googleMediaBrowserContext.data != null) return false;

      return true;
    }
    return false;
  }

  isThereAnyFacebookLinkedinIssue(): boolean {
    if (this.isFacebookPageSelected() || this.isLinkedInPageSelected()) {
      if (this.previewFilesList.length === 0) return false;

      if (
        this.previewFilesListVideosCount() == 1 &&
        this.previewFilesList.length == 1
      ) {
        this.facebookLinkedInMediaBrowserContext.data = {
          selected: this.previewFilesList[0],
        };
        return false;
      }

      if (this.previewFilesListImagesCount() == this.previewFilesList.length) {
        return false;
      }

      if (this.facebookLinkedInMediaBrowserContext.data != null) return false;
      return true;
    }

    return false;
  }

  nextStep($event: Event) {
    const target = $event.target as HTMLElement; // Cast the target to HTMLElement
    this.divPostTextAreaIsExpanded = false;
    if (this.creationStep == 0) {
      if (!this.isStep1Valid()) {
        return;
      }
    }
    if (this.creationStep == 1) {
      if (!this.isStep2Valid()) {
        return;
      }
    }
    if (this.creationStep == 2) this.canPressNext = false;
    if (
      target.innerHTML
        .toString()
        .includes(this.getTranslation('posts.nextStep'))
    ) {
      //if post type event or update or offer
      //if media is mixed (videos and and photos) and currentStep is not 1
      if (
        (this.typePost != 'N/A' ||
          (this.previewFilesList.length > 1 &&
            this.isPreviewListContainVideos())) &&
        this.creationStep != 1
      ) {
        this.creationStep = 1;
      } else {
        this.creationStep = 2;
      }
    } else {
      if (this.postService.publishNowSource.getValue()) {
        this.postForm.get('publishingDatetime')?.setValue(new Date());
        this.postForm.get('expectedPublishingDatetime')?.setValue(new Date());
      } else {
        this.canPressNext = false;

        this.postForm
          .get('expectedPublishingDatetime')
          ?.setValue(this.postService.currentPickedDateSource.getValue());
        this.postForm.get('publishingDatetime')?.setValue(null);
      }
      this.onSubmit();
    }
  }

  isPreviewListContainVideos() {
    for (let vp of this.previewFilesList) {
      if (vp.type.includes('video')) {
        return true;
      }
    }
    return false;
  }

  prevStep() {
    if (
      (this.typePost != 'N/A' ||
        (this.previewFilesList.length > 1 &&
          this.isPreviewListContainVideos())) &&
      this.creationStep != 1
    ) {
      this.creationStep = 1;
    } else this.creationStep = 0;
  }

  getChoosedSocialMediaPages(): any[] {
    const selectedPagesIds = Object.keys(
      this.postService.getSelectedSocialMediaIds()
    );
    return this.socialMediaPagesList.filter((p) =>
      selectedPagesIds.includes(p.id.toString())
    );
  }

  currentPreviewPlatform: number = 0;
  nextSocial() {
    this.currentPreviewPlatform++;
    if (this.currentPreviewPlatform >= this.getChoosedSocialMediaPages().length)
      this.currentPreviewPlatform = 0;
  }

  prevSocial() {
    this.currentPreviewPlatform--;
    if (this.currentPreviewPlatform < 0)
      this.currentPreviewPlatform =
        this.getChoosedSocialMediaPages().length - 1;
  }

  validationToolTips: string[] = [];

  textValidation() {
    if (this.postForm.get('text')?.value === '') {
      this.toolTipMsg = true;
      this.canPressNext = false;

      setTimeout(() => {
        this.canPressNext = true;
        this.toolTipMsg = false;
      }, 4800);
    } else if (this.postForm.get('text')?.value !== '') {
      this.toolTipMsg = false;
    }
  }

  titleValidation() {
    if (this.postForm.get('title')?.value === '') {
      this.toolTipMsgTitle = true;
      this.fieldsetColorTitle = true;
      setTimeout(() => {
        this.toolTipMsgTitle = false;
      }, 4800);
    } else if (this.postForm.get('title')?.value !== '') {
      this.fieldsetColorTitle = false;
      this.toolTipMsgTitle = false;
    }
  }

  postTypeValidation(): boolean {
    let postTypeValid: boolean = true;
    if (this.selectedPagesSources.length > 0) {
      const anyGooglePageSelected =
        this.selectedPagesSources.filter((p: any) => p.source == 'google')
          .length > 0;
      if (anyGooglePageSelected) {
        postTypeValid =
          this.postForm.get('typePost')?.value != 'N/A' ? true : false;
      } else {
        this.postForm.get('typePost')?.setValue('N/A');
        this.isOfferSelected =
          this.isEventSelected =
          this.isUpdateSelected =
            false;
      }
    }

    if (this.postUrl) {
      Debug.log('url post type', this.postUrl);
      this.typePost = this.postUrl.typePost;
      this.postForm.patchValue({ typePost: this.typePost });
    }
    Debug.log(
      'validating postType',
      this.typePost,
      this.postForm.get('typePost')?.value
    );

    if (postTypeValid) {
      this.toolTipMsgGoogle = false;
      this.fieldsetColorGoogle = false;
      return true;
    } else if (!postTypeValid) {
      this.fieldsetColorGoogle = true;
      this.toolTipMsgGoogle = true;
      this.canPressNext = false;
      setTimeout(() => {
        this.canPressNext = true;
        this.toolTipMsgGoogle = false;
      }, 4800);
      return false;
    }
    return true;
  }

  uplodedMediaValidation(): boolean {
    let uplodedMediaValid: boolean = true;
    let anyInstagramPageSelected = false;
    if (this.selectedPagesSources.length > 0) {
      anyInstagramPageSelected =
        this.postService?.selectedSourcesSubject
          ?.getValue()
          .filter((p: any) => p.source == 'instagram').length > 0;

      uplodedMediaValid =
        anyInstagramPageSelected && this.previewFilesList.length == 0
          ? false
          : true;
    }
    if (uplodedMediaValid && anyInstagramPageSelected) {
      this.MediaForInstagram = false;
      this.fieldsetColorstagram = false;
      return true;
    } else if (!uplodedMediaValid && anyInstagramPageSelected) {
      this.fieldsetColorstagram = true;
      this.MediaForInstagram = true;
      this.canPressNext = false;
      setTimeout(() => {
        this.canPressNext = true;
        this.MediaForInstagram = false;
      }, 4800);
      return false;
    }
    this.fieldsetColorstagram = false;
    this.MediaForInstagram = false;
    return true;
  }

  selectPagesSourcesValidation() {
    this.toolTipMsgPages = this.selectedPagesSources.length === 0;
    this.fieldsetColorPages = this.selectedPagesSources.length === 0;
    this.canPressNext = !(this.selectedPagesSources.length === 0);
    if (this.toolTipMsgPages) {
      setTimeout(() => {
        this.canPressNext = true;
        this.toolTipMsgPages = false;
      }, 4800);
    }
  }

  selectPlatformValidation() {
    this.toolTipMsgPlateforms = this.selectedPlatform.length == 0;
    this.fieldsetColorPlateforms = this.selectedPlatform.length == 0;
    this.canPressNext = !(this.selectedPlatform.length == 0);
    if (this.toolTipMsgPlateforms) {
      setTimeout(() => {
        this.canPressNext = true;
        this.toolTipMsgPlateforms = false;
      }, 4800);
    }
  }

  isStep1Valid(): boolean {
    //this.textValidation();
    this.titleValidation();
    this.selectPagesSourcesValidation();
    this.selectPlatformValidation();
    this.postTypeValidation();
    this.uplodedMediaValidation();
    const isValid =
      this.selectedPagesSources.length > 0 &&
      this.postTypeValidation() &&
      this.uplodedMediaValidation() &&
      this.postForm.get('title')?.value != ''; //&&
    // this.postForm.get('text')?.value != '';
    return isValid;
  }
  showTooltipVisibility: boolean = false;

  showToolTip() {
    this.showTooltipVisibility = true;
  }

  hideToolTip() {
    this.showTooltipVisibility = false;
  }

  isCurrentStepValid(): boolean {
    // Debug.log('currentStep',this.creationStep,this.isStep2Valid());

    return (
      (this.creationStep == 0 && this.isStep1Valid()) ||
      (this.creationStep == 1 && this.isStep2Valid()) ||
      (this.creationStep == 2 && this.isStep3Valid())
    );
  }

  updateVlidationsToolTipContetnt(
    mediaContext: { data: any },
    message: string,
    isThereIssue: boolean
  ): boolean {
    let result: boolean = true;
    if (mediaContext.data == null && isThereIssue) {
      this.addValidationMsg(message);
      result = false;
      this.showToolTip();
    }
    return result;
  }

  googleMediaInvalid() {}
  isStep2Valid(): boolean {
    let isValid: boolean = false;
    const getGoogleMediaWarningMessage = this.getTranslation(
      'posts.step2Validation.googleMediaInvalid'
    );
    const isGoogleMediaOk = this.updateVlidationsToolTipContetnt(
      this.googleMediaBrowserContext,
      getGoogleMediaWarningMessage,
      this.isThereAnyGoogleMediaIssue()
    );
    const facebookLinkedinMessage = this.getTranslation(
      'posts.step2Validation.facebookLinkedinInvalid'
    );
    const isFacbookLinkedinOk = this.updateVlidationsToolTipContetnt(
      this.facebookLinkedInMediaBrowserContext,
      facebookLinkedinMessage,
      this.isThereAnyFacebookLinkedinIssue()
    );

    //check if postType is event or offer
    let isGoogleEventOfferDateValid: boolean = false;
    const postType = this.postForm.get('typePost')?.value;
    if (postType == 'event' || postType == 'offer') {
      // add validation tip message if not already added
      const startDateInvalidMsg = this.getTranslation(
        'posts.step2Validation.eventOfferStartDateInvalid'
      );
      const endDateInvalidMsg = this.getTranslation(
        'posts.step2Validation.eventOfferEndDateInvalid'
      );
      if (this.googleEventOfferStartDate == null) {
        // Debug.log("startDate Invalid",startDateInvalidMsg,isStartDateMsgInValidationTips);
        this.addValidationMsg(startDateInvalidMsg);
        isGoogleEventOfferDateValid = false;
      } else {
        this.removeValidationMsg(startDateInvalidMsg);
        isGoogleEventOfferDateValid = true;
      }

      if (
        this.googleEventOfferEndDate == null ||
        (this.googleEventOfferEndDate &&
          this.googleEventOfferEndDate < this.googleEventOfferStartDate)
      ) {
        // Debug.log("this.googleEventOfferEndDate",this.googleEventOfferEndDate,"googleEventOfferStartDate",this.googleEventOfferStartDate)
        // this.addValidationMsg(endDateInvalidMsg);
        this.toolTipMsgGoogleEventOfferDateValid = true;
        this.fieldsetColorGoogleEventOfferDateValid = true;
        isGoogleEventOfferDateValid = false;
        this.canPressNext = false;
        setTimeout(() => {
          this.canPressNext = true;
          this.toolTipMsgGoogleEventOfferDateValid = false;
        }, 4800);
      } else {
        this.fieldsetColorGoogleEventOfferDateValid = false;
        this.toolTipMsgGoogleEventOfferDateValid = false;
        // this.removeValidationMsg(endDateInvalidMsg);
        isGoogleEventOfferDateValid = true;
      }
    } else {
      isGoogleEventOfferDateValid = true;
    }

    if (!isGoogleMediaOk) {
      this.toolTipMsgGoogleMediaInvalid = true;
      this.fieldsetColorGoogleMediaInvalid = true;
      this.canPressNext = false;
      setTimeout(() => {
        this.canPressNext = true;
        this.toolTipMsgGoogleMediaInvalid = false;
      }, 4800);
    } else {
      this.toolTipMsgGoogleMediaInvalid = false;
      this.fieldsetColorGoogleMediaInvalid = false;
    }

    if (!this.cuponCodeValidation()) {
      return false;
    }

    if (
      this.linkValidation() &&
      this.postForm.get('g_offer_event_link')?.value != ''
    ) {
      return false;
    }

    if (
      !this.dynamicAdditionalDataValidation() &&
      this.postForm.get('g_update_event_info')?.value != ''
    ) {
      return false;
    }

    if (!isFacbookLinkedinOk) {
      this.facebookLinkedinMessageVideo = true;
      this.canPressNext = false;
      setTimeout(() => {
        this.facebookLinkedinMessageVideo = false;
        this.canPressNext = true;
      }, 4800);
    } else {
      this.facebookLinkedinMessageVideo = false;
    }

    if (
      !isGoogleMediaOk ||
      !isFacbookLinkedinOk ||
      !isGoogleEventOfferDateValid
    ) {
      isValid = false;
      this.showToolTip();
    } else {
      isValid = true;
      this.validationToolTips = [];
      this.hideToolTip();
      // Debug.log('STEP2 VALID',isValid);
    }
    // Debug.log('[STEP2]',this.validationToolTips,"isValid",isValid,"googleContext",this.googleMediaBrowserContext,"facebookLinkedIn Context",this.facebookLinkedInMediaBrowserContext)
    return isValid;
  }

  isStep3Valid(): boolean {
    return this.creationStep == 2 && this.postForm.valid;
  }

  isDatePicked(): boolean {
    return !this.postService.publishNow;
  }

  publishNow(): boolean {
    return this.postService.publishNowSource.getValue();
  }

  SetPublishNow() {
    this.postService.updatePickedDate(null);
  }

  onSelectChange(value: string): void {
    // Update the selectedLabel based on the value
    if (value === 'CALL') {
      this.selectedLabel = 'generic.phone';
    } else if (value) {
      this.selectedLabel = 'generic.link';
    }
    this.DynamicAdditionalData = value;
    // this.dynamicAdditionalDataValidation();
  }

  toolTipShow() {
    setTimeout(() => {
      this.showTooltipVisibility = this.validationToolTips.length > 0;
      this.cdRef.detectChanges();
    }, 100);
  }
  toolTipHide() {
    setTimeout(() => {
      this.showTooltipVisibility = false;
      this.cdRef.detectChanges();
    }, 100);
  }

  private addValidationMsg(msg: string): void {
    if (!this.validationToolTips.includes(msg)) {
      this.validationToolTips.push(msg);
    }
  }

  private removeValidationMsg(msg: string): void {
    if (this.validationToolTips.includes(msg)) {
      this.validationToolTips = this.validationToolTips.filter((m) => m != msg);
    }
  }

  updateGoogleEventOfferDate(data: any, targetDate: string) {
    let date =
      targetDate == 'start'
        ? this.googleEventOfferStartDate
        : this.googleEventOfferEndDate;
    date = data;
    this.googleEventOfferStartDate =
      targetDate == 'start' ? data : this.googleEventOfferStartDate;
    this.googleEventOfferEndDate =
      targetDate == 'end' ? data : this.googleEventOfferEndDate;
    if (this.googleEventOfferEndDate < this.googleEventOfferStartDate) {
      this.addValidationMsg(
        this.getTranslation('posts.step2Validation.eventOfferEndDateInvalid')
      );
    } else if (this.googleEventOfferEndDate > this.googleEventOfferStartDate) {
      this.removeValidationMsg(
        this.getTranslation('posts.step2Validation.eventOfferEndDateInvalid')
      );
    }
    Debug.log('update Google Event-Offer Date', targetDate, 'to', date);
  }
  updateGoogleEventOfferTime(data: any, targetDate: string) {
    Debug.log('event data', data);

    let date =
      targetDate == 'start'
        ? this.googleEventOfferStartDate
        : this.googleEventOfferEndDate;
    let hours = Number(data.split(':')[0]);
    let minutes = Number(data.split(':')[1]);

    if (targetDate == 'start') {
      Debug.log(
        'this.googleEventOfferStartDate',
        this.googleEventOfferStartDate
      );
      this.googleEventOfferStartDate.setHours(hours);
      this.googleEventOfferStartDate.setMinutes(minutes);
    } else if (targetDate == 'end') {
      Debug.log('this.googleEventOfferEndDate', this.googleEventOfferEndDate);
      this.googleEventOfferEndDate.setHours(hours);
      this.googleEventOfferEndDate.setMinutes(minutes);
    }

    Debug.log('update Google Event-Offer TIME', targetDate, 'to', date);
  }

  handleBoolean(data: boolean) {
    this.toolTipMsgPages = data;
  }

  linkValidation(): boolean {
    const regex = /^([\w-]+\.)+[\w-]{2,}(\/[\w-]*)*\/?$/;
    if (
      !regex.test(this.postForm.get('g_offer_event_link')?.value) &&
      this.postForm.get('g_offer_event_link')?.value != ''
    ) {
      this.toolTipMsgValidLink = true;
      this.canPressNext = false;
      this.fieldsetColorValidLink = true;
      setTimeout(() => {
        this.canPressNext = true;
        this.toolTipMsgValidLink = false;
      }, 4800);
    } else {
      this.fieldsetColorValidLink = false;
      this.toolTipMsgValidLink = false;
    }

    return this.toolTipMsgValidLink;
  }

  dynamicAdditionalDataValidation() {
    const phoneRegex = /^((\+33\s?|0)[1-9](?:[\s.-]?[0-9]{2}){4})$/;
    const linkregex = /^([\w-]+\.)+[\w-]{2,}(\/[\w-]*)*\/?$/;
    let g_up_eve_info = this.postForm.get('g_update_event_info')?.value;

    const isCall = this.DynamicAdditionalData === 'CALL';
    const isOther =
      this.DynamicAdditionalData && this.DynamicAdditionalData !== 'CALL';

    if (isCall) {
      if (!phoneRegex.test(g_up_eve_info)) {
        this.AdditionalDataValidation = 'CALL';
        this.fieldsetColorDataValidation = true;
        this.canPressNext = false;
        setTimeout(() => {
          this.canPressNext = true;
          this.AdditionalDataValidation = '';
        }, 4800);
        return false;
      }
    } else if (isOther) {
      if (!linkregex.test(g_up_eve_info)) {
        this.AdditionalDataValidation = 'other';
        this.fieldsetColorDataValidation = true;
        this.canPressNext = false;
        setTimeout(() => {
          this.canPressNext = true;
          this.AdditionalDataValidation = '';
        }, 4800);
        return false;
      }
    }
    this.fieldsetColorDataValidation = false;
    this.AdditionalDataValidation = '';

    return true;
  }

  cuponCodeValidation(): boolean {
    if (
      this.postForm.get('g_offer_event_link')?.value != '' &&
      this.postForm.get('g_offer_event_promotionCode')?.value == ''
    ) {
      this.toolTipMsgcuponCode = true;
      this.fieldsetColorcuponCode = true;
      this.canPressNext = false;
      setTimeout(() => {
        this.canPressNext = true;
        this.toolTipMsgcuponCode = false;
      }, 4800);
      return false;
    } else {
      this.fieldsetColorcuponCode = false;
      this.toolTipMsgcuponCode = false;
      return true;
    }
  }

  close() {
    this.isModalOpen = false;
    this.canPressNext = true;
  }

  fileTooSmall(file: any): boolean {
    if (!this.isGooglePageSelected) return false;
    // Split the dimensions string to extract width and height
    const [width, height] = file.dimensions
      .split('x')
      .map((dim: any) => parseInt(dim.trim()));

    // Google's minimum resolution is 400x300
    const MIN_WIDTH = 400;
    const MIN_HEIGHT = 300;

    // Return true if either width or height is smaller than the minimum required
    return width < MIN_WIDTH || height < MIN_HEIGHT;
  }

  // FACEBOOK / LINKEDIN "Video OR Photo(s)" Fieldset fts
  mediaSelectionStep: string = 'initial'; // Initial state

  uploadedVideos: any[] = []; // Populate these dynamically from the existing media
  uploadedPhotos: any[] = [];
  selectedVideo: any = null;
  selectedPhotos: any[] = [];

  selectMediaType(mediaType: string) {
    this.mediaSelectionStep = mediaType;
    if (mediaType === 'images') {
      const selection = [
        ...this.previewFilesList.filter((file) => file.type.includes('image')),
      ]; // Default to all photos selected
      this.facebookLinkedInMediaBrowserContext.data = {
        isSkipped: false,
        selection: selection,
        selected: selection[0],
      };
    }
    if (mediaType === 'video') {
      const selection = [
        ...this.previewFilesList.filter((file) => file.type.includes('video')),
      ]; // Default to all photos selected
      if (selection.length == 1) {
        this.facebookLinkedInMediaBrowserContext.data = {
          isSkipped: false,
          selection: selection,
          selected: selection[0],
        };
      }
    }
    if (mediaType === 'none') {
      this.facebookLinkedInMediaBrowserContext.data = { isSkipped: true };
    }
  }

  chooseVideo() {
    if (this.uploadedVideos.length > 1) {
    } else if (this.uploadedVideos.length === 1) {
      this.selectedVideo = this.uploadedVideos[0];
    }
  }

  backToMainSelection() {
    this.mediaSelectionStep = 'initial';
    this.facebookLinkedInMediaBrowserContext.data = null;
  }

  // END  -- FACEBOOK / LINKEDIN "Video OR Photo(s)" Fieldset fts

  ngAfterViewInit(): void {
    // Loop over each element in the QueryList
    this.myElements?.forEach((element, index) => {
      const rect = element.nativeElement.getBoundingClientRect();

      const x = rect.left + window.scrollX;
      const y = rect.top + window.scrollY;
      const r = rect.right + window.scrollX + (this.screenWidth * 18) / 100;
      const h = rect.height;
      this.elementsPositions.push({ X: x, Y: y, R: r, H: h });
    });
  }

  updateHeight($event: any, index: number) {
    setTimeout(() => {
      this.toolTiHeights[index] = $event;
    }, 100);
  }

  divPostTextAreaIsExpanded: boolean = false;
  toggleExpandDivPostTextArea() {
    this.divPostTextAreaIsExpanded = !this.divPostTextAreaIsExpanded;
    if (this.divPostTextAreaIsExpanded) {
      this.postTextArea.nativeElement.focus();
    }
  }

  preparePostDataForPreview(): any {
    return {
      typePost: this.postForm.value.typePost,
      text: this.postForm.value.text,
      title: this.postForm.value.title,
      googleEventOfferStartDate: this.googleEventOfferStartDate,
      googleEventOfferEndDate: this.googleEventOfferEndDate,
      socialMedia: this.selectedPagesSources,
      mediaGoogle: this.googleMediaBrowserContext.data?.selected?.url
        ? this.googleMediaBrowserContext.data?.selected?.url
        : this.previewFilesList[0]?.url,
      MediaList: this.previewFilesList.filter((item) =>
        item.type.startsWith('image')
      ),
    };
  }
}

<div class="p-4">
  <div (click)="addSpecialHour()" class=" text-cliking_blue px-4 py-2 rounded-lg mb-4 cursor-pointer">
    + {{('pages.hours.addDate' | translate)}}
  </div>

	<!-- NEW special hours instances -->
	<div *ngFor="let specialHour of newSpecialHourPeriods; let i = index" class="mb-4 border p-4 rounded-lg bg-zinc-50 text-sm">
		
		<div class="flex justify-end mt-4 flex-col">
			<div class="flex items-start space-x-4">
				<div class="w-[22%] text-sm">
					<label class="block text-gray-700">Date</label>
					<input type="date" [(ngModel)]="specialHour.date" class="border rounded-lg w-full p-2">
				</div>
				<div class="flex items-start mt-3 space-x-4 w-3/4">
					<div class="w-1/4 flex items-start mt-4">
						<label class="flex items-start">
							<input type="checkbox" [(ngModel)]="specialHour.open" (change)="toggleOpen(specialHour, i, 'new')" class="sr-only">
							<div class="toggle-switch relative inline-flex items-center h-6 rounded-full w-11">
								<span [ngClass]="{'bg-blue-500': specialHour.open, 'bg-gray-400': !specialHour.open}" class="absolute left-0 top-0 bottom-0 right-0 rounded-full transition-colors cursor-pointer"></span>
								<span [ngClass]="{'translate-x-6': specialHour.open, 'translate-x-1': !specialHour.open}" class="inline-block w-4 h-4 transform bg-white rounded-full transition-transform cursor-pointer"></span>
							</div>
							<span class="ml-2 text-sm h-6 flex items-center">{{ specialHour.open ? 'Ouvert' : 'Fermé' }}</span>
						</label>
					</div>

					<div *ngIf="specialHour.open" class="flex items-start space-x-4">
						<div class="w-1/4 mt-4">
							<label class="flex items-center">
								<input type="checkbox" [(ngModel)]="specialHour.allDay" (change)="toggleAllDay(specialHour, i, 'new')" class="form-checkbox h-6 w-4 text-blue-600 transition duration-150 ease-in-out">
								<span class="ml-2 text-sm">24h</span>
							</label>
						</div>
						<div class="flex-grow mt-1" *ngIf="!specialHour.allDay">
							<div *ngFor="let period of specialHour.periods; let j = index" class="flex items-center space-x-2 mt-2">
								<span>de</span>
								<app-custom-time-input class="time-input"
									[(ngModel)]="period.openTime"
									[context]="{ specialHourIndex: i, periodIndex: j, type: 'open' }"
									(timeChange)="emitChanges()">
								</app-custom-time-input>
								<span>à</span>
								<app-custom-time-input class="time-input"
									[(ngModel)]="period.closeTime"
									[context]="{ specialHourIndex: i, periodIndex: j, type: 'close' }"
									(timeChange)="emitChanges()">
								</app-custom-time-input>
								<div *ngIf="j == 0" (click)="addTimePeriod(specialHour)" class="text-black flex items-center cursor-pointer">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
										<path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
									</svg>
								</div>
								<div *ngIf="j != 0" (click)="removeTimePeriod(specialHour, j)" class="ml-2 text-red-500 flex items-center justify-center w-6 h-6 rounded-full bg-gray-200 cursor-pointer">
									<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
									</svg>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
			<div class="flex mt-4 justify-center gap-2">
				<div (click)="cancelSpecialHourCreation(i)" class="text-neutral-400 bg-gray-200 px-4 py-1 flex items-center rounded-xl cursor-pointer">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 m-1">
						<path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
					</svg>
					Annuler
				</div>
				<div (click)="createNewSpecialHour(i)" class="bg-blue-500 text-white px-4 py-1 flex items-center rounded-xl cursor-pointer">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 m-1">
						<path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
					</svg>
					Valider
				</div>
			</div>
		</div>
	</div>

	<!-- EXISTING special hours instances -->
	<!-- FUTURE -->
	<div *ngFor="let specialHour of futureSpecialHours; let i = index" class="mb-5 border p-4 rounded-lg bg-zinc-50 text-sm">
		<!-- Date and Chevron at the top -->
		<div class="flex justify-between items-center cursor-pointer" (click)="toggleFormFuture(i)">
			<div class="flex justify-between items-center"><span class="text-lg font-bold">{{ specialHour.date | date: 'dd/MM/yyyy' }}</span> <span *ngIf="isDatePast(specialHour.date)" class="badge gap-2 text-[10px] ml-1 text-white badge-warning"> PAST</span></div>
			<svg xmlns="http://www.w3.org/2000/svg" [ngClass]="{'rotate-180': expandedIndexFuture === i}" class="h-6 w-6 transition-transform duration-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
			</svg>
		</div>

		<!-- The collapsible form -->
		<div *ngIf="expandedIndexFuture === i" class="flex justify-end mt-4 flex-col">
			<div class="flex items-start space-x-4 w-auto max-w-full p-0 m-0">
				<div class="w-[22%] text-sm">
					<label class="block text-gray-700">Date</label>
					<input type="date" [(ngModel)]="specialHour.date" class="border rounded-lg w-full p-2">
				</div>
				<div class="flex items-start mt-3 space-x-4 flex-shrink-0">
					<div class="flex items-start mt-4">
						<label class="flex items-start">
							<input type="checkbox" [(ngModel)]="specialHour.open" (change)="toggleOpen(specialHour, i)" class="sr-only">
							<div class="toggle-switch relative inline-flex items-center h-6 rounded-full w-11">
								<span [ngClass]="{'bg-blue-500': specialHour.open, 'bg-gray-400': !specialHour.open}" class="absolute left-0 top-0 bottom-0 right-0 rounded-full transition-colors cursor-pointer"></span>
								<span [ngClass]="{'translate-x-6': specialHour.open, 'translate-x-1': !specialHour.open}" class="inline-block w-4 h-4 transform bg-white rounded-full transition-transform cursor-pointer"></span>
							</div>
							<span class="ml-2 text-sm h-6 flex items-center">{{ specialHour.open ? ('pages.hours.open' | translate) : ('pages.hours.closed' | translate) }}</span>
						</label>
					</div>
					<div *ngIf="specialHour.open" class="flex items-start space-x-4 flex-shrink-0">
						<div class="mt-4">
							<label class="flex items-center">
								<input type="checkbox" [(ngModel)]="specialHour.allDay" (change)="toggleAllDay(specialHour, i)" class="form-checkbox h-6 w-4 text-blue-600 transition duration-150 ease-in-out">
								<span class="ml-2 text-sm">24h</span>
							</label>
						</div>
						<div class="mt-1" *ngIf="!specialHour.allDay">
							<div *ngFor="let period of specialHour.periods; let j = index" class="flex items-center space-x-2 mt-2">
								<span>{{('pages.hours.from' | translate)}}</span>
								<app-custom-time-input class="time-input"
									[(ngModel)]="period.openTime"
									[context]="{ specialHourIndex: i, periodIndex: j, type: 'open' }"
									(timeChange)="transformGroupedToSpecialHours()">
								</app-custom-time-input>
								<span>{{('pages.hours.to' | translate)}}</span>
								<app-custom-time-input class="time-input"
									[(ngModel)]="period.closeTime"
									[context]="{ specialHourIndex: i, periodIndex: j, type: 'close' }"
									(timeChange)="transformGroupedToSpecialHours()">
								</app-custom-time-input>
								<div *ngIf="j == 0" (click)="addTimePeriod(specialHour)" class="text-black flex items-center cursor-pointer">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
										<path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
									</svg>
								</div>
								<div *ngIf="j != 0" (click)="removeTimePeriod(specialHour, j)" class="ml-2 text-red-500 flex items-center justify-center w-6 h-6 rounded-full bg-gray-200 cursor-pointer">
									<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
									</svg>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="flex mt-4 justify-center gap-2">
				<div (click)="removeSpecialHour(i)" class="erase-btn bg-gray-200 px-4 py-1 flex items-center rounded-xl cursor-pointer">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 m-1">
						<path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
					</svg>
					{{('generic.delete' | translate)}}
				</div>
				<!-- <div (click)="emitChanges()" class="bg-blue-500 text-white px-4 py-1 flex items-center rounded-xl cursor-pointer">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 m-1">
						<path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
					</svg>
					Valider
				</div> -->
			</div>
		</div>
	</div>
	

	<!-- PAST -->
	<div *ngIf="pastSpecialHours.length > 0">
		<div class="cursor-pointer flex items-center" (click)="showPastHours = !showPastHours">
			<span class="mr-2 text-base">Past Special Hours</span>
			<svg xmlns="http://www.w3.org/2000/svg" [ngClass]="{'rotate-180': showPastHours}" class="h-6 w-6 transition-transform duration-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
			</svg>
			<div class="flex-grow ml-2 border-b border-gray-400"></div>
		</div>
		<div *ngIf="showPastHours" class="mt-4">
			<div *ngFor="let specialHour of pastSpecialHours; let i = index" class="mb-4 border p-4 rounded-lg bg-zinc-50 text-sm">
				<!-- Date and Chevron at the top -->
				<div class="flex justify-between items-center cursor-pointer" (click)="toggleFormPast(i)">
					<div class="flex justify-between items-center"><span class="text-lg font-bold">{{ specialHour.date | date: 'dd/MM/yyyy' }}</span> <span *ngIf="isDatePast(specialHour.date)" class="badge gap-2 text-[10px] ml-1 text-white badge-warning"> PAST</span></div>
					<svg xmlns="http://www.w3.org/2000/svg" [ngClass]="{'rotate-180': expandedIndexPast === i}" class="h-6 w-6 transition-transform duration-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
					</svg>
				</div>
			
				<!-- The collapsible form -->
				<div *ngIf="expandedIndexPast === i" class="flex justify-end mt-4 flex-col">
					<div class="flex items-start space-x-4 w-auto max-w-full p-0 m-0">
						<div class="w-[22%] text-sm">
							<label class="block text-gray-700">Date</label>
							<input type="date" [(ngModel)]="specialHour.date" class="border rounded-lg w-full p-2">
						</div>
						<div class="flex items-start mt-3 space-x-4 flex-shrink-0">
							<div class="flex items-start mt-4">
								<label class="flex items-start">
									<input type="checkbox" [(ngModel)]="specialHour.open" (change)="toggleOpen(specialHour, i)" class="sr-only">
									<div class="toggle-switch relative inline-flex items-center h-6 rounded-full w-11">
										<span [ngClass]="{'bg-blue-500': specialHour.open, 'bg-gray-400': !specialHour.open}" class="absolute left-0 top-0 bottom-0 right-0 rounded-full transition-colors cursor-pointer"></span>
										<span [ngClass]="{'translate-x-6': specialHour.open, 'translate-x-1': !specialHour.open}" class="inline-block w-4 h-4 transform bg-white rounded-full transition-transform cursor-pointer"></span>
									</div>
									<span class="ml-2 text-sm h-6 flex items-center">{{ specialHour.open ? 'Ouvert' : 'Fermé' }}</span>
								</label>
							</div>
							<div *ngIf="specialHour.open" class="flex items-start space-x-4 flex-shrink-0">
								<div class="mt-4">
									<label class="flex items-center">
										<input type="checkbox" [(ngModel)]="specialHour.allDay" (change)="toggleAllDay(specialHour, i)" class="form-checkbox h-6 w-4 text-blue-600 transition duration-150 ease-in-out">
										<span class="ml-2 text-sm">24h</span>
									</label>
								</div>
								<div class="mt-1" *ngIf="!specialHour.allDay">
									<div *ngFor="let period of specialHour.periods; let j = index" class="flex items-center space-x-2 mt-2">
										<span>{{('pages.hours.from' | translate)}}</span>
										<app-custom-time-input class="time-input"
											[(ngModel)]="period.openTime"
											[context]="{ specialHourIndex: i, periodIndex: j, type: 'open' }"
											(timeChange)="transformGroupedToSpecialHours()">
										</app-custom-time-input>
										<span>{{('pages.hours.to' | translate)}}</span>
										<app-custom-time-input class="time-input"
											[(ngModel)]="period.closeTime"
											[context]="{ specialHourIndex: i, periodIndex: j, type: 'close' }"
											(timeChange)="transformGroupedToSpecialHours()">
										</app-custom-time-input>
										<div *ngIf="j == 0" (click)="addTimePeriod(specialHour)" class="text-black flex items-center cursor-pointer">
											<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
												<path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
											</svg>
										</div>
										<div *ngIf="j != 0" (click)="removeTimePeriod(specialHour, j)" class="ml-2 text-red-500 flex items-center justify-center w-6 h-6 rounded-full bg-gray-200 cursor-pointer">
											<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
												<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
											</svg>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					
					<div class="flex mt-4 justify-center gap-2">
						<div (click)="removeSpecialHour(i)" class="erase-btn bg-gray-200 px-4 py-1 flex items-center rounded-xl cursor-pointer">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 m-1">
								<path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
							</svg>
							{{"generic.delete" | translate}}
						</div>
						<!-- <div (click)="emitChanges()" class="bg-blue-500 text-white px-4 py-1 flex items-center rounded-xl cursor-pointer">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 m-1">
								<path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
							</svg>
							Valider
						</div> -->
					</div>
				</div>
			</div>
		</div>
	</div>
	

  <div class="flex justify-end mt-4">
    
  </div>
</div>
<app-modal 
  [isOpen]="isModalOpen"
  [Heading]="heading"
  [subHeading]='subHeading' 
  (closeModal)="closeModal()" 
  [icon]="modalIcon"
	(deleteItem)="deleteSpecialHour()"
  >
</app-modal>

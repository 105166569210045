<div *ngIf="selectedPlateform=='linkedin'" class="rounded-2xl w-[90%] h-[88%] mx-auto mt-2 bg-white overflow-hidden">
	<div class="bg-[#465086] text-[16px] text-white text-center py-2 overflow-hidden text-ellipsis whitespace-nowrap max-w-full" [title]="data?.title != 'N/A' ? data?.title : ('generic.noTitle' | translate)">
		<span *ngIf="data?.title != 'N/A'">{{data?.title}}</span>
		<span *ngIf="data?.title == 'N/A'">{{'generic.noTitle' | translate}}</span>
	</div>
	<div class="p-4">
		<div class="flex relative items-center space-x-4">
			<img class="w-10 h-[5%] rounded-full" src="https://placehold.co/40" alt="Profile">
			<div class="join-vertical">
				<div class="text-sm  font-semibold">{{linkedinData?.name}}</div> 
				<div class="text-xs  text-gray-500 mr-2 ">
					<div class="join gap-2">
						{{'Il y a quelques secondes' +" •"}}
						<svg viewBox="0 0 16 16" width="12" height="12" fill="currentColor" title="Partagé avec Public" style="--color:var(--secondary-icon)">
							<g fill-rule="evenodd" transform="translate(-448 -544)">
								<g>
									<path d="M109.5 408.5c0 3.23-2.04 5.983-4.903 7.036l.07-.036c1.167-1 1.814-2.967 2-3.834.214-1 .303-1.3-.5-1.96-.31-.253-.677-.196-1.04-.476-.246-.19-.356-.59-.606-.73-.594-.337-1.107.11-1.954.223a2.666 2.666 0 0 1-1.15-.123c-.007 0-.007 0-.013-.004l-.083-.03c-.164-.082-.077-.206.006-.36h-.006c.086-.17.086-.376-.05-.529-.19-.214-.54-.214-.804-.224-.106-.003-.21 0-.313.004l-.003-.004c-.04 0-.084.004-.124.004h-.037c-.323.007-.666-.034-.893-.314-.263-.353-.29-.733.097-1.09.28-.26.863-.8 1.807-.22.603.37 1.166.667 1.666.5.33-.11.48-.303.094-.87a1.128 1.128 0 0 1-.214-.73c.067-.776.687-.84 1.164-1.2.466-.356.68-.943.546-1.457-.106-.413-.51-.873-1.28-1.01a7.49 7.49 0 0 1 6.524 7.434" transform="translate(354 143.5)"></path>
									<path d="M104.107 415.696A7.498 7.498 0 0 1 94.5 408.5a7.48 7.48 0 0 1 3.407-6.283 5.474 5.474 0 0 0-1.653 2.334c-.753 2.217-.217 4.075 2.29 4.075.833 0 1.4.561 1.333 2.375-.013.403.52 1.78 2.45 1.89.7.04 1.184 1.053 1.33 1.74.06.29.127.65.257.97a.174.174 0 0 0 .193.096" transform="translate(354 143.5)"></path>
									<path fill-rule="nonzero" d="M110 408.5a8 8 0 1 1-16 0 8 8 0 0 1 16 0zm-1 0a7 7 0 1 0-14 0 7 7 0 0 0 14 0z" transform="translate(354 143.5)"></path>
								</g>
							</g>
						</svg>
					</div>
				</div>
			</div>
			<div class="ml-auto absolute right-0 text-gray-400">
				<div class="join">
					<svg viewBox="0 0 20 20" width="20" height="20" fill="currentColor" class=" mt-[3px] x19dipnz x1lliihq x1tzjh5l x1k90msu x2h7rmj x1qfuztq" style="--color:var(--secondary-icon)"><g fill-rule="evenodd" transform="translate(-446 -350)"><path d="M458 360a2 2 0 1 1-4 0 2 2 0 0 1 4 0m6 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0m-12 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0"></path></g></svg>
				</div>
			</div>
		</div>
	</div>
	<div>
		<div *ngIf="(mediaIsVideo(linkedinMedias) || linkedinMedias.length>0) && data?.text  && screenHeight==0" class="text-sm py-2  px-4">{{data?.text | slice:0:100}}
			<span *ngIf="data?.text.length > 100" class="text-blue-700">Voir plus...</span>
		</div>
		<div *ngIf="(mediaIsVideo(linkedinMedias) || linkedinMedias.length>0) && data?.text  && screenHeight!=0" class="text-sm py-1  px-4">{{data?.text | slice:0:30}}
			<span *ngIf="data?.text.length > 30" class="text-blue-700">Voir plus...</span>
		</div>
		<div *ngIf="!mediaIsVideo(linkedinMedias) && linkedinMedias.length == 0 && data?.text " class="text-sm py-2  px-4">{{data?.text | slice:0:478}}
			<span *ngIf="data?.text.length > 478" class="text-blue-700">Voir plus...</span>
		</div>
		<app-grid-images-linkedin *ngIf="!mediaIsVideo(linkedinMedias)" [screenHeight]="data?.text? screenHeight*95/100: screenHeight" [linkedinMedias]="linkedinMedias"/>
        
	</div>
	<div class="border-t px-4 py-1 h-[40px] flex justify-between text-sm text-gray-800" [ngClass]="{'h-[80px]':screenWidth != 0 && screenWidth > 1400 && screenWidth < 1600}">
		<button class="flex items-center space-x-1 hover:bg-gray-100 rounded-lg px-1" [ngClass]="{'flex-col':screenWidth != 0 && screenWidth > 1400 && screenWidth < 1600}">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20px" fill="currentColor">
				<path d="M19.46 11l-3.91-3.91a7 7 0 01-1.69-2.74l-.49-1.47A2.76 2.76 0 0010.76 1 2.75 2.75 0 008 3.74v1.12a9.19 9.19 0 00.46 2.85L8.89 9H4.12A2.12 2.12 0 002 11.12a2.16 2.16 0 00.92 1.76A2.11 2.11 0 002 14.62a2.14 2.14 0 001.28 2 2 2 0 00-.28 1 2.12 2.12 0 002 2.12v.14A2.12 2.12 0 007.12 22h7.49a8.08 8.08 0 003.58-.84l.31-.16H21V11zM19 19h-1l-.73.37a6.14 6.14 0 01-2.69.63H7.72a1 1 0 01-1-.72l-.25-.87-.85-.41A1 1 0 015 17l.17-1-.76-.74A1 1 0 014.27 14l.66-1.09-.73-1.1a.49.49 0 01.08-.7.48.48 0 01.34-.11h7.05l-1.31-3.92A7 7 0 0110 4.86V3.75a.77.77 0 01.75-.75.75.75 0 01.71.51L12 5a9 9 0 002.13 3.5l4.5 4.5H19z"></path>
			</svg>
			<span *ngIf="screenWidth == 0 || screenWidth > 1400">{{ 'generic.like' | translate }}</span>
		</button>
		<button class="flex items-center space-x-1 hover:bg-gray-100 rounded-lg px-1" [ngClass]="{'flex-col':screenWidth != 0 && screenWidth > 1400 && screenWidth < 1600}">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="20px" id="comment-small" aria-hidden="true" role="none" data-supported-dps="16x16" fill="currentColor" focusable="false" class="social-action-bar__icon--svg lazy-loaded" aria-busy="false">
				<path d="M5 8h5v1H5zm11-.5v.08a6 6 0 01-2.75 5L8 16v-3H5.5A5.51 5.51 0 010 7.5 5.62 5.62 0 015.74 2h4.76A5.5 5.5 0 0116 7.5zm-2 0A3.5 3.5 0 0010.5 4H5.74A3.62 3.62 0 002 7.5 3.53 3.53 0 005.5 11H10v1.33l2.17-1.39A4 4 0 0014 7.58zM5 7h6V6H5z"></path>
			</svg>
			<span *ngIf="screenWidth ==0 || screenWidth>1400">{{'generic.comment' | translate}}</span>
		</button>
		<button class="flex items-center space-x-1 hover:bg-gray-100 rounded-lg px-1" [ngClass]="{'flex-col':screenWidth != 0 && screenWidth > 1400 && screenWidth < 1600}">
				<svg fill="currentColor" width="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" transform="matrix(-1, 0, 0, 1, 0, 0)rotate(90)" stroke="#000000" stroke-width="0.0002"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M5 4a2 2 0 0 0-2 2v6H0l4 4 4-4H5V6h7l2-2H5zm10 4h-3l4-4 4 4h-3v6a2 2 0 0 1-2 2H6l2-2h7V8z"></path></g></svg> 
				<span *ngIf="screenWidth ==0 || screenWidth>1400">{{'generic.repost' | translate}}</span>
		</button>
		<button class="flex items-center space-x-1 hover:bg-gray-100 rounded-lg px-1" [ngClass]="{'flex-col':screenWidth != 0 && screenWidth > 1400 && screenWidth < 1600}">
				<svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="20px" viewBox="0 0 52 52" enable-background="new 0 0 52 52" xml:space="preserve" transform="rotate(-45)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M2.1,44.5l4.4-16.3h18.6c0.5,0,1-0.5,1-1v-2c0-0.5-0.5-1-1-1H6.5l-4.3-16l0,0C2.1,8,2,7.7,2,7.4 C2,6.7,2.7,6,3.5,6.1c0.2,0,0.3,0.1,0.5,0.1l0,0l0,0l0,0l45,18.5c0.6,0.2,1,0.8,1,1.4s-0.4,1.1-0.9,1.3l0,0L4,46.4l0,0 c-0.2,0.1-0.4,0.1-0.6,0.1C2.6,46.4,2,45.8,2,45C2,44.8,2,44.7,2.1,44.5L2.1,44.5z"></path> </g></svg>
				<span *ngIf="screenWidth ==0 || screenWidth>1400">{{'generic.send' | translate}}</span>
		</button>
	</div>
</div> 
<div *ngIf="selectedPlateform=='google'" class="w-[90%] h-[88%] mx-auto mt-4 bg-white rounded-2xl overflow-hidden">
	<div class="bg-[#465086] text-[16px] text-white text-center py-2">
		<span *ngIf="data?.title!='N/A'">{{data?.title}}</span>
		<span *ngIf="data?.title=='N/A'">{{'generic.noTitle' | translate}}</span>
	</div>
	<div class="p-4">
		<div class="flex relative items-center space-x-4">
			<img class="w-10 h-[5%] rounded-full" src="https://placehold.co/40" alt="Profile">
			<div class="join-vertical">
				<div class="text-sm  font-semibold">{{googleData?.name}}</div> 
				<div class="text-xs  text-gray-500 mr-2 ">
					<div class="join gap-2">
					{{'Il y a quelques secondes' +" •"}}
					</div>
				</div>
			</div>
			<div class="ml-auto absolute right-0 text-gray-400">
				<div class="join">
					<svg fill="#000000" width="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M12,7a2,2,0,1,0-2-2A2,2,0,0,0,12,7Zm0,10a2,2,0,1,0,2,2A2,2,0,0,0,12,17Zm0-7a2,2,0,1,0,2,2A2,2,0,0,0,12,10Z"></path></g></svg>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="data?.mediaGoogle">
		<div class="flex bg-black w-full mx-auto">
			<img class="max-h-[240px] mx-auto" [ngClass]="{'max-h-[200px]': screenHeight<900 && screenHeight!=0}" src="{{data?.mediaGoogle}}" alt="Post Image">
		</div>
	</div>
	<div class="mt-1"></div>
	<div class="text-xl ml-5 join" *ngIf="data?.typePost.toLowerCase()=='update'">
		<span *ngIf="data?.title != 'N/A'">{{data?.title}}</span>
		<span *ngIf="data?.title == 'N/A'">{{'generic.noTitle' | translate}}</span>
		<span *ngIf="data?.typePost.toLowerCase()=='offer'" class="absolute right-[5%]"><svg height="24" viewBox="0 0 24 24" fill="#f9ab00" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41 0-.55-.23-1.06-.59-1.42zM5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4 7 4.67 7 5.5 6.33 7 5.5 7z"></path></svg></span>
	</div>
	<div class="text-sm ml-5">
	<span *ngIf="data?.googleEventOfferStartDate && data?.googleEventOfferEndDate">
		<span *ngIf="data?.googleEventOfferStartDate !== undefined">{{ formatDateWithMoment(data?.googleEventOfferStartDate) }}</span> -  
		<span *ngIf="data?.googleEventOfferEndDate !== undefined">{{ formatDateWithMoment(data?.googleEventOfferEndDate) }}</span>
	</span>
	</div>
	<div *ngIf="(screenHeight==0 || screenHeight>950) && data?.text" class="text-sm my-2 mx-5"> {{data?.text | slice:0:300}}
		<span *ngIf="data?.text.length > 300" class="text-blue-700">Voir plus...</span>
	</div>
	<div *ngIf="screenHeight!=0 && screenHeight<950 && data?.text" class="text-sm my-2 mx-5"> {{data?.text | slice:0:20}}
		<span *ngIf="data?.text.length > 20" class="text-blue-700">Voir plus...</span>
	</div>
	<div class="text-sm join ml-5 text-[#463bbb]" ><a  *ngIf="data?.typePost.toLowerCase()=='offer'" href="">Voir l'offre</a>  
	<a *ngIf="data?.typePost.toLowerCase()=='event'" href="">S'inscrire</a>  
		<span class="absolute right-[10%]"><svg fill="#757575" width="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M8.137,11.631a.908.908,0,0,1-.17.038A3.016,3.016,0,0,1,8,12a2.962,2.962,0,0,1-.033.328.949.949,0,0,1,.173.042L16.9,16.485a.991.991,0,0,1,.242.18A2.969,2.969,0,0,1,19,16a3.037,3.037,0,1,1-2.911,2.306c-.014-.006-.029,0-.044-.01L7.289,14.181a.953.953,0,0,1-.134-.1,3,3,0,1,1,0-4.162,1,1,0,0,1,.133-.1L16.045,5.7c.014-.007.029,0,.044-.011A2.93,2.93,0,0,1,16,5a3,3,0,1,1,3,3,2.969,2.969,0,0,1-1.862-.665,1.03,1.03,0,0,1-.242.18Z"></path></g></svg></span> 
	</div>
</div> 
<div *ngIf="selectedPlateform=='facebook'" class="w-[80%] max-h-[85%] mx-auto mt-4 bg-white rounded-2xl overflow-hidden">
	<div class="bg-[#465086] text-[16px] text-white text-center py-2 overflow-hidden text-ellipsis whitespace-nowrap max-w-full" [title]="data?.title != 'N/A' ? data?.title : ('generic.noTitle' | translate)">
		<span *ngIf="data?.title != 'N/A'">{{data?.title}}</span>
		<span *ngIf="data?.title == 'N/A'">{{'generic.noTitle' | translate}}</span>
	</div>
	<div class="p-2" >
		<div class="flex relative items-center space-x-1">
			<img class="w-8 h-[5%] rounded-full" src="https://placehold.co/40" alt="Profile">
			<div class="join-vertical">
				<div class="text-[11px] -mb-[8px] font-semibold">{{facebookData?.name}}</div> 
				<div class="text-[8px] text-gray-500 mr-2 ">
                    <div class="join gap-1">
                        <span class="-mt-[5px]">
                            {{"Il y a quelques secondes" +" •"}}
                        </span>
						<svg viewBox="0 0 16 16" width="12" height="12" fill="currentColor" title="Partagé avec Public" style="--color:var(--secondary-icon)">
							<g fill-rule="evenodd" transform="translate(-448 -544)">
								<g>
									<path d="M109.5 408.5c0 3.23-2.04 5.983-4.903 7.036l.07-.036c1.167-1 1.814-2.967 2-3.834.214-1 .303-1.3-.5-1.96-.31-.253-.677-.196-1.04-.476-.246-.19-.356-.59-.606-.73-.594-.337-1.107.11-1.954.223a2.666 2.666 0 0 1-1.15-.123c-.007 0-.007 0-.013-.004l-.083-.03c-.164-.082-.077-.206.006-.36h-.006c.086-.17.086-.376-.05-.529-.19-.214-.54-.214-.804-.224-.106-.003-.21 0-.313.004l-.003-.004c-.04 0-.084.004-.124.004h-.037c-.323.007-.666-.034-.893-.314-.263-.353-.29-.733.097-1.09.28-.26.863-.8 1.807-.22.603.37 1.166.667 1.666.5.33-.11.48-.303.094-.87a1.128 1.128 0 0 1-.214-.73c.067-.776.687-.84 1.164-1.2.466-.356.68-.943.546-1.457-.106-.413-.51-.873-1.28-1.01a7.49 7.49 0 0 1 6.524 7.434" transform="translate(354 143.5)"></path>
									<path d="M104.107 415.696A7.498 7.498 0 0 1 94.5 408.5a7.48 7.48 0 0 1 3.407-6.283 5.474 5.474 0 0 0-1.653 2.334c-.753 2.217-.217 4.075 2.29 4.075.833 0 1.4.561 1.333 2.375-.013.403.52 1.78 2.45 1.89.7.04 1.184 1.053 1.33 1.74.06.29.127.65.257.97a.174.174 0 0 0 .193.096" transform="translate(354 143.5)"></path>
									<path fill-rule="nonzero" d="M110 408.5a8 8 0 1 1-16 0 8 8 0 0 1 16 0zm-1 0a7 7 0 1 0-14 0 7 7 0 0 0 14 0z" transform="translate(354 143.5)"></path>
								</g>
							</g>
						</svg>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div>
		<div *ngIf="(mediaIsVideo(facebookMedias) || facebookMedias.length>0) && data?.text && screenHeight ==0" class="text-[11px] py-2 px-3">{{data?.text | slice:0:100}}
			<span *ngIf="data?.text.length > 100" class="text-blue-700">Voir plus...</span>
		</div>
		<div *ngIf="(mediaIsVideo(facebookMedias) || facebookMedias.length>0) && data?.text && screenHeight !=0" class="text-[11px] py-1 px-3">{{data?.text | slice:0:30}}
			<span *ngIf="data?.text.length > 30" class="text-blue-700">Voir plus...</span>
		</div>
		<div *ngIf="!mediaIsVideo(facebookMedias) && facebookMedias.length == 0 && data?.text" class="text-[11px] py-2 px-3">{{data?.text | slice:0:478}}
			<span *ngIf="data?.text.length > 478" class="text-blue-700">Voir plus...</span>
		</div>
		<app-grid-images-fb  [screenHeight]="data?.text? screenHeight*95/100: screenHeight"  *ngIf="!mediaIsVideo(facebookMedias)" [facebookMedias]="facebookMedias"/>
		<video *ngIf="mediaIsVideo(facebookMedias)" controls loop autoplay muted [src]="facebookMedias[0].url"></video>
	</div>
	<div class="border-t px-2 my-auto h-[25px] flex justify-between items-center text-[10px] text-gray-500">
		<button class="flex  space-x-1 hover:bg-gray-100 rounded-lg text-center">
			<i data-visualcompletion="css-img" style="background-image:url('https://static.xx.fbcdn.net/rsrc.php/v3/y3/r/JC3GCxn_mNT.png');background-position:0 -735px;opacity:65%;width:20px;height:20px;background-repeat:no-repeat;display:inline-block; transform: scale(0.75);"></i>
			<span>{{'generic.like' | translate}}</span>
		</button>
		<button class="flex  space-x-1 hover:bg-gray-100 rounded-lg text-center">
			<i data-visualcompletion="css-img" style="background-image:url('https://static.xx.fbcdn.net/rsrc.php/v3/y3/r/JC3GCxn_mNT.png');background-position:0 -546px;opacity:65%;width:20px;height:20px;background-repeat:no-repeat;display:inline-block; transform: scale(0.75);"></i>
			<span>{{'generic.comment' | translate}}</span>
		</button>
		<button class="flex  space-x-1 hover:bg-gray-100 rounded-lg text-center">
			<i data-visualcompletion="css-img" style="background-image:url('https://static.xx.fbcdn.net/rsrc.php/v3/y3/r/JC3GCxn_mNT.png');background-position:0 -399px;opacity:65%;width:20px;height:20px;background-repeat:no-repeat;display:inline-block; transform: scale(0.75);"></i>
			<span>{{'generic.send' | translate}}</span>
		</button>
		<button class="flex  space-x-1 hover:bg-gray-100 rounded-lg text-center">
			<i data-visualcompletion="css-img" style="background-image:url('https://static.xx.fbcdn.net/rsrc.php/v3/y3/r/JC3GCxn_mNT.png');background-position:0 -861px;opacity:65%;width:20px;height:20px;background-repeat:no-repeat;display:inline-block; transform: scale(0.75);"></i>
			<span>{{'generic.share' | translate}}</span>
		</button>
	</div>
</div> 
<div *ngIf="selectedPlateform=='instagram'" class="w-[70%] h-[85%] mx-auto mt-4 bg-white rounded-2xl overflow-hidden">
	<div class="bg-[#465086] text-[16px] text-white text-center py-2 overflow-hidden text-ellipsis whitespace-nowrap max-w-full" [title]="data?.title != 'N/A' ? data?.title : ('generic.noTitle' | translate)">
		<span *ngIf="data?.title != 'N/A'">{{data?.title}}</span>
		<span *ngIf="data?.title == 'N/A'">{{'generic.noTitle' | translate}}</span>
	</div>
	<div class="p-4">
		<div class="flex relative items-center space-x-4">
			<img class="w-10 h-[5%] rounded-full" src="https://placehold.co/40" alt="Profile">
			<div class="text-sm  font-semibold">{{instagramData?.name}}<span class="text-xs  text-gray-500 mr-2 "> • {{'Il y a quelques secondes' +" •"}}</span></div> 
			<div class="ml-auto absolute right-0 text-gray-400">
				<div class="join">
					<svg viewBox="0 0 20 20" width="20" height="20" fill="currentColor" class=" mt-[3px] x19dipnz x1lliihq x1tzjh5l x1k90msu x2h7rmj x1qfuztq" style="--color:var(--secondary-icon)"><g fill-rule="evenodd" transform="translate(-446 -350)"><path d="M458 360a2 2 0 1 1-4 0 2 2 0 0 1 4 0m6 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0m-12 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0"></path></g></svg>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="instagramMedias.length>0">
		<div class="flex w-[95%] mx-auto bg-black">
			<img class=" max-h-[330px] mx-auto" src="{{instagramMedias[0].url}}" alt="Post Image">
		</div>
	</div>
	<div class="border-t px-2 my-2  h-[25px] text-[10px] text-gray-900">
		<button class="hover:bg-gray-100 rounded-lg px-2">
			<svg aria-label="J’aime" class="x1lliihq x1n2onr6 xyb1xck" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><path d="M16.792 3.904A4.989 4.989 0 0 1 21.5 9.122c0 3.072-2.652 4.959-5.197 7.222-2.512 2.243-3.865 3.469-4.303 3.752-.477-.309-2.143-1.823-4.303-3.752C5.141 14.072 2.5 12.167 2.5 9.122a4.989 4.989 0 0 1 4.708-5.218 4.21 4.21 0 0 1 3.675 1.941c.84 1.175.98 1.763 1.12 1.763s.278-.588 1.11-1.766a4.17 4.17 0 0 1 3.679-1.938m0-2a6.04 6.04 0 0 0-4.797 2.127 6.052 6.052 0 0 0-4.787-2.127A6.985 6.985 0 0 0 .5 9.122c0 3.61 2.55 5.827 5.015 7.97.283.246.569.494.853.747l1.027.918a44.998 44.998 0 0 0 3.518 3.018 2 2 0 0 0 2.174 0 45.263 45.263 0 0 0 3.626-3.115l.922-.824c.293-.26.59-.519.885-.774 2.334-2.025 4.98-4.32 4.98-7.94a6.985 6.985 0 0 0-6.708-7.218Z"></path></svg>
		</button>
		<button class="hover:bg-gray-100 rounded-lg px-2">
			<svg aria-label="Commenter" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><path d="M20.656 17.008a9.993 9.993 0 1 0-3.59 3.615L22 22Z" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2"></path></svg>
		</button>
		<button class="hover:bg-gray-100 rounded-lg px-2">
			<svg aria-label="Partager" class="x1lliihq x1n2onr6 xyb1xck" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><line fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2" x1="22" x2="9.218" y1="3" y2="10.083"></line><polygon fill="none" points="11.698 20.334 22 3.001 2 3.001 9.218 10.084 11.698 20.334" stroke="currentColor" stroke-linejoin="round" stroke-width="2"></polygon></svg>
		</button>
		<button class="absolute right-[15%] space-x-1 hover:bg-gray-100 rounded-lg px-2">
			<svg aria-label="Enregistrer" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><polygon fill="none" points="20 21 12 13.44 4 21 4 3 20 3 20 21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></polygon></svg>
		</button>
	</div>
	<div class="text-sm ml-4 my-6 font-bold">{{instagramData?.name}}<span class="text-xs font-sans text-gray-500 mr-2 "> {{data?.text}} </span></div> 
</div>

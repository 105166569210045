<div class="bg-white rounded-lg m-4 p-4 ">
	<h1 class="mb-5">{{"socialMedia.pagesRecap"|translate}} ({{ socialMediaCount }} {{"socialMedia.connectedPages"|translate}})</h1>
	<table class="border-separate border-spacing-0 m-auto w-full text-xs">
		<tbody>
			<tr> <!-- Social Media Sources Logos -->
				<th scope="col" class="w-1/5 m-1 p-1 rounded-md"></th> <!-- col vide-->
				<th *ngFor="let media of SOCIAL_MEDIA_PLATFORMS" scope="col" class="w-1/5">
					<div class="h-10 m-1 rounded-md {{media.bg_class}}" [ngClass]="{'p-3': media.name == 'Facebook', 'p-2': media.name != 'Facebook'}">
						<img [src]="media.logoPath" alt="{{media.name}} Logo" class="object-contain max-w-full max-h-full m-auto">
					</div>
				</th>
			</tr>
		</tbody>
		<tbody>
			<ng-container *ngFor="let group of groupsWithSocialMedias"><!-- Group line -->
				<tr>
					<th class="border-2 border-gray-600 rounded-l-md p-1 w-1/5">{{ group.group.name }}</th>
					<td *ngFor="let media of SOCIAL_MEDIA_PLATFORMS; let isLast = last" class="border-r-2 border-b-2 border-t-2 border-gray-600 p-1 w-1/5" [class.rounded-r-md]="isLast"> <!-- Social Media cell-->
						<ng-container *ngIf="(group.socialMedias | filterBySource: media.source).length; else noSocialMedias">
							<div *ngFor="let sm of group.socialMedias | filterBySource: media.source" class="p-1 flex gap-x-1 items-center"> <!-- 1 Social Media div-->
								<span class="border {{media.border_class}} {{media.text_class}} rounded-md p-1 grow text-center" >
									<!-- <span [ngClass]="[media.border_class, 'rounded-md', 'p-1', 'grow', 'text-center']"> -->
										{{sm.name}}
									</span> 
									<a [href]="sm.url" target="_blank" class="w-6 h-6 bg-cliking_blue rounded-md p-0.5"> 
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="  text-white ">
											<path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />
										</svg>
									</a>
									<a class="w-6 h-6 bg-cliking_red rounded-md p-0.5 hover:cursor-pointer" (click)="openModal(sm)"> 
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="  text-white ">
											<path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
										</svg>
									</a>
								</div>
							</ng-container>
							<ng-template #noSocialMedias> <!-- div if no Social Media for this Source -->
								<div class="p-1 flex gap-x-1 items-center">
									<span class="border border-gray-400 text-gray-400 rounded-md p-1 grow text-center">
										{{'socialMedia.noSocialMedia'|translate}}
									</span> 
								</div>
						</ng-template>
					</td>
				</tr>
				<tr class="h-2"></tr>
			</ng-container>
		</tbody>
	</table>
</div>
<app-modal 
	[isOpen]="isModalOpen"
	[Heading]="'generic.confirmDeletion'"
	[subHeading]="'generic.confirmUserDeletionMsg'" 
	(closeModal)="close()" 
	[icon]="modalIcon"
	(deleteItem)="delete()">
</app-modal>
import { environment } from 'src/environments/environment';

export class Debug {
  private static styleString =
    'color:orange;background-color:black; padding:5px';

  private static iconStyle = 'font-size:25px;';

  private static currentIcon = '🕵';

  private static isDevMode() {
    return environment.env!='production';
  }

  private static displayMsg(...args: any) {
    // console.log(args.length)

    if (Debug.isDevMode()) {
      console.groupCollapsed(
        '%c' + Debug.currentIcon + '%c' + JSON.stringify(args[0]).substring(0,200)+"...",
        Debug.iconStyle,
        Debug.styleString
      );

      Debug.outPut(args);

      console.groupEnd();
    }
  }

  static log(...args: any) {
    // console.log(args.length)

    Debug.setLabel('log');

    Debug.displayMsg(args);
  }

  static info(...args: any) {
    // console.log(args.length)

    Debug.setLabel('info');

    Debug.displayMsg(args);
  }

  static warn(...args: any) {
    // console.log(args.length)

    Debug.setLabel('warn');

    Debug.displayMsg(args);
  }

  static fail(...args: any) {
    // console.log(args.length)

    Debug.setLabel('error');

    Debug.displayMsg(args);
  }

  static success(...args: any) {
    // console.log(args.length)

    Debug.setLabel('success');

    Debug.displayMsg(args);
  }

  private static outPut(...args: any) {
    for (let value of args) {
      if (typeof value == 'string') {
        console.log('%c' + value, Debug.styleString);
      } else {
        console.groupCollapsed('Collapsed Trace');

        console.trace();

        console.groupEnd();

        console.log(
          '%c' +
            JSON.stringify(
              {  type: Array.isArray(value) ? 'array' : typeof value ,value},
              null,
              1
            ),
          Debug.styleString
        );
      }
    }
  }

  private static setLabel(label: string) {
    let style = '';

    switch (label) {
      case 'log':
        style = 'color:yellow;';
        // style = 'color:white;';

        Debug.currentIcon = '🕵';

        break;

      case 'info':
        style = 'color:#79bbd9;';

        Debug.currentIcon = 'ℹ';

        break;

      case 'warn':
        style = 'color:orange;';

        Debug.currentIcon = '⚠️';

        break;

      case 'error':
        style = 'color:red;';

        Debug.currentIcon = '❌';

        break;

      case 'success':
        style = 'color:#77f894;';

        Debug.currentIcon = '✅';

        break;
    }

    style += 'padding:5px;background-color:black;';

    Debug.styleString = style;
  }
}

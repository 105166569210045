import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Debug } from 'src/app/utils/debug';

@Component({
  selector: 'app-permissions-editor',
  templateUrl: './permissions-editor.component.html',
  styleUrls: ['./permissions-editor.component.scss'],
})
export class PermissionsEditorComponent {
  @Input() AppPermissions: any;
  @Input() overrideUserPermissions: boolean = false;
  @Input() Profile!: { name: string; permissions: any[] };

  @Output() onToggleSwitched = new EventEmitter();

  isAllSelected!: boolean;
  ngOnInit() {
    // Debug.log("permissions root group",this.getRoutes())
  }

  getRoutes() {
    const routes: string[] = [];
    this.AppPermissions.forEach((permission: any) => {
      routes.push(permission.action.split('.')[0]);
    });
    // Debug.log("routes:",[...new Set(routes)])
    return [...new Set(routes)];
  }
  getMethods(route: string) {
    const methods: string[] = [];
    this.AppPermissions.forEach((permission: any) => {
      const details = permission.action.split('.');
      if (details[0] == route) methods.push(details[1]);
    });
    // Debug.log("methods",[...new Set(methods)])
    return [...new Set(methods)];
  }
  getActions(route: string, method: string) {
    const actions: any[] = [];
    this.AppPermissions.forEach((permission: any) => {
      const details = permission.action.split('.');
      if (details[0] == route && details[1] == method) {
        details[2] =
          details[2] == details[0] && details[1] == 'get'
            ? details[2] + 's'
            : details[2]; // add s next to actions label for get actions
        actions.push({
          id: permission.id,
          label: details[2]
            .replaceAll('-', ' ')
            .replaceAll('/', ' ')
            .replaceAll(':', '->'),
          action: permission.action,
        });
      }
    });
    // Debug.log("actions",[...new Set(actions)])
    return [...new Set(actions)];
  }

  permissionToggle(permission: any) {
    if (!this.isPemissionInProfile(permission.action)) {
      Debug.log('adding the permission', permission);
      this.Profile.permissions.push({
        id: permission.id,
        action: permission.action,
      });
    } else {
      Debug.log('removing permission', permission);
      this.Profile.permissions = this.Profile.permissions.filter(
        (p: any) => p.action !== permission.action
      );
    }

    this.Profile.permissions = this.Profile.permissions;
    this.onToggleSwitched.emit(this.Profile.permissions);
  }

  isPemissionInProfile(permission: string) {
    return (
      this.Profile.permissions.filter((p: any) => p.action === permission)
        .length > 0
    );
  }

  // toggleAll(){
  //   this.isAllSelected=!this.isAllSelected
  //   for(let pg of this.AppPermissions){
  //     pg.value=this.isAllSelected
  //     for(let p of pg.permissions){
  //       p.value = this.isAllSelected
  //     }
  //   }
  //   Debug.log(this.AppPermissions)
  //   this.onToggleSwitched.emit(this.AppPermissions)
  // }
}

<div class="mx-auto mt-10 max-w-8xl text-3xl pb-5 overflow-auto h-[85vh]">
  <div
    class="text-sm font-medium text-center text-gray-500 dark:text-gray-400 dark:border-gray-700 p-5 rounded-md m-5"
  >
    <div role="tablist" class="tabs tabs-lifted">
      <!-- users -->
      <input
        type="radio"
        name="my_tabs_2"
        role="tab"
        class="tab [--tab-border-color:#5d78ff]"
        aria-label="Users"
        checked
      />
      <div
        role="tabpanel"
        class="tab-content bg-base-100 border-cliking_blue rounded-box p-6"
      >
        <app-users-manager />
      </div>

      <!-- groups -->
      <input
        type="radio"
        name="my_tabs_2"
        role="tab"
        class="tab [--tab-border-color:#5d78ff]"
        aria-label="Groups"
      />
      <div
        role="tabpanel"
        class="tab-content bg-base-100 border-cliking_blue rounded-box p-6"
      >
        <app-group-manager />
      </div>

      <!-- profiles -->
      <input
        type="radio"
        name="my_tabs_2"
        role="tab"
        class="tab [--tab-border-color:#5d78ff] border-cliking_blue"
        aria-label="Profiles"
      />
      <div
        role="tabpanel"
        class="tab-content bg-base-100 border-cliking_blue rounded-box p-6"
      >
        <form class="mx-5 mt-5" action="" method="post">
          <label class="input input-bordered flex items-center gap-2">
            <input
              (keyup)="checkifprofileExists($event)"
              type="text"
              class="grow"
              name="name"
              placeholder="Enter profile and press '+' icon"
              [(ngModel)]="tmpProfile"
            />
            <span *ngIf="alreadyExists" class="text-red-600"
              >already exist</span
            >
            <span *ngIf="tmpProfile.trim().length < 3" class="text-orange-400"
              >Profile length need to 3 or more
            </span>
            <svg
              *ngIf="!alreadyExists && tmpProfile.trim().length >= 3"
              (click)="CreateProfile()"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              fill="currentColor"
              class="cursor-pointer w-4 h-4 opacity-70"
            >
              <path
                d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
              />
            </svg>
          </label>
        </form>

        <app-table
          *ngIf="Profiles.data?.length > 0"
          [skipColumns]="['permissions']"
          [entity]="'Profile'"
          [res]="Profiles"
          [isWriteEnabled]="true"
          (editItem)="editProfile($event)"
          (deleteItem)="deleteProfile($event)"
        >
        </app-table>
      </div>

      <!-- ProfilePermissions -->
      <input
        type="radio"
        name="my_tabs_2"
        role="tab"
        class="tab [--tab-border-color:#5d78ff]"
        aria-label="Permissions"
      />

      <div
        role="tabpanel"
        class="tab-content bg-base-100 border-cliking_blue rounded-box p-6"
      >
        <div class="join">
          <select
            (change)="updateSelectedProfile($event)"
            class="select select-bordered w-full max-w-xs"
          >
            <option disabled selected>select a profile</option>
            <option *ngFor="let profile of Profiles.data" [value]="profile.id">
              {{ profile.name }}
            </option>
          </select>
          <button
            [disabled]="!isPermissionsFormDirty"
            class="mx-5 btn btn-accent text-white"
            (click)="savePermissions()"
          >
            Save
          </button>
        </div>
        <div *ngIf="selectedProfile?.name">
          <h3 class="text-xs mt-5 ml-2 text-left">
            {{ selectedProfile?.name }} Actions :
            <span class="text-cliking_green"
              >{{ selectedProfile.permissions.length }} Granted</span
            >
            /
            <span class="text-cliking_red_nuance"
              >{{
                AppPermissions.length - selectedProfile.permissions.length
              }}
              Denied</span
            >
            of {{ AppPermissions.length }} Total
          </h3>
          <app-permissions-editor
            [Profile]="selectedProfile"
            [AppPermissions]="AppPermissions"
            (onToggleSwitched)="UpdatePermissions($event)"
          />
        </div>
      </div>

      <!-- permissionsMapper -->
      <input
        type="radio"
        name="my_tabs_2"
        role="tab"
        class="tab [--tab-border-color:#5d78ff]"
        aria-label="PermissionsMapper"
      />

      <div
        role="tabpanel"
        class="tab-content bg-base-100 border-cliking_blue rounded-box p-6"
      >
        <div class="join">
          <app-ui-permissions-mapper
            [appProfiles]="Profiles.data"
            [systemPermissions]="AppPermissions"
          />
        </div>
      </div>

      <!-- accreditations -->
      <input
        type="radio"
        name="my_tabs_2"
        role="tab"
        class="tab [--tab-border-color:#5d78ff]"
        aria-label="Accreditations"
      />
      <div
        role="tabpanel"
        class="tab-content bg-base-100 border-cliking_blue rounded-box p-6"
      >
        <div class="join">
          <button
            class="mx-5 btn bg-cliking_blue hover:bg-cliking_green text-white"
            (click)="isAccreditationInputsVisible = true"
          >
            New
          </button>
          <button class="btn" (click)="showAccreditations()">
            Saved Accreditations
            <div class="badge badge-secondary">
              {{ AppAccreditations.length }}
            </div>
          </button>
        </div>
        <div *ngIf="isAccreditationInputsVisible" class="join">
          <select
            name="selectedUser"
            (change)="updateSelectedUser($event)"
            class="select select-bordered w-full max-w-xs mx-2"
          >
            <option disabled selected>select a user</option>
            <option *ngFor="let user of users.data" [value]="user.id">
              {{ user.firstName + " " + user.lastName }}
            </option>
          </select>
          <select
            name="selectedProfile"
            (change)="updateSelectedProfile($event)"
            class="select select-bordered w-full max-w-xs mx-2"
          >
            <option disabled selected>select a profile</option>
            <option *ngFor="let profile of Profiles.data" [value]="profile.id">
              {{ profile.name }}
            </option>
          </select>
          <select
            name="selectedGroup"
            (change)="updateSelectedGroup($event)"
            class="select select-bordered w-full max-w-xs mx-2"
          >
            <option disabled selected>select a group</option>
            <option value="0">All groups</option>
            <option *ngFor="let group of groups.data" [value]="group.id">
              {{ group.name }}
            </option>
          </select>
          <button
            [disabled]="!selectedUser || !selectedProfile || !selectedGroup"
            class="mx-5 btn btn-accent text-white"
            (click)="saveAccreditations()"
          >
            Save
          </button>
        </div>

        <div
          *ngIf="
            selectedUser &&
            selectedProfile &&
            selectedGroup &&
            overrideUserPermissions
          "
        ></div>
        <app-table
          [skipEdit]="true"
          [res]="accreditationsDisplay"
          [isWriteEnabled]="true"
          (deleteItem)="deleteAccreditation($event.item)"
        />
      </div>
      <!-- bulljs tab button -->
      <input
        type="radio"
        name="my_tabs_2"
        role="tab"
        class="tab [--tab-border-color:#5d78ff]"
        aria-label="Jobs"
        (click)="fetchJobs()"
      />
      <!-- bulljs -->
      <div
        role="tabpanel"
        class="tab-content bg-base-100 border-cliking_blue rounded-box p-6 w-max-content"
      >
        <!-- load fetch background jobs as accordian -->

        <div class="join join-vertical overflow-scroll w-full">
          <div
            class="collapse collapse-arrow join-item border-base-300 border text-left"
            *ngFor="let job of jobs; let index = index"
          >
            <input type="checkbox" name="my-accordion-4" checked />
            <div class="collapse-title text-xl font-medium">
              {{ job.label }}
              <span class="badge-primary text-xs px-2 rounded-lg"
                >[{{ job.name }}]
                <span class="text-yellow-400"
                  >last 15 on all categories (and all failed jobs)</span
                >
              </span>
            </div>
            <div class="collapse-content">
              <div
                role="tablist"
                class="tabs tabs-bordered w-full overflow-scroll"
              >
                <!-- ACTIVE Jobs -->
                <input
                  type="radio"
                  [name]="'job_categories_' + index"
                  role="tab"
                  class="tab"
                  [attr.aria-label]="'ACTIVE (' + job['ACTIVE'].count + ')'"
                  checked="checked"
                />
                <div
                  role="tabpanel"
                  class="tab-content p-10 w-max-content overflow-x-auto"
                >
                  <button
                    *ngIf="job['ACTIVE'].count > 0"
                    class="btn btn-error mt-2 mb-2"
                    (click)="clearJobs(job.name, 'ACTIVE')"
                  >
                    Clear Active Jobs
                  </button>
                  <ngx-json-viewer
                    class="break-words overflow-x-auto whitespace-pre-wrap"
                    *ngIf="job['ACTIVE'].count > 0"
                    [json]="job['ACTIVE'].jobs"
                  ></ngx-json-viewer>
                </div>

                <!-- WAITING Jobs -->
                <input
                  type="radio"
                  [name]="'job_categories_' + index"
                  role="tab"
                  class="tab"
                  [attr.aria-label]="'WAITING (' + job['WAITING'].count + ')'"
                />
                <div
                  role="tabpanel"
                  class="tab-content p-10 w-max-content overflow-x-auto"
                >
                  <button
                    *ngIf="job['WAITING'].count > 0"
                    class="btn btn-error mt-2 mb-2"
                    (click)="clearJobs(job.name, 'WAITING')"
                  >
                    Clear Waiting Jobs
                  </button>
                  <ngx-json-viewer
                    class="break-words overflow-x-auto whitespace-pre-wrap"
                    *ngIf="job['WAITING'].count > 0"
                    [json]="job['WAITING']?.jobs"
                  ></ngx-json-viewer>
                </div>

                <!-- COMPLETED Jobs -->
                <input
                  type="radio"
                  [name]="'job_categories_' + index"
                  role="tab"
                  class="tab"
                  [attr.aria-label]="
                    'COMPLETED (' + job['COMPLETED'].count + ')'
                  "
                />
                <div
                  role="tabpanel"
                  class="tab-content p-10 w-max-content overflow-x-auto"
                >
                  <button
                    *ngIf="job['COMPLETED'].count > 0"
                    class="btn btn-error mt-2 mb-2"
                    (click)="clearJobs(job.name, 'COMPLETED')"
                  >
                    Clear Completed Jobs
                  </button>
                  <ngx-json-viewer
                    class="break-words overflow-x-auto whitespace-pre-wrap"
                    *ngIf="job['COMPLETED'].count > 0"
                    [json]="job['COMPLETED']?.jobs"
                  ></ngx-json-viewer>
                </div>

                <!-- FAILED Jobs -->
                <input
                  type="radio"
                  [name]="'job_categories_' + index"
                  role="tab"
                  class="tab"
                  [attr.aria-label]="'FAILED (' + job['FAILED'].count + ')'"
                />
                <div
                  role="tabpanel"
                  class="tab-content p-10 w-max-content overflow-x-auto"
                >
                  <button
                    *ngIf="job['FAILED'].count > 0"
                    class="btn btn-error mt-2 mb-2"
                    (click)="clearJobs(job.name, 'FAILED')"
                  >
                    Clear Failed Jobs
                  </button>
                  <ngx-json-viewer
                    class="break-words overflow-x-auto whitespace-pre-wrap"
                    *ngIf="job['FAILED'].count > 0"
                    [json]="job['FAILED']?.jobs"
                  ></ngx-json-viewer>
                </div>
                <!-- DELAYED Jobs -->
                <input
                  type="radio"
                  [name]="'job_categories_' + index"
                  role="tab"
                  class="tab"
                  [attr.aria-label]="'DELAYED (' + job['DELAYED'].count + ')'"
                />
                <div
                  role="tabpanel"
                  class="tab-content p-10 w-max-content overflow-x-auto"
                >
                  <button
                    *ngIf="job['DELAYED'].count > 0"
                    class="btn btn-error mt-2 mb-2"
                    (click)="clearJobs(job.name, 'DELAYED')"
                  >
                    Clear Delayed Jobs
                  </button>
                  <ngx-json-viewer
                    class="break-words overflow-x-auto whitespace-pre-wrap"
                    *ngIf="job['DELAYED'].count > 0"
                    [json]="job['DELAYED']?.jobs"
                  ></ngx-json-viewer>
                </div>

                <!-- PAUSED Jobs -->
                <!-- <input
                  type="radio"
                  [name]="'job_categories_' + index"
                  role="tab"
                  class="tab"
                  [attr.aria-label]="'PAUSED (' + job['PAUSED']?.count + ')'"
                />
                <div role="tabpanel" class="tab-content p-10 w-max-content  overflow-x-auto">
                  <button *ngIf="job['PAUSED'].count > 0" class="btn btn-error mt-2 mb-2" (click)="clearJobs(job.name, 'PAUSED')">
                    Clear Paused Jobs
                  </button>
                  <ngx-json-viewer class="break-words overflow-x-auto whitespace-pre-wrap" *ngIf="job['PAUSED'].count > 0" [json]="job['PAUSED']?.jobs"></ngx-json-viewer>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-modal
  [isOpen]="isModalOpen"
  [Heading]="head"
  [subHeading]="subHead"
  [icon]="modalIcon"
  (closeModal)="close()"
>
</app-modal>

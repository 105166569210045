import {
  Component,
  ElementRef,
  HostListener,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { BaseComponent } from '../../base/base.component';
import { CalendarService } from 'src/app/services/utilities/calendar.service';
import { CALENDERMODE } from 'src/app/interfaces/CalendarMode';
import { Debug } from 'src/app/utils/debug';

@Component({
  selector: 'app-generic-drop-down',
  templateUrl: './generic-drop-down.component.html',
  styleUrls: ['./generic-drop-down.component.scss'],
})
export class GenericDropDownComponent extends BaseComponent {
  windowWidth: number = window.innerWidth;
  isSmallView: boolean = false;
  @Input() data: any;
  @Input() min!: number;
  @Input() max!: number;
  @Input() step: number = 1;
  @Output() itemSelected = new EventEmitter<any>();
  calendarViewSelected!: CALENDERMODE;
  isDropdownOpen: boolean = false;
  selected: any;

  constructor(
    private eRef: ElementRef,
    protected override translationService: TranslationService,
    private calendarService: CalendarService
  ) {
    super(translationService);
    this.checkWindowSize();
  }

  ngOnInit(): void {
    if (this.min != null && this.max != null) {
      this.data = [];
      for (let i = this.min; i <= this.max; i += this.step) {
        this.data.push(i);
      }
    }

    this.calendarService
      .getCurrentCalendarMode()
      .subscribe((mode: CALENDERMODE) => {
        this.selected = mode;
      });
  }

  onItemSelect(item: any, event: MouseEvent) {
    event.stopPropagation();
    Debug.log('tesst', item);
    if (this.selected !== item) {
      this.itemSelected.emit(item);
    }
    this.selected = item;
    this.isDropdownOpen = false;
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (
      !this.eRef.nativeElement.contains(event.target) &&
      this.isDropdownOpen
    ) {
      this.isDropdownOpen = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowWidth = window.innerWidth;
    if (this.windowWidth < 1160 && this.selected.includes('generic.by')) {
      this.selected = this.selected
        .toLowerCase()
        .replace('generic.by', 'date.');
      Debug.log('😍', this.selected);
    } else if (
      this.windowWidth > 1160 &&
      !this.selected.includes('generic.by')
    ) {
      // Small view (windowWidth <= 1160)
      this.selected =
        'generic.by' +
        this.selected.replace('date.', '').charAt(0).toUpperCase() +
        this.selected.replace('date.', '').slice(1);
    }
  }

  private checkWindowSize() {
    this.windowWidth = window.innerWidth;
  }
}

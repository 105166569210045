import { Component, ElementRef, Renderer2, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ScreenSizeService } from 'src/app/services/utilities/screen-size.service';
import { environment } from 'src/environments/environment';
import { TranslationService } from 'src/app/services/utilities/translation.service';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent {
  selectedLanguage: any = { code: 'fr', label: 'Français' }; // Set a default language
  isDropdownOpen: boolean = false;

  @Input() windowWidth: any;
  @Input() isMobileView: any;

  languages = [
    { code: 'fr', label: 'Français' },
    { code: 'en', label: 'English' }
    // Add other languages as needed
  ];
  env = environment;
  screenWidth: number = 0;
  screenHeight: number = 0;

  constructor(private eRef: ElementRef, private renderer: Renderer2,private translate: TranslateService, private screenSizeService: ScreenSizeService, private translationService: TranslationService) {}

  ngOnInit(){
    this.screenSizeService.screenWidth$.subscribe((width: number) => this.screenWidth = width);
    this.screenSizeService.screenHeight$.subscribe((height: number) => this.screenHeight = height);
    this.selectLanguage(this.translationService.getLanguage())
  }
  
  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;

    // If the dropdown is open, add a click listener to the document
    if (this.isDropdownOpen) {
      this.renderer.listen('document', 'click', (event) => {
        if (!this.eRef.nativeElement.contains(event.target)) {
          this.isDropdownOpen = false;
        }
      });
    }
  }

  selectLanguage(languageCode: string) {
    const language = this.languages.find(l => l.code === languageCode);
    if (language) {
      this.selectedLanguage = language;
      this.translate.use(language.code); // Switch the language
    }
    if(this.isDropdownOpen)
      this.toggleDropdown();
  }

}

<div class="relative "  style="overflow-y: scroll;">
    <!-- <button *ngIf="list.length>3" class="bg-cliking_blue h-6 w-[95%] mx-auto rounded-lg mt-3 flex items-center justify-between relative" (click)="listToggle()">
      <div class="text-[15px] ml-3 text-white">
        {{ list.length + " " + (type | translate)}}
      </div>
      <div class="w-4 mr-4 text-white">
        <svg width="20px" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 520 512">
          <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/>
        </svg>
      </div>
    </button> -->
    <table class="ml-8 mt-2" >
      <tr *ngFor="let item of list">
        <td class="text-right"> <!-- Ajout de la classe text-right -->
          <span style="display: flex; align-items: center; justify-content: space-between; width: 100%;" class="hover:bg-indigo-50 px-2 hover:rounded-2xl">
            <span style="display: flex; align-items: center;" [style.width.px]="screenWidth*30/100">
              <img *ngIf="item.source=='linkedin'" width="25px" src="assets/img/linkedin.svg" alt="linkedin logo">
              <img *ngIf="item.source=='google'" width="25px" src="assets/img/google.svg" alt="google logo">
              <img *ngIf="item.source=='facebook'" width="25px" src="assets/img/facebook.svg" alt="facebook logo">
              <img *ngIf="item.source=='instagram'" width="25px" src="assets/img/instagram.svg" alt="instagram logo">
              <span class="ml-3 text-sm">{{ item.name }}</span>
            </span>
            <a *ngIf="type.includes('selectedPage')" href="{{item.url}}" target="_blank"  class="text-[#1E1E1E] rounded-2xl bg-[#FCFCFF] hover:bg-[#BEC9FF] join">
              <span class="px-2 text-sm">{{ 'generic.open' | translate }}</span>
              <svg width="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <path d="M21 9.00001L21 3.00001M21 3.00001H15M21 3.00001L12 12M10 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V14" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </g>
              </svg>
            </a>
          </span>
        </td>
      </tr>
    </table>
    
  
    <!-- <ul *ngIf="listOpen || list.length<4" class="bg-white rounded-2xl ml-[2.5%] w-[95%] z-10 absolute" [ngClass]="{'shadow-lg':listOpen || list.length<4}">
      <li *ngFor="let item of list" class="bg-[#ffffff] hover:bg-[#EFF2FF] rounded-2xl h-8">
        <div class="text-sm text-[#544E61] ml-2 font my-auto">
          <span class="join my-auto">
            <img *ngIf="item.source=='linkedin'" width="22px" class=" mt-[5%]" src="assets/img/linkedin.svg" alt="">
            <img *ngIf="item.source=='google'" width="20px" class=" mt-[5%]" src="assets/img/google.svg" alt="">
            <img *ngIf="item.source=='facebook'" width="20px" class=" mt-[5%]" src="assets/img/facebook.svg" alt="">
            <img *ngIf="item.source=='instagram'" width="20px" class=" mt-[5%]" src="assets/img/instagram.svg" alt="">
              <span class="ml-3" [ngClass]="{'mt-[10%]':!type.includes('selectedPage'), 'mt-[5%]':type.includes('selectedPage')}">{{ item.name }} </span>
          </span> 
          <a *ngIf="type.includes('selectedPage')" href="{{item.url}}" target="_blank" class="absolute right-[10%] text-[#1E1E1E] mt-1 px-2 rounded-2xl bg-[#FCFCFF] hover:bg-[#BEC9FF] join">
            <span class="px-2"> {{'generic.open' | translate}} </span>
            <svg width="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M21 9.00001L21 3.00001M21 3.00001H15M21 3.00001L12 12M10 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V14" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
          </a>
        </div>
      </li>
    </ul> -->
</div>


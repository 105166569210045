import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SocialMediaService } from '../../../../services/social-medias/social-media.service';
import { Router } from '@angular/router';
import { IResponse } from 'src/app/interfaces/IResponse';
import { Subscription } from 'rxjs';
import { GroupService } from '../../../../services/repositories/group.service';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { AuthService } from 'src/app/services/utilities/auth.service';
import { PostService } from 'src/app/services/repositories/post.service';
import { Debug } from 'src/app/utils/debug';
import { SocialMediaAPIService } from 'src/app/services/utilities/socialMediaApi.service';
import { ICON } from 'src/app/interfaces/ModalIcon';

@Component({
  selector: 'app-choose-page',
  templateUrl: './choose-page.component.html',
  styleUrls: ['./choose-page.component.scss'],
})
export class ChoosePageComponent implements OnInit {
  isModalOpen: boolean = false;
  modalIcon: ICON = ICON.warning;
  isDropdownOpen: boolean = false;
  selectedItemId: string | null = null;
  type: string | null = null;
  socialMediaId: string = '';
  pages: any[] = [];
  selectedPageId: string = '';
  UserAccreditations!: any;
  isUserSuperAdmin!: boolean;
  data: any = {};
  currentGroup: any = null;
  groupsFlatList: any = [];
  groups: IResponse = {
    data: [],
    navigation: {
      page: 0,
      totalPages: 0,
      totalRows: 0,
      itemsPerPage: 0,
    },
    success: false,
    error: '',
    status: 0,
  };

  private groupsSubscription: Subscription | undefined;

  constructor(
    public postService: PostService,
    private route: ActivatedRoute,
    private router: Router,
    private socialMediaService: SocialMediaService,
    private groupService: GroupService,
    private authService: AuthService,
    private eRef: ElementRef,
    private renderer: Renderer2,
    private translationService: TranslationService,
    private socialMediaAPI: SocialMediaAPIService
  ) {}

  ngOnInit() {
    this.socialMediaId = this.route.snapshot.paramMap.get('id') || '';
    this.fetchPages();

    this.authService.loadCurrentUser().subscribe((user: any) => {
			console.log("user (choosepage)", user)
      this.UserAccreditations = user.data.accreditations;

      this.isUserSuperAdmin = user.data.isAdmin;
      this.data = this.getAccreditedGroups();
      if (!this.isUserSuperAdmin) {
        this.postService.updateGroup(this.UserAccreditations[0].group);
        this.groupsFlatList = this.getAccreditedGroups();
        if (this.groupsFlatList.length == 1) {
          this.currentGroup = this.groupsFlatList[0].group;
        }
        console.log('groupsFlatList', this.groupsFlatList);
        
      } else {
        this.groupService.getGroups().subscribe((groups: any) => {
          this.UserAccreditations = [];
          for (let group of groups.data) {
            this.UserAccreditations.push({ group });
          }
          if (this.groupsFlatList.length == 1) {
            this.currentGroup = this.groupsFlatList[0].group;
          }

          this.groupsFlatList = this.UserAccreditations;
        });
      }
      // Listen for changes in currentGroups for superadmin as well
				this.postService.currentGroups.subscribe((groupIds: any) => {
					this.groupService.getGroupByIds(groupIds).subscribe((res: any) => {
						let flatGroupList = res.data;
						this.groupsFlatList = flatGroupList.map((g: any) => {
							return {
								group: g,
							};
						});
					});
				});
    });
  }

  getAccreditedGroups() {
    if (this.isUserSuperAdmin) {
      return [];
    }
    const accg: any = [];
    for (let accreditation of this.UserAccreditations) {
      function recurseGroups(group: any, profile: any) {
        // Push the current group and profile to the result
        accg.push({ group: group, profile: profile });

        // Recursively process each child group if they exist
        if (group.children && group.children.length > 0) {
          for (let child of group.children) {
            recurseGroups(child, profile);
          }
        }
      }

      if (accreditation.applyToSubGroups) {
        recurseGroups(accreditation.group, accreditation.profile);
      } else {
        accg.push({
          group: accreditation.group,
          profile: accreditation.profile,
        });
      }
    }

    return accg;
  }

  getTranslation(key: string): string {
    return this.translationService.t(key);
  }

  fetchPages() {
    Debug.log('fetchPages');
    this.socialMediaService
      .getPagesFromSocialMedia(this.socialMediaId)
      .subscribe(
        (data) => {
          Debug.log('DATA.pages: ', data);
          this.pages = data.data; // Assign the data to your component's property here
          if (this.pages) {
            const patterns = ['google', 'facebook', 'linkedin', 'instagram'];
            for (const pattern in patterns) {
              if (this.pages[0].url.includes(patterns[pattern])) {
                this.type = patterns[pattern];
                break;
              }
            }
          }
        },
        (error) => {
          console.error('Error fetching pages:', error);
          // Handle the error appropriately
        }
      );
  }

  onSubmit() {
    if (!this.currentGroup) {
      this.isModalOpen = true;
      return;
    }

    this.socialMediaService
      .updateSelectedPage(
        this.socialMediaId,
        this.selectedPageId,
        this.currentGroup.id
      )
      .subscribe(
        (response) => {
          // Handle the response
          Debug.log('Social Media enregistré !');
          this.socialMediaAPI
            .fetchPagePosts(this.socialMediaId)
            .subscribe((res: any) => {});
          this.router.navigate(['/social-medias']);
        },
        (error) => {
          // Handle the error
          console.error('Error saving page:', error);
        }
      );
  }

  // updateCurrentSubGroup(data:any){
  //   Debug.log("updateCurrentSubGroup",data);
  //   this.currentGroup=data
  // }

  // updateCurrentGroup(data:any){
  //   Debug.log("Current group object:", this.currentGroup);
  // }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
    // If the dropdown is open, add a click listener to the document

    if (this.isDropdownOpen) {
      this.renderer.listen('document', 'click', (event) => {
        if (!this.eRef.nativeElement.contains(event.target)) {
          this.isDropdownOpen = false;
        }
      });
    }
  }

  selectGroup(group: any) {
    this.currentGroup = group;
    this.toggleDropdown();
  }

  selectItem(page: any) {
    this.selectedItemId = page.id; // Supposons que page a une propriété id pour identifier l'élément sélectionné
    // this.toggleDropdown(); // Fermez le dropdown après la sélection si nécessaire
  }
  // Add methods to handle user's page selection

  //close modal
  close() {
    this.isModalOpen = false;
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent {
  @Input() value: any;
  @Output() valueChanged: EventEmitter<number> = new EventEmitter();
  displayValue: string = '';
  ngOnInit() {
    this.updateDisplayValue();
  }

  update(event: any) {
    this.valueChanged.emit(event.target.value)
  }

  private updateDisplayValue() {
    const d = this.value as Date;
    if(d!==undefined)
      this.displayValue = d.getHours().toString().padStart(2, '0') + ':' + d.getMinutes().toString().padStart(2, '0');
  }
}

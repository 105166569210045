import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PostService } from '../repositories/post.service';
@Injectable({
  providedIn: 'root',
})
export class UiPermissionsService {
  private apiUrl = environment.apiUrl;

  public uiMapSubject!: BehaviorSubject<any | null>;
  constructor(private http: HttpClient, private postService: PostService) {}
  getPermissions(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/ui-permissions`);
  }

  updatePermissions(permissions: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/ui-permissions`, permissions);
  }

  public UserActionsOnCurrentGroup: any[] = [];

  isActionsOnUserAccreditations(
    UIMap: any,
    userAccreditations: any,
    isAdmin: boolean
  ): boolean {
    if (isAdmin) return true;

    this.UserActionsOnCurrentGroup = [];
    const userGroupAccessMap: any = [];
    let groupsScope: any = [];
    // console.log(
    //   '[ui permission service] userAccreditation ',
    //   userAccreditations
    // );
    userAccreditations.forEach((userAccreditation: any) => {
      if (userAccreditation.group.children.length > 0) {
        groupsScope = this.groupNamesInTree(userAccreditation.group.children);
        groupsScope.push(userAccreditation.group.name);
      }

      const userActions = userAccreditation.profile.permissions.map(
        (permission: any) => permission.action
      );
      groupsScope.forEach((group: any) => {
        userGroupAccessMap.push({
          uiPermissionName: UIMap.name,
          group: group,
          hasPermission: UIMap.actions.every((item: any) =>
            userActions.includes(item)
          ),
        });
      });
    });

    let result = false;
    for (let accreditation of userAccreditations) {
      //get all group name in entire group tree
      let groupNamesTree = this.groupNamesInTree(accreditation.group);

      if (groupsScope.every((item: any) => groupNamesTree.includes(item))) {
        //TODO :: (when we add custom permissions for the user) check if user has overriden permission on the current selected group
        for (let p of accreditation.profile.permissions) {
          this.UserActionsOnCurrentGroup.push(p.action);
        }

        result = UIMap.actions.every((action: any) =>
          this.UserActionsOnCurrentGroup.includes(action)
        );
      }
    }
    return result;
  }

  getGroup() {
    return this.postService.currentGroupSource.getValue().name;
  }

  groupNamesInTree(tree: any): string[] {
    //tree can be either one object or array of objects so we make sure it is one case any time
    let treeArray = !Array.isArray(tree) ? [tree] : tree;

    let results: string[] = [];
    for (let tree of treeArray) {
      for (let prop in tree) {
        if (prop == 'name') {
          results.push(tree[prop]);
        }
        if (prop == 'children') {
          results = [...results, ...this.groupNamesInTree(tree[prop])];
        }
      }
    }
    return Array.from(new Set(results));
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-media-preview',
  templateUrl: './media-preview.component.html',
  styleUrls: ['./media-preview.component.scss']
})
export class MediaPreviewComponent {

  @Input() slides:any[] = [];
  @Input() isVisible:boolean = false;
  @Input() startingIndex:number = 0;
  @Output() closed = new EventEmitter();
  close(){
    this.isVisible=false;
    this.closed.emit(this.isVisible);
  }
  checkIfBlankClicked(event:any){
    if(event.target.id=="media-preview"){
      this.close()
    }
  }
}
<h1 class="p-2 text-cliking_blue">{{"socialMedia.socialMediaConection"|translate}}</h1>
  
<div class="flex justify-evenly py-3">
	<!-- LinkedIn Button Container -->
	<div class="flex flex-col items-center justify-center mb-4">
		<!-- Logo and Name Div -->
		<div [ngClass]="{'w-48': isMobileView, 'w-56': !isMobileView}" class="flex items-center justify-center bg-linkedin text-white font-bold py-4 px-4  rounded-t-lg h-20 flex-shrink-0">
			<img src="assets/img/linkedin-logo.png" alt="LinkedIn Logo" class="object-contain max-w-full max-h-full">
		</div>
		<!-- Connection Button Div -->
		<div [ngClass]="{'w-48': isMobileView, 'w-56': !isMobileView}" class="flex items-center justify-center bg-white border border-t-0 rounded-b-lg h-14">
			<button (click)="linkedInConnect()" class="w-42 hover:bg-gradient-to-r from-linkedin to-linkedin btn-blue">{{"socialMedia.connectAPage"|translate}}</button>
		</div>
	</div>

	<!-- Google Button Container -->
	<div class="flex flex-col items-center justify-center mb-4">
		<!-- Logo and Name Div -->
		<div [ngClass]="{'w-48': isMobileView, 'w-56': !isMobileView}" class="flex items-center justify-center bg-orange-600 text-white font-bold py-4 px-4 rounded-t-lg h-20 flex-shrink-0">
			<img src="assets/img/google-logo.png" alt="Google Logo" class="object-contain max-w-full max-h-full">
		</div>
		<!-- Connection Button Div -->
		<div [ngClass]="{'w-48': isMobileView, 'w-56': !isMobileView}" class="flex items-center justify-center bg-white border border-t-0 rounded-b-lg h-14">
			<button (click)="googleConnect()" class="w-42 hover:bg-gradient-to-r from-orange-600 to-orange-600 btn-blue">{{"socialMedia.connectAPage"|translate}}</button>
		</div>
	</div>

	<!-- Facebook Button Container -->
	<div class="flex flex-col items-center justify-center mb-4">
		<!-- Logo and Name Div -->
		<div [ngClass]="{'w-48': isMobileView, 'w-56': !isMobileView}" class="flex items-center justify-center bg-facebook text-white font-bold py-4 px-4 rounded-t-lg h-20 flex-shrink-0">
			<img src="assets/img/facebook-logo.png" alt="Facebook Logo" class="object-contain max-w-full max-h-full">
		</div>
		<!-- Connection Button Div -->
		<div [ngClass]="{'w-48': isMobileView, 'w-56': !isMobileView}" class="flex items-center justify-center bg-white border border-t-0 rounded-b-lg h-14">
			<button (click)="facebookConnect()" class="w-42 hover:bg-gradient-to-r from-facebook to-facebook btn-blue">{{"socialMedia.connectAPage"|translate}}</button>

		</div>
	</div>

	<!-- Instagram Button Container -->
	<div class="flex flex-col items-center justify-center mb-4">
		<!-- Logo and Name Div -->
		<div [ngClass]="{'w-48': isMobileView, 'w-56': !isMobileView}" class="flex items-center justify-center bg-gradient-to-r from-pink-500 to-yellow-500 text-white font-bold py-4 px-4 rounded-t-lg h-20 flex-shrink-0">
			<img src="assets/img/instagram-logo.png" alt="Instagram Logo" class="object-contain max-w-full max-h-full">
		</div>
		<!-- Connection Button Div -->
		<div [ngClass]="{'w-48': isMobileView, 'w-56': !isMobileView}" class="flex items-center justify-center bg-white border border-t-0 rounded-b-lg h-14">
			<button (click)="instagramConnect()" class="w-42 hover:bg-gradient-to-r from-pink-500 to-yellow-500 btn-blue">{{"socialMedia.connectAPage"|translate}}</button>
		</div>
	</div>
</div>